<template>
  <div>
    {{ /*enter new trade modal*/ }}
    <b-modal
      id="tradeModal"
      v-model="enterNewTradeModal"
      :title="$t('fx_spot_forward_swap.titles.enter_new_trade')"
      @hidden="resetTradeModal"
      size="xl"
      :ok-title="$t('fx_spot_forward_swap.modals.create_trade_modal_ok')"
      :cancel-title="$t('fx_spot_forward_swap.modals.modal_cancel')"
      @ok="tradeOkOperation"
      :body-bg-variant="'light'"
      :header-bg-variant="'dark'"
      class="tradeModal"
      body-class="compact_form_modal"
      footer-class="compact_modal_footer"
      no-close-on-backdrop
    >
      <b-overlay :show="tradeModalLoading" rounded="sm" no-fade>
        <b-container>
          <validation-observer ref="tradeValidation">
            <b-form>
              <b-row>
                <!-- /////**************Order Details && Client & Counterparty Details && Trade Details ***************************************************************** */ -->

                <b-col
                  cols="12"
                  sm="12"
                  md="12"
                  lg="6"
                  xl="6"
                  class="pl-0"
                  style="padding-right:0.2rem!important"
                >
                  <!-- /*******************************Order Details***************************************************************** */ -->
                  <b-card
                    :title="$t('fx_spot_forward_swap.titles.order_details')"
                  >
                    <b-row>
                      <!-- order date-->
                      <b-col cols="6" xl="2" lg="2" md="3" sm="6">
                        <b-form-group
                          :label="
                            $t('fx_spot_forward_swap.trade_form.order_date')
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t('fx_spot_forward_swap.trade_form.order_date')
                            "
                            rules="required"
                          >
                            <flat-pickr
                              :placeholder="
                                $t('fx_spot_forward_swap.trade_form.order_date')
                              "
                              v-model="tradeForm.orderDate"
                              onClose="testClose"
                              class="form-control"
                              :config="orderDateConfig"
                              :state="errors.length > 0 ? false : null"
                              @on-change="checkOrdersDate('Order Date', true)"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- order time-->
                      <b-col cols="6" xl="2" lg="2" md="3" sm="6">
                        <b-form-group
                          :label="
                            $t('fx_spot_forward_swap.trade_form.order_time')
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t('fx_spot_forward_swap.trade_form.order_time')
                            "
                            rules="required|min:8"
                          >
                            <cleave
                              id="orderTime"
                              v-model="tradeForm.orderTime"
                              class="form-control"
                              :raw="false"
                              :options="cleaveOptions.time"
                              :placeholder="
                                $t('fx_spot_forward_swap.trade_form.order_time')
                              "
                              :state="errors.length > 0 ? false : null"
                              @blur.native="checkTime('Order Time')"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Order duration date -->
                      <b-col cols="6" xl="2" lg="2" md="3" sm="6">
                        <b-form-group
                          :label="
                            $t(
                              'fx_spot_forward_swap.trade_form.order_duration_date'
                            )
                          "
                          v-if="orderDurationInputsVisible"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t(
                                'fx_spot_forward_swap.trade_form.order_duration_date'
                              )
                            "
                            :rules="
                              tradeForm.timeInForce == 'GTD' ? 'required' : ''
                            "
                          >
                            <flat-pickr
                              :placeholder="
                                $t(
                                  'fx_spot_forward_swap.trade_form.order_duration_date'
                                )
                              "
                              v-model="tradeForm.orderDurationDate"
                              onClose="testClose"
                              class="form-control"
                              :config="orderDurationDateConfig"
                              :state="errors.length > 0 ? false : null"
                              :disabled="orderDurationDataDisabled"
                              @on-change="
                                checkOrdersDate('Order Duration Date', true)
                              "
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Order duration time -->
                      <b-col cols="6" xl="2" lg="2" md="3" sm="6">
                        <b-form-group
                          :label="
                            $t(
                              'fx_spot_forward_swap.trade_form.order_duration_time'
                            )
                          "
                          v-if="orderDurationInputsVisible"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t(
                                'fx_spot_forward_swap.trade_form.order_duration_time'
                              )
                            "
                            :rules="
                              tradeForm.timeInForce == 'GTD'
                                ? 'required|min:8'
                                : ''
                            "
                          >
                            <cleave
                              id="orderDurationTime"
                              v-model="tradeForm.orderDurationTime"
                              class="form-control"
                              :raw="false"
                              :options="cleaveOptions.time"
                              :placeholder="
                                $t(
                                  'fx_spot_forward_swap.trade_form.order_duration_time'
                                )
                              "
                              :state="errors.length > 0 ? false : null"
                              @blur.native="
                                checkOrdersTime('Order Duration Time')
                              "
                              :disabled="orderDurationTimeDisabled"
                            />

                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- entry type -->
                      <b-col cols="6" xl="2" lg="2" md="3" sm="6">
                        <b-form-group
                          :label="
                            $t('fx_spot_forward_swap.trade_form.entry_type')
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t('fx_spot_forward_swap.trade_form.entry_type')
                            "
                            rules="required"
                          >
                            <v-select
                              v-model="tradeForm.entryType"
                              :options="optEntryTypes"
                              :clearable="false"
                              :placeholder="
                                $t(
                                  'fx_spot_forward_swap.trade_form.select_entry_type'
                                )
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                              @option:selected="changeEntryType($event)"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- buy/sell -->
                      <b-col cols="6" xl="2" lg="2" md="3" sm="6">
                        <b-form-group
                          :label="
                            $t('fx_spot_forward_swap.trade_form.buy_sell')
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t('fx_spot_forward_swap.trade_form.buy_sell')
                            "
                            rules="required"
                          >
                            <v-select
                              v-model="tradeForm.buySell"
                              :options="optbuySells"
                              :clearable="false"
                              :placeholder="
                                $t(
                                  'fx_spot_forward_swap.trade_form.select_buy_sell'
                                )
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                              @option:selected="
                                changedBuySell(tradeForm.buySell)
                              "
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- order type-->
                      <b-col cols="6" xl="2" lg="2" md="3" sm="6">
                        <b-form-group
                          :label="
                            $t('fx_spot_forward_swap.trade_form.order_type')
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t('fx_spot_forward_swap.trade_form.order_type')
                            "
                            rules="required"
                          >
                            <v-select
                              v-model="tradeForm.orderType"
                              :options="optOrderTypes"
                              :clearable="false"
                              :placeholder="
                                $t(
                                  'fx_spot_forward_swap.trade_form.select_order_type'
                                )
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                              class="compact_vue-select"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- time in force-->
                      <b-col cols="6" xl="2" lg="2" md="3" sm="6">
                        <b-form-group
                          :label="
                            $t('fx_spot_forward_swap.trade_form.time_in_force')
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t(
                                'fx_spot_forward_swap.trade_form.time_in_force'
                              )
                            "
                            rules="required"
                          >
                            <v-select
                              v-model="tradeForm.timeInForce"
                              :options="optTimeInForces"
                              :clearable="false"
                              :placeholder="
                                $t(
                                  'fx_spot_forward_swap.trade_form.select_time_in_force'
                                )
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                              @option:selected="changeTimeInForceType"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- cpty order date-->
                      <b-col cols="6" xl="2" lg="2" md="3" sm="6">
                        <b-form-group
                          :label="
                            $t(
                              'fx_spot_forward_swap.trade_form.cpty_order_date'
                            )
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t(
                                'fx_spot_forward_swap.trade_form.cpty_order_date'
                              )
                            "
                            rules="required"
                          >
                            <flat-pickr
                              :placeholder="
                                $t(
                                  'fx_spot_forward_swap.trade_form.cpty_order_date'
                                )
                              "
                              v-model="tradeForm.cptyOrderDate"
                              onClose="testClose"
                              class="form-control"
                              :config="cptyOrderDateConfig"
                              :state="errors.length > 0 ? false : null"
                              @on-change="
                                checkTraSettlCptyDate('Cpty Order Date', true)
                              "
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- cpty order time-->
                      <b-col cols="6" xl="2" lg="2" md="3" sm="6">
                        <b-form-group
                          :label="
                            $t(
                              'fx_spot_forward_swap.trade_form.cpty_order_time'
                            )
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t(
                                'fx_spot_forward_swap.trade_form.cpty_order_time'
                              )
                            "
                            rules="required|min:8"
                          >
                            <cleave
                              id="orderTime"
                              v-model="tradeForm.cptyOrderTime"
                              class="form-control"
                              :raw="false"
                              :options="cleaveOptions.time"
                              :placeholder="
                                $t(
                                  'fx_spot_forward_swap.trade_form.cpty_order_time'
                                )
                              "
                              :state="errors.length > 0 ? false : null"
                              @blur.native="checkTime('Execution Time')"
                            />

                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Stop Price -->
                      <b-col
                        cols="6"
                        xl="2"
                        lg="2"
                        md="3"
                        sm="6"
                        v-if="
                          tradeForm.orderType == 'Stop' ||
                            tradeForm.orderType == 'Stop Limit' ||
                            tradeForm.orderType == 'Trailing Stop'
                        "
                      >
                        <b-form-group
                          :label="
                            $t('fx_spot_forward_swap.trade_form.stop_price')
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t('fx_spot_forward_swap.trade_form.stop_price')
                            "
                            :rules="
                              tradeForm.orderType == 'Stop'
                                ? 'required|min_value:0'
                                : '' || tradeForm.orderType == 'Trailing Stop'
                                ? 'required|min_value:0'
                                : ''
                            "
                          >
                            <cleave
                              id="stopPrice"
                              :state="errors.length > 0 ? false : null"
                              v-model="tradeForm.stopPrice"
                              class="form-control"
                              :raw="true"
                              :options="cleaveOptions.number"
                              :placeholder="
                                $t('fx_spot_forward_swap.trade_form.stop_price')
                              "
                              @blur.native="changeStopPrice()"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Trailing Amount -->
                      <b-col
                        cols="6"
                        xl="2"
                        lg="2"
                        md="3"
                        sm="6"
                        v-if="tradeForm.orderType == 'Trailing Stop'"
                      >
                        <b-form-group
                          :label="
                            $t(
                              'fx_spot_forward_swap.trade_form.trailing_amount'
                            )
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t(
                                'fx_spot_forward_swap.trade_form.trailing_amount'
                              )
                            "
                            :rules="
                              tradeForm.orderType == 'Trailing Stop'
                                ? 'required|min_value:0'
                                : ''
                            "
                          >
                            <cleave
                              id="trailingAmount"
                              :state="errors.length > 0 ? false : null"
                              v-model="tradeForm.trailingAmount"
                              class="form-control"
                              :raw="true"
                              :options="cleaveOptions.number"
                              :placeholder="
                                $t('fx_spot_forward_swap.trade_form.stop_price')
                              "
                              @blur.native="changeTrailingAmount()"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>

                    <!-- OCO orders inputs-->
                    <!-- cpty profit limit & cpty stop loss stop & cpty stop loss limit -->
                    <b-row>
                      <!-- enable connected OCO orders-->
                      <b-col
                        cols="12"
                        xl="3"
                        lg="3"
                        md="3"
                        sm="12"
                        class="mt-1"
                      >
                        <b-form-group>
                          <validation-provider
                            #default="{ errors }"
                            name="enable OCO orders"
                          >
                            <b-form-checkbox
                              plain
                              v-model="tradeForm.enableConnectedOCOOrders"
                              :disabled="tradeForm.productType == 'SWAP'"
                              @change="changeEnableOCOOrder()"
                            >
                              {{
                                $t("fx_spot_forward_swap.trade_form.enable_oco")
                              }}
                            </b-form-checkbox>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- cpty Take Profit Limit -->
                      <b-col cols="4" xl="3" lg="3" md="3" sm="4">
                        <b-form-group
                          :label="
                            $t(
                              'fx_spot_forward_swap.trade_form.cpty_take_profit_limit'
                            )
                          "
                          v-if="tradeForm.enableConnectedOCOOrders"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t(
                                'fx_spot_forward_swap.trade_form.cpty_take_profit_limit'
                              )
                            "
                            :rules="
                              tradeForm.enableConnectedOCOOrders
                                ? 'required|min_value:0'
                                : ''
                            "
                          >
                            <cleave
                              id="cptyTakeProfitLimit"
                              :state="errors.length > 0 ? false : null"
                              v-model="tradeForm.cptyTakeProfitLimit"
                              class="form-control"
                              :raw="true"
                              :options="cleave8DecimalOptions.number"
                              :placeholder="
                                $t(
                                  'fx_spot_forward_swap.trade_form.cpty_take_profit_limit'
                                )
                              "
                              @blur.native="
                                checkEnableOCOCptyInputs('takeProfitLimit')
                              "
                            />

                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- cpty Stop Loss Stop -->
                      <b-col cols="4" xl="3" lg="3" md="3" sm="4">
                        <b-form-group
                          :label="
                            $t(
                              'fx_spot_forward_swap.trade_form.cpty_stop_loss_stop'
                            )
                          "
                          v-if="tradeForm.enableConnectedOCOOrders"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t(
                                'fx_spot_forward_swap.trade_form.cpty_stop_loss_stop'
                              )
                            "
                          >
                            <cleave
                              id="cptystopLossStop"
                              :state="errors.length > 0 ? false : null"
                              v-model="tradeForm.cptyStopLossStop"
                              class="form-control"
                              :raw="true"
                              :options="cleave8DecimalOptions.number"
                              :placeholder="
                                $t(
                                  'fx_spot_forward_swap.trade_form.cpty_stop_loss_stop'
                                )
                              "
                              @blur.native="
                                checkEnableOCOCptyInputs('stopLossStop')
                              "
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- cpty Stop Loss Limit -->
                      <b-col cols="4" xl="3" lg="3" md="3" sm="4">
                        <b-form-group
                          :label="
                            $t(
                              'fx_spot_forward_swap.trade_form.cpty_stop_loss_limit'
                            )
                          "
                          v-if="tradeForm.enableConnectedOCOOrders"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t(
                                'fx_spot_forward_swap.trade_form.cpty_stop_loss_limit'
                              )
                            "
                          >
                            <cleave
                              id="cptyStopLossLimit"
                              :state="errors.length > 0 ? false : null"
                              v-model="tradeForm.cptyStopLossLimit"
                              class="form-control"
                              :raw="true"
                              :options="cleave8DecimalOptions.number"
                              :placeholder="
                                $t(
                                  'fx_spot_forward_swap.trade_form.cpty_stop_loss_limit'
                                )
                              "
                              @blur.native="
                                checkEnableOCOCptyInputs('stopLossLimit')
                              "
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>

                    <!-- client profit limit & client stop loss stop & client stop loss limit -->
                    <b-row>
                      <!-- Take Profit Limit -->
                      <b-col offset-xl="3" cols="4" xl="3" lg="3" md="3" sm="4">
                        <b-form-group
                          :label="
                            $t(
                              'fx_spot_forward_swap.trade_form.take_profit_limit'
                            )
                          "
                          v-if="tradeForm.enableConnectedOCOOrders"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t(
                                'fx_spot_forward_swap.trade_form.take_profit_limit'
                              )
                            "
                            :rules="
                              tradeForm.enableConnectedOCOOrders
                                ? 'required|min_value:0'
                                : ''
                            "
                          >
                            <cleave
                              id="takeProfitLimit"
                              :state="errors.length > 0 ? false : null"
                              v-model="tradeForm.takeProfitLimit"
                              class="form-control"
                              :raw="true"
                              :options="cleave8DecimalOptions.number"
                              :placeholder="
                                $t(
                                  'fx_spot_forward_swap.trade_form.take_profit_limit'
                                )
                              "
                              @blur.native="
                                checkEnableOCOInputs('takeProfitLimit')
                              "
                            />

                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Stop Loss Stop -->
                      <b-col cols="4" xl="3" lg="3" md="3" sm="4">
                        <b-form-group
                          :label="
                            $t('fx_spot_forward_swap.trade_form.stop_loss_stop')
                          "
                          v-if="tradeForm.enableConnectedOCOOrders"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t(
                                'fx_spot_forward_swap.trade_form.stop_loss_stop'
                              )
                            "
                          >
                            <cleave
                              id="stopLossStop"
                              :state="errors.length > 0 ? false : null"
                              v-model="tradeForm.stopLossStop"
                              class="form-control"
                              :raw="true"
                              :options="cleave8DecimalOptions.number"
                              :placeholder="
                                $t(
                                  'fx_spot_forward_swap.trade_form.stop_loss_stop'
                                )
                              "
                              @blur.native="
                                checkEnableOCOInputs('stopLossStop')
                              "
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Stop Loss Limit -->
                      <b-col cols="4" xl="3" lg="3" md="3" sm="4">
                        <b-form-group
                          :label="
                            $t(
                              'fx_spot_forward_swap.trade_form.stop_loss_limit'
                            )
                          "
                          v-if="tradeForm.enableConnectedOCOOrders"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t(
                                'fx_spot_forward_swap.trade_form.stop_loss_limit'
                              )
                            "
                          >
                            <cleave
                              id="stopLossLimit"
                              :state="errors.length > 0 ? false : null"
                              v-model="tradeForm.stopLossLimit"
                              class="form-control"
                              :raw="true"
                              :options="cleave8DecimalOptions.number"
                              :placeholder="
                                $t(
                                  'fx_spot_forward_swap.trade_form.stop_loss_limit'
                                )
                              "
                              @blur.native="
                                checkEnableOCOInputs('stopLossLimit')
                              "
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-card>

                  <!-- /******************************* Client & Counterparty Details***************************************************************** */ -->
                  <b-card
                    :title="
                      $t(
                        'fx_spot_forward_swap.titles.client_counterparty_details'
                      )
                    "
                  >
                    <b-row>
                      <!-- client -->
                      <b-col cols="12" xl="3" lg="3" md="3" sm="12">
                        <b-form-group
                          :label="$t('fx_spot_forward_swap.trade_form.client')"
                        >
                          <div class="d-flex align-items-center">
                            <validation-provider
                              #default="{ errors }"
                              :name="
                                $t('fx_spot_forward_swap.trade_form.client')
                              "
                              style="width: 100%;"
                              :rules="
                                tradeForm.entryType == 'Execution'
                                  ? 'required'
                                  : ''
                              "
                            >
                              <v-select
                                v-model="tradeForm.client"
                                :options="optClients"
                                :clearable="false"
                                :placeholder="
                                  $t(
                                    'fx_spot_forward_swap.trade_form.select_client'
                                  )
                                "
                                :reduce="(val) => val"
                                :state="errors.length > 0 ? false : null"
                                :loading="loadingClient"
                                :filterable="true"
                                @option:selecting="selectedClient"
                                class="compact_vue-select"
                              />
                              <!-- @search="
                                (search, loading) => {
                                  loading(false);
                                  searchClient(search);
                                }
                              " -->
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                            <b-dropdown
                              variant="link"
                              no-caret
                              :right="$store.state.appConfig.isRTL"
                              class="input-dropdown animate__animated animate__fadeIn"
                              v-if="this.searchedClients.length > 0"
                            >
                              <template #button-content>
                                <feather-icon
                                  icon="MoreVerticalIcon"
                                  size="16"
                                  class="align-middle text-body"
                                />
                              </template>

                              <b-dropdown-item @click="seeFilteredClientModal">
                                <feather-icon icon="EyeIcon" />
                                <span class="align-middle ml-50">{{
                                  $t("general_text.see_verified_clients")
                                }}</span>
                              </b-dropdown-item>
                            </b-dropdown>
                          </div>
                        </b-form-group>
                      </b-col>
                      <!-- client account -->
                      <b-col cols="12" xl="3" lg="3" md="3" sm="12">
                        <b-form-group
                          :label="
                            $t('fx_spot_forward_swap.trade_form.client_account')
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t(
                                'fx_spot_forward_swap.trade_form.client_account'
                              )
                            "
                            :rules="
                              tradeForm.entryType == 'Execution'
                                ? 'required'
                                : ''
                            "
                          >
                            <b-form-input
                              id="clientAccount"
                              v-model="tradeForm.clientAccount"
                              name="clientAccount"
                              :state="errors.length > 0 ? false : null"
                              :placeholder="
                                $t(
                                  'fx_spot_forward_swap.trade_form.client_account'
                                )
                              "
                              :disabled="true"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- counter party -->
                      <b-col cols="12" xl="3" lg="3" md="3" sm="12">
                        <b-form-group
                          :label="
                            $t('fx_spot_forward_swap.trade_form.counterparty')
                          "
                        >
                          <div class="d-flex align-items-center">
                            <validation-provider
                              #default="{ errors }"
                              style="width: 100%;"
                              :name="
                                $t(
                                  'fx_spot_forward_swap.trade_form.counterparty'
                                )
                              "
                              :rules="
                                tradeForm.entryType == 'Execution'
                                  ? 'required'
                                  : ''
                              "
                            >
                              <v-select
                                v-model="tradeForm.counterparty"
                                :options="optCounterParties"
                                :clearable="false"
                                :placeholder="
                                  $t(
                                    'fx_spot_forward_swap.trade_form.select_counterparty'
                                  )
                                "
                                :reduce="(val) => val"
                                :loading="loadingCounterparty"
                                :filterable="true"
                                @option:selecting="selectedCounterparty"
                                class="compact_vue-select"
                              />
                              <!-- @search="
                                (search, loading) => {
                                  loading(false);
                                  searchCounterparty(search);
                                }
                              " -->
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                            <b-dropdown
                              variant="link"
                              no-caret
                              :right="$store.state.appConfig.isRTL"
                              class="input-dropdown animate__animated animate__fadeIn"
                              v-if="this.searchedCounterparties.length > 0"
                            >
                              <template #button-content>
                                <feather-icon
                                  icon="MoreVerticalIcon"
                                  size="16"
                                  class="align-middle text-body"
                                />
                              </template>

                              <b-dropdown-item
                                @click="seeFilteredCounterpartyModal"
                              >
                                <feather-icon icon="EyeIcon" />
                                <span class="align-middle ml-50">{{
                                  $t("general_text.see_verified_clients")
                                }}</span>
                              </b-dropdown-item>
                            </b-dropdown>
                          </div>
                        </b-form-group>
                      </b-col>
                      <!-- cpty account -->
                      <b-col cols="12" xl="3" lg="3" md="3" sm="12">
                        <b-form-group
                          :label="
                            $t('fx_spot_forward_swap.trade_form.cpty_account')
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t('fx_spot_forward_swap.trade_form.cpty_account')
                            "
                            :rules="
                              tradeForm.entryType == 'Execution'
                                ? 'required'
                                : ''
                            "
                          >
                            <b-form-input
                              id="cptyAccount"
                              v-model="tradeForm.cptyAccount"
                              name="cpty account"
                              :state="errors.length > 0 ? false : null"
                              :placeholder="
                                $t(
                                  'fx_spot_forward_swap.trade_form.cpty_account'
                                )
                              "
                              :disabled="true"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-card>

                  <!-- /******************************* Trade Details***************************************************************** */ -->
                  <b-card
                    :title="$t('fx_spot_forward_swap.titles.trade_details')"
                    style="padding-bottom:0.5rem;"
                  >
                    <b-row>
                      <!-- Trade Date -->
                      <b-col cols="6" xl="4" lg="4" md="5" sm="6">
                        <b-form-group
                          :label="
                            $t('fx_spot_forward_swap.trade_form.trade_date')
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t('fx_spot_forward_swap.trade_form.trade_date')
                            "
                          >
                            <b-input-group
                              :class="
                                tradeForm.tradeDateName == 'Sunday' ||
                                tradeForm.tradeDateName == 'Saturday'
                                  ? 'tradeDateName'
                                  : ''
                              "
                              :append="tradeForm.tradeDateName"
                            >
                              <flat-pickr
                                :placeholder="
                                  $t(
                                    'fx_spot_forward_swap.trade_form.trade_date'
                                  )
                                "
                                v-model="tradeForm.tradeDate"
                                onClose="testClose"
                                class="form-control"
                                :config="tradeDateConfig"
                                @on-change="checkTraSettlCptyDate('Trade Date')"
                              />
                            </b-input-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- T+ -->
                      <b-col
                        cols="6"
                        xl="1"
                        lg="1"
                        md="2"
                        sm="6"
                        style="padding-left: 0.5rem !important; padding-right:0.5rem !important"
                        v-if="tradeForm.entryType == 'Execution'"
                      >
                        <b-form-group label="T+">
                          <validation-provider
                            #default="{ errors }"
                            name="T+"
                            rules="min_value:0"
                          >
                            <b-form-input
                              id="T+"
                              v-model.number="tradeForm.Tplus"
                              name="T+"
                              placeholder="T+"
                              @change="arrangeTPlus"
                              type="number"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Settlement Date -->
                      <b-col
                        cols="6"
                        xl="4"
                        lg="4"
                        md="5"
                        sm="6"
                        v-if="tradeForm.entryType == 'Execution'"
                      >
                        <b-form-group
                          :label="
                            $t(
                              'fx_spot_forward_swap.trade_form.settlement_date'
                            )
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t(
                                'fx_spot_forward_swap.trade_form.settlement_date'
                              )
                            "
                            rules="required"
                          >
                            <b-input-group
                              :class="
                                tradeForm.settlementDateName == 'Sunday' ||
                                tradeForm.settlementDateName == 'Saturday'
                                  ? 'settlementDateName'
                                  : ''
                              "
                              :append="tradeForm.settlementDateName"
                            >
                              <flat-pickr
                                :placeholder="
                                  $t(
                                    'fx_spot_forward_swap.trade_form.settlement_date'
                                  )
                                "
                                v-model="tradeForm.settlementDate"
                                onClose="testClose"
                                class="form-control"
                                :config="dateConfig"
                                @on-change="
                                  checkTraSettlCptyDate('Settlement Date')
                                "
                              />
                            </b-input-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- execution time -->
                      <b-col
                        cols="6"
                        xl="3"
                        lg="3"
                        md="4"
                        sm="6"
                        v-if="tradeForm.entryType == 'Execution'"
                      >
                        <b-form-group
                          :label="
                            $t('fx_spot_forward_swap.trade_form.execution_time')
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t(
                                'fx_spot_forward_swap.trade_form.execution_time'
                              )
                            "
                            rules="required|min:8"
                          >
                            <cleave
                              id="executionTime"
                              v-model="tradeForm.executionTime"
                              class="form-control"
                              :raw="false"
                              :options="cleaveOptions.time"
                              :placeholder="
                                $t(
                                  'fx_spot_forward_swap.trade_form.execution_time'
                                )
                              "
                              :state="errors.length > 0 ? false : null"
                              @blur.native="checkTime('Execution Time')"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- counter party trader -->
                      <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                        <b-form-group
                          :label="
                            $t(
                              'fx_spot_forward_swap.trade_form.counterparty_trader'
                            )
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t(
                                'fx_spot_forward_swap.trade_form.counterparty_trader'
                              )
                            "
                          >
                            <v-select
                              v-model="tradeForm.counterpartyTrader"
                              @option:created="
                                createNewCounterpartyTrader($event)
                              "
                              :options="optCounterPartyTraders"
                              :clearable="false"
                              :placeholder="
                                $t(
                                  'fx_spot_forward_swap.trade_form.select_counterparty_trader'
                                )
                              "
                              :reduce="(val) => val"
                              class="compact_vue-select"
                              taggable
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- sfc user -->
                      <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                        <b-form-group
                          :label="
                            $t('fx_spot_forward_swap.trade_form.sfc_user')
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t('fx_spot_forward_swap.trade_form.sfc_user')
                            "
                            rules="required"
                          >
                            <v-select
                              v-model="tradeForm.scfUser"
                              :options="optScfUsers"
                              :clearable="false"
                              :placeholder="
                                $t(
                                  'fx_spot_forward_swap.trade_form.select_sfc_user'
                                )
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- order taken via -->
                      <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                        <b-form-group
                          :label="
                            $t(
                              'fx_spot_forward_swap.trade_form.order_taken_via'
                            )
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t(
                                'fx_spot_forward_swap.trade_form.order_taken_via'
                              )
                            "
                          >
                            <v-select
                              v-model="tradeForm.orderTakenVia"
                              :options="optOrderTakenVias"
                              @option:created="
                                createNewIntermediary($event, 'TAKEN')
                              "
                              :clearable="false"
                              :placeholder="
                                $t(
                                  'fx_spot_forward_swap.trade_form.select_order_taken_via'
                                )
                              "
                              :reduce="(val) => val"
                              taggable
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- order given through -->
                      <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                        <b-form-group
                          :label="
                            $t(
                              'fx_spot_forward_swap.trade_form.order_given_through'
                            )
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t(
                                'fx_spot_forward_swap.trade_form.order_given_through'
                              )
                            "
                          >
                            <v-select
                              v-model="tradeForm.orderGivenThrough"
                              :options="optOrderGivenThroughs"
                              @option:created="
                                createNewIntermediary($event, 'GIVEN')
                              "
                              :clearable="false"
                              :placeholder="
                                $t(
                                  'fx_spot_forward_swap.trade_form.select_order_given_through'
                                )
                              "
                              :reduce="(val) => val"
                              taggable
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- acting capacity -->
                      <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                        <b-form-group
                          :label="
                            $t(
                              'fx_spot_forward_swap.trade_form.acting_capacity'
                            )
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t(
                                'fx_spot_forward_swap.trade_form.acting_capacity'
                              )
                            "
                            rules="required"
                          >
                            <v-select
                              v-model="tradeForm.actingCapaticy"
                              :options="optActingCapacities"
                              :clearable="false"
                              :placeholder="
                                $t(
                                  'fx_spot_forward_swap.trade_form.select_acting_capacity'
                                )
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- note -->
                      <b-col cols="12" xl="12" lg="12" md="12" sm="12">
                        <b-form-group>
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('equity_bond.trade_form.note')"
                          >
                            <b-form-textarea
                              id="form-textarea"
                              :placeholder="$t('equity_bond.trade_form.note')"
                              no-resize
                              v-model="tradeForm.note"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-card>
                </b-col>

                <!-- ///////**************FX Details && Settlement Details ***************************************************************** */ -->

                <b-col
                  cols="12"
                  sm="12"
                  md="12"
                  lg="6"
                  xl="6"
                  class="pr-0"
                  style="padding-left:0.2rem!important"
                >
                  <!-- /******************************* FX Details***************************************************************** */ -->
                  <b-card
                    :title="$t('fx_spot_forward_swap.titles.fx_details')"
                    style="padding-bottom:1rem !important;"
                  >
                    <b-row>
                      <!-- product type-->
                      <b-col cols="6" xl="3" lg="3" md="3" sm="6">
                        <b-form-group
                          :label="
                            $t('fx_spot_forward_swap.trade_form.product_type')
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t('fx_spot_forward_swap.trade_form.product_type')
                            "
                            rules="required"
                          >
                            <v-select
                              v-model="tradeForm.productType"
                              :options="optproductTypes"
                              :clearable="false"
                              :placeholder="
                                $t(
                                  'fx_spot_forward_swap.trade_form.select_product_type'
                                )
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                              @option:selected="
                                selectedProductType(tradeForm.productType)
                              "
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- settlement type-->
                      <b-col cols="6" xl="3" lg="3" md="3" sm="6">
                        <b-form-group
                          :label="
                            $t(
                              'fx_spot_forward_swap.trade_form.settlement_type'
                            )
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t(
                                'fx_spot_forward_swap.trade_form.settlement_type'
                              )
                            "
                            rules="required"
                          >
                            <v-select
                              v-model="tradeForm.settlementType"
                              :options="optSettlementTypes"
                              :clearable="false"
                              :placeholder="
                                $t(
                                  'fx_spot_forward_swap.trade_form.select_settlement_type'
                                )
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                              @option:selected="
                                selectedSettlementType(tradeForm.settlementType)
                              "
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- cost center -->
                      <b-col cols="6" xl="3" lg="3" md="3" sm="6">
                        <b-form-group
                          :label="$t('equity_bond.trade_form.cost_center')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('equity_bond.trade_form.cost_center')"
                            rules="required"
                          >
                            <v-select
                              v-model="tradeForm.costCenter"
                              :options="optCostCenters"
                              :clearable="false"
                              :placeholder="
                                $t('equity_bond.trade_form.select_cost_center')
                              "
                              :reduce="(val) => val.CostCenter"
                              label="CostCenter"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- cpty Rate -->
                      <b-col cols="6" xl="3" lg="3" md="3" sm="6">
                        <b-form-group
                          :label="
                            $t(
                              'fx_spot_forward_swap.trade_form.counterparty_rate'
                            )
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t(
                                'fx_spot_forward_swap.trade_form.counterparty_rate'
                              )
                            "
                            :rules="
                              tradeForm.entryType == 'Execution'
                                ? 'required|min_value:0'
                                : ''
                            "
                          >
                            <b-input-group>
                              <!-- <b-input-group-prepend is-text>
                                <b-form-checkbox
                                  plain
                                  name="checkbox-input"
                                  v-model="tradeForm.cptyCalculateFromRate1"
                                  v-b-tooltip.hover.top
                                  :title="
                                    $t(
                                      'fx_spot_forward_swap.trade_form.counterparty_rate_tooltip'
                                    )
                                  "
                                  @change="changedCptyCalculateFromRate1"
                                />
                              </b-input-group-prepend> -->

                              <cleave
                                id="counterpartyRate"
                                :state="errors.length > 0 ? false : null"
                                v-model="tradeForm.counterpartyRate"
                                class="form-control"
                                :raw="true"
                                :options="cleave8DecimalOptions.number"
                                :placeholder="
                                  $t(
                                    'fx_spot_forward_swap.trade_form.counterparty_rate'
                                  )
                                "
                                @blur.native="
                                  changedCounterpartyRate(
                                    tradeForm.counterpartyRate
                                  )
                                "
                                :disabled="!tradeForm.cptyCalculateFromRate1"
                              />
                            </b-input-group>

                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- <b-col cols="12" xl="12" lg="12" md="12" sm="12">
                    
                      <h6 style="text-decoration:underline;text-align:center;">
                        {{ $t("fx_spot_forward_swap.trade_form.sfc") }}</h6>
                    </b-col> -->
                      <!-- ------ SFC ------------ -->
                      <!-- cptyAmount1 -->
                      <b-col cols="6" xl="6" lg="6" md="4" sm="6">
                        <b-form-group
                          :label="
                            $t('fx_spot_forward_swap.trade_form.cpty_amount1')
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t('fx_spot_forward_swap.trade_form.cpty_amount1')
                            "
                            :rules="
                              tradeForm.entryType == 'Execution'
                                ? 'required|min_value:0'
                                : ''
                            "
                          >
                            <cleave
                              id="cpty_amount1"
                              :state="errors.length > 0 ? false : null"
                              v-model="tradeForm.cptyAmount1"
                              class="form-control"
                              :raw="true"
                              :options="cleaveOptions.number"
                              :placeholder="
                                $t(
                                  'fx_spot_forward_swap.trade_form.cpty_amount1'
                                )
                              "
                              @blur.native="
                                calculateAsCptyAmount1(tradeForm.cptyAmount1)
                              "
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- cptyCcy1 -->
                      <b-col cols="6" xl="3" lg="3" md="4" sm="6">
                        <b-form-group
                          :label="$t('fx_spot_forward_swap.trade_form.ccy')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_spot_forward_swap.trade_form.ccy')"
                            rules="required"
                          >
                            <v-select
                              v-model="tradeForm.cptyCcy1"
                              :options="optPriceTypes"
                              :clearable="false"
                              :placeholder="
                                $t('fx_spot_forward_swap.trade_form.ccy')
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                              @option:selected="
                                selectedCptyCcy1(tradeForm.productType)
                              "
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- sfcBuySell1 -->
                      <b-col cols="6" xl="3" lg="3" md="4" sm="6">
                        <b-form-group
                          :label="
                            $t('fx_spot_forward_swap.trade_form.sfc_buy_sell1')
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t(
                                'fx_spot_forward_swap.trade_form.sfc_buy_sell1'
                              )
                            "
                            rules="required"
                          >
                            <v-select
                              v-model="tradeForm.cptyBuySell1"
                              :options="optcptyBuySells"
                              :clearable="false"
                              :placeholder="
                                $t(
                                  'fx_spot_forward_swap.trade_form.sfc_buy_sell1'
                                )
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                              :disabled="true"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- ------ SFC 2 ------------ -->
                      <!-- cptyAmount2 -->
                      <b-col cols="6" xl="6" lg="6" md="4" sm="6">
                        <b-form-group
                          :label="
                            $t('fx_spot_forward_swap.trade_form.cpty_amount2')
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t('fx_spot_forward_swap.trade_form.cpty_amount2')
                            "
                            :rules="
                              tradeForm.entryType == 'Execution'
                                ? 'required|min_value:0'
                                : ''
                            "
                          >
                            <cleave
                              id="cpty_amount2"
                              :state="errors.length > 0 ? false : null"
                              v-model="tradeForm.cptyAmount2"
                              class="form-control"
                              :raw="true"
                              :options="cleaveOptions.number"
                              :placeholder="
                                $t(
                                  'fx_spot_forward_swap.trade_form.cpty_amount2'
                                )
                              "
                              @blur.native="
                                calculateAsCptyAmount2(tradeForm.cptyAmount2)
                              "
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- cptyCcy2 -->
                      <b-col cols="6" xl="3" lg="3" md="4" sm="6">
                        <b-form-group
                          :label="$t('fx_spot_forward_swap.trade_form.ccy')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_spot_forward_swap.trade_form.ccy')"
                            rules="required"
                          >
                            <v-select
                              v-model="tradeForm.cptyCcy2"
                              :options="optPriceTypes"
                              :clearable="false"
                              :placeholder="
                                $t('fx_spot_forward_swap.trade_form.ccy')
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                              @option:selected="
                                selectedCptyCcy2(tradeForm.productType)
                              "
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- sfcBuySell2 -->
                      <b-col cols="6" xl="3" lg="3" md="4" sm="6">
                        <b-form-group
                          :label="
                            $t('fx_spot_forward_swap.trade_form.sfc_buy_sell2')
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t(
                                'fx_spot_forward_swap.trade_form.sfc_buy_sell2'
                              )
                            "
                            rules="required"
                          >
                            <v-select
                              v-model="tradeForm.cptyBuySell2"
                              :options="optcptyBuySells"
                              :clearable="false"
                              :placeholder="
                                $t(
                                  'fx_spot_forward_swap.trade_form.sfc_buy_sell2'
                                )
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                              :disabled="true"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- calculate client rate radio buttons -->
                      <b-col cols="6" sm="4" xl="4" lg="4" md="6" class="mt-1">
                        <b-form-group>
                          <b-form-radio-group
                            v-model="tradeForm.calcFromSpread"
                            :options="optCalcFromSpread"
                            name="radios-stacked"
                            stacked
                            plain
                            class="fxDetailRadio"
                            @change="
                              changedCalcFromSpread(tradeForm.calcFromSpread)
                            "
                          />
                        </b-form-group>
                      </b-col>

                      <!-- Spread -->
                      <b-col cols="6" xl="4" lg="4" md="6" sm="6">
                        <b-form-group
                          :label="$t('fx_spot_forward_swap.trade_form.spread')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_spot_forward_swap.trade_form.spread')"
                            :rules="
                              tradeForm.entryType == 'Execution' &&
                              tradeForm.calcFromSpread ==
                                'Calculate Client Rate From Spread'
                                ? 'required'
                                : ''
                            "
                          >
                            <b-input-group append="pips">
                              <cleave
                                id="spread"
                                :state="errors.length > 0 ? false : null"
                                v-model="tradeForm.spread"
                                class="form-control"
                                :raw="true"
                                :options="cleave8DecimalOptions.number"
                                :placeholder="
                                  $t('fx_spot_forward_swap.trade_form.spread')
                                "
                                @blur.native="changedSpread(tradeForm.spread)"
                                :disabled="
                                  tradeForm.calcFromSpread !=
                                    'Calculate Client Rate From Spread'
                                "
                              />
                            </b-input-group>

                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- Client Rate -->
                      <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                        <b-form-group
                          :label="
                            $t('fx_spot_forward_swap.trade_form.client_rate')
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t('fx_spot_forward_swap.trade_form.client_rate')
                            "
                            :rules="
                              tradeForm.entryType == 'Execution' &&
                              tradeForm.calcFromSpread ==
                                'Calculate Spread From Client Rate'
                                ? 'required'
                                : ''
                            "
                          >
                            <b-input-group>
                              <!-- <b-input-group-prepend is-text>
                                <b-form-checkbox
                                  plain
                                  name="checkbox-input"
                                  v-model="tradeForm.clientCalculateFromRate2"
                                  v-b-tooltip.hover.top
                                  :title="
                                    $t(
                                      'fx_spot_forward_swap.trade_form.client_rate_tooltip'
                                    )
                                  "
                                  @change="changedClientCalculateFromRate2"
                                  :disabled="
                                    tradeForm.calcFromSpread ==
                                      'Calculate Client Rate From Spread'
                                  "
                                >
                                </b-form-checkbox>
                              </b-input-group-prepend> -->

                              <cleave
                                id="clientRate"
                                :state="errors.length > 0 ? false : null"
                                v-model="tradeForm.clientRate"
                                class="form-control"
                                :raw="true"
                                :options="cleave8DecimalOptions.number"
                                :placeholder="
                                  $t(
                                    'fx_spot_forward_swap.trade_form.client_rate'
                                  )
                                "
                                @blur.native="
                                  changedClientRate(tradeForm.clientRate)
                                "
                                :disabled="
                                  !tradeForm.clientCalculateFromRate2 ||
                                    tradeForm.calcFromSpread ==
                                      'Calculate Client Rate From Spread'
                                "
                              />
                            </b-input-group>

                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- ------ Client ------------ -->

                      <!-- <b-col cols="12" xl="12" lg="12" md="12" sm="12">
                      <h6 style="text-decoration:underline;text-align:center;">
                      {{ $t("fx_spot_forward_swap.trade_form.client2") }}</h6>
                    </b-col> -->

                      <!-- clientAmount1 -->
                      <b-col cols="6" xl="6" lg="6" md="4" sm="6">
                        <b-form-group
                          :label="
                            $t('fx_spot_forward_swap.trade_form.client_amount1')
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t(
                                'fx_spot_forward_swap.trade_form.client_amount1'
                              )
                            "
                            :rules="
                              tradeForm.entryType == 'Execution'
                                ? 'required|min_value:0'
                                : ''
                            "
                          >
                            <cleave
                              id="clientPrice"
                              :state="errors.length > 0 ? false : null"
                              v-model="tradeForm.clientAmount1"
                              class="form-control"
                              :raw="true"
                              :options="cleaveOptions.number"
                              :placeholder="
                                $t(
                                  'fx_spot_forward_swap.trade_form.client_amount1'
                                )
                              "
                              @blur.native="
                                calculateAsClientAmount1(
                                  tradeForm.clientAmount1
                                )
                              "
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- clientCcy1 -->
                      <b-col cols="6" xl="3" lg="3" md="4" sm="6">
                        <b-form-group
                          :label="$t('fx_spot_forward_swap.trade_form.ccy')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_spot_forward_swap.trade_form.ccy')"
                            rules="required"
                          >
                            <v-select
                              v-model="tradeForm.clientCcy1"
                              :options="optPriceTypes"
                              :clearable="false"
                              :placeholder="
                                $t('fx_spot_forward_swap.trade_form.ccy')
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                              :disabled="true"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- clientBuySell1 -->
                      <b-col cols="6" xl="3" lg="3" md="4" sm="6">
                        <b-form-group
                          :label="
                            $t(
                              'fx_spot_forward_swap.trade_form.client_buy_sell1'
                            )
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t(
                                'fx_spot_forward_swap.trade_form.client_buy_sell1'
                              )
                            "
                            rules="required"
                          >
                            <v-select
                              v-model="tradeForm.clientBuySell1"
                              :options="optclientBuySells"
                              :clearable="false"
                              :placeholder="
                                $t(
                                  'fx_spot_forward_swap.trade_form.client_buy_sell1'
                                )
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                              :disabled="true"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- ------ Client 2 ------------ -->
                      <!-- clientAmount2 -->
                      <b-col cols="6" xl="6" lg="6" md="4" sm="6">
                        <b-form-group
                          :label="
                            $t('fx_spot_forward_swap.trade_form.client_amount2')
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t(
                                'fx_spot_forward_swap.trade_form.client_amount2'
                              )
                            "
                            :rules="
                              tradeForm.entryType == 'Execution'
                                ? 'required|min_value:0'
                                : ''
                            "
                          >
                            <cleave
                              id="clientPrice"
                              :state="errors.length > 0 ? false : null"
                              v-model="tradeForm.clientAmount2"
                              class="form-control"
                              :raw="true"
                              :options="cleaveOptions.number"
                              :placeholder="
                                $t(
                                  'fx_spot_forward_swap.trade_form.client_amount2'
                                )
                              "
                              @blur.native="
                                calculateAsClientAmount2(
                                  tradeForm.clientAmount2
                                )
                              "
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- clientCcy2 -->
                      <b-col cols="6" xl="3" lg="3" md="4" sm="6">
                        <b-form-group
                          :label="$t('fx_spot_forward_swap.trade_form.ccy')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_spot_forward_swap.trade_form.ccy')"
                            rules="required"
                          >
                            <v-select
                              v-model="tradeForm.clientCcy2"
                              :options="optPriceTypes"
                              :clearable="false"
                              :placeholder="
                                $t('fx_spot_forward_swap.trade_form.ccy')
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                              :disabled="true"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- clientBuySell2 -->
                      <b-col cols="6" xl="3" lg="3" md="4" sm="6">
                        <b-form-group
                          :label="
                            $t(
                              'fx_spot_forward_swap.trade_form.client_buy_sell2'
                            )
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t(
                                'fx_spot_forward_swap.trade_form.client_buy_sell2'
                              )
                            "
                            rules="required"
                          >
                            <v-select
                              v-model="tradeForm.clientBuySell2"
                              :options="optclientBuySells"
                              :clearable="false"
                              :placeholder="
                                $t(
                                  'fx_spot_forward_swap.trade_form.client_buy_sell2'
                                )
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                              :disabled="true"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- Spot Ref -->
                      <b-col cols="6" xl="6" lg="6" md="4" sm="6">
                        <b-form-group
                          :label="
                            $t('fx_spot_forward_swap.trade_form.spot_ref')
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="spot_ref"
                            :rules="
                              tradeForm.entryType == 'Execution'
                                ? 'required|min_value:0'
                                : ''
                            "
                          >
                            <cleave
                              id="spot_ref"
                              :state="errors.length > 0 ? false : null"
                              v-model="tradeForm.spotRef"
                              class="form-control"
                              :raw="true"
                              :options="cleave8DecimalOptions.number"
                              :placeholder="
                                $t('fx_spot_forward_swap.trade_form.spot_ref')
                              "
                              @blur.native="changeSpotRef(tradeForm.spotRef)"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- spread comm -->
                      <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                        <b-form-group
                          :label="
                            $t('fx_spot_forward_swap.trade_form.spread_comm')
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t('fx_spot_forward_swap.trade_form.spread_comm')
                            "
                            :rules="
                              tradeForm.entryType == 'Execution'
                                ? 'required'
                                : ''
                            "
                          >
                            <cleave
                              id="spreadAmount"
                              :state="errors.length > 0 ? false : null"
                              v-model="tradeForm.spreadAmount"
                              class="form-control"
                              :raw="true"
                              :options="cleaveOptions.number"
                              :placeholder="
                                $t(
                                  'fx_spot_forward_swap.trade_form.spread_comm'
                                )
                              "
                              @blur.native="
                                changeSpreadAmount(tradeForm.spreadAmount)
                              "
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- spread price types CCY -->
                      <b-col cols="12" xl="2" lg="2" md="4" sm="12">
                        <b-form-group
                          :label="$t('fx_spot_forward_swap.trade_form.ccy')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_spot_forward_swap.trade_form.ccy')"
                            rules="required"
                          >
                            <v-select
                              v-model="tradeForm.spreadCcy"
                              :options="optPriceTypes"
                              :clearable="false"
                              :placeholder="
                                $t('fx_spot_forward_swap.trade_form.ccy')
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                              :disabled="true"
                              @option:selected="
                                selectedSpreadCcy(tradeForm.productType)
                              "
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Comission -->
                      <b-col cols="12" xl="6" lg="6" md="4" sm="12">
                        <b-form-group
                          :label="
                            $t('fx_spot_forward_swap.trade_form.comission')
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t('fx_spot_forward_swap.trade_form.comission')
                            "
                            rules="min_value:0"
                          >
                            <cleave
                              id="clientPrice"
                              :state="errors.length > 0 ? false : null"
                              v-model="tradeForm.comissionAmount"
                              class="form-control"
                              :raw="true"
                              :options="cleaveOptions.number"
                              :placeholder="
                                $t('fx_spot_forward_swap.trade_form.comission')
                              "
                              @blur.native="
                                changeComissionAmount(tradeForm.comissionAmount)
                              "
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- comission CCY -->
                      <b-col cols="12" xl="3" lg="3" md="4" sm="12">
                        <b-form-group
                          :label="
                            $t('fx_spot_forward_swap.trade_form.price_type')
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t('fx_spot_forward_swap.trade_form.price_type')
                            "
                          >
                            <v-select
                              v-model="tradeForm.comissionCcy"
                              :options="optPriceTypes"
                              :clearable="false"
                              :placeholder="
                                $t('fx_spot_forward_swap.trade_form.price_type')
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- cut off time -->
                      <b-col cols="12" xl="3" lg="3" md="4" sm="12">
                        <b-form-group
                          :label="
                            $t('fx_spot_forward_swap.trade_form.cut_off_time')
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t('fx_spot_forward_swap.trade_form.cut_off_time')
                            "
                            :rules="
                              tradeForm.entryType == 'Execution' &&
                              tradeForm.settlementType != 'PHYSICAL'
                                ? 'required'
                                : ''
                            "
                          >
                            <v-select
                              :disabled="
                                tradeForm.settlementType == 'PHYSICAL' &&
                                  tradeForm.productType == 'SPOT'
                              "
                              v-model="tradeForm.cutOffTime"
                              :options="optCutOffTimes"
                              :clearable="false"
                              :placeholder="
                                $t(
                                  'fx_spot_forward_swap.trade_form.cut_off_time'
                                )
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- deliverable ccy-->
                      <!-- <b-col cols="12" xl="2" lg="2" md="4" sm="12">
                        <b-form-group
                          :label="
                            $t(
                              'fx_spot_forward_swap.trade_form.deliverable_ccy'
                            )
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t(
                                'fx_spot_forward_swap.trade_form.deliverable_ccy'
                              )
                            "
                            rules="required"
                          >
                            <v-select
                              :disabled="tradeForm.settlementType == 'PHYSICAL'"
                              v-model="tradeForm.deliverableCcy"
                              :options="optPriceTypes"
                              :clearable="false"
                              :placeholder="
                                $t(
                                  'fx_spot_forward_swap.trade_form.deliverable_ccy'
                                )
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col> -->
                    </b-row>
                  </b-card>
                </b-col>
              </b-row>

              <!-- /******************************* Swap Details***************************************************************** */ -->

              <b-row
                v-if="tradeForm.productType == 'SWAP'"
                class="animate__animated animate__fadeIn"
              >
                <b-card :title="$t('fx_spot_forward_swap.titles.swap_details')">
                  <b-row class="flex align-items-center">
                    <!-- Swap Trade Date -->
                    <b-col cols="6" xl="2" lg="2" md="4" sm="6">
                      <b-form-group
                        :label="
                          $t('fx_spot_forward_swap.trade_form.swap_trade_date')
                        "
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="
                            $t(
                              'fx_spot_forward_swap.trade_form.swap_trade_date'
                            )
                          "
                          :rules="
                            tradeForm.entryType == 'Execution' &&
                            tradeForm.productType == 'SWAP'
                              ? 'required'
                              : ''
                          "
                        >
                          <b-input-group
                            :class="
                              tradeForm.swapTradeDateName == 'Sunday' ||
                              tradeForm.swapTradeDateName == 'Saturday'
                                ? 'swapTradeName'
                                : ''
                            "
                            :append="tradeForm.swapTradeDateName"
                          >
                            <flat-pickr
                              :placeholder="
                                $t(
                                  'fx_spot_forward_swap.trade_form.swap_trade_date'
                                )
                              "
                              v-model="tradeForm.swapTradeDate"
                              onClose="testClose"
                              class="form-control"
                              :config="tradeDateConfig"
                              :disabled="true"
                            />
                          </b-input-group>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <!-- Maturity -->
                    <b-col cols="6" xl="2" lg="2" md="4" sm="6">
                      <b-form-group
                        :label="$t('fx_spot_forward_swap.trade_form.maturity')"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('fx_spot_forward_swap.trade_form.maturity')"
                          :rules="
                            tradeForm.entryType == 'Execution' &&
                            tradeForm.productType == 'SWAP'
                              ? 'required|min_value:0'
                              : ''
                          "
                        >
                          <b-input-group append="Days">
                            <b-form-input
                              id="maturity"
                              v-model.number="tradeForm.maturity"
                              :state="errors.length > 0 ? false : null"
                              type="number"
                              :placeholder="
                                $t('fx_spot_forward_swap.trade_form.maturity')
                              "
                              @change="
                                setSwapTradetoSettleDays(tradeForm.maturity)
                              "
                            />
                          </b-input-group>

                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <!-- Swap settle Date -->
                    <b-col cols="6" xl="2" lg="2" md="4" sm="6">
                      <b-form-group
                        :label="
                          $t('fx_spot_forward_swap.trade_form.swap_settle_date')
                        "
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="
                            $t(
                              'fx_spot_forward_swap.trade_form.swap_settle_date'
                            )
                          "
                          :rules="
                            tradeForm.entryType == 'Execution' &&
                            tradeForm.productType == 'SWAP'
                              ? 'required'
                              : ''
                          "
                        >
                          <b-input-group
                            :class="
                              tradeForm.swapSettleDateName == 'Sunday' ||
                              tradeForm.swapSettleDateName == 'Saturday'
                                ? 'swapSettleName'
                                : ''
                            "
                            :append="tradeForm.swapSettleDateName"
                          >
                            <flat-pickr
                              :placeholder="
                                $t(
                                  'fx_spot_forward_swap.trade_form.swap_settle_date'
                                )
                              "
                              v-model="tradeForm.swapSettleDate"
                              onClose="testClose"
                              class="form-control"
                              :config="dateConfig"
                              @on-change="
                                arrangeSwapTradetoSettleDays('Swap Settle Date')
                              "
                            />
                          </b-input-group>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <!-- Swap Points -->
                    <b-col cols="6" xl="2" lg="2" md="4" sm="6">
                      <b-form-group
                        :label="
                          $t('fx_spot_forward_swap.trade_form.swap_points')
                        "
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="
                            $t('fx_spot_forward_swap.trade_form.swap_points')
                          "
                          :rules="
                            tradeForm.entryType == 'Execution' &&
                            tradeForm.productType == 'SWAP'
                              ? 'required|min_value:0'
                              : ''
                          "
                        >
                          <b-input-group append="pips">
                            <cleave
                              id="swap_points"
                              :state="errors.length > 0 ? false : null"
                              v-model="tradeForm.swapPoints"
                              class="form-control"
                              :raw="true"
                              :options="cleave8DecimalOptions.number"
                              :placeholder="
                                $t(
                                  'fx_spot_forward_swap.trade_form.swap_points'
                                )
                              "
                              @blur.native="
                                changedSwapPoints(tradeForm.swapPoints)
                              "
                            />
                          </b-input-group>

                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <!-- swap cpty rate -->
                    <b-col cols="6" xl="2" lg="2" md="4" sm="6">
                      <b-form-group
                        :label="
                          $t('fx_spot_forward_swap.trade_form.swap_cpty_rate')
                        "
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="
                            $t('fx_spot_forward_swap.trade_form.swap_cpty_rate')
                          "
                          :rules="
                            tradeForm.entryType == 'Execution' &&
                            tradeForm.productType == 'SWAP'
                              ? 'required|min_value:0'
                              : ''
                          "
                        >
                          <cleave
                            id="swap_cpty_rate"
                            :state="errors.length > 0 ? false : null"
                            v-model="tradeForm.swapCptyRate"
                            class="form-control"
                            :raw="true"
                            :options="cleave8DecimalOptions.number"
                            :placeholder="
                              $t(
                                'fx_spot_forward_swap.trade_form.swap_cpty_rate'
                              )
                            "
                            :disabled="true"
                          />

                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <!-- swap calculate client rate radio buttons -->
                    <b-col
                      cols="6"
                      xl="2"
                      lg="2"
                      md="4"
                      sm="6"
                      class="mt-1 padleft-0"
                    >
                      <b-form-group>
                        <b-form-radio-group
                          v-model="tradeForm.swapCalcFromSpread"
                          :options="optCalcFromSpread"
                          name="radios-stacked2"
                          @change="changeSwapCalcFromSpread"
                          plain
                          stacked
                          class="fxDetailRadio"
                        />
                      </b-form-group>
                    </b-col>

                    <!-- <b-col cols="12" xl="12" lg="12" md="12" sm="12">
                      <h6 style="text-decoration:underline;text-align:center;">
                      {{ $t("fx_spot_forward_swap.trade_form.swap_sfc") }}</h6>
                    </b-col> -->

                    <!-- Swap SFC -->
                    <!-- swap cptyAmount1 -->
                    <b-col cols="6" xl="2" lg="2" md="4" sm="6">
                      <b-form-group
                        :label="
                          $t(
                            'fx_spot_forward_swap.trade_form.swap_cpty_amount1'
                          )
                        "
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="
                            $t(
                              'fx_spot_forward_swap.trade_form.swap_cpty_amount1'
                            )
                          "
                          :rules="
                            tradeForm.entryType == 'Execution' &&
                            tradeForm.productType == 'SWAP'
                              ? 'required|min_value:0'
                              : ''
                          "
                        >
                          <cleave
                            id="clientPrice"
                            :state="errors.length > 0 ? false : null"
                            v-model="tradeForm.swapCptyAmount1"
                            class="form-control"
                            :raw="true"
                            :options="cleaveOptions.number"
                            :placeholder="
                              $t(
                                'fx_spot_forward_swap.trade_form.swap_cpty_amount1'
                              )
                            "
                            @blur.native="
                              changedSwapCptyAmount1(tradeForm.swapCptyAmount1)
                            "
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- swapCptyCcy1 -->
                    <b-col cols="6" xl="1" lg="1" md="4" sm="6">
                      <b-form-group
                        :label="$t('fx_spot_forward_swap.trade_form.ccy')"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('fx_spot_forward_swap.trade_form.ccy')"
                          :rules="
                            tradeForm.entryType == 'Execution' &&
                            tradeForm.productType == 'SWAP'
                              ? 'required'
                              : ''
                          "
                        >
                          <v-select
                            v-model="tradeForm.swapCptyCcy1"
                            :options="optPriceTypes"
                            :clearable="false"
                            :placeholder="
                              $t('fx_spot_forward_swap.trade_form.ccy')
                            "
                            :reduce="(val) => val"
                            :state="errors.length > 0 ? false : null"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!--swap  cptyBuySell 1 -->
                    <b-col cols="6" xl="1" lg="1" md="4" sm="6">
                      <b-form-group
                        :label="
                          $t(
                            'fx_spot_forward_swap.trade_form.swap_cpty_buysell1'
                          )
                        "
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="
                            $t(
                              'fx_spot_forward_swap.trade_form.swap_cpty_buysell1'
                            )
                          "
                          :rules="
                            tradeForm.entryType == 'Execution' &&
                            tradeForm.productType == 'SWAP'
                              ? 'required'
                              : ''
                          "
                        >
                          <v-select
                            v-model="tradeForm.swapCptyBuySell1"
                            :options="optcptyBuySells"
                            :clearable="false"
                            :placeholder="
                              $t(
                                'fx_spot_forward_swap.trade_form.swap_cpty_buysell1'
                              )
                            "
                            :reduce="(val) => val"
                            :state="errors.length > 0 ? false : null"
                            :disabled="true"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <!-- swap SFC 2 -->
                    <!-- swap cptyAmount 2 -->
                    <b-col cols="6" xl="2" lg="2" md="4" sm="6">
                      <b-form-group
                        :label="
                          $t(
                            'fx_spot_forward_swap.trade_form.swap_cpty_amount2'
                          )
                        "
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="
                            $t(
                              'fx_spot_forward_swap.trade_form.swap_cpty_amount2'
                            )
                          "
                          :rules="
                            tradeForm.entryType == 'Execution' &&
                            tradeForm.productType == 'SWAP'
                              ? 'required|min_value:0'
                              : ''
                          "
                        >
                          <cleave
                            id="clientPrice"
                            :state="errors.length > 0 ? false : null"
                            v-model="tradeForm.swapCptyAmount2"
                            class="form-control"
                            :raw="true"
                            :options="cleaveOptions.number"
                            :placeholder="
                              $t(
                                'fx_spot_forward_swap.trade_form.swap_cpty_amount2'
                              )
                            "
                            @blur.native="
                              changedSwapCptyAmount2(tradeForm.swapCptyAmount2)
                            "
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- swap cptyPriceType 2 -->
                    <b-col cols="6" xl="1" lg="1" md="4" sm="6">
                      <b-form-group
                        :label="$t('fx_spot_forward_swap.trade_form.ccy')"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('fx_spot_forward_swap.trade_form.ccy')"
                          :rules="
                            tradeForm.entryType == 'Execution' &&
                            tradeForm.productType == 'SWAP'
                              ? 'required'
                              : ''
                          "
                        >
                          <v-select
                            v-model="tradeForm.swapCptyCcyType2"
                            :options="optPriceTypes"
                            :clearable="false"
                            :placeholder="
                              $t('fx_spot_forward_swap.trade_form.ccy')
                            "
                            :reduce="(val) => val"
                            :state="errors.length > 0 ? false : null"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- swap cptyBuySell 2 -->
                    <b-col cols="6" xl="1" lg="1" md="4" sm="6">
                      <b-form-group
                        :label="
                          $t(
                            'fx_spot_forward_swap.trade_form.swap_cpty_buysell2'
                          )
                        "
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="
                            $t(
                              'fx_spot_forward_swap.trade_form.swap_cpty_buysell2'
                            )
                          "
                          :rules="
                            tradeForm.entryType == 'Execution' &&
                            tradeForm.productType == 'SWAP'
                              ? 'required'
                              : ''
                          "
                        >
                          <v-select
                            v-model="tradeForm.swapCptyBuySell2"
                            :options="optcptyBuySells"
                            :clearable="false"
                            :placeholder="
                              $t(
                                'fx_spot_forward_swap.trade_form.swap_cpty_buysell2'
                              )
                            "
                            :reduce="(val) => val"
                            :state="errors.length > 0 ? false : null"
                            :disabled="true"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <!-- Swap Spread -->
                    <b-col cols="6" xl="2" lg="2" md="4" sm="6">
                      <b-form-group
                        :label="
                          $t('fx_spot_forward_swap.trade_form.swap_spread')
                        "
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="
                            $t('fx_spot_forward_swap.trade_form.swap_spread')
                          "
                          :rules="
                            tradeForm.entryType == 'Execution' &&
                            tradeForm.productType == 'SWAP'
                              ? 'required|min_value:0'
                              : ''
                          "
                        >
                          <b-input-group append="pips">
                            <cleave
                              id="swap_spread"
                              :state="errors.length > 0 ? false : null"
                              v-model="tradeForm.swapSpread"
                              class="form-control"
                              :raw="true"
                              :options="cleave8DecimalOptions.number"
                              :placeholder="
                                $t(
                                  'fx_spot_forward_swap.trade_form.swap_spread'
                                )
                              "
                              @blur.native="
                                changedSwapSpread(tradeForm.swapSpread)
                              "
                              :disabled="
                                tradeForm.swapCalcFromSpread ==
                                  'Calculate Spread From Client Rate'
                              "
                            />
                          </b-input-group>

                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <!-- swap Client Rate -->
                    <b-col cols="6" xl="2" lg="2" md="4" sm="6">
                      <b-form-group
                        :label="
                          $t('fx_spot_forward_swap.trade_form.swap_client_rate')
                        "
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="
                            $t(
                              'fx_spot_forward_swap.trade_form.swap_client_rate'
                            )
                          "
                          :rules="
                            tradeForm.entryType == 'Execution' &&
                            tradeForm.productType == 'SWAP'
                              ? 'required|min_value:0'
                              : ''
                          "
                        >
                          <cleave
                            id="clientRate"
                            :state="errors.length > 0 ? false : null"
                            v-model="tradeForm.swapClientRate"
                            class="form-control"
                            :raw="true"
                            :options="cleave8DecimalOptions.number"
                            :placeholder="
                              $t(
                                'fx_spot_forward_swap.trade_form.swap_client_rate'
                              )
                            "
                            :disabled="
                              tradeForm.swapCalcFromSpread ==
                                'Calculate Client Rate From Spread'
                            "
                            @blur.native="
                              changedSwapClientRate(tradeForm.swapSpread)
                            "
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <!-- <b-col cols="12" xl="12" lg="12" md="12" sm="12">
                      <h6 style="text-decoration:underline;text-align:center;">
                      {{ $t("fx_spot_forward_swap.trade_form.swap_client") }}</h6>
                    </b-col> -->

                    <!-- swap Client -->
                    <!-- swap clientAmount1 -->
                    <b-col cols="6" xl="2" lg="2" md="4" sm="6">
                      <b-form-group
                        :label="
                          $t(
                            'fx_spot_forward_swap.trade_form.swap_client_amount1'
                          )
                        "
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="
                            $t(
                              'fx_spot_forward_swap.trade_form.swap_client_amount1'
                            )
                          "
                          :rules="
                            tradeForm.entryType == 'Execution' &&
                            tradeForm.productType == 'SWAP'
                              ? 'required|min_value:0'
                              : ''
                          "
                        >
                          <cleave
                            id="clientPrice"
                            :state="errors.length > 0 ? false : null"
                            v-model="tradeForm.swapClientAmount1"
                            class="form-control"
                            :raw="true"
                            :options="cleaveOptions.number"
                            :placeholder="
                              $t(
                                'fx_spot_forward_swap.trade_form.swap_client_amount1'
                              )
                            "
                            @blur.native="
                              changedSwapClientAmount1(
                                tradeForm.swapClientAmount1
                              )
                            "
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- swap clientPriceType 1 -->
                    <b-col cols="6" xl="1" lg="1" md="4" sm="6">
                      <b-form-group
                        :label="$t('fx_spot_forward_swap.trade_form.ccy')"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('fx_spot_forward_swap.trade_form.ccy')"
                          :rules="
                            tradeForm.entryType == 'Execution' &&
                            tradeForm.productType == 'SWAP'
                              ? 'required'
                              : ''
                          "
                        >
                          <v-select
                            v-model="tradeForm.swapClientCcy1"
                            :options="optPriceTypes"
                            :clearable="false"
                            :placeholder="
                              $t('fx_spot_forward_swap.trade_form.ccy')
                            "
                            :reduce="(val) => val"
                            :state="errors.length > 0 ? false : null"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- swap clientBuySell 1 -->
                    <b-col cols="6" xl="1" lg="1" md="4" sm="6">
                      <b-form-group
                        :label="
                          $t(
                            'fx_spot_forward_swap.trade_form.swap_client_buysell1'
                          )
                        "
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="
                            $t(
                              'fx_spot_forward_swap.trade_form.swap_client_buysell1'
                            )
                          "
                          :rules="
                            tradeForm.entryType == 'Execution' &&
                            tradeForm.productType == 'SWAP'
                              ? 'required'
                              : ''
                          "
                        >
                          <v-select
                            v-model="tradeForm.swapClientBuySell1"
                            :options="optclientBuySells"
                            :clearable="false"
                            :placeholder="
                              $t(
                                'fx_spot_forward_swap.trade_form.swap_client_buysell1'
                              )
                            "
                            :reduce="(val) => val"
                            :state="errors.length > 0 ? false : null"
                            :disabled="true"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <!-- swap Client 2 -->
                    <!-- swap clientAmount 2 -->
                    <b-col cols="6" xl="2" lg="2" md="4" sm="6">
                      <b-form-group
                        :label="
                          $t(
                            'fx_spot_forward_swap.trade_form.swap_client_amount2'
                          )
                        "
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="
                            $t(
                              'fx_spot_forward_swap.trade_form.swap_client_amount2'
                            )
                          "
                          :rules="
                            tradeForm.entryType == 'Execution' &&
                            tradeForm.productType == 'SWAP'
                              ? 'required|min_value:0'
                              : ''
                          "
                        >
                          <cleave
                            id="clientPrice"
                            :state="errors.length > 0 ? false : null"
                            v-model="tradeForm.swapClientAmount2"
                            class="form-control"
                            :raw="true"
                            :options="cleaveOptions.number"
                            :placeholder="
                              $t(
                                'fx_spot_forward_swap.trade_form.swap_client_amount2'
                              )
                            "
                            @blur.native="
                              changedSwapClientAmount2(
                                tradeForm.swapClientAmount2
                              )
                            "
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- swap clientPriceType 2 -->
                    <b-col cols="6" xl="1" lg="1" md="4" sm="6">
                      <b-form-group
                        :label="$t('fx_spot_forward_swap.trade_form.ccy')"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('fx_spot_forward_swap.trade_form.ccy')"
                          :rules="
                            tradeForm.entryType == 'Execution' &&
                            tradeForm.productType == 'SWAP'
                              ? 'required'
                              : ''
                          "
                        >
                          <v-select
                            v-model="tradeForm.swapClientCcy2"
                            :options="optPriceTypes"
                            :clearable="false"
                            :placeholder="
                              $t('fx_spot_forward_swap.trade_form.ccy')
                            "
                            :reduce="(val) => val"
                            :state="errors.length > 0 ? false : null"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- swap clientBuySell 2 -->
                    <b-col cols="6" xl="1" lg="1" md="4" sm="6">
                      <b-form-group
                        :label="
                          $t(
                            'fx_spot_forward_swap.trade_form.swap_client_buysell2'
                          )
                        "
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="
                            $t(
                              'fx_spot_forward_swap.trade_form.swap_client_buysell2'
                            )
                          "
                          :rules="
                            tradeForm.entryType == 'Execution' &&
                            tradeForm.productType == 'SWAP'
                              ? 'required'
                              : ''
                          "
                        >
                          <v-select
                            v-model="tradeForm.swapClientBuySell2"
                            :options="optclientBuySells"
                            :clearable="false"
                            :placeholder="
                              $t(
                                'fx_spot_forward_swap.trade_form.swap_client_buysell2'
                              )
                            "
                            :reduce="(val) => val"
                            :state="errors.length > 0 ? false : null"
                            :disabled="true"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <!--swap Spred amount -->
                    <!-- swap spread amount -->
                    <b-col cols="6" xl="2" lg="2" md="4" sm="6">
                      <b-form-group
                        :label="
                          $t('fx_spot_forward_swap.trade_form.spread_amount')
                        "
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="
                            $t('fx_spot_forward_swap.trade_form.spread_amount')
                          "
                          :rules="
                            tradeForm.entryType == 'Execution' &&
                            tradeForm.productType == 'SWAP'
                              ? 'required'
                              : ''
                          "
                        >
                          <cleave
                            id="clientPrice"
                            :state="errors.length > 0 ? false : null"
                            v-model="tradeForm.swapSpreadAmount"
                            class="form-control"
                            :raw="true"
                            :options="cleaveOptions.number"
                            :placeholder="
                              $t(
                                'fx_spot_forward_swap.trade_form.spread_amount'
                              )
                            "
                            :disabled="true"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!--swap  spread price types CCY -->
                    <b-col cols="6" xl="2" lg="2" md="4" sm="6">
                      <b-form-group
                        :label="
                          $t('fx_spot_forward_swap.trade_form.price_type')
                        "
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="
                            $t('fx_spot_forward_swap.trade_form.price_type')
                          "
                          :rules="
                            tradeForm.entryType == 'Execution' ? 'required' : ''
                          "
                        >
                          <v-select
                            v-model="tradeForm.swapSpreadCcy"
                            :options="optPriceTypes"
                            :clearable="false"
                            :placeholder="
                              $t('fx_spot_forward_swap.trade_form.price_type')
                            "
                            :reduce="(val) => val"
                            :state="errors.length > 0 ? false : null"
                            :disabled="true"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-card>
              </b-row>

              <!-- /******************************* Settlement Details***************************************************************** */ -->

              <b-row class="justify-content-end">
                <b-col
                  cols="12"
                  xl="6"
                  lg="6"
                  md="12"
                  sm="12"
                  class="pl-0 pr-0"
                >
                  <b-card title="Settlement Details">
                    <b-row>
                      <!-- UTI  -->
                      <b-col cols="6" xl="6" lg="6" md="6" sm="6">
                        <b-form-group
                          :label="$t('fx_spot_forward_swap.trade_form.uti')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_spot_forward_swap.trade_form.uti')"
                          >
                            <b-form-input
                              id="UTI"
                              v-model="tradeForm.uti"
                              name="UTI"
                              :state="errors.length > 0 ? false : null"
                              :placeholder="
                                $t('fx_spot_forward_swap.trade_form.uti')
                              "
                              :disabled="true"
                            />

                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Unique Link ID -->
                      <b-col cols="6" xl="6" lg="6" md="6" sm="6">
                        <b-form-group
                          :label="
                            $t('fx_spot_forward_swap.trade_form.unique_link_id')
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t(
                                'fx_spot_forward_swap.trade_form.unique_link_id'
                              )
                            "
                          >
                            <b-form-input
                              id="uniqueLinkID"
                              v-model.number="tradeForm.uniqueLinkID"
                              type="number"
                              name="uniqueLinkID"
                              :state="errors.length > 0 ? false : null"
                              :placeholder="
                                $t(
                                  'fx_spot_forward_swap.trade_form.unique_link_id'
                                )
                              "
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-card>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-container>
        <template #overlay v-if="tradeModalLoading">
          <div
            class="text-center d-flex justify-content-center align-items-start"
          >
            <b-icon icon="stopwatch" font-scale="3" animation="cylon"> </b-icon>
            <p id="cancel-label">
              <feather-icon icon="DownloadIcon" />
              <b>{{
                $t("fx_spot_forward_swap.overlay_messages.creating_message")
              }}</b>
            </p>
          </div>
        </template>
      </b-overlay>
      <template #modal-footer>
        <b-row class="align-items-center" style="flex: 1;">
          <b-col
            cols="12"
            md="12"
            xl="6"
            lg="6"
            sm="12"
            class="d-flex justify-content-start align-items-center"
          >
            <b-overlay
              :show="loadingDefaultEntry"
              rounded="lg"
              opacity="0.6"
              class="d-flex align-items-center"
            >
              <template v-slot:overlay>
                <div class="d-flex align-items-center">
                  <b-spinner small type="grow" variant="secondary" />
                  <b-spinner type="grow" variant="dark" />
                  <b-spinner small type="grow" variant="secondary" />
                  <!-- We add an SR only text for screen readers -->
                  <span class="sr-only">Please wait...</span>
                </div>
              </template>

              <b-form-checkbox
                plain
                v-model="showDefaultEntry"
                class="float-right"
                style="margin-right:0.8rem"
              >
                {{ $t("general_title.default_entry_title") }}
              </b-form-checkbox>
              <validation-observer
                ref="tradeDefaultEntryValidation"
                style="margin-right:0.5rem"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('general_title.default_entry_name')"
                  :rules="showDefaultEntry ? 'required|min:3' : ''"
                >
                  <b-form-input
                    v-if="showDefaultEntry"
                    id="defaultEntryName"
                    v-model="defaultEntryName"
                    name="defaultEntryName"
                    class="float-right animate__animated animate__fadeIn "
                    type="text"
                    :placeholder="$t('general_title.default_entry_name')"
                    autofocus
                    :state="errors.length > 0 ? false : null"
                  />
                </validation-provider>
              </validation-observer>

              <b-button
                @click="saveDefaultEntry"
                v-if="showDefaultEntry"
                variant="gradient-info"
                class="animate__animated animate__fadeIn"
                size="sm"
              >
                {{ $t("general_title.default_entry_title") }}
              </b-button>
            </b-overlay>
          </b-col>

          <!-- trade ok operation buttons -->
          <b-col
            cols="12"
            md="12"
            xl="6"
            lg="6"
            sm="12"
            class="d-flex justify-content-end align-items-center"
          >
            <b-dropdown
              :text="$t('general_title.default_entries')"
              dropup
              right
              class="float-right ml-1 animate__animated animate__fadeIn"
              variant="dark"
              v-if="optDefaultEntries.length > 0"
              size="sm"
            >
              <b-dropdown-item
                v-for="(d, index) in optDefaultEntries"
                :key="index"
              >
                <feather-icon
                  class="mr-1"
                  style="color:red;"
                  @click="deleteSavedDefault(d.id)"
                  icon="XIcon"
                  size="12"
                />
                <span @click="selectDefaultData(d)">
                  {{ d.DefaultEntryName }}
                </span></b-dropdown-item
              >
            </b-dropdown>

            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="secondary"
              size="sm"
              class="ml-1"
              @click="resetTradeModal"
            >
              {{ $t("fx_spot_forward_swap.modals.modal_cancel") }}
            </b-button>

            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              size="sm"
              class="ml-1"
              @click="tradeOkOperation"
            >
              {{ $t("fx_spot_forward_swap.modals.create_trade_modal_ok") }}
            </b-button>
          </b-col>
        </b-row>
      </template>
      {{/****END*** enter new trade modal*/}}
    </b-modal>
    {{/** view filtered client modal*/}}
    <b-modal
      id="clientModal"
      v-model="viewFilteredClientModal"
      :title="$t('client_table_modal.filtered_clients')"
      :ok-title="$t('general_buttons.cancel')"
      @ok="resetClientModal"
      size="lg"
      :body-bg-variant="'light'"
      :header-bg-variant="'light'"
      ok-only
      ok-variant="danger"
    >
      <b-container>
        <b-form>
          <b-row class="mt-0">
            <b-col
              cols="12"
              xl="12"
              md="12"
              sm="12"
              class="bodyRow mt-1"
              style="padding: 0.5rem 0;"
            >
              <b-table
                striped
                hover
                ref="refClientsModal"
                class="position-relative"
                :items="searchedClients"
                selectable
                select-mode="single"
                @row-clicked="selectedClientFromTable"
                responsive
                head-variant="light"
                :fields="clientTableColumns"
                show-empty
                :empty-text="$t('general_messages.no_matching_recors_found')"
              >
                <template #cell(AccountName)="data">
                  <strong>{{ data.item.AccountName }}</strong>
                </template>

                <template #cell(AccountNumber)="data">
                  <strong>{{ data.item.AccountNumber }}</strong>
                </template>

                <template #cell(SettlementType)="data">
                  <strong>{{ data.item.SettlementType }}</strong>
                </template>
              </b-table>
            </b-col>
          </b-row>
        </b-form>
      </b-container>
      {{/****END*** view filtered modal*/}}
    </b-modal>
    {{/** view filtered counterparty modal*/}}
    <b-modal
      id="counterpartyModal"
      v-model="viewFilteredCounterpartyModal"
      :title="$t('client_table_modal.filtered_counterparties')"
      :ok-title="$t('general_buttons.cancel')"
      @ok="resetCounterpartyModal"
      size="lg"
      :body-bg-variant="'light'"
      :header-bg-variant="'light'"
      ok-only
      ok-variant="danger"
    >
      <b-container>
        <b-form>
          <b-row class="mt-0">
            <b-col
              cols="12"
              xl="12"
              md="12"
              sm="12"
              class="bodyRow mt-1"
              style="padding: 0.5rem 0;"
            >
              <b-table
                striped
                hover
                ref="refClientsModal"
                class="position-relative"
                :items="searchedCounterparties"
                selectable
                select-mode="single"
                @row-clicked="selectedCounterpartyFromTable"
                responsive
                head-variant="light"
                :fields="clientTableColumns"
                show-empty
                :empty-text="$t('general_messages.no_matching_recors_found')"
              >
                <template #cell(AccountName)="data">
                  <strong>{{ data.item.AccountName }}</strong>
                </template>

                <template #cell(AccountNumber)="data">
                  <strong>{{ data.item.AccountNumber }}</strong>
                </template>

                <template #cell(SettlementType)="data">
                  <strong>{{ data.item.SettlementType }}</strong>
                </template>
              </b-table>
            </b-col>
          </b-row>
        </b-form>
      </b-container>
      {{/****END*** view filtered counterparty modal*/}}
    </b-modal>
  </div>
</template>

<script>
import {
  BOverlay,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormRating,
  VBModal,
  BTabs,
  BTab,
  BCardText,
  BIcon,
  BPopover,
  BFormTimepicker,
  VBTooltip,
  BTooltip,
} from "bootstrap-vue";
import store from "@/store";
import { onUnmounted } from "@vue/composition-api";
import fXSpotForwardSwapStoreModule from "./FXSpotForwardSwapStoreModule";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  required,
  alphaNum,
  email,
  max,
  max_value,
  min_value,
} from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import axios from "axios";
import axiosIns from "@/libs/axios";
import router from "@/router";
import moment from "moment";
import Cleave from "vue-cleave-component";
import "animate.css";
import _ from "lodash";
import { mapState, mapGetters, mapActions } from "vuex";

export default {
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BTabs,
    BTab,
    BCardText,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormRating,
    BIcon,
    BPopover,
    BFormTimepicker,
    BTooltip,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    ToastificationContent,
    flatPickr,
    Cleave,
  },

  directives: {
    Ripple,
    "b-modal": VBModal,
    "b-tooltip": VBTooltip,
  },

  setup() {
    const FXSPOTFORWARDSWAP_APP_STORE_MODULE_NAME = "fxSFS";
    // Register module
    if (!store.hasModule(FXSPOTFORWARDSWAP_APP_STORE_MODULE_NAME))
      store.registerModule(
        FXSPOTFORWARDSWAP_APP_STORE_MODULE_NAME,
        fXSpotForwardSwapStoreModule
      );

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(FXSPOTFORWARDSWAP_APP_STORE_MODULE_NAME))
        store.unregisterModule(FXSPOTFORWARDSWAP_APP_STORE_MODULE_NAME);
    });
  },

  mounted() {},

  props: {
    searchedCounterparties: {
      type: Array,
      default: function() {
        return [];
      },
    },
    searchedClients: {
      type: Array,
      default: function() {
        return [];
      },
    },
    optCostCenters: {
      type: Array,
      default: function() {
        return [];
      },
    },
    optClients: {
      type: Array,
      default: function() {
        return [];
      },
    },
    optCounterParties: {
      type: Array,
      default: function() {
        return [];
      },
    },
  },
  data() {
    return {
      loadingDefaultEntry: false,
      enterNewTradeModal: false,
      tradeModalLoading: false,
      loadingSecurityDesc: false,
      loadingClient: false,
      loadingCounterparty: false,
      timerSecuritydesc: null,
      orderDurationDataDisabled: true,
      orderDurationTimeDisabled: true,
      orderDurationInputsVisible: true,
      showDefaultEntry: false,
      defaultEntryName: null,
      weekday: [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ],
      optEntryTypes: ["Order", "Execution"],
      optbuySells: ["Buy", "Sell"],
      optOrderTypes: ["Limit", "Market", "Stop Limit", "Stop", "Trailing Stop"],
      optTimeInForces: ["DAY", "GTC", "GTD", "FOK", "FAK"],
      optproductTypes: ["SPOT", "FORWARD", "SWAP"],
      optSettlementTypes: ["PHYSICAL", "CASH"],
      optCalcFromSpread: [
        {
          text: "Calculate Client Rate From Spread",
          value: "Calculate Client Rate From Spread",
        },
        {
          text: "Calculate Spread From Client Rate",
          value: "Calculate Spread From Client Rate",
        },
      ],
      optPriceTypes: [],
      optCutOffTimes: ["10:00 NY", "12:00 LN"],
      optcptyBuySells: [],
      optclientBuySells: [],
      optCounterPartyTraders: [],
      optScfUsers: [],
      optActingCapacities: ["PRINCIPAL", "AGENT", "MATCHED PRINCIPAL"],
      optOrderTakenVias: [],
      optOrderGivenThroughs: [],
      fetchedTradeData: {},
      checkedHolidayDate: null,
      optDefaultEntries: [],
      tradeForm: {
        costCenter: null,
        orderDurationDate: null,
        orderDurationDateName: null,
        orderDurationTime: null,
        entryType: null,
        buySell: null,
        orderType: null,
        timeInForce: null,
        orderDate: null,
        orderDateName: null,
        orderTime: null,
        cptyOrderDate: null,
        cptyOrderDateName: null,
        cptyOrderTime: null,
        enableConnectedOCOOrders: false,
        stopPrice: null,
        trailingAmount: null,
        takeProfitLimit: null,
        stopLossStop: null,
        stopLossLimit: null,
        cptyTakeProfitLimit: null,
        cptyStopLossStop: null,
        cptyStopLossLimit: null,
        client: null,
        clientAccount: null,
        counterparty: null,
        cptyAccount: null,
        productType: null,
        settlementType: null,
        counterpartyRate: null,
        cptyCalculateFromRate1: true,
        clientRate: null,
        clientCalculateFromRate2: true,
        cptyAmount1: null,
        cptyAmount2: null,
        cptyCcy1: null,
        cptyCcy2: null,
        cptyBuySell1: null, // sfcBuySell1
        cptyBuySell2: null, // sfcBuySell2
        calcFromSpread: "Calculate Spread From Client Rate",
        spread: null,
        clientAmount1: null,
        clientAmount2: null,
        clientCcy1: null,
        clientCcy2: null,
        clientBuySell1: null,
        clientBuySell2: null,
        spotRef: null,
        spreadAmount: null,
        spreadCcy: null,
        comissionAmount: null,
        comissionCcy: null,
        cutOffTime: null,
        deliverableCcy: null,
        tradeDate: null,
        tradeDateName: null,
        defTradeDateName: null,
        settlementDate: null,
        settlementDateName: null,
        Tplus: null,
        executionTime: null,
        counterpartyTrader: null,
        scfUser: null,
        orderTakenVia: null,
        orderGivenThrough: null,
        actingCapaticy: null,
        note: null,
        swapTradeDate: null,
        swapTradeDateName: null,
        maturity: null,
        swapSettleDate: null,
        swapSettleDateName: null,
        swapPoints: null,
        swapCptyRate: null,
        swapCptyAmount1: null,
        swapCptyCcy1: null,
        swapCptyBuySell1: null,
        swapCptyAmount2: null,
        swapCptyCcyType2: null,
        swapCptyBuySell2: null,
        swapSpread: null,
        swapCalcFromSpread: "Calculate Client Rate From Spread",
        swapClientRate: null,
        swapClientAmount1: null,
        swapClientCcy1: null,
        swapClientBuySell1: null,
        swapClientAmount2: null,
        swapClientCcy2: null,
        swapClientBuySell2: null,
        swapSpreadAmount: null,
        swapSpreadCcy: null,
        uti: null,
        uniqueLinkID: null,
        tradeRef: null,
        entryType2: null,
        tradeStart: null,
        tradeEnd: null,
        settleStart: null,
        settleEnd: null,
      },
      timeConfig: {
        enableTime: true,
        noCalendar: true,
        dateFormat: "H:i",
        time_24hr: true,
        wrap: true,
        static: true,
      },
      dateConfig: {
        allowInput: true,
        dateFormat: "d-m-Y",
        wrap: true,
        static: true,
      },
      orderDateConfig: {
        wrap: true,
        static: true,
        allowInput: true,
        dateFormat: "d-m-Y",
        plugins: [
          ShortcutButtonsPlugin({
            button: [
              {
                label: "Yesterday",
              },
              {
                label: "Today",
              },
              {
                label: "Tomorrow",
              },
            ],
            label: "or",
            onClick: (index, fp) => {
              let date;
              switch (index) {
                case 0:
                  date = new Date(Date.now() - 24 * 60 * 60 * 1000);
                  this.tradeForm.orderDateName = "Yesterday";
                  break;
                case 1:
                  date = new Date();
                  this.tradeForm.orderDateName = "Today";
                  break;
                case 2:
                  date = new Date(Date.now() + 24 * 60 * 60 * 1000);
                  this.tradeForm.orderDateName = "Tomorrow";
                  break;
              }
              fp.setDate(date);
            },
          }),
        ],
      },
      orderDurationDateConfig: {
        wrap: true,
        static: true,
        allowInput: true,
        dateFormat: "d-m-Y",
        plugins: [
          ShortcutButtonsPlugin({
            button: [
              {
                label: "Yesterday",
              },
              {
                label: "Today",
              },
              {
                label: "Tomorrow",
              },
            ],
            label: "or",
            onClick: (index, fp) => {
              let date;
              switch (index) {
                case 0:
                  date = new Date(Date.now() - 24 * 60 * 60 * 1000);
                  this.tradeForm.orderDurationDateName = "Yesterday";
                  break;
                case 1:
                  date = new Date();
                  this.tradeForm.orderDurationDateName = "Today";
                  break;
                case 2:
                  date = new Date(Date.now() + 24 * 60 * 60 * 1000);
                  this.tradeForm.orderDurationDateName = "Tomorrow";
                  break;
              }
              fp.setDate(date);
            },
          }),
        ],
      },
      cptyOrderDateConfig: {
        wrap: true,
        static: true,
        allowInput: true,
        dateFormat: "d-m-Y",
        plugins: [
          ShortcutButtonsPlugin({
            button: [
              {
                label: "Yesterday",
              },
              {
                label: "Today",
              },
              {
                label: "Tomorrow",
              },
            ],
            label: "or",
            onClick: (index, fp) => {
              let date;
              switch (index) {
                case 0:
                  date = new Date(Date.now() - 24 * 60 * 60 * 1000);
                  this.tradeForm.cptyOrderDateName = "Yesterday";
                  break;
                case 1:
                  date = new Date();
                  this.tradeForm.cptyOrderDateName = "Today";
                  break;
                case 2:
                  date = new Date(Date.now() + 24 * 60 * 60 * 1000);
                  this.tradeForm.cptyOrderDateName = "Tomorrow";
                  break;
              }
              fp.setDate(date);
            },
          }),
        ],
      },
      tradeDateConfig: {
        wrap: true,
        static: true,
        allowInput: true,
        dateFormat: "d-m-Y",
        plugins: [
          ShortcutButtonsPlugin({
            button: [
              {
                label: "Yesterday",
              },
              {
                label: "Today",
              },
              {
                label: "Tomorrow",
              },
            ],
            label: "or",
            onClick: (index, fp) => {
              let date;
              switch (index) {
                case 0:
                  date = new Date(Date.now() - 24 * 60 * 60 * 1000);
                  this.tradeForm.defTradeDateName = "Yesterday";
                  break;
                case 1:
                  date = new Date();
                  this.tradeForm.defTradeDateName = "Today";
                  break;
                case 2:
                  date = new Date(Date.now() + 24 * 60 * 60 * 1000);
                  this.tradeForm.defTradeDateName = "Tomorrow";
                  break;
              }

              fp.setDate(date);
            },
          }),
        ],
      },
      cleaveOptions: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: "thousand",
          numeralDecimalScale: 2,
          numeralDecimalMarkAlternativeInput: ".",
          completeDecimalsOnBlur: true,
        },
        time: {
          time: true,
          timePattern: ["h", "m", "s"],
        },
      },
      cleave4DecimalOptions: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: "thousand",
          numeralDecimalScale: 8,
          numeralDecimalMarkAlternativeInput: ".",
          completeDecimalsOnBlur: true,
        },
      },
      cleave8DecimalOptions: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: "thousand",
          numeralDecimalScale: 8,
          numeralDecimalMarkAlternativeInput: ".",
          completeDecimalsOnBlur: true,
        },
      },
      viewFilteredClientModal: false,
      viewFilteredCounterpartyModal: false,
      clientTableColumns: [
        {
          key: "AccountName",
          label: this.$t("client_table_modal.account_name"),
          class: "text-center",
        },
        {
          key: "AccountNumber",
          label: this.$t("client_table_modal.account_code"),
          sortable: false,
          class: "text-center",
        },
        {
          key: "SettlementType",
          label: this.$t("client_table_modal.settlement_type"),
          sortable: false,
          class: "text-center",
        },
      ],
    };
  },
  computed: {
    user: {
      get() {
        return this.$store.getters["authUser"];
      },
      set(newValue) {
        return this.$store.dispatch("setUser", newValue);
      },
    },
  },
  methods: {
    ...mapActions(["saveLastSearchFxForwardSwap"]),
    saveDefaultEntry() {
      this.$refs.tradeDefaultEntryValidation.validate().then((success) => {
        if (success) {
          // set will be save default entry data
          if (this.showDefaultEntry) {
            this.loadingDefaultEntry = true;

            var defaultEntryData = {
              DefaultEntryName: this.defaultEntryName,
              DefaultEntryData: this.tradeForm,
              DefaultType: "FxExecuted",
              UserId: this.user.id,
            };

            store
              .dispatch("fxSFS/saveDefaultEntry", {
                defaultEntryData: this.showDefaultEntry
                  ? defaultEntryData
                  : null,
              })
              .then((res) => {
                if (res.data.info == "success") {
                  this.defaultEntryName = null;
                  this.saveDefaultEntrySuccessToastMessage();
                  this.getSavedDefaultEntries();
                  this.loadingDefaultEntry = false;
                } else if (res.data.info == "existing") {
                  this.loadingDefaultEntry = false;
                  this.errorMessage(res.data.message);
                } else {
                  this.loadingDefaultEntry = false;
                  this.errorMessage(res.data.message);
                }
              })
              .catch((error) => {
                console.log(error);
              });
          }
        }
      });
    },
    selectDefaultData(data) {
      if (data.DefaultEntryData) {
        this.tradeForm = data.DefaultEntryData;
        this.changeTimeInForceType();
        if (
          data.DefaultEntryData.orderDateName &&
          data.DefaultEntryData.orderDateName != null
        ) {
          if (data.DefaultEntryData.orderDateName == "Yesterday") {
            this.tradeForm.orderDate = this.dateFormat(
              new Date(Date.now() - 24 * 60 * 60 * 1000)
            );
          } else if (data.DefaultEntryData.orderDateName == "Tomorrow") {
            this.tradeForm.orderDate = this.dateFormat(
              new Date(Date.now() + 24 * 60 * 60 * 1000)
            );
          } else if (data.DefaultEntryData.orderDateName == "Today") {
            this.tradeForm.orderDate = this.dateFormat(Date.now());
          }
        }

        if (
          data.DefaultEntryData.orderDurationDateName &&
          data.DefaultEntryData.orderDurationDateName != null
        ) {
          if (data.DefaultEntryData.orderDurationDateName == "Yesterday") {
            this.tradeForm.orderDurationDate = this.dateFormat(
              new Date(Date.now() - 24 * 60 * 60 * 1000)
            );
          } else if (
            data.DefaultEntryData.orderDurationDateName == "Tomorrow"
          ) {
            this.tradeForm.orderDurationDate = this.dateFormat(
              new Date(Date.now() + 24 * 60 * 60 * 1000)
            );
          } else if (data.DefaultEntryData.orderDurationDateName == "Today") {
            this.tradeForm.orderDurationDate = this.dateFormat(Date.now());
          }
        }

        if (
          data.DefaultEntryData.cptyOrderDateName &&
          data.DefaultEntryData.cptyOrderDateName != null
        ) {
          if (data.DefaultEntryData.cptyOrderDateName == "Yesterday") {
            this.tradeForm.cptyOrderDate = this.dateFormat(
              new Date(Date.now() - 24 * 60 * 60 * 1000)
            );
          } else if (data.DefaultEntryData.cptyOrderDateName == "Tomorrow") {
            this.tradeForm.cptyOrderDate = this.dateFormat(
              new Date(Date.now() + 24 * 60 * 60 * 1000)
            );
          } else if (data.DefaultEntryData.cptyOrderDateName == "Today") {
            this.tradeForm.cptyOrderDate = this.dateFormat(Date.now());
          }
        }

        if (
          data.DefaultEntryData.defTradeDateName &&
          data.DefaultEntryData.defTradeDateName != null
        ) {
          if (data.DefaultEntryData.defTradeDateName == "Yesterday") {
            this.tradeForm.tradeDate = this.dateFormat(
              new Date(Date.now() - 24 * 60 * 60 * 1000)
            );
          } else if (data.DefaultEntryData.defTradeDateName == "Tomorrow") {
            this.tradeForm.tradeDate = this.dateFormat(
              new Date(Date.now() + 24 * 60 * 60 * 1000)
            );
          } else if (data.DefaultEntryData.defTradeDateName == "Today") {
            this.tradeForm.tradeDate = this.dateFormat(Date.now());
          }
        }
      }
    },

    getSavedDefaultEntries() {
      store
        .dispatch("fxSFS/getSavedDefaultEntries", {
          DefaultType: "FxExecuted",
          UserId: this.user.id,
        })
        .then((res) => {
          this.optDefaultEntries = res.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    deleteSavedDefault(id) {
      store
        .dispatch("fxSFS/deleteSavedDefault", {
          auth: this.user,
          id: id,
        })
        .then((res) => {
          this.searchName = null;
          this.getSavedDefaultEntries();

          this.successMessage(res.data.message);
        })
        .catch((error) => {
          this.errorMessage(error);

          console.log(error);
        });
    },

    getArangeFxExecutedTCUsers() {
      store
        .dispatch("fxSFS/fetchArangeFxExecutedTCUsers", {
          auth: this.user,
          teamName: this.user.role,
        })
        .then((res) => {
          if (res.data.info == "Pass") {
            this.optScfUsers = res.data.users;
          } else if (res.data.info == "no_users") {
            this.errorMessage(res.data.message);
          } else {
            this.errorMessage(res.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getActingCapacities() {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`getActingCapacities`)
          .then((res) => {
            if (res.data.length > 0) {
              this.optActingCapacities = res.data.map((x) => x.ActingCapacity);
            }
          })
          .catch((error) => reject(error));
      });
    },
    getArrangeFxExecutedOrderTakenGivenThrough() {
      store
        .dispatch(
          "fxSFS/fetchArrangeFxExecutedOrderTakenGivenThrough",
          this.user
        )
        .then((res) => {
          if (
            res.data.orderGivenThroughs.length > 0 ||
            res.data.orderTakenVias.length > 0
          ) {
            this.optOrderGivenThroughs = res.data.orderGivenThroughs;
            this.optOrderTakenVias = res.data.orderTakenVias;
          }
          // else {
          //   this.errorMessage(
          //     this.$t("fx_spot_forward_swap.messages.creating_message")
          //   );
          // }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getArrangeFxExecutedCurrencies() {
      store
        .dispatch("fxSFS/fetchArrangeFxExecutedCurrencies", this.user)
        .then((res) => {
          if (res.data.info == "Pass") {
            this.optPriceTypes = res.data.currencies;

            this.tradeForm.cptyCcy1 = this.optPriceTypes.find(
              (x) => x === "GBP"
            )
              ? "GBP"
              : null;
            this.tradeForm.cptyCcy2 = this.optPriceTypes.find(
              (x) => x === "USD"
            )
              ? "USD"
              : null;
            this.tradeForm.clientCcy1 = this.optPriceTypes.find(
              (x) => x === "GBP"
            )
              ? "GBP"
              : null;
            this.tradeForm.clientCcy2 = this.optPriceTypes.find(
              (x) => x === "USD"
            )
              ? "USD"
              : null;
            this.tradeForm.spreadCcy = this.optPriceTypes.find(
              (x) => x === "USD"
            )
              ? "USD"
              : null;
            this.tradeForm.deliverableCcy = this.optPriceTypes.find(
              (x) => x === "GBP"
            )
              ? "GBP"
              : null;
          } else {
            this.errorMessage(res.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    tradeOkOperation(bvModalEvt) {
      bvModalEvt.preventDefault();

      this.$refs.tradeValidation.validate().then((success) => {
        if (success) {
          this.$checkAccessRight("FxExecuted", "Save").then((res) => {
            if (res.data.valid) {
              // this.tradeModalLoading = true;
              //set and check trade data
              let tradeData = this.setTradeData();
              store
                .dispatch("fxSFS/createNewFXEntry", {
                  auth: this.user,
                  tradeData: tradeData,
                })
                .then((res) => {
                  if (res.data.info == "Pass") {
                    (this.enterNewTradeModal = false),
                      this.$emit("createdFxSFS", true);
                    this.createTradeToastMessage();
                    this.resetTradeModal();
                    this.tradeModalLoading = false;
                  } else {
                    this.errorMessage(res.data.message);
                    this.tradeModalLoading = false;
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            } else {
              this.enterNewTradeModal = false;
              this.notAuthToastMessage();
            }
          });
        } else {
          let notEnteredDatas = "";

          let checkRequiredDatas = _.map(
            this.$refs.tradeValidation.errors,
            (value, key) => ({ key, value })
          );

          if (checkRequiredDatas.length > 0) {
            let first = true;
            checkRequiredDatas.forEach((r) => {
              if (r.value.length > 0) {
                if (first) {
                  notEnteredDatas += r.key;
                  first = false;
                } else {
                  notEnteredDatas += "," + r.key;
                  first = false;
                }
              }
            });
            this.checkRequiredInputsMessage(notEnteredDatas, 20000);
          }
        }
      });
    },

    searchClient(search) {
      if (search.length > 2) {
        if (this.timerSecuritydesc) {
          clearTimeout(this.timerSecuritydesc);
          this.timerSecuritydesc = null;
        }

        this.timerSecuritydesc = setTimeout(() => {
          this.loadingClient = true;

          return new Promise((resolve, reject) => {
            axiosIns
              .post(`searchClient`, { auth: this.user, search: search })
              .then((res) => {
                if (res.data.info == "Pass") {
                  this.optClients = [];
                  this.searchedClients = res.data.resData;
                  res.data.resData.forEach((data) => {
                    this.optClients.push(data.AccountName);
                  });

                  this.loadingClient = false;
                } else {
                  this.notFoundMessage("Client");
                  this.loadingClient = false;
                }
              })
              .catch((error) => reject(error));
          });
        }, 800);
      }
    },
    selectedClient(search) {
      this.tradeForm.clientAccount = null;
      let selecting = this.searchedClients.find(
        (s) => s.AccountName === search
      );
      this.tradeForm.clientAccount = selecting.AccountNumber;
    },
    selectedClientFromTable(client) {
      this.tradeForm.clientAccount = null;

      let selecting = this.searchedClients.find(
        (s) => s.AccountName === client.AccountName
      );
      this.tradeForm.client = client.AccountName;
      this.tradeForm.clientAccount = client.AccountNumber;
      this.viewFilteredClientModal = false;
    },
    seeFilteredClientModal() {
      this.viewFilteredClientModal = true;
    },
    resetClientModal() {
      this.viewFilteredClientModal = false;
    },
    searchCounterparty(search) {
      if (search.length > 2) {
        if (this.timerSecuritydesc) {
          clearTimeout(this.timerSecuritydesc);
          this.timerSecuritydesc = null;
        }

        this.timerSecuritydesc = setTimeout(() => {
          this.loadingCounterparty = true;

          return new Promise((resolve, reject) => {
            axiosIns
              .post(`searchCounterparty`, { auth: this.user, search: search })
              .then((res) => {
                if (res.data.info == "Pass") {
                  this.optCounterParties = [];
                  this.searchedCounterparties = [];

                  this.searchedCounterparties = res.data.resData;

                  if (res.data.resData.length > 0) {
                    res.data.resData.forEach((data) => {
                      this.optCounterParties.push(data.AccountName);
                    });
                  }

                  this.loadingCounterparty = false;
                } else {
                  this.notFoundMessage("Counterparty");
                  this.loadingCounterparty = false;
                }
              })
              .catch((error) => reject(error));
          });
        }, 800);
      }
    },
    changedBuySell(type) {
      this.arrangeBuySell();
      this.arrangeRatesAndAmounts();
      this.arrangeInputFormats();
      ["takeProfitLimit", "stopLossStop", "stopLossLimit"].forEach((el) => {
        this.checkEnableOCOInputs(el);
      });
      ["takeProfitLimit", "stopLossStop", "stopLossLimit"].forEach((el) => {
        this.checkEnableOCOCptyInputs(el);
      });
    },
    arrangeBuySell() {
      if (this.tradeForm.buySell == "Buy") {
        this.tradeForm.swapCptyBuySell1 = "Sells";
        this.tradeForm.swapCptyBuySell2 = "Buys";
        this.tradeForm.clientBuySell1 = "Buys";
        this.tradeForm.clientBuySell2 = "Sells";
        this.tradeForm.swapClientBuySell1 = "Buys";
        this.tradeForm.swapClientBuySell2 = "Sells";
        this.tradeForm.cptyBuySell1 = "Buys"; //sfcBuySell1
        this.tradeForm.cptyBuySell2 = "Sells"; //sfcBuySell2
      } else if (this.tradeForm.buySell == "Sell") {
        this.tradeForm.clientBuySell1 = "Sells";
        this.tradeForm.clientBuySell2 = "Buys";
        this.tradeForm.swapClientBuySell1 = "Sells";
        this.tradeForm.swapClientBuySell2 = "Buys";
        this.tradeForm.cptyBuySell1 = "Sells"; //sfcBuySell1
        this.tradeForm.cptyBuySell2 = "Buys"; //sfcBuySell2
        this.tradeForm.swapCptyBuySell1 = "Buys";
        this.tradeForm.swapCptyBuySell2 = "Sells";
      }
    },
    selectedCounterparty(search) {
      this.tradeForm.counterparty = null;
      this.tradeForm.cptyAccount = null;
      this.tradeForm.counterpartyTrader = null;

      let selecting = this.searchedClients.find(
        (s) => s.AccountName === search
      );
      this.tradeForm.cptyAccount = selecting.AccountNumber;

      return new Promise((resolve, reject) => {
        axiosIns
          .post(`selectedCounterpartyForTrader`, {
            auth: this.user,
            search: search,
          })
          .then((res) => {
            this.tradeForm.counterpartyTrader = null;
            this.optCounterPartyTraders = [];
            if (res.data.info == "Pass") {
              if (res.data.resTrader.length > 0) {
                this.tradeForm.counterpartyTrader =
                  res.data.resTrader[0].Trader;
                let listTraders = [];
                res.data.resTrader.forEach((element) => {
                  if (element.Trader && !listTraders.includes(element.Trader)) {
                    listTraders.push(element.Trader);
                  }
                });
                this.optCounterPartyTraders = listTraders;
              }
            }
          })
          .catch((error) => reject(error));
      });
    },
    createNewCounterpartyTrader(trader) {
      if (this.tradeForm.counterparty) {
        if (trader && trader.length > 2) {
          return new Promise((resolve, reject) => {
            axiosIns
              .post(`createNewCounterpartyForTrader`, {
                auth: this.user,
                counterparty: this.tradeForm.counterparty,
                trader: trader,
              })
              .then((res) => {
                if (res.data.info == "Pass") {
                  this.tradeForm.counterpartyTrader = trader;
                  this.optCounterPartyTraders.push(trader);
                  this.successMessage(
                    "Counterparty Trader created successfully"
                  );
                } else {
                  this.wrongToastMessage(res.data.message);
                }
              })
              .catch((error) => reject(error));
          });
        }
      } else {
        this.wrongToastMessage("Please select a counterparty first");
      }
    },
    createNewIntermediary(intermediary, direction) {
      if (intermediary && intermediary.length > 2) {
        return new Promise((resolve, reject) => {
          axiosIns
            .post(`createNewIntermediary`, {
              intermediary: {
                title: intermediary,
                direction: direction,
              },
            })
            .then((res) => {
              if (res.data.info) {
                if (direction === "GIVEN") {
                  this.tradeForm.orderGivenThrough = intermediary;
                  this.optOrderGivenThroughs.push(intermediary);
                } else {
                  this.tradeForm.orderTakenVia = intermediary;
                  this.optOrderTakenVias.push(intermediary);
                }
                this.successMessage("Intermediary created successfully");
              } else {
                this.wrongToastMessage(res.data.message);
              }
            })
            .catch((error) => reject(error));
        });
      } else {
        this.wrongToastMessage("Please enter a valid intermediary name");
      }
    },
    selectedCounterpartyFromTable(cpty) {
      this.tradeForm.counterparty = null;
      this.tradeForm.cptyAccount = null;
      this.tradeForm.counterpartyTrader = null;

      this.tradeForm.counterparty = cpty.AccountName;
      this.tradeForm.cptyAccount = cpty.AccountNumber;

      return new Promise((resolve, reject) => {
        axiosIns
          .post(`selectedCounterpartyForTrader`, {
            auth: this.user,
            search: cpty.AccountName,
          })
          .then((res) => {
            if (res.data.info == "Pass") {
              if (res.data.resTrader.length > 0) {
                this.tradeForm.counterpartyTrader =
                  res.data.resTrader[0].Trader;
                let listTraders = [];
                res.data.resTrader.forEach((element) => {
                  if (element.Trader && !listTraders.includes(element.Trader)) {
                    listTraders.push(element.Trader);
                  }
                });
                this.optCounterPartyTraders = listTraders;
              }
            }
            this.viewFilteredCounterpartyModal = false;
          })
          .catch((error) => reject(error));
      });
    },
    seeFilteredCounterpartyModal() {
      this.viewFilteredCounterpartyModal = true;
    },
    resetCounterpartyModal() {
      this.viewFilteredCounterpartyModal = false;
    },
    selectedProductType(type) {
      if (type == "SPOT" && this.tradeForm.counterpartyRate != null) {
        this.convertNumberInputFormat();
        this.tradeForm.spotRef = this.tradeForm.counterpartyRate;
        this.arrangeRatesAndAmounts();
      }
    },
    selectedSettlementType(type) {
      this.arrangeCurrencies();
      if (type == "PHYSICAL") {
        this.tradeForm.cutOffTime = null;
      }
    },
    changedCounterpartyRate(type) {
      this.convertNumberInputFormat();
      this.arrangeRatesAndAmounts();
      if (this.tradeForm.cptyAmount1 != null) {
        this.tradeForm.cptyAmount2 =
          this.tradeForm.cptyAmount1 * this.tradeForm.counterpartyRate;
      }
      if (
        this.tradeForm.productType == "SPOT" &&
        this.tradeForm.counterpartyRate != null
      ) {
        this.tradeForm.spotRef = this.tradeForm.counterpartyRate;
      }

      if (this.tradeForm.productType == "SWAP") {
        this.tradeForm.enableConnectedOCOOrders = false;
      }
      this.arrangeInputFormats();
    },
    changedCptyCalculateFromRate1() {
      this.convertNumberInputFormat();
      this.arrangeRatesAndAmounts();
      this.arrangeInputFormats();
    },

    selectedCptyCcy1() {
      this.tradeForm.clientCcy1 = this.tradeForm.cptyCcy1;
      this.tradeForm.deliverableCcy = this.tradeForm.cptyCcy1;
    },
    selectedCptyCcy2() {
      this.tradeForm.clientCcy2 = this.tradeForm.cptyCcy2;
      this.tradeForm.deliverableCcy = this.tradeForm.cptyCcy2;
      this.tradeForm.spreadCcy = this.tradeForm.cptyCcy2;
    },
    arrangeCurrencies() {
      this.tradeForm.clientCcy1 = this.tradeForm.cptyCcy1;
      this.tradeForm.clientCcy2 = this.tradeForm.cptyCcy2;
      this.tradeForm.swapCptyCcy1 = this.tradeForm.cptyCcy1;
      this.tradeForm.swapCptyCcy2 = this.tradeForm.cptyCcy2;
      this.tradeForm.swapClientCcy1 = this.tradeForm.cptyCcy1;
      this.tradeForm.swapClientCcy2 = this.tradeForm.cptyCcy2;
    },
    changedCalcFromSpread(type) {
      if (type == "Calculate Client Rate From Spread") {
        this.tradeForm.clientCalculateFromRate2 = true;
      }

      // this.arrangeRatesAndAmounts();
      // this.arrangeInputFormats();
    },
    changedSpread(spread) {
      this.convertNumberInputFormat();
      this.arrangeRatesAndAmounts();
      this.arrangeInputFormats();
    },
    changedClientRate(type) {
      this.convertNumberInputFormat();
      this.arrangeRatesAndAmounts();
      this.arrangeInputFormats();
    },
    changedClientCalculateFromRate2() {
      this.convertNumberInputFormat();
      this.arrangeRatesAndAmounts();
      this.arrangeInputFormats();
    },
    calculateAsCptyAmount1(amount) {
      this.convertNumberInputFormat();
      if (this.tradeForm.cptyAmount1 == this.tradeForm.clientAmount1) {
        this.tradeForm.spreadCcy = this.tradeForm.cptyCcy2;
      } else if (this.tradeForm.cptyAmount2 == this.tradeForm.clientAmount2) {
        this.tradeForm.spreadCcy = this.tradeForm.cptyCcy1;
      } else {
        this.tradeForm.clientAmount1 = this.tradeForm.cptyAmount1;
        this.tradeForm.spreadCcy = this.tradeForm.cptyCcy2;
      }

      if (this.tradeForm.cptyCalculateFromRate1) {
        if (
          this.tradeForm.counterpartyRate != null &&
          this.tradeForm.cptyAmount1 != null
        ) {
          if (this.tradeForm.cptyAmount2 > 0) {
            if (
              this.tradeForm.cptyAmount1 * this.tradeForm.counterpartyRate !=
              this.tradeForm.cptyAmount2
            ) {
              if (
                this.tradeForm.cptyAmount1 == this.tradeForm.clientAmount1 &&
                this.tradeForm.cptyAmount2 != null
              ) {
                this.tradeForm.cptyAmount2 = this.roundToEight(
                  this.tradeForm.cptyAmount1 * this.tradeForm.counterpartyRate
                ).toFixed(8);
              } else if (
                this.tradeForm.clientAmount2 == this.tradeForm.cptyAmount2
              ) {
                this.tradeForm.cptyAmount1 = this.roundToEight(
                  this.tradeForm.cptyAmount2 / this.tradeForm.counterpartyRate
                ).toFixed(8);
              } else {
                this.tradeForm.cptyAmount2 = this.roundToEight(
                  this.tradeForm.cptyAmount1 * this.tradeForm.counterpartyRate
                ).toFixed(8);
              }
            }
          } else {
            this.tradeForm.cptyAmount2 = this.roundToEight(
              this.tradeForm.cptyAmount1 * this.tradeForm.counterpartyRate
            );
          }
        }
      } else {
        if (
          this.tradeForm.cptyAmount1 != null &&
          this.tradeForm.cptyAmount2 != null
        ) {
          if (this.tradeForm.counterpartyRate > 0) {
            if (
              this.tradeForm.cptyAmount2 / this.tradeForm.cptyAmount1 !=
              this.tradeForm.counterpartyRate
            ) {
              this.tradeForm.counterpartyRate =
                this.tradeForm.cptyAmount2 / this.tradeForm.cptyAmount1;
            } else {
              this.tradeForm.counterpartyRate =
                this.tradeForm.cptyAmount2 / this.tradeForm.cptyAmount1;
            }
          }
        }
      }
      this.arrangeRatesAndAmounts();
      this.tradeForm.clientAmount1 = this.tradeForm.cptyAmount1;
      this.arrangeInputFormats();
    },
    calculateAsCptyAmount2(amount) {
      this.convertNumberInputFormat();
      if (this.tradeForm.cptyAmount1 == this.tradeForm.clientAmount1) {
        this.tradeForm.spreadCcy = this.tradeForm.cptyCcy2;
      } else if (this.tradeForm.cptyAmount2 == this.tradeForm.clientAmount2) {
        this.tradeForm.spreadCcy = this.tradeForm.cptyCcy1;
      } else {
        this.tradeForm.clientAmount2 = this.tradeForm.cptyAmount2;
        this.tradeForm.spreadCcy = this.tradeForm.cptyCcy2;
      }

      if (this.tradeForm.cptyCalculateFromRate1) {
        if (
          this.tradeForm.counterpartyRate != null &&
          this.tradeForm.cptyAmount2 != null
        ) {
          if (
            this.tradeForm.cptyAmount1 > 0 &&
            this.tradeForm.cptyAmount2 > 0
          ) {
            if (
              this.tradeForm.cptyAmount2 / this.tradeForm.counterpartyRate !=
              this.tradeForm.cptyAmount1
            ) {
              this.tradeForm.cptyAmount1 = this.roundToEight(
                this.tradeForm.cptyAmount2 / this.tradeForm.counterpartyRate
              );
            }
          } else {
            if (this.tradeForm.cptyAmount2 == this.tradeForm.clientAmount2) {
              this.tradeForm.cptyAmount1 = this.roundToEight(
                this.tradeForm.cptyAmount2 / this.tradeForm.counterpartyRate
              );
            } else if (
              this.tradeForm.cptyAmount1 == this.tradeForm.clientAmount1 &&
              this.tradeForm.cptyAmount1 != null
            ) {
              this.tradeForm.cptyAmount2 = this.roundToEight(
                this.tradeForm.cptyAmount1 * this.tradeForm.counterpartyRate
              );
            } else {
              this.tradeForm.cptyAmount1 = this.roundToEight(
                this.tradeForm.cptyAmount2 / this.tradeForm.counterpartyRate
              );
            }
          }
        }
      } else {
        if (
          this.tradeForm.cptyAmount1 != null &&
          this.tradeForm.cptyAmount2 != null
        ) {
          if (this.tradeForm.counterpartyRate > 0) {
            if (
              this.tradeForm.cptyAmount2 / this.tradeForm.cptyAmount1 !=
              this.tradeForm.counterpartyRate
            ) {
              this.tradeForm.counterpartyRate =
                this.tradeForm.cptyAmount2 / this.tradeForm.cptyAmount1;
            } else {
              this.tradeForm.counterpartyRate =
                this.tradeForm.cptyAmount2 / this.tradeForm.cptyAmount1;
            }
          }
        }
      }
      this.arrangeRatesAndAmounts();
      this.arrangeInputFormats();
    },
    calculateAsClientAmount1() {
      this.convertNumberInputFormat();
      if (this.tradeForm.cptyAmount1 == this.tradeForm.clientAmount1) {
        this.tradeForm.spreadCcy = this.tradeForm.cptyCcy2;
      } else if (this.tradeForm.cptyAmount2 == this.tradeForm.clientAmount2) {
        this.tradeForm.spreadCcy = this.tradeForm.cptyCcy1;
      } else {
        this.tradeForm.cptyAmount1 = this.tradeForm.clientAmount1;
        this.tradeForm.spreadCcy = this.tradeForm.cptyCcy2;
      }

      if (this.tradeForm.clientAmount1 > 0) {
        if (
          this.tradeForm.cptyAmount1 != null &&
          this.tradeForm.cptyAmount2 != null
        ) {
          this.tradeForm.cptyAmount1 = this.tradeForm.clientAmount1;
        }
      }

      if (this.tradeForm.clientCalculateFromRate2) {
        if (
          this.tradeForm.clientRate != null &&
          this.tradeForm.clientAmount1 != null
        ) {
          if (this.tradeForm.clientAmount2 > 0) {
            if (
              this.roundToEight(
                this.tradeForm.clientAmount1 * this.tradeForm.clientRate
              ).toFixed(8) != this.tradeForm.clientAmount2 &&
              this.roundToEight(
                (
                  this.tradeForm.clientAmount1 * this.tradeForm.clientRate
                ).toFixed(8)
              ) -
                this.tradeForm.clientAmount2 >
                0.01
            ) {
              if (this.tradeForm.clientAmount1 == this.tradeForm.cptyAmount1) {
                this.tradeForm.clientAmount2 = this.roundToEight(
                  this.tradeForm.clientAmount1 * this.tradeForm.clientRate
                ).toFixed(8);
              } else if (
                this.tradeForm.clientAmount2 == this.tradeForm.cptyAmount2
              ) {
                this.tradeForm.clientAmount1 = this.roundToEight(
                  this.tradeForm.clientAmount2 / this.tradeForm.clientRate
                ).toFixed(8);
              } else {
                this.tradeForm.clientAmount2 = this.tradeForm
                  .roundToEight(
                    this.tradeForm.clientAmount1 * this.tradeForm.clientRate
                  )
                  .toFixed(8);
              }
            }
          } else {
            this.tradeForm.clientAmount2 = this.roundToEight(
              this.tradeForm.clientAmount1 * this.tradeForm.clientRate
            ).toFixed(8);
          }
        }
      } else {
        if (
          this.tradeForm.clientAmount1 != null &&
          this.tradeForm.clientAmount2 != null
        ) {
          if (this.tradeForm.clientRate > 0) {
            if (
              this.roundToEight(
                this.tradeForm.clientAmount2 / this.tradeForm.clientAmount1
              ).toFixed(8) != this.tradeForm.clientRate &&
              this.roundToEight(
                (
                  this.tradeForm.clientAmount2 / this.tradeForm.clientAmount1
                ).toFixed(8)
              ) -
                this.tradeForm.clientRate >
                0.01
            ) {
              this.tradeForm.clientRate = this.roundToEight(
                this.tradeForm.clientAmount2 / this.tradeForm.clientAmount1
              ).toFixed(8);
            }
          } else {
            this.tradeForm.clientRate = this.roundToEight(
              this.tradeForm.clientAmount2 / this.tradeForm.clientAmount1
            ).toFixed(8);
          }
        }
      }

      this.arrangeRatesAndAmounts();

      if (this.tradedAmountsConsistent()) {
        this.wrongToastMessage(
          $t("fx_spot_forward_swap.messages.traded_amount_consistent"),
          8000
        );
        this.tradeForm.clientAmount1 = this.tradeForm.cptyAmount1;
        this.calculateAsCptyAmount1();
      }

      this.arrangeInputFormats();
    },
    calculateAsClientAmount2() {
      this.convertNumberInputFormat();
      if (this.tradeForm.cptyAmount1 == this.tradeForm.clientAmount1) {
        this.tradeForm.spreadCcy = this.tradeForm.cptyCcy2;
      } else if (this.tradeForm.cptyAmount2 == this.tradeForm.clientAmount2) {
        this.tradeForm.spreadCcy = this.tradeForm.cptyCcy1;
      } else {
        this.tradeForm.cptyAmount1 = this.tradeForm.clientAmount1;
        this.tradeForm.spreadCcy = this.tradeForm.cptyCcy1;
      }

      if (this.tradeForm.clientCalculateFromRate2) {
        if (
          this.tradeForm.clientRate != null &&
          this.tradeForm.clientAmount2 != null
        ) {
          if (
            this.tradeForm.clientAmount1 > 0 &&
            this.tradeForm.clientAmount2 > 0
          ) {
            if (
              this.roundToEight(
                this.tradeForm.clientAmount2 / this.tradeForm.clientRate
              ).toFixed(8) != this.tradeForm.clientAmount1 &&
              this.roundToEight(
                (
                  this.tradeForm.clientAmount2 / this.tradeForm.clientRate
                ).toFixed(8)
              ) -
                this.tradeForm.clientAmount1 >
                0.01
            ) {
              if (this.tradeForm.clientAmount2 == this.tradeForm.cptyAmount2) {
                this.tradeForm.clientAmount1 = this.roundToEight(
                  this.tradeForm.clientAmount2 / this.tradeForm.clientRate
                ).toFixed(8);
              } else if (
                this.tradeForm.clientAmount1 == this.tradeForm.cptyAmount1
              ) {
                this.tradeForm.clientAmount1 = this.roundToEight(
                  this.tradeForm.clientAmount2 / this.tradeForm.clientRate
                ).toFixed(8);
              } else {
                this.tradeForm.clientAmount1 = this.tradeForm
                  .roundToEight(
                    this.tradeForm.clientAmount2 / this.tradeForm.clientRate
                  )
                  .toFixed(8);
              }
            }
          } else {
            this.tradeForm.clientAmount1 = this.roundToEight(
              this.tradeForm.clientAmount2 * this.tradeForm.clientRate
            ).toFixed(8);
          }
        }
      } else {
        if (
          this.tradeForm.clientAmount1 != null &&
          this.tradeForm.clientAmount2 != null
        ) {
          if (this.tradeForm.clientRate > 0) {
            if (
              this.roundToEight(
                this.tradeForm.clientAmount2 / this.tradeForm.clientAmount1
              ).toFixed(8) != this.tradeForm.clientRate &&
              this.roundToEight(
                (
                  this.tradeForm.clientAmount2 / this.tradeForm.clientAmount1
                ).toFixed(8)
              ) -
                this.tradeForm.clientRate >
                0.01
            ) {
              this.tradeForm.clientRate = this.roundToEight(
                this.tradeForm.clientAmount2 / this.tradeForm.clientAmount1
              ).toFixed(8);
            }
          } else {
            this.tradeForm.clientRate = this.roundToEight(
              this.tradeForm.clientAmount2 / this.tradeForm.clientAmount1
            ).toFixed(8);
          }
        }
      }

      this.arrangeRatesAndAmounts();

      if (this.tradedAmountsConsistent()) {
        this.wrongToastMessage(
          $t("fx_spot_forward_swap.messages.traded_amount_consistent"),
          8000
        );
        this.tradeForm.clientAmount1 = this.tradeForm.cptyAmount1;
        this.calculateAsCptyAmount1();
      }

      this.arrangeInputFormats();
    },
    changeSpotRef() {
      this.arrangeInputFormats();
    },
    changeSpreadAmount() {
      this.arrangeInputFormats();
    },
    changeComissionAmount() {
      this.arrangeInputFormats();
    },
    selectedSpreadCcy() {
      this.convertNumberInputFormat();
      this.arrangeRatesAndAmounts();
      this.arrangeInputFormats();
    },

    tradedAmountsConsistent() {
      if (
        this.tradeForm.clientAmount1 != null &&
        this.tradeForm.clientAmount2
      ) {
        if (
          this.tradeForm.cptyAmount1 != this.tradeForm.clientAmount1 &&
          this.tradeForm.cptyAmount2 != this.tradeForm.clientAmount2
        ) {
          return true;
        }
      }
    },

    changedSwapPoints() {
      this.convertNumberInputFormat();
      this.arrangeRatesAndAmounts();
      this.arrangeInputFormats();
    },
    changedSwapCptyAmount1() {
      // this.convertNumberInputFormat()
      this.arrangeInputFormats();
    },
    changedSwapCptyAmount2() {
      // this.convertNumberInputFormat()
      this.arrangeInputFormats();
    },
    changedSwapClientAmount1() {
      // this.convertNumberInputFormat()
      this.arrangeInputFormats();
    },
    changedSwapClientAmount2() {
      // this.convertNumberInputFormat()
      this.arrangeInputFormats();
    },
    changedSwapSpread() {
      this.convertNumberInputFormat();
      this.arrangeRatesAndAmounts();
      this.arrangeInputFormats();
    },
    changedSwapClientRate() {
      this.convertNumberInputFormat();
      this.arrangeRatesAndAmounts();
      this.arrangeInputFormats();
    },
    setSwapTradetoSettleDays(day) {
      if (this.tradeForm.swapTradeDate) {
        this.getHolidays().then((holidays) => {
          if (holidays.length > 0) {
            let tradeDate = new Date(
              this.MDYdateFormat(this.tradeForm.swapTradeDate)
            );

            //set settle date name
            this.tradeForm.swapTradeDateName = this.weekday[tradeDate.getDay()];

            let period = this.tradeForm.maturity;
            tradeDate.setDate(tradeDate.getDate() + period);

            //check holiday
            this.checkHolidaysDate(holidays, tradeDate);
            //found settlement date name
            let swapSettleDateName = this.weekday[
              this.checkedHolidayDate.getDay()
            ];

            //check weekend settlement date
            if (swapSettleDateName == "Saturday") {
              this.checkedHolidayDate.setDate(
                this.checkedHolidayDate.getDate() + 2
              );
            } else if (swapSettleDateName == "Sunday") {
              this.checkedHolidayDate.setDate(
                this.checkedHolidayDate.getDate() + 2
              );
            }

            //again check holiday
            this.checkHolidaysDate(holidays, tradeDate);

            // set trade date & name
            this.tradeForm.swapSettleDate = this.dateFormat(
              this.checkedHolidayDate
            );
            this.tradeForm.swapSettleDateName = this.weekday[
              this.checkedHolidayDate.getDay()
            ];
          } else {
            let tradeDate = new Date(
              this.MDYdateFormat(this.tradeForm.swapTradeDate)
            );

            //set settle date name
            this.tradeForm.swapTradeDateName = this.weekday[tradeDate.getDay()];

            let period = this.tradeForm.maturity;

            tradeDate.setDate(tradeDate.getDate() + period);

            //found settlement date name
            let tradeDateName = this.weekday[tradeDate.getDay()];

            //check weekend settlement date
            if (tradeDateName == "Saturday") {
              tradeDate.setDate(tradeDate.getDate() + 2);
            } else if (tradeDateName == "Sunday") {
              tradeDate.setDate(tradeDate.getDate() + 2);
            }

            // set swap trade date
            this.tradeForm.swapSettleDate = this.dateFormat(tradeDate);
            this.tradeForm.swapSettleDateName = this.weekday[
              tradeDate.getDay()
            ];
          }
        });

        // let settleDate = new Date(
        //   this.MDYdateFormat(this.tradeForm.swapSettleDate)
        // );

        // //set settle date name
        // this.tradeForm.swapSettleDateName = this.weekday[settleDate.getDay()];

        // let period = this.tradeForm.maturity;

        // settleDate.setDate(settleDate.getDate() + period);
        // //set trade date name
        // this.tradeForm.swapTradeDateName = this.weekday[settleDate.getDay()];

        // this.tradeForm.swapTradeDate = settleDate;
      }
    },

    arrangeSwapTradetoSettleDays(type) {
      if (type == "Swap Trade Date") {
        if (
          this.tradeForm.swapTradeDate != null &&
          this.tradeForm.maturity != null
        ) {
        } else if (
          this.tradeForm.swapTradeDate != null &&
          this.tradeForm.swapSettleDate != null
        ) {
        }
      } else if (type == "Swap Settle Date") {
        // if (this.tradeForm.maturity != null && this.tradeForm.maturity > 0) {
        //   this.getHolidays().then((holidays) => {
        //     if (holidays.length > 0) {
        //       let settleDate = new Date(
        //         this.MDYdateFormat(this.tradeForm.swapSettleDate)
        //       );
        //       //set settle date name
        //       this.tradeForm.swapSettleDateName = this.weekday[
        //         settleDate.getDay()
        //       ];
        //       let period = this.tradeForm.maturity;
        //       settleDate.setDate(settleDate.getDate() + period);
        //       //check holiday
        //       this.checkHolidaysDate(holidays, settleDate);
        //       //found settlement date name
        //       let swapTradeDateName = this.weekday[
        //         this.checkedHolidayDate.getDay()
        //       ];
        //       //check weekend settlement date
        //       if (swapTradeDateName == "Saturday") {
        //         this.checkedHolidayDate.setDate(
        //           this.checkedHolidayDate.getDate() + 2
        //         );
        //       } else if (swapTradeDateName == "Sunday") {
        //         this.checkedHolidayDate.setDate(
        //           this.checkedHolidayDate.getDate() + 1
        //         );
        //       }
        //       //again check holiday
        //       this.checkHolidaysDate(holidays, settleDate);
        //       // set trade date & name
        //       this.tradeForm.swapTradeDate = this.dateFormat(
        //         this.checkedHolidayDate
        //       );
        //       this.tradeForm.swapTradeDateName = this.weekday[
        //         this.checkedHolidayDate.getDay()
        //       ];
        //     } else {
        //       let settleDate = new Date(
        //         this.MDYdateFormat(this.tradeForm.swapSettleDate)
        //       );
        //       //set settle date name
        //       this.tradeForm.swapSettleDateName = this.weekday[
        //         settleDate.getDay()
        //       ];
        //       let period = this.tradeForm.maturity;
        //       settleDate.setDate(settleDate.getDate() + period);
        //       //found settlement date name
        //       let settlementDateName = this.weekday[settleDate.getDay()];
        //       //check weekend settlement date
        //       if (settlementDateName == "Saturday") {
        //         settleDate.setDate(settleDate.getDate() + 2);
        //       } else if (settlementDateName == "Sunday") {
        //         settleDate.setDate(settleDate.getDate() + 1);
        //       }
        //       // set swap trade date
        //       this.tradeForm.swapTradeDate = this.dateFormat(settleDate);
        //       this.tradeForm.swapTradeDateName = this.weekday[
        //         settleDate.getDay()
        //       ];
        //     }
        //   });
        //   // let settleDate = new Date(
        //   //   this.MDYdateFormat(this.tradeForm.swapSettleDate)
        //   // );
        //   // //set settle date name
        //   // this.tradeForm.swapSettleDateName = this.weekday[settleDate.getDay()];
        //   // let period = this.tradeForm.maturity;
        //   // settleDate.setDate(settleDate.getDate() + period);
        //   // //set settle date name
        //   // this.tradeForm.swapTradeDateName = this.weekday[settleDate.getDay()];
        //   // this.tradeForm.swapTradeDate = settleDate;
        // }
      }
    },

    changeSwapCalcFromSpread() {
      this.convertNumberInputFormat();
      this.arrangeRatesAndAmounts();
      this.arrangeInputFormats();
    },

    arrangeRatesAndAmounts() {
      this.convertNumberInputFormat();
      if (this.tradeForm.cptyAmount1 == this.tradeForm.clientAmount1) {
        this.tradeForm.spreadCcy = this.tradeForm.cptyCcy2;
      } else if (this.tradeForm.cptyAmount2 == this.tradeForm.clientAmount2) {
        this.tradeForm.spreadCcy = this.tradeForm.cptyCcy1;
      } else {
        this.tradeForm.clientAmount1 = this.tradeForm.cptyAmount1;
        this.tradeForm.spreadCcy = this.tradeForm.cptyCcy2;
      }

      if (this.tradeForm.cptyCalculateFromRate1) {
        if (this.tradeForm.counterpartyRate != null) {
          if (this.tradeForm.cptyAmount1 != null) {
            if (this.tradeForm.cptyAmount2 > 0) {
              if (
                this.roundToEight(
                  this.tradeForm.cptyAmount1 * this.tradeForm.counterpartyRate
                ).toFixed(8) != this.tradeForm.cptyAmount2 &&
                Math.abs(
                  this.roundToEight(
                    this.tradeForm.cptyAmount1 * this.tradeForm.counterpartyRate
                  ).toFixed(8) - this.tradeForm.cptyAmount2
                ) > 0.01
              ) {
                if (
                  this.tradeForm.cptyAmount1 == this.tradeForm.clientAmount1
                ) {
                  this.tradeForm.cptyAmount2 = this.roundToEight(
                    this.tradeForm.cptyAmount1 * this.tradeForm.counterpartyRate
                  ).toFixed(8);
                } else if (
                  this.tradeForm.clientAmount2 == this.tradeForm.cptyAmount2
                ) {
                  this.tradeForm.cptyAmount1 = this.roundToEight(
                    this.tradeForm.cptyAmount2 / this.tradeForm.counterpartyRate
                  ).toFixed(8);
                } else {
                  this.tradeForm.cptyAmount2 = this.roundToEight(
                    this.tradeForm.cptyAmount1 * this.tradeForm.counterpartyRate
                  ).toFixed(8);
                }
              } else {
                this.tradeForm.cptyAmount2 = this.roundToEight(
                  this.tradeForm.cptyAmount1 * this.tradeForm.counterpartyRate
                ).toFixed(8);
              }
            }
          } else if (this.tradeForm.cptyAmount2 > 0) {
            if (this.tradeForm.cptyAmount1 > 0) {
              if (
                this.roundToEight(
                  this.tradeForm.cptyAmount2 / this.tradeForm.counterpartyRate
                ).toFixed(8) != this.tradeForm.cptyAmount1 &&
                Math.abs(
                  this.roundToEight(
                    this.tradeForm.cptyAmount2 / this.tradeForm.counterpartyRate
                  ).toFixed(8) - this.tradeForm.cptyAmount1
                ) > 0.01
              ) {
                if (
                  this.tradeForm.cptyAmount2 == this.tradeForm.clientAmount2
                ) {
                  this.tradeForm.cptyAmount1 =
                    this.tradeForm.cptyAmount2 /
                    this.tradeForm.counterpartyRate;
                } else if (
                  this.tradeForm.cptyAmount1 == this.tradeForm.clientAmount1
                ) {
                  this.tradeForm.cptyAmount2 = this.roundToEight(
                    this.tradeForm.cptyAmount1 * this.tradeForm.counterpartyRate
                  ).toFixed(8);
                } else {
                  this.tradeForm.cptyAmount1 =
                    this.tradeForm.cptyAmount2 /
                    this.tradeForm.counterpartyRate;
                }
              }
            } else {
              this.tradeForm.cptyAmount1 = this.roundToEight(
                this.tradeForm.cptyAmount2 / this.tradeForm.counterpartyRate
              ).toFixed(8);
            }
          }
        }
      } else {
        if (
          this.tradeForm.cptyAmount1 != null &&
          this.tradeForm.cptyAmount2 != null
        ) {
          if (
            this.roundToEight(
              this.tradeForm.cptyAmount1 / this.tradeForm.cptyAmount2
            ).toFixed(8) != this.tradeForm.counterpartyRate
          ) {
            this.tradeForm.counterpartyRate = this.roundToEight(
              this.tradeForm.cptyAmount2 / this.tradeForm.cptyAmount1
            ).toFixed(8);
          }
        }
      }

      if (
        this.tradeForm.calcFromSpread == "Calculate Spread From Client Rate"
      ) {
        if (
          this.tradeForm.counterpartyRate != null &&
          this.tradeForm.clientRate != null
        ) {
          if (this.tradeForm.cptyBuySell1 == "Buys") {
            this.tradeForm.spread =
              (this.tradeForm.clientRate - this.tradeForm.counterpartyRate) /
              10000;
          } else if (this.tradeForm.cptyBuySell1 == "Sells") {
            this.tradeForm.spread =
              (this.tradeForm.counterpartyRate - this.tradeForm.clientRate) /
              10000;
          }
        }
      } else if (
        this.tradeForm.calcFromSpread == "Calculate Client Rate From Spread"
      ) {
        if (this.tradeForm.spread != null) {
          if (this.tradeForm.counterpartyRate != null) {
            if (this.tradeForm.cptyBuySell1 == "Buys") {
              this.tradeForm.clientRate =
                this.tradeForm.counterpartyRate + this.tradeForm.spread / 10000;
            } else if (this.tradeForm.cptyBuySell1 == "Sells") {
              this.tradeForm.clientRate =
                this.tradeForm.counterpartyRate - this.tradeForm.spread / 10000;
            }
          } else if (this.tradeForm.clientRate != null) {
            if (this.tradeForm.cptyBuySell1 == "Buys") {
              this.tradeForm.counterpartyRate =
                this.tradeForm.clientRate - this.tradeForm.spread / 10000;
            } else if (this.tradeForm.cptyBuySell1 == "Sells") {
              this.tradeForm.counterpartyRate =
                this.tradeForm.clientRate + this.tradeForm.spread / 10000;
            }
          }
        }
      }

      if (this.tradeForm.clientCalculateFromRate2) {
        if (this.tradeForm.clientRate != null) {
          if (this.tradeForm.clientAmount1 != null) {
            if (this.tradeForm.clientAmount2 > 0) {
              if (
                this.roundToEight(
                  this.tradeForm.clientAmount1 * this.tradeForm.clientRate
                ).toFixed(8) != this.tradeForm.clientAmount2 &&
                Math.abs(
                  this.roundToEight(
                    this.tradeForm.clientAmount1 * this.tradeForm.clientRate
                  ).toFixed(8) - this.tradeForm.clientAmount2
                ) > 0.01
              ) {
                if (
                  this.tradeForm.clientAmount1 == this.tradeForm.cptyAmount1
                ) {
                  this.tradeForm.clientAmount2 = this.roundToEight(
                    this.tradeForm.clientAmount1 * this.tradeForm.clientRate
                  ).toFixed(8);
                } else if (
                  this.tradeForm.clientAmount2 == this.tradeForm.cptyAmount2
                ) {
                  this.tradeForm.clientAmount1 = this.roundToEight(
                    this.tradeForm.clientAmount2 / this.tradeForm.clientRate
                  ).toFixed(8);
                } else {
                  this.tradeForm.clientAmount2 = this.roundToEight(
                    this.tradeForm.clientAmount1 * this.tradeForm.clientRate
                  ).toFixed(8);
                }
              }
            } else {
              this.tradeForm.clientAmount2 = this.roundToEight(
                this.tradeForm.clientAmount1 * this.tradeForm.clientRate
              ).toFixed(8);
            }
          } else if (this.tradeForm.clientAmount2 != null) {
            if (this.tradeForm.clientAmount1 > 0) {
              if (
                this.roundToEight(
                  this.tradeForm.clientAmount2 / this.tradeForm.clientRate
                ).toFixed(8) != this.tradeForm.clientAmount1 &&
                Math.abs(
                  this.roundToEight(
                    this.tradeForm.clientAmount2 / this.tradeForm.clientRate
                  ).toFixed(8) - this.tradeForm.clientAmount1
                ) > 0.01
              ) {
                if (
                  this.tradeForm.clientAmount2 == this.tradeForm.cptyAmount2
                ) {
                  this.clientAmount1 =
                    this.tradeForm.clientAmount2 / this.tradeForm.clientRate;
                } else if (
                  this.tradeForm.clientAmount2 == this.tradeForm.cptyAmount2
                ) {
                  this.tradeForm.clientAmount2 = this.roundToEight(
                    this.tradeForm.clientAmount1 * this.tradeForm.clientRate
                  ).toFixed(8);
                } else {
                  this.tradeForm.clientAmount1 =
                    this.tradeForm.clientAmount2 / this.tradeForm.clientRate;
                }
              }
            } else {
              this.clientAmount1 = this.roundToEight(
                this.tradeForm.clientAmount2 / this.tradeForm.clientRate
              ).toFixed(8);
            }
          }
        }
      } else {
        if (
          this.tradeForm.clientAmount1 != null &&
          this.tradeForm.clientAmount2 != null
        ) {
          if (
            this.roundToEight(
              this.tradeForm.clientAmount1 / this.tradeForm.clientAmount2
            ).toFixed(8) != this.tradeForm.clientRate
          ) {
            this.tradeForm.clientRate = this.roundToEight(
              this.tradeForm.clientAmount2 / this.tradeForm.clientAmount1
            ).toFixed(8);
          }
        }
      }

      // if (
      //   this.tradeForm.calcFromSpread == "Calculate Client Rate From Spread"
      // ) {
      //   if (
      //     this.tradeForm.counterpartyRate != null &&
      //     this.tradeForm.spread != null
      //   ) {
      //     if (this.tradeForm.cptyBuySell1 == "Buys") {
      //       this.tradeForm.counterpartyRate = this.roundToEight(
      //         this.tradeForm.counterpartyRate + this.tradeForm.spread / 10000
      //       ).toFixed(8);
      //     } else if (this.tradeForm.cptyBuySell1 == "Sells") {
      //       this.tradeForm.spread = this.roundToEight(
      //         this.tradeForm.counterpartyRate - this.tradeForm.spread / 10000
      //       ).toFixed(8);
      //     }
      //   }
      // }

      if (
        this.tradeForm.calcFromSpread == "Calculate Spread From Client Rate"
      ) {
        if (
          this.tradeForm.counterpartyRate != null &&
          this.tradeForm.clientRate != null
        ) {
          if (this.tradeForm.cptyBuySell1 == "Buys") {
            this.tradeForm.spread = this.roundToEight(
              (this.tradeForm.clientRate - this.tradeForm.counterpartyRate) *
                10000
            ).toFixed(8);
          } else if (this.tradeForm.cptyBuySell1 == "Sells") {
            this.tradeForm.spread = this.roundToEight(
              (this.tradeForm.counterpartyRate - this.tradeForm.clientRate) *
                10000
            ).toFixed(8);
          }
        }
      }

      if (this.tradeForm.spreadCcy != null) {
        if (this.tradeForm.spreadCcy == this.tradeForm.cptyCcy1) {
          if (this.tradeForm.cptyAmount1 != null) {
            if (this.tradeForm.clientRate != null) {
              if (this.tradeForm.spread != null) {
                this.tradeForm.spreadAmount = this.roundToEight(
                  (((this.tradeForm.cptyAmount1 * this.tradeForm.spread) /
                    this.tradeForm.clientRate /
                    10000) *
                    1) /
                    100
                ).toFixed(8);
              } else if (this.tradeForm.counterpartyRate != null) {
                if (this.tradeForm.cptyBuySell1 == "Buys") {
                  this.tradeForm.spreadAmount = this.roundToEight(
                    (((this.tradeForm.cptyAmount1 *
                      (this.tradeForm.clientRate -
                        this.tradeForm.counterpartyRate)) /
                      this.tradeForm.clientRate) *
                      1) /
                      100
                  ).toFixed(8);
                } else if (this.tradeForm.cptyBuySell1 == "Sells") {
                  this.tradeForm.spreadAmount = this.roundToEight(
                    (((this.tradeForm.cptyAmount1 *
                      (this.tradeForm.counterpartyRate -
                        this.tradeForm.clientRate)) /
                      this.tradeForm.clientRate) *
                      1) /
                      100
                  ).toFixed(8);
                }
              }
            }
          }
        } else if (this.tradeForm.spreadCcy == this.tradeForm.cptyCcy2) {
          if (this.tradeForm.cptyAmount1) {
            if (this.tradeForm.spread) {
              this.tradeForm.spreadAmount = this.roundToEight(
                this.tradeForm.cptyAmount1 * (this.tradeForm.spread / 10000)
              ).toFixed(8);
            }
          }
        }
      }

      if (this.tradeForm.productType == "SWAP") {
        this.tradeForm.swapCptyBuySell1 = this.tradeForm.cptyBuySell1;
        this.tradeForm.swapCptyBuySell2 = this.tradeForm.cptyBuySell2;

        this.tradeForm.swapClientBuySell1 = this.tradeForm.clientBuySell1;
        this.tradeForm.swapClientBuySell2 = this.tradeForm.clientBuySell2;

        this.tradeForm.swapSpreadCcy = this.tradeForm.spreadCcy;

        if (this.tradeForm.spreadCcy == this.tradeForm.cptyCcy2) {
          this.tradeForm.swapClientAmount1 = this.tradeForm.clientAmount1;
          this.tradeForm.swapCptyAmount1 = this.tradeForm.cptyAmount1;
        } else if (this.tradeForm.spreadCcy == this.tradeForm.cptyCcy1) {
          this.tradeForm.swapClientAmount2 = this.tradeForm.clientAmount2;
          this.tradeForm.swapCptyAmount2 = this.tradeForm.cptyAmount2;
        }

        // calculate swap cpty rate
        if (this.tradeForm.counterpartyRate != null) {
          if (this.tradeForm.swapPoints != null) {
            if (this.tradeForm.counterpartyRate != null) {
              this.tradeForm.swapCptyRate = this.formatPrice(
                this.tradeForm.counterpartyRate +
                  this.tradeForm.swapPoints / 10000,
                8
              ).toFixed(8);
            } else {
              this.tradeForm.swapCptyRate = this.formatPrice(
                this.tradeForm.counterpartyRate,
                8
              ).toFixed(8);
            }
          } else {
            this.tradeForm.swapCptyRate = this.formatPrice(
              this.tradeForm.counterpartyRate,
              8
            ).toFixed(8);
          }
        } else {
          this.tradeForm.swapCptyRate = null;
        }

        // calculate swap client rate
        if (this.tradeForm.productType == "SWAP") {
          if (
            this.tradeForm.swapCalcFromSpread ==
            "Calculate Client Rate From Spread"
          ) {
            if (this.tradeForm.swapCptyRate != null) {
              if (this.tradeForm.swapSpread != null) {
                if (this.tradeForm.buySell == "Buy") {
                  this.tradeForm.swapClientRate = this.roundToEight(
                    Number.parseFloat(this.tradeForm.swapCptyRate) -
                      this.tradeForm.swapSpread / 10000
                  ).toFixed(8);
                } else {
                  this.tradeForm.swapClientRate = this.roundToEight(
                    Number.parseFloat(this.tradeForm.swapCptyRate) +
                      this.tradeForm.swapSpread / 10000
                  ).toFixed(8);
                }
              } else {
                if (this.tradeForm.buySell == "Buy") {
                  this.tradeForm.swapClientRate = this.roundToEight(
                    Number.parseFloat(this.tradeForm.swapCptyRate)
                  ).toFixed(8);
                } else {
                  this.tradeForm.swapClientRate = this.roundToEight(
                    Number.parseFloat(this.tradeForm.swapCptyRate)
                  ).toFixed(8);
                }
              }
            }
          } else if (
            this.tradeForm.swapCalcFromSpread ==
            "Calculate Spread From Client Rate"
          ) {
            if (this.tradeForm.swapCptyRate != null) {
              if (this.tradeForm.swapClientRate != null) {
                if (this.tradeForm.buySell == "Buy") {
                  this.tradeForm.swapSpread = this.roundToEight(
                    (Number(-this.tradeForm.swapClientRate) +
                      Number(this.tradeForm.swapCptyRate)) *
                      10000
                  ).toFixed(8);
                } else {
                  this.tradeForm.swapSpread = this.roundToEight(
                    (Number(this.tradeForm.swapClientRate) +
                      Number(this.tradeForm.swapCptyRate)) *
                      10000
                  ).toFixed(8);
                }
              } else {
                this.tradeForm.swapSpread = null;
              }
            } else {
              this.tradeForm.swapSpread = null;
            }
          }

          if (
            this.tradeForm.swapClientAmount1 != null ||
            this.tradeForm.swapClientAmount2 != null
          ) {
            if (this.tradeForm.swapSpreadCcy == this.tradeForm.cptyCcy1) {
              if (this.tradeForm.swapClientRate != null) {
                if (
                  this.tradeForm.swapCptyAmount2 > 0 &&
                  this.tradeForm.swapCptyRate > 0
                ) {
                  this.tradeForm.swapCptyAmount1 = this.roundToEight(
                    Number(this.tradeForm.swapCptyAmount2) /
                      Number(this.tradeForm.swapCptyRate)
                  ).toFixed(8);
                  this.tradeForm.swapClientAmount1 = this.roundToEight(
                    Number(this.tradeForm.swapClientAmount2) /
                      Number(this.tradeForm.swapClientRate)
                  ).toFixed(8);
                }
                if (this.tradeForm.buySell == "Buy") {
                  if (this.tradeForm.swapSpread != null) {
                    this.tradeForm.swapSpreadAmount = this.roundToEight(
                      Number(this.tradeForm.swapClientAmount2) /
                        Number(this.tradeForm.swapClientRate) -
                        Number(this.tradeForm.swapClientAmount2) /
                          Number(this.tradeForm.swapCptyRate)
                    ).toFixed(8);
                  } else {
                    this.tradeForm.swapSpreadAmount = null;
                  }
                } else {
                  if (this.tradeForm.swapSpread != null) {
                    this.tradeForm.swapSpreadAmount = this.roundToEight(
                      -(
                        Number(this.tradeForm.swapClientAmount2) /
                        Number(this.tradeForm.swapClientRate)
                      ) +
                        Number(this.tradeForm.swapClientAmount2) /
                          Number(this.tradeForm.swapCptyRate)
                    ).toFixed(8);
                  } else {
                    this.tradeForm.swapSpreadAmount = null;
                  }
                }
              }
            } else if (
              this.tradeForm.swapSpreadCcy == this.tradeForm.cptyCcy2
            ) {
              if (this.tradeForm.swapClientRate != null) {
                if (
                  this.tradeForm.swapCptyAmount1 > 0 &&
                  this.tradeForm.swapCptyRate != null
                ) {
                  this.tradeForm.swapCptyAmount2 = this.roundToEight(
                    Number(this.tradeForm.swapCptyAmount1) *
                      Number(this.tradeForm.swapCptyRate)
                  ).toFixed(8);
                  this.tradeForm.swapClientAmount2 = this.roundToEight(
                    Number(this.tradeForm.swapClientAmount1) *
                      Number(this.tradeForm.swapClientRate)
                  ).toFixed(8);
                }
                if (this.tradeForm.buySell == "Buy") {
                  if (this.tradeForm.swapSpread != null) {
                    this.tradeForm.swapSpreadAmount = this.roundToEight(
                      -(
                        Number(this.tradeForm.swapClientAmount1) *
                        Number(this.tradeForm.swapClientRate)
                      ) +
                        Number(this.tradeForm.swapClientAmount1) *
                          Number(this.tradeForm.swapCptyRate)
                    ).toFixed(8);
                  } else {
                    this.tradeForm.swapSpreadAmount = null;
                  }
                } else {
                  if (this.tradeForm.swapSpread != null) {
                    this.tradeForm.swapSpreadAmount = this.roundToEight(
                      -(
                        Number(this.tradeForm.swapClientAmount1) *
                        Number(this.tradeForm.swapClientRate)
                      ) -
                        Number(this.tradeForm.swapClientAmount1) *
                          Number(this.tradeForm.swapCptyRate)
                    ).toFixed(8);
                  } else {
                    this.tradeForm.swapSpreadAmount = null;
                  }
                }
              }
            }
          }
        }
      }
    },
    convertNumberInputFormat() {
      if (this.tradeForm.counterpartyRate) {
        this.tradeForm.counterpartyRate = Number(
          this.tradeForm.counterpartyRate
        );
      }
      if (this.tradeForm.clientRate) {
        this.tradeForm.clientRate = Number(this.tradeForm.clientRate);
      }
      if (this.tradeForm.cptyAmount1) {
        this.tradeForm.cptyAmount1 = Number(this.tradeForm.cptyAmount1);
      }
      if (this.tradeForm.cptyAmount2) {
        this.tradeForm.cptyAmount2 = Number(this.tradeForm.cptyAmount2);
      }
      if (this.tradeForm.spread) {
        this.tradeForm.spread = Number(this.tradeForm.spread);
      }
      if (this.tradeForm.spreadAmount) {
        this.tradeForm.spreadAmount = Number(this.tradeForm.spreadAmount);
      }
      if (this.tradeForm.comissionAmount) {
        this.tradeForm.comissionAmount = Number(this.tradeForm.comissionAmount);
      }
      if (this.tradeForm.clientAmount1) {
        this.tradeForm.clientAmount1 = Number(this.tradeForm.clientAmount1);
      }
      if (this.tradeForm.clientAmount2) {
        this.tradeForm.clientAmount2 = Number(this.tradeForm.clientAmount2);
      }
      if (this.tradeForm.spotRef) {
        this.tradeForm.spotRef = Number(this.tradeForm.spotRef);
      }
      if (this.tradeForm.stopPrice) {
        this.tradeForm.stopPrice = Number(this.tradeForm.stopPrice);
      }
      if (this.tradeForm.trailingAmount) {
        this.tradeForm.trailingAmount = Number(this.tradeForm.trailingAmount);
      }
      if (this.tradeForm.takeProfitLimit) {
        this.tradeForm.takeProfitLimit = Number(this.tradeForm.takeProfitLimit);
      }

      if (this.tradeForm.stopLossLimit) {
        this.tradeForm.stopLossLimit = Number(this.tradeForm.stopLossLimit);
      }
      if (this.tradeForm.stopLossStop) {
        this.tradeForm.stopLossStop = Number(this.tradeForm.stopLossStop);
      }
      if (this.tradeForm.cptyTakeProfitLimit) {
        this.tradeForm.cptyTakeProfitLimit = Number(
          this.tradeForm.cptyTakeProfitLimit
        );
      }
      if (this.tradeForm.cptyStopLossLimit) {
        this.tradeForm.cptyStopLossLimit = Number(
          this.tradeForm.cptyStopLossLimit
        );
      }
      if (this.tradeForm.cptyStopLossStop) {
        this.tradeForm.cptyStopLossStop = Number(
          this.tradeForm.cptyStopLossStop
        );
      }
      if (this.tradeForm.swapClientAmount1) {
        this.tradeForm.swapClientAmount1 = Number(
          this.tradeForm.swapClientAmount1
        );
      }
      if (this.tradeForm.swapClientAmount2) {
        this.tradeForm.swapClientAmount2 = Number(
          this.tradeForm.swapClientAmount2
        );
      }
      if (this.tradeForm.swapSpreadAmount) {
        this.tradeForm.swapSpreadAmount = Number(
          this.tradeForm.swapSpreadAmount
        );
      }
      if (this.tradeForm.swapSpread) {
        this.tradeForm.swapSpread = Number(this.tradeForm.swapSpread);
      }
      if (this.tradeForm.swapClientRate) {
        this.tradeForm.swapClientRate = Number(this.tradeForm.swapClientRate);
      }
      if (this.tradeForm.swapCptyRate) {
        this.tradeForm.swapCptyRate = Number(this.tradeForm.swapCptyRate);
      }
      if (this.tradeForm.swapCptyAmount1) {
        this.tradeForm.swapCptyAmount1 = Number(this.tradeForm.swapCptyAmount1);
      }
      if (this.tradeForm.swapCptyAmount2) {
        this.tradeForm.swapCptyAmount2 = Number(this.tradeForm.swapCptyAmount2);
      }
      if (this.tradeForm.swapPoints) {
        this.tradeForm.swapPoints = Number(this.tradeForm.swapPoints);
      }
    },

    arrangeInputFormats() {
      if (this.tradeForm.counterpartyRate) {
        //this.tradeForm.counterpartyRate = this.formatPrice(this.tradeForm.counterpartyRate, 4);
        this.tradeForm.counterpartyRate = Number.parseFloat(
          this.tradeForm.counterpartyRate
        ).toFixed(8);
      }
      if (this.tradeForm.spotRef) {
        // this.tradeForm.spotRef = this.formatPrice(this.tradeForm.spotRef, 4);
        this.tradeForm.spotRef = Number.parseFloat(
          this.tradeForm.spotRef
        ).toFixed(8);
      }
      if (this.tradeForm.clientRate) {
        // this.tradeForm.clientRate = this.formatPrice(this.tradeForm.clientRate, 4);
        this.tradeForm.clientRate = Number.parseFloat(
          this.tradeForm.clientRate
        ).toFixed(8);
      }
      if (this.tradeForm.cptyAmount1) {
        this.tradeForm.cptyAmount1 = Number.parseFloat(
          this.tradeForm.cptyAmount1
        ).toFixed(2);
      }
      if (this.tradeForm.cptyAmount2) {
        this.tradeForm.cptyAmount2 = Number.parseFloat(
          this.tradeForm.cptyAmount2
        ).toFixed(2);
      }
      if (this.tradeForm.spread) {
        this.tradeForm.spread = Number.parseFloat(
          this.tradeForm.spread
        ).toFixed(8);
      }
      if (this.tradeForm.spreadAmount) {
        this.tradeForm.spreadAmount = Number.parseFloat(
          this.tradeForm.spreadAmount
        ).toFixed(2);
      }
      if (this.tradeForm.comissionAmount) {
        this.tradeForm.comissionAmount = Number.parseFloat(
          this.tradeForm.comissionAmount
        ).toFixed(2);
      }
      if (this.tradeForm.clientAmount1) {
        this.tradeForm.clientAmount1 = Number.parseFloat(
          this.tradeForm.clientAmount1
        ).toFixed(2);
      }
      if (this.tradeForm.clientAmount2) {
        this.tradeForm.clientAmount2 = Number.parseFloat(
          this.tradeForm.clientAmount2
        ).toFixed(2);
      }

      if (this.tradeForm.stopPrice) {
        this.tradeForm.stopPrice = Number.parseFloat(
          this.tradeForm.stopPrice
        ).toFixed(2);
      }
      if (this.tradeForm.trailingAmount) {
        this.tradeForm.trailingAmount = Number.parseFloat(
          this.tradeForm.trailingAmount
        ).toFixed(2);
      }
      if (this.tradeForm.takeProfitLimit) {
        this.tradeForm.takeProfitLimit = Number.parseFloat(
          this.tradeForm.takeProfitLimit
        ).toFixed(8);
      }
      if (this.tradeForm.stopLossLimit) {
        this.tradeForm.stopLossLimit = Number.parseFloat(
          this.tradeForm.stopLossLimit
        ).toFixed(8);
      }
      if (this.tradeForm.stopLossStop) {
        this.tradeForm.stopLossStop = Number.parseFloat(
          this.tradeForm.stopLossStop
        ).toFixed(8);
      }
      if (this.tradeForm.cptyTakeProfitLimit) {
        this.tradeForm.cptyTakeProfitLimit = Number.parseFloat(
          this.tradeForm.cptyTakeProfitLimit
        ).toFixed(8);
      }
      if (this.tradeForm.cptyStopLossLimit) {
        this.tradeForm.cptyStopLossLimit = Number.parseFloat(
          this.tradeForm.cptyStopLossLimit
        ).toFixed(8);
      }
      if (this.tradeForm.cptyStopLossStop) {
        this.tradeForm.cptyStopLossStop = Number.parseFloat(
          this.tradeForm.cptyStopLossStop
        ).toFixed(8);
      }
      if (this.tradeForm.swapClientAmount1) {
        this.tradeForm.swapClientAmount1 = Number.parseFloat(
          this.tradeForm.swapClientAmount1
        ).toFixed(2);
      }
      if (this.tradeForm.swapClientAmount2) {
        this.tradeForm.swapClientAmount2 = Number.parseFloat(
          this.tradeForm.swapClientAmount2
        ).toFixed(2);
      }
      if (this.tradeForm.swapSpreadAmount) {
        this.tradeForm.swapSpreadAmount = Number.parseFloat(
          this.tradeForm.swapSpreadAmount
        ).toFixed(2);
      }
      if (this.tradeForm.swapSpread) {
        this.tradeForm.swapSpread = Number.parseFloat(
          this.tradeForm.swapSpread
        ).toFixed(8);
      }
      if (this.tradeForm.swapClientRate) {
        this.tradeForm.swapClientRate = Number.parseFloat(
          this.tradeForm.swapClientRate
        ).toFixed(8);
      }
      if (this.tradeForm.swapCptyRate) {
        this.tradeForm.swapCptyRate = Number.parseFloat(
          this.tradeForm.swapCptyRate
        ).toFixed(8);
      }
      if (this.tradeForm.swapCptyAmount1) {
        this.tradeForm.swapCptyAmount1 = Number.parseFloat(
          this.tradeForm.swapCptyAmount1
        ).toFixed(2);
      }
      if (this.tradeForm.swapCptyAmount2) {
        this.tradeForm.swapCptyAmount2 = Number.parseFloat(
          this.tradeForm.swapCptyAmount2
        ).toFixed(2);
      }
      if (this.tradeForm.swapPoints) {
        this.tradeForm.swapPoints = Number.parseFloat(
          this.tradeForm.swapPoints
        ).toFixed(8);
      }
    },
    checkEnableOCOInputs(input) {
      this.convertNumberInputFormat();
      if (input == "takeProfitLimit") {
        if (this.tradeForm.takeProfitLimit) {
          if (this.tradeForm.takeProfitLimit > 0) {
            if (this.tradeForm.buySell == "Buy") {
              if (this.tradeForm.stopLossStop > 0) {
                if (
                  this.tradeForm.takeProfitLimit < this.tradeForm.stopLossStop
                ) {
                  this.checkedEnableOCOInputsMessage(
                    this.$t(
                      "fx_spot_forward_swap.messages.take_profit_lower_stop_price"
                    )
                  );
                  this.tradeForm.takeProfitLimit = null;
                }
              }
              if (
                this.tradeForm.takeProfitLimit > 0 &&
                this.tradeForm.stopLossLimit > 0
              ) {
                if (
                  this.tradeForm.takeProfitLimit < this.tradeForm.stopLossLimit
                ) {
                  this.checkedEnableOCOInputsMessage(
                    this.$t(
                      "fx_spot_forward_swap.messages.take_profit_lower_limit_price"
                    )
                  );
                  this.tradeForm.takeProfitLimit = null;
                }
              }
            } else if (this.tradeForm.buySell == "Sell") {
              if (this.tradeForm.stopLossStop > 0) {
                if (
                  this.tradeForm.takeProfitLimit > this.tradeForm.stopLossStop
                ) {
                  this.checkedEnableOCOInputsMessage(
                    this.$t(
                      "fx_spot_forward_swap.messages.take_profit_greater_stop_price"
                    )
                  );
                  this.tradeForm.takeProfitLimit = null;
                }
              }
              if (
                this.tradeForm.takeProfitLimit > 0 &&
                this.tradeForm.stopLossLimit > 0
              ) {
                if (
                  this.tradeForm.takeProfitLimit > this.tradeForm.stopLossLimit
                ) {
                  this.checkedEnableOCOInputsMessage(
                    this.$t(
                      "fx_spot_forward_swap.messages.take_profit_greater_limit_price"
                    )
                  );
                  this.tradeForm.takeProfitLimit = null;
                }
              }
            }
          } else {
            this.greaterThanZeroMessage(
              this.$t(
                "fx_spot_forward_swap.messages.greater_then_zero_take_profit"
              )
            );
          }
        }
      } else if (input == "stopLossStop") {
        if (this.tradeForm.stopLossStop) {
          this.tradeForm.stopLossLimit = null;
          if (this.tradeForm.stopLossStop > 0) {
            if (this.tradeForm.buySell == "Buy") {
              if (this.tradeForm.takeProfitLimit > 0) {
                if (
                  this.tradeForm.stopLossStop > this.tradeForm.takeProfitLimit
                ) {
                  this.checkedEnableOCOInputsMessage(
                    this.$t(
                      "fx_spot_forward_swap.messages.stop_loss_greater_profit_price"
                    )
                  );
                  this.tradeForm.stopLossStop = null;
                }
              }
            } else if (this.tradeForm.buySell == "Sell") {
              if (this.tradeForm.takeProfitLimit > 0) {
                if (
                  this.tradeForm.takeProfitLimit > this.tradeForm.stopLossStop
                ) {
                  this.checkedEnableOCOInputsMessage(
                    this.$t(
                      "fx_spot_forward_swap.messages.stop_loss_lower_profit_price"
                    )
                  );
                  this.tradeForm.stopLossStop = null;
                }
              }
            }
          } else {
            this.greaterThanZeroMessage(
              this.$t(
                "fx_spot_forward_swap.messages.greater_then_zero_stop_loss_stop"
              )
            );
          }
        }
      } else if (input == "stopLossLimit") {
        if (this.tradeForm.stopLossLimit) {
          this.tradeForm.stopLossStop = null;
          if (this.tradeForm.stopLossLimit > 0) {
            if (this.tradeForm.buySell == "Buy") {
              if (this.tradeForm.takeProfitLimit > 0) {
                if (
                  this.tradeForm.stopLossLimit > this.tradeForm.takeProfitLimit
                ) {
                  this.checkedEnableOCOInputsMessage(
                    this.$t(
                      "fx_spot_forward_swap.messages.stop_loss_limit_greater_profit_price"
                    )
                  );
                  this.tradeForm.stopLossLimit = null;
                }
              }
            } else if (this.tradeForm.buySell == "Sell") {
              if (this.tradeForm.takeProfitLimit > 0) {
                if (
                  this.tradeForm.takeProfitLimit > this.tradeForm.stopLossLimit
                ) {
                  this.checkedEnableOCOInputsMessage(
                    this.$t(
                      "fx_spot_forward_swap.messages.stop_loss_limit_lower_profit_price"
                    )
                  );
                  this.tradeForm.stopLossLimit = null;
                }
              }
            }
          } else {
            this.greaterThanZeroMessage(
              this.$t(
                "fx_spot_forward_swap.messages.greater_then_zero_stop_loss_limit"
              )
            );
          }
        }
      }
      this.arrangeInputFormats();
    },
    checkEnableOCOCptyInputs(input) {
      this.convertNumberInputFormat();
      if (input == "takeProfitLimit") {
        if (this.tradeForm.cptyTakeProfitLimit) {
          if (this.tradeForm.cptyTakeProfitLimit > 0) {
            if (this.tradeForm.buySell == "Buy") {
              if (this.tradeForm.cptyStopLossStop > 0) {
                if (
                  this.tradeForm.cptyTakeProfitLimit <
                  this.tradeForm.cptyStopLossStop
                ) {
                  this.checkedEnableOCOInputsMessage(
                    this.$t(
                      "fx_spot_forward_swap.messages.take_profit_lower_stop_price"
                    )
                  );
                  this.tradeForm.cptyTakeProfitLimit = null;
                }
              }
              if (
                this.tradeForm.cptyTakeProfitLimit > 0 &&
                this.tradeForm.cptyStopLossLimit > 0
              ) {
                if (
                  this.tradeForm.cptyTakeProfitLimit <
                  this.tradeForm.cptyStopLossLimit
                ) {
                  this.checkedEnableOCOInputsMessage(
                    this.$t(
                      "fx_spot_forward_swap.messages.take_profit_lower_limit_price"
                    )
                  );
                  this.tradeForm.cptyTakeProfitLimit = null;
                }
              }
            } else if (this.tradeForm.buySell == "Sell") {
              if (this.tradeForm.cptyStopLossStop > 0) {
                if (
                  this.tradeForm.cptyTakeProfitLimit >
                  this.tradeForm.cptyStopLossStop
                ) {
                  this.checkedEnableOCOInputsMessage(
                    this.$t(
                      "fx_spot_forward_swap.messages.take_profit_greater_stop_price"
                    )
                  );
                  this.tradeForm.cptyTakeProfitLimit = null;
                }
              }
              if (
                this.tradeForm.cptyTakeProfitLimit > 0 &&
                this.tradeForm.cptyStopLossLimit > 0
              ) {
                if (
                  this.tradeForm.cptyTakeProfitLimit >
                  this.tradeForm.cptyStopLossLimit
                ) {
                  this.checkedEnableOCOInputsMessage(
                    this.$t(
                      "fx_spot_forward_swap.messages.take_profit_greater_limit_price"
                    )
                  );
                  this.tradeForm.cptyTakeProfitLimit = null;
                }
              }
            }
          } else {
            this.greaterThanZeroMessage(
              this.$t(
                "fx_spot_forward_swap.messages.greater_then_zero_take_profit"
              )
            );
          }
        }
      } else if (input == "stopLossStop") {
        if (this.tradeForm.cptyStopLossStop) {
          this.tradeForm.cptyStopLossLimit = null;
          if (this.tradeForm.cptyStopLossStop > 0) {
            if (this.tradeForm.buySell == "Buy") {
              if (this.tradeForm.cptyTakeProfitLimit > 0) {
                if (
                  this.tradeForm.cptyStopLossStop >
                  this.tradeForm.cptyTakeProfitLimit
                ) {
                  this.checkedEnableOCOInputsMessage(
                    this.$t(
                      "fx_spot_forward_swap.messages.stop_loss_greater_profit_price"
                    )
                  );
                  this.tradeForm.cptyStopLossStop = null;
                }
              }
            } else if (this.tradeForm.buySell == "Sell") {
              if (this.tradeForm.cptyTakeProfitLimit > 0) {
                if (
                  this.tradeForm.cptyTakeProfitLimit >
                  this.tradeForm.cptyStopLossStop
                ) {
                  this.checkedEnableOCOInputsMessage(
                    this.$t(
                      "fx_spot_forward_swap.messages.stop_loss_lower_profit_price"
                    )
                  );
                  this.tradeForm.cptyStopLossStop = null;
                }
              }
            }
          } else {
            this.greaterThanZeroMessage(
              this.$t(
                "fx_spot_forward_swap.messages.greater_then_zero_stop_loss_stop"
              )
            );
          }
        }
      } else if (input == "stopLossLimit") {
        if (this.tradeForm.cptyStopLossLimit) {
          this.tradeForm.cptyStopLossStop = null;
          if (this.tradeForm.cptyStopLossLimit > 0) {
            if (this.tradeForm.buySell == "Buy") {
              if (this.tradeForm.cptyTakeProfitLimit > 0) {
                if (
                  this.tradeForm.cptyStopLossLimit >
                  this.tradeForm.cptyTakeProfitLimit
                ) {
                  this.checkedEnableOCOInputsMessage(
                    this.$t(
                      "fx_spot_forward_swap.messages.stop_loss_limit_greater_profit_price"
                    )
                  );
                  this.tradeForm.cptyStopLossLimit = null;
                }
              }
            } else if (this.tradeForm.buySell == "Sell") {
              if (this.tradeForm.cptyTakeProfitLimit > 0) {
                if (
                  this.tradeForm.cptyTakeProfitLimit >
                  this.tradeForm.cptyStopLossLimit
                ) {
                  this.checkedEnableOCOInputsMessage(
                    this.$t(
                      "fx_spot_forward_swap.messages.stop_loss_limit_lower_profit_price"
                    )
                  );
                  this.tradeForm.cptyStopLossLimit = null;
                }
              }
            }
          } else {
            this.greaterThanZeroMessage(
              this.$t(
                "fx_spot_forward_swap.messages.greater_then_zero_stop_loss_limit"
              )
            );
          }
        }
      }
      this.arrangeInputFormats();
    },

    changeEnableOCOOrder() {
      if (!this.tradeForm.enableConnectedOCOOrders) {
        this.tradeForm.takeProfitLimit = null;
        this.tradeForm.stopLossLimit = null;
        this.tradeForm.stopLossStop = null;
        this.tradeForm.cptyTakeProfitLimit = null;
        this.tradeForm.cptyStopLossLimit = null;
        this.tradeForm.cptyStopLossStop = null;
      }
    },

    getHolidays() {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getHolidays`, this.user)
          .then((res) => {
            resolve(res.data);
          })
          .catch((error) => reject(error));
      });
    },

    checkHolidaysDate(holidays, tradeDate) {
      let checkDate = tradeDate;
      let status = true;
      holidays.forEach((h) => {
        if (this.dateFormat(h.Date) == this.dateFormat(tradeDate)) {
          checkDate.setDate(checkDate.getDate() + 1);
          status = false;
          this.checkHolidaysDate(holidays, checkDate);
        }
      });
      if (status) {
        this.checkedHolidayDate = checkDate;
      }
    },

    arrangeTPlus() {
      if (this.tradeForm.tradeDate && this.tradeForm.tradeDateName != null) {
        if (this.tradeForm.Tplus > 0) {
          this.getHolidays().then((holidays) => {
            if (holidays.length > 0) {
              if (!this.tradeForm.tradeDate) {
                return;
              }

              let tradeDate = new Date(
                this.MDYdateFormat(this.tradeForm.tradeDate)
              );

              let period = this.tradeForm.Tplus;
              tradeDate.setDate(tradeDate.getDate() + period);

              this.checkHolidaysDate(holidays, tradeDate);

              //found settlement date name
              let settlementDateName = this.weekday[
                this.checkedHolidayDate.getDay()
              ];

              //check weekend settlement date
              if (settlementDateName == "Saturday") {
                this.checkedHolidayDate.setDate(
                  this.checkedHolidayDate.getDate() + 2
                );
              } else if (settlementDateName == "Sunday") {
                this.checkedHolidayDate.setDate(
                  this.checkedHolidayDate.getDate() + 2
                );
              }

              //again check holiday
              this.checkHolidaysDate(holidays, this.checkedHolidayDate);

              // set settlement date
              this.tradeForm.settlementDate = this.dateFormat(
                this.checkedHolidayDate
              );
              this.tradeForm.settlementDateName = this.weekday[
                this.checkedHolidayDate.getDay()
              ];
            } else {
              if (!this.tradeForm.tradeDate) {
                return;
              }

              let tradeDate = new Date(
                this.MDYdateFormat(this.tradeForm.tradeDate)
              );

              let period = this.tradeForm.Tplus;

              tradeDate.setDate(tradeDate.getDate() + period);

              //found settlement date name
              let settlementDateName = this.weekday[tradeDate.getDay()];

              //check weekend settlement date
              if (settlementDateName == "Saturday") {
                tradeDate.setDate(tradeDate.getDate() + 2);
              } else if (settlementDateName == "Sunday") {
                tradeDate.setDate(tradeDate.getDate() + 2);
              }

              // set settlement date
              this.tradeForm.settlementDate = this.dateFormat(tradeDate);
              this.tradeForm.settlementDateName = this.weekday[
                tradeDate.getDay()
              ];
            }
          });
        }
      }
    },

    checkTraSettlCptyDate(type, defDateName = false) {
      if (type == "Trade Date" && this.tradeForm.tradeDate) {
        if (defDateName) {
          this.defTradeDateName = null;
        }

        let tradeDate = new Date(this.MDYdateFormat(this.tradeForm.tradeDate));

        //set trade date name
        this.tradeForm.tradeDateName = this.weekday[tradeDate.getDay()];

        //arrange as T+
        if (this.tradeForm.Tplus > 0) {
          this.arrangeTPlus();
        }

        if (
          Date.parse(this.YMDdateFormat(this.tradeForm.tradeDate)) >
          Date.parse(this.YMDdateFormat(this.tradeForm.settlementDate))
        ) {
          this.checkedTraSettlDateMessage(
            this.$t(
              "fx_spot_forward_swap.messages.trade_date_after_settlement_date"
            )
          );
          this.tradeForm.tradeDate = null;
        }

        if (
          Date.parse(this.YMDdateFormat(this.tradeForm.tradeDate)) <
          Date.parse(this.YMDdateFormat(this.tradeForm.orderDate))
        ) {
          this.checkedTraSettlDateMessage(
            this.$t(
              "fx_spot_forward_swap.messages.trade_date_before_order_date"
            )
          );
          this.tradeForm.tradeDate = null;
        }
        if (this.tradeForm.productType == "FI") {
          if (
            Date.parse(this.YMDdateFormat(this.tradeForm.tradeDate)) <
            Date.parse(this.YMDdateFormat(this.tradeForm.cptyOrderDate))
          ) {
            this.checkedTraSettlDateMessage(
              this.$t(
                "fx_spot_forward_swap.messages.trade_date_before_counterparty_order_date"
              )
            );
            this.tradeForm.tradeDate = null;
          }
        }
      } else if (type == "Settlement Date" && this.tradeForm.settlementDate) {
        let settleDate = new Date(
          this.MDYdateFormat(this.tradeForm.settlementDate)
        );

        //set settle date name
        this.tradeForm.settlementDateName = this.weekday[settleDate.getDay()];

        if (
          Date.parse(this.YMDdateFormat(this.tradeForm.tradeDate)) >
          Date.parse(this.YMDdateFormat(this.tradeForm.settlementDate))
        ) {
          this.checkedTraSettlDateMessage(
            this.$t(
              "fx_spot_forward_swap.messages.trade_date_after_settlement_date"
            )
          );
          this.tradeForm.settlementDate = null;
        }

        if (
          Date.parse(this.YMDdateFormat(this.tradeForm.settlementDate)) <
          Date.parse(this.YMDdateFormat(this.tradeForm.orderDate))
        ) {
          this.checkedTraSettlDateMessage(
            this.$t(
              "fx_spot_forward_swap.messages.trade_date_after_settlement_date"
            )
          );
          this.tradeForm.settlementDate = null;
        }
        if (this.tradeForm.productType == "FI") {
          if (
            Date.parse(this.YMDdateFormat(this.tradeForm.settlementDate)) <
            Date.parse(this.YMDdateFormat(this.tradeForm.cptyOrderDate))
          ) {
            this.checkedTraSettlDateMessage(
              this.$t(
                "fx_spot_forward_swap.messages.settlement_date_before_counterparty_order_date"
              )
            );
            this.tradeForm.settlementDate = null;
          }
        }
      } else if ("Cpty Order Date" && this.tradeForm.cptyOrderDate) {
        if (defDateName) {
          this.tradeForm.cptyOrderDateName = null;
        }
        if (
          Date.parse(this.YMDdateFormat(this.tradeForm.tradeDate)) <
          Date.parse(this.YMDdateFormat(this.tradeForm.cptyOrderDate))
        ) {
          this.checkedTraSettlDateMessage(
            this.$t(
              "fx_spot_forward_swap.messages.trade_date_before_counterparty_order_date"
            )
          );
          this.tradeForm.cptyOrderDate = null;
        }

        if (
          Date.parse(this.YMDdateFormat(this.tradeForm.settlementDate)) <
          Date.parse(this.YMDdateFormat(this.tradeForm.cptyOrderDate))
        ) {
          this.checkedTraSettlDateMessage(
            this.$t(
              "fx_spot_forward_swap.messages.settlement_date_before_counterparty_order_date"
            )
          );
          this.tradeForm.cptyOrderDate = null;
        }
      }
    },
    checkTime(type) {
      if (type == "Order Time") {
        //set time
        if (
          this.tradeForm.orderTime != null &&
          this.tradeForm.orderTime.length < 8
        ) {
          for (let i = this.tradeForm.orderTime.length; i < 8; i++) {
            if (i == 2) {
              this.tradeForm.orderTime += ":";
            } else if (i == 5) {
              this.tradeForm.orderTime += ":";
            } else {
              this.tradeForm.orderTime += "0";
            }
          }
        }

        if (
          Date.parse(this.YMDdateFormat(this.tradeForm.tradeDate)) ==
          Date.parse(this.YMDdateFormat(this.tradeForm.orderDate))
        ) {
          if (this.tradeForm.orderTime > this.tradeForm.executionTime) {
            this.checkTimeMessage(
              this.$t(
                "fx_spot_forward_swap.messages.order_time_later_execution_time"
              )
            );
            this.tradeForm.orderTime = null;
          }
        }

        this.checkOrdersTime("Order Time");
      } else if ("Execution Time") {
        //set time
        if (
          this.tradeForm.executionTime != null &&
          this.tradeForm.executionTime.length < 8
        ) {
          for (let i = this.tradeForm.executionTime.length; i < 8; i++) {
            if (i == 2) {
              this.tradeForm.executionTime += ":";
            } else if (i == 5) {
              this.tradeForm.executionTime += ":";
            } else {
              this.tradeForm.executionTime += "0";
            }
          }
        }

        if (
          Date.parse(this.YMDdateFormat(this.tradeForm.tradeDate)) ==
          Date.parse(this.YMDdateFormat(this.tradeForm.orderDate))
        ) {
          if (this.tradeForm.orderTime > this.tradeForm.executionTime) {
            this.checkTimeMessage(
              this.$t(
                "fx_spot_forward_swap.messages.execution_time_later_order_time"
              )
            );
            this.tradeForm.executionTime = null;
          }
        }

        //set time
        if (
          this.tradeForm.cptyOrderTime != null &&
          this.tradeForm.cptyOrderTime.length < 8
        ) {
          for (let i = this.tradeForm.cptyOrderTime.length; i < 8; i++) {
            if (i == 2) {
              this.tradeForm.cptyOrderTime += ":";
            } else if (i == 5) {
              this.tradeForm.cptyOrderTime += ":";
            } else {
              this.tradeForm.cptyOrderTime += "0";
            }
          }
        }

        if (
          Date.parse(this.YMDdateFormat(this.tradeForm.tradeDate)) ==
          Date.parse(this.YMDdateFormat(this.tradeForm.cptyOrderDate))
        ) {
          if (this.tradeForm.cptyOrderTime > this.tradeForm.executionTime) {
            this.checkTimeMessage(
              this.$t(
                "fx_spot_forward_swap.messages.execution_time_later_counterparty_order_time"
              )
            );
            this.tradeForm.executionTime = null;
          }
        }
      }
    },
    checkOrdersTime(type) {
      if (type == "Order Duration Time") {
        //set time
        if (
          this.tradeForm.orderDurationTime != null &&
          this.tradeForm.orderDurationTime.length < 8
        ) {
          for (let i = this.tradeForm.orderDurationTime.length; i < 8; i++) {
            if (i == 2) {
              this.tradeForm.orderDurationTime += ":";
            } else if (i == 5) {
              this.tradeForm.orderDurationTime += ":";
            } else {
              this.tradeForm.orderDurationTime += "0";
            }
          }
        }

        if (
          Date.parse(this.YMDdateFormat(this.tradeForm.orderDate)) ==
          Date.parse(this.YMDdateFormat(this.tradeForm.orderDurationDate))
        ) {
          if (this.tradeForm.orderTime > this.tradeForm.orderDurationTime) {
            this.checkTimeMessage(
              this.$t(
                "fx_spot_forward_swap.messages.order_duration_later_order_time"
              )
            );
            this.tradeForm.orderDurationTime = null;
          }
        }
      } else if (type == "Order Time") {
        //set time
        if (
          this.tradeForm.orderTime != null &&
          this.tradeForm.orderTime.length < 8
        ) {
          for (let i = this.tradeForm.orderTime.length; i < 8; i++) {
            if (i == 2) {
              this.tradeForm.orderTime += ":";
            } else if (i == 5) {
              this.tradeForm.orderTime += ":";
            } else {
              this.tradeForm.orderTime += "0";
            }
          }
        }

        if (
          Date.parse(this.YMDdateFormat(this.tradeForm.orderDate)) ==
          Date.parse(this.YMDdateFormat(this.tradeForm.orderDurationDate))
        ) {
          if (this.tradeForm.orderTime > this.tradeForm.orderDurationTime) {
            this.checkTimeMessage(
              this.$t(
                "fx_spot_forward_swap.messages.order_time_less_order_duration_time"
              )
            );
            this.tradeForm.orderTime = null;
          }
        }
      }
    },
    checkOrdersDate(type, defDateName = false) {
      if (type == "Order Date") {
        if (defDateName) {
          this.tradeForm.orderDateName = null;
        }

        this.tradeForm.cptyOrderDate = this.tradeForm.orderDate;
        this.tradeForm.tradeDate = this.tradeForm.orderDate;

        if (this.tradeForm.timeInForce == "DAY") {
          this.tradeForm.orderDurationDate = this.tradeForm.orderDate;
        }

        if (
          Date.parse(this.YMDdateFormat(this.tradeForm.orderDate)) >
          Date.parse(this.YMDdateFormat(this.tradeForm.orderDurationDate))
        ) {
          this.checkedTraSettlDateMessage(
            this.$t(
              "fx_spot_forward_swap.messages.order_date_after_order_duration_date"
            )
          );
          this.tradeForm.orderDate = null;
        }
      } else if (type == "Order Duration Date") {
        if (defDateName) {
          this.tradeForm.orderDurationDateName = null;
        }
        if (
          Date.parse(this.YMDdateFormat(this.tradeForm.orderDate)) >
          Date.parse(this.YMDdateFormat(this.tradeForm.orderDurationDate))
        ) {
          this.checkedTraSettlDateMessage(
            this.$t(
              "fx_spot_forward_swap.messages.order_duration_less_order_date"
            )
          );
          this.tradeForm.orderDurationDate = null;
        }
      }
    },
    changeEntryType(type) {
      if (this.tradeForm.entryType == "Execution") {
        this.convertNumberInputFormat();
        this.changedCounterpartyRate();
        this.changedClientRate();
        this.arrangeInputFormats();
      }
    },
    changeStopPrice() {
      // this.convertNumberInputFormat();
      this.arrangeInputFormats();
    },
    changeTrailingAmount() {
      // this.convertNumberInputFormat();
      this.arrangeInputFormats();
    },
    changeTimeInForceType() {
      if (this.tradeForm.timeInForce == "DAY") {
        this.tradeForm.orderDurationDate = this.tradeForm.orderDate;
        this.tradeForm.orderDurationTime = moment()
          .endOf("day")
          .format("HH:mm:ss")
          .toString();
        this.orderDurationDataDisabled = true;
        this.orderDurationTimeDisabled = true;
        this.orderDurationInputsVisible = true;
      } else if (this.tradeForm.timeInForce == "GTC") {
        this.orderDurationInputsVisible = false;
        this.tradeForm.orderDurationDate = null;
        this.tradeForm.orderDurationTime = null;
      } else if (this.tradeForm.timeInForce == "GTD") {
        this.tradeForm.orderDurationDate = this.tradeForm.orderDate;
        this.tradeForm.orderDurationTime = moment()
          .endOf("day")
          .format("HH:mm:ss")
          .toString();
        this.orderDurationDataDisabled = false;
        this.orderDurationTimeDisabled = false;
        this.orderDurationInputsVisible = true;
      } else if (this.tradeForm.timeInForce == "FOK") {
        this.orderDurationInputsVisible = false;
        this.tradeForm.orderDurationDate = null;
        this.tradeForm.orderDurationTime = null;
      } else if (this.tradeForm.timeInForce == "FAK") {
        this.orderDurationInputsVisible = false;
        this.tradeForm.orderDurationDate = null;
        this.tradeForm.orderDurationTime = null;
      }
    },
    resetTradeModal() {
      this.enterNewTradeModal = false;
      this.tradeModalLoading = false;
      this.checkedHolidayDate = null;
      this.showDefaultEntry = false;
      this.fetchedTradeData = {};
      // this.searchedCounterparties = [];
      // this.searchedClients = [];

      this.tradeForm = {
        costCenter: null,
        orderDurationDate: null,
        orderDurationDateName: null,
        orderDurationTime: null,
        entryType: null,
        buySell: null,
        orderType: null,
        timeInForce: null,
        orderDate: null,
        orderDateName: null,
        orderTime: null,
        cptyOrderDate: null,
        cptyOrderDateName: null,
        cptyOrderTime: null,
        enableConnectedOCOOrders: false,
        stopPrice: null,
        trailingAmount: null,
        takeProfitLimit: null,
        stopLossStop: null,
        stopLossLimit: null,
        cptyTakeProfitLimit: null,
        cptyStopLossStop: null,
        cptyStopLossLimit: null,
        client: null,
        clientAccount: null,
        counterparty: null,
        cptyAccount: null,
        productType: null,
        settlementType: null,
        counterpartyRate: null,
        cptyCalculateFromRate1: true,
        clientRate: null,
        clientCalculateFromRate2: true,
        cptyAmount1: null,
        cptyAmount2: null,
        cptyCcy1: null,
        cptyCcy2: null,
        cptyBuySell1: null,
        cptyBuySell2: null,
        calcFromSpread: "Calculate Spread From Client Rate",
        spread: null,
        clientAmount1: null,
        clientAmount2: null,
        clientCcy1: null,
        clientCcy2: null,
        clientBuySell1: null,
        clientBuySell2: null,
        spotRef: null,
        spreadAmount: null,
        spreadCcy: null,
        comissionAmount: null,
        comissionCcy: null,
        cutOffTime: null,
        deliverableCcy: null,
        tradeDate: null,
        tradeDateName: null,
        defTradeDateName: null,
        settlementDate: null,
        settlementDateName: null,
        Tplus: null,
        executionTime: null,
        counterpartyTrader: null,
        scfUser: null,
        orderTakenVia: null,
        orderGivenThrough: null,
        actingCapaticy: null,
        note: null,
        swapTradeDate: null,
        swapTradeDateName: null,
        maturity: null,
        swapSettleDate: null,
        swapSettleDateName: null,
        swapPoints: null,
        swapCptyRate: null,
        swapCptyAmount1: null,
        swapCptyCcy1: null,
        swapCptyBuySell1: null,
        swapCptyAmount2: null,
        swapCptyCcyType2: null,
        swapCptyBuySell2: null,
        swapSpread: null,
        swapCalcFromSpread: "Calculate Client Rate From Spread",
        swapClientRate: null,
        swapClientAmount1: null,
        swapClientCcy1: null,
        swapClientBuySell1: null,
        swapClientAmount2: null,
        swapClientCcy2: null,
        swapClientBuySell2: null,
        swapSpreadAmount: null,
        swapSpreadCcy: null,
        uti: null,
        uniqueLinkID: null,
        tradeRef: null,
        entryType2: null,
        tradeStart: null,
        tradeEnd: null,
        settleStart: null,
        settleEnd: null,
      };

      // this.optClients = [];
      this.optPriceTypes = [];
      // this.optCounterParties = [];
      this.optCounterPartyTraders = [];
      this.optScfUsers = [];
      this.optOrderTakenVias = [];
      this.optOrderGivenThroughs = [];
    },

    greaterThanZeroMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: `${val} must be greater than 0`,
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    },
    checkedEnableOCOInputsMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    checkRequiredInputsMessage(message, timeout) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Warning",
            text:
              this.$t("fx_spot_forward_swap.messages.required_fill_inputs") +
              ` ${message}`,
            icon: "AlertTriangleIcon",
            variant: "warning",
          },
        },
        {
          timeout,
        }
      );
    },
    databaseErrorMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: this.$t(
            "fx_spot_forward_swap.toast_messages.database_connection_error"
          ),
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    },
    checkedTraSettlDateMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    checkTimeMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    errorMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    notFoundMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: `The searched ${val} data is not found`,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    wrongToastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: val,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    successMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Successfully",
          text: val,
          icon: "ThumbsUpIcon",
          variant: "success",
        },
      });
    },
    createTradeToastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Successfully",
          text: this.$t(
            "fx_spot_forward_swap.toast_messages.trade_create_success"
          ),
          icon: "ThumbsUpIcon",
          variant: "success",
        },
      });
    },
    saveDefaultEntrySuccessToastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Successfully",
          text: this.$t("general_title.save_default_success"),
          icon: "ThumbsUpIcon",
          variant: "success",
        },
      });
    },
    notAuthToastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Not Authorized",
          text: this.$t("general_messages.not_authorized_message"),
          icon: "UserXIcon",
          variant: "warning",
        },
      });
    },
    clearDate() {
      this.newHoliday.date = null;
    },
    DMYdateFormat(val) {
      if (val != undefined) {
        return moment(String(val), "DD-MM-YYYY").format("DD-MM-YYYY");
      }
    },
    YMDdateFormat(val) {
      if (val != undefined) {
        return moment(String(val), "DD-MM-YYYY").format("YYYY-MM-DD");
      }
    },
    MDYdateFormat(val) {
      if (val != undefined) {
        return moment(String(val), "DD-MM-YYYY").format("MM-DD-YYYY");
      }
    },
    dateFormat(val) {
      if (val != undefined) {
        return moment(String(val)).format("DD-MM-YYYY");
      }
    },
    timeFormat(val) {
      if (val != undefined) {
        return moment(String(val), "HH:mm:ss")._i;
      }
    },
    HmstimeFormat(val) {
      if (val != undefined) {
        return moment(String(val)).format("HH:mm:ss");
      }
    },
    dateFormatWithTime(val) {
      if (val != undefined) {
        return moment(String(val), "DD-MM-YYYY HH:mm:ss").format(
          "DD-MM-YYYY HH:mm:ss"
        );
      }
    },
    formatPrice(value, exp) {
      if (value == undefined || value == null) return Math.round(0);
      if (typeof exp === "undefined" || +exp === 0) return Math.round(value);

      value = +value;
      exp = +exp;

      if (isNaN(value) || !(typeof exp === "number" && exp % 1 === 0))
        return NaN;

      // Shift
      value = value.toString().split("e");
      value = Math.round(
        +(value[0] + "e" + (value[1] ? +value[1] + exp : exp))
      );

      // Shift back
      value = value.toString().split("e");
      return +(value[0] + "e" + (value[1] ? +value[1] - exp : -exp));
    },

    roundToEight(val) {
      if (val != undefined && val != null) {
        return Math.round(val * 100000000) / 100000000;
      }
    },
    roundToPointFour(val) {
      if (val != undefined && val > 0) {
        return Math.round(val * 10000) / 10000;
        // return +(Math.round(val + "e+2") + "e-2");
      }
    },
    setTradeData() {
      if (
        this.tradeForm.timeInForce == "GTC" ||
        this.tradeForm.timeInForce == "FOK" ||
        this.tradeForm.timeInForce == "FAK"
      ) {
        this.tradeForm.orderDurationDate = null;
        this.tradeForm.orderDurationTime = null;
      }

      if (!this.tradeForm.enableConnectedOCOOrders) {
        this.tradeForm.takeProfitLimit = null;
        this.tradeForm.stopLossStop = null;
        this.tradeForm.stopLossLimit = null;
        this.tradeForm.cptyTakeProfitLimit = null;
        this.tradeForm.cptyStopLossStop = null;
        this.tradeForm.cptyStopLossLimit = null;
      }

      let setTrade = {
        costCenter: this.tradeForm.costCenter,
        client: this.tradeForm.client != null ? this.tradeForm.client : null,
        clientAcc:
          this.tradeForm.clientAccount != null
            ? this.tradeForm.clientAccount
            : null,
        counterparty:
          this.tradeForm.counterparty != null
            ? this.tradeForm.counterparty
            : null,
        cptyAccount:
          this.tradeForm.cptyAccount != null
            ? this.tradeForm.cptyAccount
            : null,
        cptyRate:
          this.tradeForm.counterpartyRate != null
            ? parseFloat(this.tradeForm.counterpartyRate)
            : null,
        cptyBuySell1:
          this.tradeForm.cptyBuySell1 != null
            ? this.tradeForm.cptyBuySell1
            : null,
        cptyCcy1:
          this.tradeForm.cptyCcy1 != null ? this.tradeForm.cptyCcy1 : null,
        cptyCcy2:
          this.tradeForm.cptyCcy2 != null ? this.tradeForm.cptyCcy2 : null,
        cptyAmount1:
          this.tradeForm.cptyAmount1 != null
            ? parseFloat(this.tradeForm.cptyAmount1)
            : null,
        cptyAmount2:
          this.tradeForm.cptyAmount2 != null
            ? parseFloat(this.tradeForm.cptyAmount2)
            : null,
        spreadCcy:
          this.tradeForm.spreadCcy != null ? this.tradeForm.spreadCcy : null,
        spread:
          this.tradeForm.spread != null
            ? parseFloat(this.tradeForm.spread)
            : null,
        spreadAmount:
          this.tradeForm.spreadAmount != null
            ? parseFloat(this.tradeForm.spreadAmount)
            : null,
        comissionCcy:
          this.tradeForm.comissionCcy != null
            ? this.tradeForm.comissionCcy
            : null,
        comissionAmount:
          this.tradeForm.comissionAmount != null
            ? parseFloat(this.tradeForm.comissionAmount)
            : null,
        clientRate:
          this.tradeForm.clientRate != null
            ? parseFloat(this.tradeForm.clientRate)
            : null,
        clientAmount1:
          this.tradeForm.clientAmount1 != null
            ? parseFloat(this.tradeForm.clientAmount1)
            : null,
        clientAmount2:
          this.tradeForm.clientAmount2 != null
            ? parseFloat(this.tradeForm.clientAmount2)
            : null,
        tradeDate:
          this.tradeForm.tradeDate != null
            ? this.YMDdateFormat(this.tradeForm.tradeDate)
            : null,

        settlementDate:
          this.tradeForm.settlementDate != null
            ? this.YMDdateFormat(this.tradeForm.settlementDate)
            : null,
        orderTime:
          this.tradeForm.orderTime != null
            ? this.timeFormat(this.tradeForm.orderTime)
            : null,
        executionTime:
          this.tradeForm.executionTime != null
            ? this.timeFormat(this.tradeForm.executionTime)
            : null,

        cptyTrader:
          this.tradeForm.counterpartyTrader != null
            ? this.tradeForm.counterpartyTrader
            : null,
        tcUser: this.tradeForm.scfUser != null ? this.tradeForm.scfUser : null,
        orderTakenVia:
          this.tradeForm.orderTakenVia != null
            ? this.tradeForm.orderTakenVia
            : null,
        orderGivenThrough:
          this.tradeForm.orderGivenThrough != null
            ? this.tradeForm.orderGivenThrough
            : null,
        actingCapaticy:
          this.tradeForm.actingCapaticy != null
            ? this.tradeForm.actingCapaticy
            : null,
        traderNote: this.tradeForm.note != null ? this.tradeForm.note : null,
        savedBy: this.user.name
          ? this.user.name + " " + this.user.surname
          : null,
        saveDate: moment().format("YYYY-MM-DD"),
        saveTime: moment().format("HH:mm:ss"),
        amendedBy: null,
        amendmentDate: null,
        amentmentTime: null,
        amendmentReason: null,
        canceled: 0,
        canceledBy: null,
        canceledDate: null,
        canceledTime: null,
        canceledReason: null,
        relatedFxOptionId: null,
        cpRef: null,
        productType:
          this.tradeForm.productType != null
            ? this.tradeForm.productType
            : null,
        settlementType:
          this.tradeForm.settlementType != null
            ? this.tradeForm.settlementType
            : null,
        spotRef:
          this.tradeForm.spotRef != null
            ? parseFloat(this.tradeForm.spotRef)
            : null,
        cutOffTime:
          this.tradeForm.settlementType != null
            ? this.tradeForm.settlementType
            : null,
        deliverableCcy:
          this.tradeForm.deliverableCcy != null
            ? this.tradeForm.deliverableCcy
            : null,
        confirmationDate: null,
        confirmationTime: null,
        confirmationTrader: null,
        cptyUti: null,
        tcUti: this.tradeForm.uti != null ? this.tradeForm.uti : null,
        uniqueLinkId:
          this.tradeForm.uniqueLinkID != null
            ? this.tradeForm.uniqueLinkID
            : null,
        clearingMemberId: null,
        confirmationDateClient: null,
        confirmationTimeClient: null,
        rabate: null,
        future4: "",
        future5: "",
        future6: "",
        future7: "",
        future8: "",
        future9: "",
        future10: "",
        future11: "",
        future12: "",
        future13: "",
        future14: "",
        future15: "",
        entryType:
          this.tradeForm.entryType != null ? this.tradeForm.entryType : null,
        buySell: this.tradeForm.buySell != null ? this.tradeForm.buySell : null,
        orderType:
          this.tradeForm.orderType != null ? this.tradeForm.orderType : null,
        timeInForce:
          this.tradeForm.timeInForce != null
            ? this.tradeForm.timeInForce
            : null,
        stopPrice:
          this.tradeForm.stopPrice != null
            ? parseFloat(this.tradeForm.stopPrice)
            : null,

        trailingAmount:
          this.tradeForm.trailingAmount != null
            ? parseFloat(this.tradeForm.trailingAmount)
            : null,

        takeProfitLimit: this.tradeForm.takeProfitLimit
          ? parseFloat(this.tradeForm.takeProfitLimit)
          : null,

        stopLoss: this.tradeForm.stopLossStop
          ? parseFloat(this.tradeForm.stopLossStop)
          : null,

        stopLossLimit: this.tradeForm.stopLossLimit
          ? parseFloat(this.tradeForm.stopLossLimit)
          : null,

        cptyTakeProfitLimit: this.tradeForm.cptyTakeProfitLimit
          ? parseFloat(this.tradeForm.cptyTakeProfitLimit)
          : null,

        cptyStopLoss: this.tradeForm.cptyStopLossStop
          ? parseFloat(this.tradeForm.cptyStopLossStop)
          : null,

        cptyStopLossLimit: this.tradeForm.cptyStopLossLimit
          ? parseFloat(this.tradeForm.cptyStopLossLimit)
          : null,

        orderAmount: null,

        enableOCO:
          this.tradeForm.enableConnectedOCOOrders != null
            ? this.tradeForm.enableConnectedOCOOrders.toString()
            : null,

        orderPrice: null,

        orderDate:
          this.tradeForm.orderDate != null
            ? this.YMDdateFormat(this.tradeForm.orderDate)
            : null,

        cptyOrderDate:
          this.tradeForm.cptyOrderDate != null
            ? this.YMDdateFormat(this.tradeForm.cptyOrderDate)
            : null,

        counterpartOrderTime:
          this.tradeForm.cptyOrderTime != null
            ? this.timeFormat(this.tradeForm.cptyOrderTime)
            : null,

        orderDurationDate:
          this.tradeForm.orderDurationDate != null
            ? this.YMDdateFormat(this.tradeForm.orderDurationDate)
            : null,
        orderDurationTime:
          this.tradeForm.orderDurationTime != null
            ? this.timeFormat(this.tradeForm.orderDurationTime)
            : null,

        ocoLink: null,
        swapMaturity:
          this.tradeForm.maturity != null ? this.tradeForm.maturity : null,
        swapForwardRate:
          this.tradeForm.swapPoints != null
            ? parseFloat(this.tradeForm.swapPoints)
            : null,
        swapSpread:
          this.tradeForm.swapSpread != null
            ? parseFloat(this.tradeForm.swapSpread)
            : null,
        swapClientRate:
          this.tradeForm.swapClientRate != null
            ? parseFloat(this.tradeForm.swapClientRate)
            : null,
        swapClientBuySell1:
          this.tradeForm.swapClientBuySell1 != null
            ? this.tradeForm.swapClientBuySell1
            : null,
        swapClientBuySell2:
          this.tradeForm.swapClientBuySell2 != null
            ? this.tradeForm.swapClientBuySell2
            : null,
        swapClientCcy1:
          this.tradeForm.swapClientCcy1 != null
            ? this.tradeForm.swapClientCcy1
            : null,
        swapClientCcy2:
          this.tradeForm.swapClientCcy2 != null
            ? this.tradeForm.swapClientCcy2
            : null,
        swapClientAmount1:
          this.tradeForm.swapClientAmount1 != null
            ? parseFloat(this.tradeForm.swapClientAmount1)
            : null,
        swapClientAmount2:
          this.tradeForm.swapClientAmount2 != null
            ? parseFloat(this.tradeForm.swapClientAmount2)
            : null,
        swapCptyRate:
          this.tradeForm.swapCptyRate != null
            ? parseFloat(this.tradeForm.swapCptyRate)
            : null,
        swapCptyBuySell1:
          this.tradeForm.swapCptyBuySell1 != null
            ? this.tradeForm.swapCptyBuySell1
            : null,
        swapCptyBuySell2:
          this.tradeForm.swapCptyBuySell2 != null
            ? this.tradeForm.swapCptyBuySell2
            : null,
        swapCptyCcy1:
          this.tradeForm.swapCptyCcy1 != null
            ? this.tradeForm.swapCptyCcy1
            : null,
        swapCptyCcy2:
          this.tradeForm.swapCptyCcy2 != null
            ? this.tradeForm.swapCptyCcy2
            : null,
        swapCptyAmount1:
          this.tradeForm.swapCptyAmount1 != null
            ? parseFloat(this.tradeForm.swapCptyAmount1)
            : null,
        swapCptyAmount2:
          this.tradeForm.swapCptyAmount2 != null
            ? parseFloat(this.tradeForm.swapCptyAmount2)
            : null,
        swapSpreadCcy:
          this.tradeForm.swapSpreadCcy != null
            ? this.tradeForm.swapSpreadCcy
            : null,
        swapSpreadAmount:
          this.tradeForm.swapSpreadAmount != null
            ? parseFloat(this.tradeForm.swapSpreadAmount)
            : null,
        swapTradeDate: this.tradeForm.swapTradeDate
          ? this.YMDdateFormat(this.tradeForm.swapTradeDate)
          : null,
        swapSettleDate:
          this.tradeForm.swapSettleDate != null
            ? this.YMDdateFormat(this.tradeForm.swapSettleDate)
            : null,
        calcFromSpread: this.tradeForm.calcFromSpread,
        // startTradeDate:
        //   this.tradeForm.tradeStart != null
        //     ? this.YMDdateFormat(this.tradeForm.tradeStart)
        //     : null,
        // endTradeDate:
        //   this.tradeForm.tradeEnd != null
        //     ? this.YMDdateFormat(this.tradeForm.tradeEnd)
        //     : null,
        // startSettlementDate:
        //   this.tradeForm.settleStart != null
        //     ? this.YMDdateFormat(this.tradeForm.settleStart)
        //     : null,
        // endSettlementDate:
        //   this.tradeForm.settleEnd != null
        //     ? this.YMDdateFormat(this.tradeForm.settleEnd)
        //     : null,
        // ID: 0,
      };

      return setTrade;
    },
  },
  watch: {
    // "tradeForm.swapSettleDateName": {
    //   handler: function(val, before) {
    //     console.log(val);
    //   },
    //   deep: true,
    // },
    showDefaultEntry: {
      handler: function(val, before) {
        if (!val) {
          this.defaultEntryName = null;
        }
      },
    },
    enterNewTradeModal: {
      handler: function(val, before) {
        if (val) {
          this.getArangeFxExecutedTCUsers();
          this.getArrangeFxExecutedCurrencies();
          this.getArrangeFxExecutedOrderTakenGivenThrough();
          this.getActingCapacities();

          this.tradeForm.orderDate = moment().format("DD-MM-YYYY");
          this.tradeForm.orderTime = moment().format("HH:mm:ss");

          this.tradeForm.orderDurationDate = moment().format("DD-MM-YYYY ");
          this.tradeForm.orderDurationTime = moment()
            .endOf("day")
            .format("HH:mm:ss")
            .toString();

          this.orderDurationDataDisabled = true;
          this.orderDurationTimeDisabled = true;
          this.orderDurationInputsVisible = true;

          this.tradeForm.cptyOrderDate = moment().format("DD-MM-YYYY ");
          this.tradeForm.cptyOrderTime = moment().format("HH:mm:ss");

          this.tradeForm.tradeDate = moment().format("DD-MM-YYYY ");

          let tradeDate = new Date(
            this.MDYdateFormat(this.tradeForm.tradeDate)
          );

          //set trade date name
          this.tradeForm.tradeDateName = this.weekday[tradeDate.getDay()];

          this.tradeForm.scfUser = this.user.name + " " + this.user.surname;
          this.tradeForm.entryType = "Order";
          this.tradeForm.buySell = "Buy";
          this.tradeForm.orderType = "Limit";
          this.tradeForm.timeInForce = "DAY";
          (this.tradeForm.productType = "SPOT"),
            (this.tradeForm.settlementType = "PHYSICAL");
          this.tradeForm.actingCapaticy = "PRINCIPAL";

          this.tradeForm.cptyBuySell1 = "Buys"; //sfcBuySell1
          this.tradeForm.cptyBuySell2 = "Sells"; //sfcBuySell2
          this.tradeForm.clientBuySell1 = "Buys";
          this.tradeForm.clientBuySell2 = "Sells";
        }
      },
    },
    "tradeForm.productType": {
      handler: function(val, before) {
        if (val == "SWAP") {
          this.tradeForm.swapTradeDate = moment().format("DD-MM-YYYY ");

          let swapTradeDate = new Date(
            this.MDYdateFormat(this.tradeForm.swapTradeDate)
          );

          //set swap trade date name
          this.tradeForm.swapTradeDateName = this.weekday[
            swapTradeDate.getDay()
          ];

          this.tradeForm.swapClientBuySell1 = "Buys";
          this.tradeForm.swapClientBuySell2 = "Sells";
          this.tradeForm.swapCptyBuySell1 = "Sells";
          this.tradeForm.swapCptyBuySell2 = "Buys";

          this.tradeForm.swapCptyCcy1 = this.optPriceTypes.find(
            (x) => x === "GBP"
          )
            ? "GBP"
            : null;
          this.tradeForm.swapCptyCcy2 = this.optPriceTypes.find(
            (x) => x === "USD"
          )
            ? "USD"
            : null;
          this.tradeForm.swapClientCcy1 = this.optPriceTypes.find(
            (x) => x === "GBP"
          )
            ? "GBP"
            : null;
          this.tradeForm.swapClientCcy2 = this.optPriceTypes.find(
            (x) => x === "USD"
          )
            ? "USD"
            : null;
          this.tradeForm.swapSpreadCcy = this.optPriceTypes.find(
            (x) => x === "USD"
          )
            ? "USD"
            : null;
        } else {
          this.tradeForm.swapTradeDate = null;
          this.tradeForm.swapTradeDateName = null;
          this.tradeForm.maturity = null;
          this.tradeForm.swapSettleDate = null;
          this.tradeForm.swapSettleDateName = null;
          this.tradeForm.swapPoints = null;
          this.tradeForm.swapCptyRate = null;
          this.tradeForm.swapCptyAmount1 = null;
          this.tradeForm.swapCptyCcy1 = null;
          this.tradeForm.swapCptyBuySell1 = null;
          this.tradeForm.swapCptyAmount2 = null;
          this.tradeForm.swapCptyCcyType2 = null;
          this.tradeForm.swapCptyBuySell2 = null;
          this.tradeForm.swapSpread = null;
          this.tradeForm.swapCalcFromSpread =
            "Calculate Client Rate From Spread";
          this.tradeForm.swapClientRate = null;
          this.tradeForm.swapClientAmount1 = null;
          this.tradeForm.swapClientCcy1 = null;
          this.tradeForm.swapClientBuySell1 = null;
          this.tradeForm.swapClientAmount2 = null;
          this.tradeForm.swapClientCcy2 = null;
          this.tradeForm.swapClientBuySell2 = null;
          this.tradeForm.swapSpreadAmount = null;
          this.tradeForm.swapSpreadCcy = null;
        }

        if (val == "SWAP") {
          this.tradeForm.enableConnectedOCOOrders = false;
          this.tradeForm.takeProfitLimit = null;
          this.tradeForm.stopLossStop = null;
          this.tradeForm.stopLossLimit = null;
          this.tradeForm.cptyTakeProfitLimit = null;
          this.tradeForm.cptyStopLossStop = null;
          this.tradeForm.cptyStopLossLimit = null;
        }
      },
      deep: true,
    },
    fetchedTradeData: {
      handler: function(val, before) {
        if (val.ID != 0) {
          // this.tradeForm = {
          // costCenter:val.CostCenter,
          //   orderDurationDate:
          //     val.OrderDurationDate != null
          //       ? this.dateFormat(val.OrderDurationDate)
          //       : null,
          //   orderDurationTime:
          //     val.OrderDurationTime != null
          //       ? this.HmstimeFormat(val.OrderDurationTime)
          //       : null,
          //   entryType: val.EntryType ?? null,
          //   buySell: val.BuySell ?? null,
          //   orderType: val.OrderType ?? null,
          //   timeInForce: val.TimeinForce ?? null,
          //   orderDate:
          //     val.OrderDate != null ? this.dateFormat(val.OrderDate) : null,
          //   orderTime:
          //     val.OrderTime != null ? this.HmstimeFormat(val.OrderTime) : null,
          //   cptyOrderDate:
          //     val.CounterpartyOrderDate != null
          //       ? this.dateFormat(val.CounterpartyOrderDate)
          //       : null,
          //   cptyOrderTime:
          //     val.CptyOrderTime != null
          //       ? this.HmstimeFormat(val.CptyOrderTime)
          //       : null,
          //   enableConnectedOCOOrders: val.EnableOCO == "true" ? true : false,
          //   stopPrice: val.StopPrice ?? null,
          //   trailingAmount: val.TrailingAmount ?? null,
          //   takeProfitLimit: val.TakeProfitLimit ?? null,
          //   stopLossStop: val.StopLoss ?? null,
          //   stopLossLimit: val.StopLossLimit ?? null,
          //   instrumentType: val.InstrumentType ?? null,
          //   securityDesc: val.SecurityDescription ?? null,
          //   quantity: val.Quantity ?? null,
          //   clientPrice:
          //     val.Price != null ? this.formatPrice(val.Price, 2) : null,
          //   clientPriceType: val.Ccy ?? null,
          //   amount: val.Amount != null ? this.formatPrice(val.Amount, 2) : null,
          //   counterpartyPrice:
          //     val.CounterpartyPrice != null
          //       ? this.formatPrice(val.CounterpartyPrice, 2)
          //       : null,
          //   clean: val.Clean == 0 ? false : true,
          //   accrued: val.AccuredInterest ?? null,
          //   client: val.Client ?? null,
          //   clientAccount: val.Account ?? null,
          //   isin: val.Isin ?? null,
          //   exchangeTraded: val.ExchangeTraded ?? null,
          //   counterparty: val.Counterparty ?? null,
          //   cptyAccount: val.CounterpartyAccount ?? null,
          //   counterpartyTrader: val.CounterpartyTrader ?? null,
          //   scfUser: val.TcUser ?? null,
          //   actingCapaticy: val.ActingCapacity ?? null,
          //   tradeDate:
          //     val.TradeDate != null ? this.dateFormat(val.TradeDate) : null,
          //   settlementDate:
          //     val.SettlementDate != null
          //       ? this.dateFormat(val.SettlementDate)
          //       : null,
          //   executionTime:
          //     val.ExecutionTime != null
          //       ? this.HmstimeFormat(val.ExecutionTime)
          //       : null,
          //   cptyExecutionTime:
          //     val.CptyExecutionTime != null
          //       ? this.HmstimeFormat(val.CptyExecutionTime)
          //       : null,
          //   clientRate:
          //     val.CommissionRate != null
          //       ? this.formatPrice(val.CommissionRate, 2)
          //       : null,
          //   clientCommission: val.CommissionAmount ?? null,
          //   clientAmount: val.ClientAmount ?? null,
          //   counterpartyRate: val.CounterpartyRate ?? null,
          //   tax: val.Tax ?? null,
          //   cptyCommission: val.CounterpartyCommission ?? null,
          //   counterpartyAmount: val.CounterpartyAmount ?? null,
          //   orderTakenVia: val.OrderTakenVia ?? null,
          //   orderGivenThrough: val.OrderGivenThrough ?? null,
          //   profit: val.TcProfit ?? null,
          //   tradeReportingNeeded: val.tradeReportingNeeded == 0 ? false : true,
          //   note: val.TraderNote ?? null,
          //   calculateCommissionFromRate1: false,
          //   calculateCommissionFromRate2: false,
          //   uti: val.TcUti ?? null,
          //   uniqueLinkID: val.UniqueLinkId ?? null,
          //   tradeRef: val.ID,
          //   tradeStart: null,
          //   tradeEnd: null,
          //   settleStart: null,
          //   settleEnd: null,
          // };
        }
      },
    },
  },
};
</script>

<style>
#tradeModal .modal-header .modal-title {
  color: white !important;
}

.fxDetailRadio .custom-control:nth-child(odd) {
  margin-bottom: 5px;
}

.animate__animated {
  animation-fill-mode: none;
}
.swapSettleName .input-group-append .input-group-text {
  color: red;
}
.swapTradeName .input-group-append .input-group-text {
  color: red;
}
.tradeDateName .input-group-append .input-group-text {
  color: red;
}
.settlementDateName .input-group-append .input-group-text {
  color: red;
}

.animate__animated {
  animation-fill-mode: none;
}

.input-dropdown .btn {
  padding: 0;
}

.shortcut-buttons-flatpickr-button {
  border-radius: 5px;
  background: white;
  border: 1px solid #d8d6de;
}

.flatpickr-wrapper {
  width: 100% !important;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>

<!-- compact design scss code -->

<style lang="scss" scoped>
@import "../../assets/scss/compact-design.scss";

// .v-select::v-deep .vs__selected {
//     position: relative !important;
//   }

.fxDetailRadio::v-deep .form-check-label {
  font-size: 0.75rem !important;
}
</style>
