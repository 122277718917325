<template>
  <div>
    {{ /*search trade modal*/ }}
    <b-modal
      id="searchTradeModal"
      v-model="searchForTradesModal"
      :title="$t('fx_spot_forward_swap.titles.search_for_trade')"
      @hidden="resetSearchForTradesModal"
      size="xl"
      :ok-title="$t('fx_spot_forward_swap.modals.search_trade_modal_ok')"
      :cancel-title="$t('fx_spot_forward_swap.modals.modal_cancel')"
      @ok="searchForTrades"
      :body-bg-variant="'light'"
      :header-bg-variant="'dark'"
      class="searchTradeModal"
      body-class="compact_form_modal"
      footer-class="compact_modal_footer"
      no-close-on-backdrop
    >
      <b-overlay :show="tradeModalLoading" rounded="sm" no-fade>
        <b-container>
          <validation-observer ref="searchValidation">
            <b-form>
              <b-row>
                <b-card :title="$t('equity_bond.titles.basic_search')">
                  <b-row>
                    <!-- client -->
                    <b-col cols="6" xl="2" lg="2" md="4" sm="6">
                      <b-form-group
                        :label="$t('fx_spot_forward_swap.trade_form.client')"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('fx_spot_forward_swap.trade_form.client')"
                        >
                          <v-select
                            v-model="tradeForm.client"
                            :options="optClients"
                            :clearable="true"
                            :placeholder="
                              $t(
                                'fx_spot_forward_swap.trade_form.select_client'
                              )
                            "
                            :reduce="(val) => val"
                            :state="errors.length > 0 ? false : null"
                            :loading="loadingClient"
                            @option:selecting="selectedClient"
                            @input="checkAccount"
                            class="compact_vue-select"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- client account -->
                    <b-col cols="6" xl="2" lg="2" md="4" sm="6">
                      <b-form-group
                        :label="
                          $t('fx_spot_forward_swap.trade_form.client_account')
                        "
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="
                            $t('fx_spot_forward_swap.trade_form.client_account')
                          "
                        >
                          <b-form-input
                            id="clientAccount"
                            v-model="tradeForm.clientAccount"
                            name="clientAccount"
                            :state="errors.length > 0 ? false : null"
                            :placeholder="
                              $t(
                                'fx_spot_forward_swap.trade_form.client_account'
                              )
                            "
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- counterparty -->
                    <b-col cols="6" xl="2" lg="2" md="4" sm="6">
                      <b-form-group
                        :label="
                          $t('fx_spot_forward_swap.trade_form.counterparty')
                        "
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="
                            $t('fx_spot_forward_swap.trade_form.counterparty')
                          "
                        >
                          <v-select
                            v-model="tradeForm.counterparty"
                            :options="optCounterParties"
                            :clearable="true"
                            :placeholder="
                              $t(
                                'fx_spot_forward_swap.trade_form.select_counterparty'
                              )
                            "
                            :reduce="(val) => val"
                            :loading="loadingCounterparty"
                            @option:selecting="selectedCounterparty"
                            @input="checkCptyAccount"
                            class="compact_vue-select"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- counterparty account -->
                    <b-col cols="6" xl="2" lg="2" md="4" sm="6">
                      <b-form-group
                        :label="
                          $t('fx_spot_forward_swap.trade_form.cpty_account')
                        "
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="
                            $t('fx_spot_forward_swap.trade_form.cpty_account')
                          "
                        >
                          <b-form-input
                            id="cptyAccount"
                            v-model="tradeForm.cptyAccount"
                            name="cpty account"
                            :state="errors.length > 0 ? false : null"
                            :placeholder="
                              $t('fx_spot_forward_swap.trade_form.cpty_account')
                            "
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- order date -->
                    <b-col cols="6" xl="2" lg="2" md="4" sm="6">
                      <b-form-group
                        :label="
                          $t('fx_spot_forward_swap.trade_form.order_date')
                        "
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="
                            $t('fx_spot_forward_swap.trade_form.order_date')
                          "
                        >
                          <b-input-group>
                            <flat-pickr
                              :placeholder="
                                $t('fx_spot_forward_swap.trade_form.order_date')
                              "
                              v-model="orderDateRange"
                              onClose="testClose"
                              class="form-control"
                              :config="orderDateConfig"
                              :state="errors.length > 0 ? false : null"
                              @on-change="checkOrdersDate('Order Date')"
                            />
                            <b-input-group-append>
                              <b-button
                                @click="clearOrderDate()"
                                variant="secondary"
                                size="sm"
                                >{{ $t("general_buttons.clear") }}</b-button
                              >
                            </b-input-group-append>
                          </b-input-group>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- settlement date -->
                    <b-col cols="6" xl="2" lg="2" md="4" sm="6">
                      <b-form-group
                        :label="
                          $t('fx_spot_forward_swap.trade_form.settlement_date')
                        "
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="
                            $t(
                              'fx_spot_forward_swap.trade_form.settlement_date'
                            )
                          "
                        >
                          <b-input-group>
                            <flat-pickr
                              :placeholder="
                                $t(
                                  'fx_spot_forward_swap.trade_form.settlement_date'
                                )
                              "
                              v-model="settleDateRange"
                              onClose="testClose"
                              class="form-control"
                              :config="dateConfig"
                              @on-change="
                                checkTraSettlCptyDate('Settlement Date')
                              "
                            />
                            <b-input-group-append>
                              <b-button
                                @click="clearSettleDate()"
                                variant="secondary"
                                size="sm"
                                >{{ $t("general_buttons.clear") }}</b-button
                              >
                            </b-input-group-append>
                          </b-input-group>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-card>
              </b-row>

              <b-button
                v-b-toggle.collapse-1
                variant="outline-primary"
                @click="
                  updownButtonText == 'down'
                    ? (updownButtonText = 'up')
                    : (updownButtonText = 'down')
                "
                size="sm"
              >
                <i :class="'fa-solid fa-angles-' + updownButtonText"></i>
                {{ $t("equity_bond.titles.advanced_search") }}
              </b-button>

              <b-collapse id="collapse-1" style="margin-top:0.5rem;">
                <!-- /////**************Order Details && Client & Counterparty Details && Trade Details ***************************************************************** */ -->

                <b-row>
                  <b-col
                    cols="12"
                    sm="12"
                    md="12"
                    lg="6"
                    xl="6"
                    class="pl-0"
                    style="padding-right:0.2rem!important"
                  >
                    <!-- /*******************************Order Details***************************************************************** */ -->
                    <b-card
                      :title="$t('fx_spot_forward_swap.titles.order_details')"
                    >
                      <b-row>
                        <!-- order time-->
                        <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                          <b-form-group
                            :label="
                              $t('fx_spot_forward_swap.trade_form.order_time')
                            "
                          >
                            <div>
                              <b-input-group>
                                <cleave
                                  class="form-control"
                                  v-model="tradeForm.orderTime"
                                  :options="cleaveOptions.time"
                                  @blur.native="checkTime('Order Time')"
                                  :placeholder="
                                    $t('fx_spot_forward_swap.trade_form.min')
                                  "
                                />
                                <cleave
                                  class="form-control"
                                  v-model="tradeForm.orderTimeEnd"
                                  :options="cleaveOptions.time"
                                  @blur.native="checkTime('Order Time')"
                                  :placeholder="
                                    $t('fx_spot_forward_swap.trade_form.max')
                                  "
                                />
                              </b-input-group>
                            </div>
                          </b-form-group>
                        </b-col>

                        <!-- Order duration date -->
                        <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                          <b-form-group
                            :label="
                              $t(
                                'fx_spot_forward_swap.trade_form.order_duration_date'
                              )
                            "
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="
                                $t(
                                  'fx_spot_forward_swap.trade_form.order_duration_date'
                                )
                              "
                            >
                              <b-input-group>
                                <flat-pickr
                                  :placeholder="
                                    $t(
                                      'fx_spot_forward_swap.trade_form.order_duration_date'
                                    )
                                  "
                                  v-model="durationDateRange"
                                  onClose="testClose"
                                  class="form-control"
                                  :config="dateConfig"
                                  :state="errors.length > 0 ? false : null"
                                  @on-change="
                                    checkOrdersDate('Order Duration Date')
                                  "
                                />
                                <b-input-group-append>
                                  <b-button
                                    @click="clearDurationDate()"
                                    variant="secondary"
                                    size="sm"
                                    >{{ $t("general_buttons.clear") }}</b-button
                                  >
                                </b-input-group-append>
                              </b-input-group>
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <!-- Order duration time -->
                        <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                          <b-form-group
                            :label="
                              $t(
                                'fx_spot_forward_swap.trade_form.order_duration_time'
                              )
                            "
                          >
                            <div>
                              <b-input-group>
                                <cleave
                                  class="form-control"
                                  v-model="tradeForm.orderDurationTime"
                                  :options="cleaveOptions.time"
                                  @blur.native="
                                    checkOrdersTime('Order Duration Time')
                                  "
                                  :placeholder="
                                    $t('fx_spot_forward_swap.trade_form.min')
                                  "
                                />
                                <cleave
                                  class="form-control"
                                  v-model="tradeForm.orderDurationTimeEnd"
                                  :options="cleaveOptions.time"
                                  @blur.native="
                                    checkOrdersTime('Order Duration Time')
                                  "
                                  :placeholder="
                                    $t('fx_spot_forward_swap.trade_form.max')
                                  "
                                />
                              </b-input-group>
                            </div>
                          </b-form-group>
                        </b-col>
                        <!-- entry type -->
                        <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                          <b-form-group
                            :label="
                              $t('fx_spot_forward_swap.trade_form.entry_type')
                            "
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="
                                $t('fx_spot_forward_swap.trade_form.entry_type')
                              "
                            >
                              <v-select
                                v-model="tradeForm.entryType"
                                :options="optEntryTypes"
                                clearable
                                :placeholder="
                                  $t(
                                    'fx_spot_forward_swap.trade_form.all_entry'
                                  )
                                "
                                :reduce="(val) => val"
                                :state="errors.length > 0 ? false : null"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <!-- buy/sell -->
                        <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                          <b-form-group
                            :label="
                              $t('fx_spot_forward_swap.trade_form.buy_sell')
                            "
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="
                                $t('fx_spot_forward_swap.trade_form.buy_sell')
                              "
                            >
                              <v-select
                                v-model="tradeForm.buySell"
                                :options="optbuySells"
                                :clearable="true"
                                :placeholder="
                                  $t(
                                    'fx_spot_forward_swap.trade_form.select_buy_sell'
                                  )
                                "
                                :reduce="(val) => val"
                                :state="errors.length > 0 ? false : null"
                                @option:selected="
                                  changedBuySell(tradeForm.buySell)
                                "
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <!-- order type-->
                        <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                          <b-form-group
                            :label="
                              $t('fx_spot_forward_swap.trade_form.order_type')
                            "
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="
                                $t('fx_spot_forward_swap.trade_form.order_type')
                              "
                            >
                              <v-select
                                v-model="tradeForm.orderType"
                                :options="optOrderTypes"
                                :clearable="true"
                                :placeholder="
                                  $t(
                                    'fx_spot_forward_swap.trade_form.select_order_type'
                                  )
                                "
                                :reduce="(val) => val"
                                :state="errors.length > 0 ? false : null"
                                class="compact_vue-select"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <!-- time in force-->
                        <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                          <b-form-group
                            :label="
                              $t(
                                'fx_spot_forward_swap.trade_form.time_in_force'
                              )
                            "
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="
                                $t(
                                  'fx_spot_forward_swap.trade_form.time_in_force'
                                )
                              "
                            >
                              <v-select
                                v-model="tradeForm.timeInForce"
                                :options="optTimeInForces"
                                :clearable="true"
                                :multiple="true"
                                :placeholder="
                                  $t(
                                    'fx_spot_forward_swap.trade_form.select_time_in_force'
                                  )
                                "
                                :reduce="(val) => val"
                                :state="errors.length > 0 ? false : null"
                                @option:selected="changeTimeInForceType"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>

                        <!-- cpty order date-->
                        <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                          <b-form-group
                            :label="
                              $t(
                                'fx_spot_forward_swap.trade_form.cpty_order_date'
                              )
                            "
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="
                                $t(
                                  'fx_spot_forward_swap.trade_form.cpty_order_date'
                                )
                              "
                            >
                              <b-input-group>
                                <flat-pickr
                                  :placeholder="
                                    $t(
                                      'fx_spot_forward_swap.trade_form.cpty_order_date'
                                    )
                                  "
                                  v-model="cptyOrderDateRange"
                                  onClose="testClose"
                                  class="form-control"
                                  :config="dateConfig"
                                  :state="errors.length > 0 ? false : null"
                                  @on-change="
                                    checkTraSettlCptyDate('Cpty Order Date')
                                  "
                                />
                                <b-input-group-append>
                                  <b-button
                                    @click="clearCptyOrderDate()"
                                    variant="secondary"
                                    size="sm"
                                    >{{ $t("general_buttons.clear") }}</b-button
                                  >
                                </b-input-group-append>
                              </b-input-group>
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <!-- cpty order time-->
                        <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                          <b-form-group
                            :label="
                              $t(
                                'fx_spot_forward_swap.trade_form.cpty_order_time'
                              )
                            "
                          >
                            <div>
                              <b-input-group>
                                <cleave
                                  class="form-control"
                                  v-model="tradeForm.cptyOrderTime"
                                  :options="cleaveOptions.time"
                                  @blur.native="checkTime('Execution Time')"
                                  :placeholder="
                                    $t('fx_spot_forward_swap.trade_form.min')
                                  "
                                />
                                <cleave
                                  class="form-control"
                                  v-model="tradeForm.cptyOrderTimeEnd"
                                  :options="cleaveOptions.time"
                                  @blur.native="checkTime('Execution Time')"
                                  :placeholder="
                                    $t('fx_spot_forward_swap.trade_form.max')
                                  "
                                />
                              </b-input-group>
                            </div>
                          </b-form-group>
                        </b-col>
                        <!-- Stop Price -->
                        <b-col
                          cols="6"
                          xl="2"
                          lg="2"
                          md="4"
                          sm="6"
                          v-if="
                            tradeForm.orderType == 'Stop' ||
                              tradeForm.orderType == 'Stop Limit' ||
                              tradeForm.orderType == 'Trailing Stop'
                          "
                        >
                          <b-form-group
                            :label="
                              $t(
                                'fx_spot_forward_swap.trade_form.stop_price_start'
                              )
                            "
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="
                                $t(
                                  'fx_spot_forward_swap.trade_form.stop_price_start'
                                )
                              "
                            >
                              <cleave
                                id="stopPrice"
                                :state="errors.length > 0 ? false : null"
                                v-model.number="tradeForm.stopPrice"
                                class="form-control"
                                :raw="true"
                                :options="cleaveOptions.number"
                                :placeholder="
                                  $t(
                                    'fx_spot_forward_swap.trade_form.stop_price_start'
                                  )
                                "
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>

                        <!-- Stop Price End -->
                        <b-col
                          cols="6"
                          xl="2"
                          lg="2"
                          md="4"
                          sm="6"
                          v-if="
                            tradeForm.orderType == 'Stop' ||
                              tradeForm.orderType == 'Stop Limit' ||
                              tradeForm.orderType == 'Trailing Stop'
                          "
                        >
                          <b-form-group
                            :label="
                              $t(
                                'fx_spot_forward_swap.trade_form.stop_price_end'
                              )
                            "
                          >
                            <cleave
                              id="stopPrice"
                              v-model.number="tradeForm.stopPriceEnd"
                              class="form-control"
                              :raw="true"
                              :options="cleaveOptions.number"
                              :placeholder="
                                $t(
                                  'fx_spot_forward_swap.trade_form.stop_price_end'
                                )
                              "
                            />
                          </b-form-group>
                        </b-col>
                        <!-- Trailing Amount -->
                        <b-col
                          cols="6"
                          xl="2"
                          lg="2"
                          md="4"
                          sm="6"
                          v-if="tradeForm.orderType == 'Trailing Stop'"
                        >
                          <b-form-group
                            :label="
                              $t(
                                'fx_spot_forward_swap.trade_form.trailing_amount'
                              )
                            "
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="
                                $t(
                                  'fx_spot_forward_swap.trade_form.trailing_amount_start'
                                )
                              "
                            >
                              <b-form-input
                                id="trailingAmount"
                                v-model.number="tradeForm.trailingAmount"
                                name="trailingAmount"
                                type="number"
                                :state="errors.length > 0 ? false : null"
                                :placeholder="
                                  $t(
                                    'fx_spot_forward_swap.trade_form.trailing_amount_start'
                                  )
                                "
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>

                        <!-- Trailing Amount End -->
                        <b-col
                          cols="6"
                          xl="2"
                          lg="2"
                          md="4"
                          sm="6"
                          v-if="tradeForm.orderType == 'Trailing Stop'"
                        >
                          <b-form-group
                            :label="
                              $t(
                                'fx_spot_forward_swap.trade_form.trailing_amount_end'
                              )
                            "
                          >
                            <b-form-input
                              id="trailingAmount"
                              v-model.number="tradeForm.trailingAmountEnd"
                              name="trailingAmount"
                              type="number"
                              :placeholder="
                                $t(
                                  'fx_spot_forward_swap.trade_form.trailing_amount_end'
                                )
                              "
                            />
                          </b-form-group>
                        </b-col>
                      </b-row>

                      <!-- OCO orders inputs-->
                      <b-row>
                        <!-- enable connected OCO orders-->
                        <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                          <b-form-group
                            :label="
                              $t('fx_spot_forward_swap.trade_form.enable_oco')
                            "
                          >
                            <validation-provider
                              #default="{ errors }"
                              name="enable OCO orders"
                            >
                              <!-- <b-form-checkbox
                            v-model="tradeForm.enableConnectedOCOOrders"
                          >
                            {{
                              $t("fx_spot_forward_swap.trade_form.enable_oco")
                            }}
                          </b-form-checkbox> -->

                              <v-select
                                label="title"
                                v-model="tradeForm.enableConnectedOCOOrders"
                                :options="optEnableOCOs"
                                :clearable="false"
                                :reduce="(val) => val"
                                class="compact_searchform_vue-select"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>

                        <!-- Take Profit Limit min && max-->
                        <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                          <b-form-group
                            :label="
                              $t(
                                'fx_spot_forward_swap.trade_form.take_profit_limit'
                              )
                            "
                            v-if="
                              tradeForm.enableConnectedOCOOrders ==
                                'Enable Connected OCO Orders'
                            "
                          >
                            <div>
                              <b-input-group>
                                <cleave
                                  id="takeProfitLimit"
                                  v-model.number="tradeForm.takeProfitLimit"
                                  class="form-control"
                                  :raw="true"
                                  :options="cleaveOptions.number"
                                  :placeholder="
                                    $t('fx_spot_forward_swap.trade_form.min')
                                  "
                                  @blur.native="
                                    checkEnableOCOInputs('takeProfitLimit')
                                  "
                                />
                                <cleave
                                  id="takeProfitLimit"
                                  v-model.number="tradeForm.takeProfitLimitEnd"
                                  class="form-control"
                                  :raw="true"
                                  :options="cleaveOptions.number"
                                  :placeholder="
                                    $t('fx_spot_forward_swap.trade_form.max')
                                  "
                                  @blur.native="
                                    checkEnableOCOInputs('takeProfitLimit')
                                  "
                                />
                              </b-input-group>
                            </div>
                          </b-form-group>
                        </b-col>

                        <!-- Stop Loss Stop min && max -->
                        <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                          <b-form-group
                            :label="
                              $t(
                                'fx_spot_forward_swap.trade_form.stop_loss_stop'
                              )
                            "
                            v-if="
                              tradeForm.enableConnectedOCOOrders ==
                                'Enable Connected OCO Orders'
                            "
                          >
                            <div>
                              <b-input-group>
                                <cleave
                                  id="stopLossStop"
                                  v-model.number="tradeForm.stopLossStop"
                                  class="form-control"
                                  :raw="true"
                                  :options="cleaveOptions.number"
                                  :placeholder="
                                    $t('fx_spot_forward_swap.trade_form.min')
                                  "
                                  @blur.native="
                                    checkEnableOCOInputs('stopLossStop')
                                  "
                                />
                                <cleave
                                  id="stopLossStop"
                                  v-model.number="tradeForm.stopLossStopEnd"
                                  class="form-control"
                                  :raw="true"
                                  :options="cleaveOptions.number"
                                  :placeholder="
                                    $t('fx_spot_forward_swap.trade_form.max')
                                  "
                                  @blur.native="
                                    checkEnableOCOInputs('stopLossStop')
                                  "
                                />
                              </b-input-group>
                            </div>
                          </b-form-group>
                        </b-col>

                        <!-- Stop Loss Limit min && max -->
                        <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                          <b-form-group
                            :label="
                              $t(
                                'fx_spot_forward_swap.trade_form.stop_loss_limit'
                              )
                            "
                            v-if="
                              tradeForm.enableConnectedOCOOrders ==
                                'Enable Connected OCO Orders'
                            "
                          >
                            <div>
                              <b-input-group>
                                <cleave
                                  id="stopLossLimit"
                                  v-model.number="tradeForm.stopLossLimit"
                                  class="form-control"
                                  :raw="true"
                                  :options="cleaveOptions.number"
                                  :placeholder="
                                    $t('fx_spot_forward_swap.trade_form.min')
                                  "
                                  @blur.native="
                                    checkEnableOCOInputs('stopLossLimit')
                                  "
                                />
                                <cleave
                                  id="stopLossLimit"
                                  v-model.number="tradeForm.stopLossLimitEnd"
                                  class="form-control"
                                  :raw="true"
                                  :options="cleaveOptions.number"
                                  :placeholder="
                                    $t('fx_spot_forward_swap.trade_form.max')
                                  "
                                  @blur.native="
                                    checkEnableOCOInputs('stopLossLimit')
                                  "
                                />
                              </b-input-group>
                            </div>
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </b-card>

                    <!-- /******************************* Client & Counterparty Details***************************************************************** */ -->
                    <!-- <b-card
                  :title="
                    $t(
                      'fx_spot_forward_swap.titles.client_counterparty_details'
                    )
                  "
                >
                  <b-row> -->
                    <!-- client -->
                    <!-- <b-col cols="12" xl="3" lg="3" md="3" sm="12">
                        <b-form-group
                          :label="$t('fx_spot_forward_swap.trade_form.client')"
                        >
                          <div class="d-flex align-items-center">
                            <validation-provider
                              #default="{ errors }"
                              :name="
                                $t('fx_spot_forward_swap.trade_form.client')
                              "
                              style="width: 100%;"
                              :rules="
                                tradeForm.entryType == 'Execution'
                                  ? 'required'
                                  : ''
                              "
                            >
                              <v-select
                                v-model="tradeForm.client"
                                :options="optClients"
                                :clearable="false"
                                :placeholder="
                                  $t(
                                    'fx_spot_forward_swap.trade_form.select_client'
                                  )
                                "
                                :reduce="(val) => val"
                                :state="errors.length > 0 ? false : null"
                                :loading="loadingClient"
                                :filterable="true"
                                @option:selecting="selectedClient"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                            <b-dropdown
                              variant="link"
                              no-caret
                              :right="$store.state.appConfig.isRTL"
                              class="input-dropdown animate__animated animate__fadeIn"
                              v-if="this.searchedClients.length > 0"
                            >
                              <template #button-content class="p-1">
                                <feather-icon
                                  icon="MoreVerticalIcon"
                                  size="16"
                                  class="align-middle text-body"
                                />
                              </template>

                              <b-dropdown-item @click="seeFilteredClientModal">
                                <feather-icon icon="EyeIcon" />
                                <span class="align-middle ml-50">{{
                                  $t("general_text.see_verified_clients")
                                }}</span>
                              </b-dropdown-item>
                            </b-dropdown>
                          </div>
                        </b-form-group>
                      </b-col> -->
                    <!-- client account -->
                    <!-- <b-col cols="12" xl="3" lg="3" md="3" sm="12" >
                        <b-form-group
                          :label="
                            $t('fx_spot_forward_swap.trade_form.client_account')
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t(
                                'fx_spot_forward_swap.trade_form.client_account'
                              )
                            "
                            :rules="
                              tradeForm.entryType == 'Execution'
                                ? 'required'
                                : ''
                            "
                          >
                            <b-form-input
                              id="clientAccount"
                              v-model="tradeForm.clientAccount"
                              name="clientAccount"
                              :state="errors.length > 0 ? false : null"
                              :placeholder="
                                $t(
                                  'fx_spot_forward_swap.trade_form.client_account'
                                )
                              "
                              :disabled="true"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col> -->
                    <!-- counter party -->
                    <!-- <b-col cols="12" xl="3" lg="3" md="3" sm="12" >
                        <b-form-group
                          :label="
                            $t('fx_spot_forward_swap.trade_form.counterparty')
                          "
                        >
                          <div class="d-flex align-items-center">
                            <validation-provider
                              #default="{ errors }"
                              style="width: 100%;"
                              :name="
                                $t(
                                  'fx_spot_forward_swap.trade_form.counterparty'
                                )
                              "
                              :rules="
                                tradeForm.entryType == 'Execution'
                                  ? 'required'
                                  : ''
                              "
                            >
                              <v-select
                                v-model="tradeForm.counterparty"
                                :options="optCounterParties"
                                :clearable="false"
                                :placeholder="
                                  $t(
                                    'fx_spot_forward_swap.trade_form.select_counterparty'
                                  )
                                "
                                :reduce="(val) => val"
                                :loading="loadingCounterparty"
                                :filterable="true"
                                @option:selecting="selectedCounterparty"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                            <b-dropdown
                              variant="link"
                              no-caret
                              :right="$store.state.appConfig.isRTL"
                              class="input-dropdown animate__animated animate__fadeIn"
                              v-if="this.searchedCounterparties.length > 0"
                            >
                              <template #button-content class="p-1">
                                <feather-icon
                                  icon="MoreVerticalIcon"
                                  size="16"
                                  class="align-middle text-body"
                                />
                              </template>

                              <b-dropdown-item
                                @click="seeFilteredCounterpartyModal"
                              >
                                <feather-icon icon="EyeIcon" />
                                <span class="align-middle ml-50">{{
                                  $t("general_text.see_verified_clients")
                                }}</span>
                              </b-dropdown-item>
                            </b-dropdown>
                          </div>
                        </b-form-group>
                      </b-col> -->
                    <!-- cpty account -->
                    <!-- <b-col cols="12" xl="3" lg="3" md="3" sm="12" >
                        <b-form-group
                          :label="
                            $t('fx_spot_forward_swap.trade_form.cpty_account')
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t('fx_spot_forward_swap.trade_form.cpty_account')
                            "
                            :rules="
                              tradeForm.entryType == 'Execution'
                                ? 'required'
                                : ''
                            "
                          >
                            <b-form-input
                              id="cptyAccount"
                              v-model="tradeForm.cptyAccount"
                              name="cpty account"
                              :state="errors.length > 0 ? false : null"
                              :placeholder="
                                $t(
                                  'fx_spot_forward_swap.trade_form.cpty_account'
                                )
                              "
                              :disabled="true"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col> -->
                    <!-- </b-row>
                </b-card> -->

                    <!-- /******************************* Trade Details***************************************************************** */ -->

                    <b-card
                      :title="$t('fx_spot_forward_swap.titles.trade_details')"
                      style="padding-bottom:1rem !important;"
                    >
                      <b-row>
                        <!-- Trade Date -->
                        <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                          <b-form-group
                            :label="
                              $t('fx_spot_forward_swap.trade_form.trade_date')
                            "
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="
                                $t('fx_spot_forward_swap.trade_form.trade_date')
                              "
                            >
                              <b-input-group>
                                <flat-pickr
                                  :placeholder="
                                    $t(
                                      'fx_spot_forward_swap.trade_form.trade_date'
                                    )
                                  "
                                  v-model="tradeDateRange"
                                  onClose="testClose"
                                  class="form-control"
                                  :config="dateConfig"
                                  @on-change="
                                    checkTraSettlCptyDate('Trade Date')
                                  "
                                />
                                <b-input-group-append>
                                  <b-button
                                    @click="clearTradeDate()"
                                    variant="secondary"
                                    size="sm"
                                    >{{ $t("general_buttons.clear") }}</b-button
                                  >
                                </b-input-group-append>
                              </b-input-group>
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <!-- execution time -->
                        <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                          <b-form-group
                            :label="
                              $t(
                                'fx_spot_forward_swap.trade_form.execution_time'
                              )
                            "
                          >
                            <div>
                              <b-input-group>
                                <cleave
                                  class="form-control"
                                  v-model="tradeForm.executionTime"
                                  :options="cleaveOptions.time"
                                  @blur.native="checkTime('Execution Time')"
                                  :placeholder="
                                    $t('equity_bond.trade_form.min')
                                  "
                                />
                                <cleave
                                  class="form-control"
                                  v-model="tradeForm.executionTimeEnd"
                                  :options="cleaveOptions.time"
                                  @blur.native="checkTime('Execution Time')"
                                  :placeholder="
                                    $t('equity_bond.trade_form.max')
                                  "
                                />
                              </b-input-group>
                            </div>
                          </b-form-group>
                        </b-col>
                        <!-- counter party trader -->
                        <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                          <b-form-group
                            :label="
                              $t(
                                'fx_spot_forward_swap.trade_form.counterparty_trader'
                              )
                            "
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="
                                $t(
                                  'fx_spot_forward_swap.trade_form.counterparty_trader'
                                )
                              "
                            >
                              <v-select
                                v-model="tradeForm.counterpartyTrader"
                                :options="optCounterPartyTraders"
                                :clearable="true"
                                :placeholder="
                                  $t(
                                    'fx_spot_forward_swap.trade_form.select_counterparty_trader'
                                  )
                                "
                                :reduce="(val) => val"
                                class="compact_vue-select"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <!-- sfc user -->
                        <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                          <b-form-group
                            :label="
                              $t('fx_spot_forward_swap.trade_form.sfc_user')
                            "
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="
                                $t('fx_spot_forward_swap.trade_form.sfc_user')
                              "
                            >
                              <v-select
                                v-model="tradeForm.scfUser"
                                :options="optScfUsers"
                                :clearable="true"
                                :placeholder="
                                  $t(
                                    'fx_spot_forward_swap.trade_form.select_sfc_user'
                                  )
                                "
                                :reduce="(val) => val"
                                :state="errors.length > 0 ? false : null"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <!-- order taken via -->
                        <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                          <b-form-group
                            :label="
                              $t(
                                'fx_spot_forward_swap.trade_form.order_taken_via'
                              )
                            "
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="
                                $t(
                                  'fx_spot_forward_swap.trade_form.order_taken_via'
                                )
                              "
                            >
                              <v-select
                                v-model="tradeForm.orderTakenVia"
                                :options="optOrderTakenVias"
                                :clearable="true"
                                :placeholder="
                                  $t(
                                    'fx_spot_forward_swap.trade_form.select_order_taken_via'
                                  )
                                "
                                :reduce="(val) => val"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <!-- order taken through -->
                        <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                          <b-form-group
                            :label="
                              $t(
                                'fx_spot_forward_swap.trade_form.order_given_through'
                              )
                            "
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="
                                $t(
                                  'fx_spot_forward_swap.trade_form.order_given_through'
                                )
                              "
                            >
                              <v-select
                                v-model="tradeForm.orderGivenThrough"
                                :options="optOrderGivenThroughs"
                                :clearable="true"
                                :placeholder="
                                  $t(
                                    'fx_spot_forward_swap.trade_form.select_order_given_through'
                                  )
                                "
                                :reduce="(val) => val"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <!-- acting capacity -->
                        <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                          <b-form-group
                            :label="
                              $t(
                                'fx_spot_forward_swap.trade_form.acting_capacity'
                              )
                            "
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="
                                $t(
                                  'fx_spot_forward_swap.trade_form.acting_capacity'
                                )
                              "
                            >
                              <v-select
                                v-model="tradeForm.actingCapaticy"
                                :options="optActingCapacities"
                                :clearable="true"
                                :placeholder="
                                  $t(
                                    'fx_spot_forward_swap.trade_form.select_acting_capacity'
                                  )
                                "
                                :reduce="(val) => val"
                                :state="errors.length > 0 ? false : null"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <!-- note -->
                        <b-col cols="12" xl="12" lg="12" md="12" sm="12">
                          <b-form-group>
                            <validation-provider
                              #default="{ errors }"
                              :name="$t('fx_spot_forward_swap.trade_form.note')"
                            >
                              <b-form-textarea
                                id="form-textarea"
                                :placeholder="
                                  $t('fx_spot_forward_swap.trade_form.note')
                                "
                                no-resize
                                v-model="tradeForm.note"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </b-card>
                  </b-col>

                  <!-- ///////**************FX Details && Settlement Details ***************************************************************** */ -->

                  <b-col
                    cols="12"
                    sm="12"
                    md="12"
                    lg="6"
                    xl="6"
                    class="pr-0"
                    style="padding-left:0.2rem!important"
                  >
                    <b-card
                      :title="$t('fx_spot_forward_swap.titles.fx_details')"
                    >
                      <b-row>
                        <!-- product type-->
                        <b-col cols="6" xl="3" lg="3" md="3" sm="6">
                          <b-form-group
                            :label="
                              $t('fx_spot_forward_swap.trade_form.product_type')
                            "
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="
                                $t(
                                  'fx_spot_forward_swap.trade_form.product_type'
                                )
                              "
                            >
                              <v-select
                                v-model="tradeForm.productType"
                                :options="optproductTypes"
                                :clearable="true"
                                :placeholder="
                                  $t(
                                    'fx_spot_forward_swap.trade_form.select_product_type'
                                  )
                                "
                                :reduce="(val) => val"
                                :state="errors.length > 0 ? false : null"
                                @option:selected="
                                  selectedProductType(tradeForm.productType)
                                "
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <!-- settlement type-->
                        <b-col cols="6" xl="3" lg="3" md="3" sm="6">
                          <b-form-group
                            :label="
                              $t(
                                'fx_spot_forward_swap.trade_form.settlement_type'
                              )
                            "
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="
                                $t(
                                  'fx_spot_forward_swap.trade_form.settlement_type'
                                )
                              "
                            >
                              <v-select
                                v-model="tradeForm.settlementType"
                                :options="optSettlementTypes"
                                :clearable="true"
                                :placeholder="
                                  $t(
                                    'fx_spot_forward_swap.trade_form.select_settlement_type'
                                  )
                                "
                                :reduce="(val) => val"
                                :state="errors.length > 0 ? false : null"
                                @option:selected="
                                  selectedSettlementType(
                                    tradeForm.settlementType
                                  )
                                "
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <!-- cost center -->
                        <b-col cols="6" xl="3" lg="3" md="3" sm="6">
                          <b-form-group
                            :label="$t('equity_bond.trade_form.cost_center')"
                          >
                            <v-select
                              v-model="tradeForm.costCenter"
                              :options="optCostCenters"
                              :clearable="true"
                              :placeholder="
                                $t('equity_bond.trade_form.select_cost_center')
                              "
                              :reduce="(val) => val.CostCenter"
                              label="CostCenter"
                            />
                          </b-form-group>
                        </b-col>
                        <!-- Counterparty Rate -->
                        <b-col cols="6" xl="3" lg="3" md="3" sm="6">
                          <b-form-group
                            :label="
                              $t(
                                'fx_spot_forward_swap.trade_form.counterparty_rate'
                              )
                            "
                          >
                            <div>
                              <b-input-group>
                                <cleave
                                  id="counterpartyRate"
                                  v-model.number="tradeForm.counterpartyRate"
                                  class="form-control"
                                  :raw="true"
                                  :options="cleave8DecimalOptions.number"
                                  :placeholder="
                                    $t('fx_spot_forward_swap.trade_form.min')
                                  "
                                />
                                <cleave
                                  id="counterpartyRate"
                                  v-model.number="tradeForm.counterpartyRateEnd"
                                  class="form-control"
                                  :raw="true"
                                  :options="cleave8DecimalOptions.number"
                                  :placeholder="
                                    $t('fx_spot_forward_swap.trade_form.max')
                                  "
                                />
                              </b-input-group>
                            </div>
                          </b-form-group>
                        </b-col>
                        <!-- <b-col cols="12" xl="12" lg="12" md="12" sm="12">
                    
                      <h6 style="text-decoration:underline;text-align:center;">
                        {{ $t("fx_spot_forward_swap.trade_form.sfc") }}</h6>
                    </b-col> -->

                        <!-- ------ SFC ------------ -->

                        <!-- cptyAmount1 -->
                        <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                          <b-form-group
                            :label="
                              $t('fx_spot_forward_swap.trade_form.cpty_amount1')
                            "
                          >
                            <div>
                              <b-input-group>
                                <cleave
                                  id="clientPrice"
                                  v-model.number="tradeForm.cptyAmount1"
                                  class="form-control"
                                  :raw="true"
                                  :options="cleave4DecimalOptions.number"
                                  @blur.native="
                                    calculateAsCptyAmount1(
                                      tradeForm.cptyAmount1
                                    )
                                  "
                                  :placeholder="
                                    $t('fx_spot_forward_swap.trade_form.min')
                                  "
                                />
                                <cleave
                                  id="clientPrice"
                                  v-model.number="tradeForm.cptyAmount1End"
                                  class="form-control"
                                  :raw="true"
                                  :options="cleave4DecimalOptions.number"
                                  @blur.native="
                                    calculateAsCptyAmount1(
                                      tradeForm.cptyAmount1End
                                    )
                                  "
                                  :placeholder="
                                    $t('fx_spot_forward_swap.trade_form.min')
                                  "
                                />
                              </b-input-group>
                            </div>
                          </b-form-group>
                        </b-col>

                        <!-- cptyCcy1 -->
                        <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                          <b-form-group
                            :label="$t('fx_spot_forward_swap.trade_form.ccy')"
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="$t('fx_spot_forward_swap.trade_form.ccy')"
                            >
                              <v-select
                                v-model="tradeForm.cptyCcy1"
                                :options="optPriceTypes"
                                :clearable="true"
                                :placeholder="
                                  $t('fx_spot_forward_swap.trade_form.ccy')
                                "
                                :reduce="(val) => val"
                                :state="errors.length > 0 ? false : null"
                                @option:selected="
                                  selectedCptyCcy1(tradeForm.productType)
                                "
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <!-- //sfcBuySell1 -->
                        <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                          <b-form-group
                            :label="
                              $t(
                                'fx_spot_forward_swap.trade_form.sfc_buy_sell1'
                              )
                            "
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="
                                $t(
                                  'fx_spot_forward_swap.trade_form.sfc_buy_sell1'
                                )
                              "
                            >
                              <v-select
                                v-model="tradeForm.cptyBuySell1"
                                :options="optcptyBuySells"
                                :clearable="true"
                                :placeholder="
                                  $t(
                                    'fx_spot_forward_swap.trade_form.sfc_buy_sell1'
                                  )
                                "
                                :reduce="(val) => val"
                                :state="errors.length > 0 ? false : null"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>

                        <!-- ------ SFC 2 ------------ -->
                        <!-- cptyAmount2 -->
                        <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                          <b-form-group
                            :label="
                              $t('fx_spot_forward_swap.trade_form.cpty_amount2')
                            "
                          >
                            <div>
                              <b-input-group>
                                <cleave
                                  id="clientPrice"
                                  v-model.number="tradeForm.cptyAmount2"
                                  class="form-control"
                                  :raw="true"
                                  :options="cleave4DecimalOptions.number"
                                  @blur.native="
                                    calculateAsCptyAmount2(
                                      tradeForm.cptyAmount2
                                    )
                                  "
                                  :placeholder="
                                    $t('fx_spot_forward_swap.trade_form.min')
                                  "
                                />
                                <cleave
                                  id="clientPrice"
                                  v-model.number="tradeForm.cptyAmount2End"
                                  class="form-control"
                                  :raw="true"
                                  :options="cleave4DecimalOptions.number"
                                  @blur.native="
                                    calculateAsCptyAmount2(
                                      tradeForm.cptyAmount2End
                                    )
                                  "
                                  :placeholder="
                                    $t('fx_spot_forward_swap.trade_form.min')
                                  "
                                />
                              </b-input-group>
                            </div>
                          </b-form-group>
                        </b-col>
                        <!-- cptyCcy2 -->
                        <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                          <b-form-group
                            :label="$t('fx_spot_forward_swap.trade_form.ccy')"
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="$t('fx_spot_forward_swap.trade_form.ccy')"
                            >
                              <v-select
                                v-model="tradeForm.cptyCcy2"
                                :options="optPriceTypes"
                                :clearable="true"
                                :placeholder="
                                  $t('fx_spot_forward_swap.trade_form.ccy')
                                "
                                :reduce="(val) => val"
                                :state="errors.length > 0 ? false : null"
                                @option:selected="
                                  selectedCptyCcy2(tradeForm.productType)
                                "
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <!-- //sfcBuySell1 -->
                        <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                          <b-form-group
                            :label="
                              $t(
                                'fx_spot_forward_swap.trade_form.sfc_buy_sell2'
                              )
                            "
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="
                                $t(
                                  'fx_spot_forward_swap.trade_form.sfc_buy_sell2'
                                )
                              "
                            >
                              <v-select
                                v-model="tradeForm.cptyBuySell2"
                                :options="optcptyBuySells"
                                :clearable="true"
                                :placeholder="
                                  $t(
                                    'fx_spot_forward_swap.trade_form.sfc_buy_sell2'
                                  )
                                "
                                :reduce="(val) => val"
                                :state="errors.length > 0 ? false : null"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>

                        <!-- calculate client rate radio buttons -->
                        <b-col
                          cols="6"
                          sm="4"
                          xl="4"
                          lg="4"
                          md="6"
                          class="mt-1"
                        >
                          <b-form-group>
                            <b-form-radio-group
                              v-model="tradeForm.calcFromSpread"
                              :options="optCalcFromSpread"
                              name="radios-stacked"
                              stacked
                              plain
                              class="fxDetailRadio"
                              @change="
                                changedCalcFromSpread(tradeForm.calcFromSpread)
                              "
                            />
                          </b-form-group>
                        </b-col>

                        <!-- Spread min & max -->
                        <b-col cols="6" xl="4" lg="4" md="6" sm="6">
                          <b-form-group
                            :label="
                              $t('fx_spot_forward_swap.trade_form.spread')
                            "
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="
                                $t('fx_spot_forward_swap.trade_form.spread')
                              "
                            >
                              <b-input-group append="pips">
                                <cleave
                                  id="spread"
                                  :state="errors.length > 0 ? false : null"
                                  v-model.number="tradeForm.spread"
                                  class="form-control"
                                  :raw="true"
                                  :options="cleave4DecimalOptions.number"
                                  :placeholder="
                                    $t('fx_spot_forward_swap.trade_form.min')
                                  "
                                  @blur.native="changedSpread(tradeForm.spread)"
                                />
                                <cleave
                                  id="spread"
                                  v-model.number="tradeForm.spreadEnd"
                                  class="form-control"
                                  :raw="true"
                                  :options="cleave4DecimalOptions.number"
                                  :placeholder="
                                    $t('fx_spot_forward_swap.trade_form.max')
                                  "
                                  @blur.native="
                                    changedSpread(tradeForm.spreadEnd)
                                  "
                                />
                              </b-input-group>

                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>

                        <!-- Client Rate -->
                        <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                          <b-form-group
                            :label="
                              $t('fx_spot_forward_swap.trade_form.client_rate')
                            "
                          >
                            <div>
                              <b-input-group>
                                <cleave
                                  id="clientRate"
                                  v-model.number="tradeForm.clientRate"
                                  class="form-control"
                                  :raw="true"
                                  :options="cleave8DecimalOptions.number"
                                  :placeholder="
                                    $t('fx_spot_forward_swap.trade_form.min')
                                  "
                                />
                                <cleave
                                  id="clientRate"
                                  v-model.number="tradeForm.clientRateEnd"
                                  class="form-control"
                                  :raw="true"
                                  :options="cleave8DecimalOptions.number"
                                  :placeholder="
                                    $t('fx_spot_forward_swap.trade_form.max')
                                  "
                                />
                              </b-input-group>
                            </div>
                          </b-form-group>
                        </b-col>

                        <!-- ------ Client ------------ -->

                        <!-- <b-col cols="12" xl="12" lg="12" md="12" sm="12">
                      <h6 style="text-decoration:underline;text-align:center;">
                      {{ $t("fx_spot_forward_swap.trade_form.client2") }}</h6>
                    </b-col> -->

                        <!-- clientAmount1 -->
                        <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                          <b-form-group
                            :label="
                              $t(
                                'fx_spot_forward_swap.trade_form.client_amount1'
                              )
                            "
                          >
                            <div>
                              <b-input-group>
                                <cleave
                                  id="clientPrice"
                                  v-model.number="tradeForm.clientAmount1"
                                  class="form-control"
                                  :raw="true"
                                  :options="cleave4DecimalOptions.number"
                                  @blur.native="
                                    calculateAsclientAmount1(
                                      tradeForm.clientAmount1
                                    )
                                  "
                                  :placeholder="
                                    $t('fx_spot_forward_swap.trade_form.min')
                                  "
                                />
                                <cleave
                                  id="clientPrice"
                                  v-model.number="tradeForm.clientAmount1End"
                                  class="form-control"
                                  :raw="true"
                                  :options="cleave4DecimalOptions.number"
                                  @blur.native="
                                    calculateAsclientAmount1(
                                      tradeForm.clientAmount1End
                                    )
                                  "
                                  :placeholder="
                                    $t('fx_spot_forward_swap.trade_form.min')
                                  "
                                />
                              </b-input-group>
                            </div>
                          </b-form-group>
                        </b-col>
                        <!-- clientCcy1 -->
                        <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                          <b-form-group
                            :label="$t('fx_spot_forward_swap.trade_form.ccy')"
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="$t('fx_spot_forward_swap.trade_form.ccy')"
                            >
                              <v-select
                                v-model="tradeForm.clientCcy1"
                                :options="optPriceTypes"
                                :clearable="true"
                                :placeholder="
                                  $t('fx_spot_forward_swap.trade_form.ccy')
                                "
                                :reduce="(val) => val"
                                :state="errors.length > 0 ? false : null"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <!-- clientBuySell1 -->
                        <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                          <b-form-group
                            :label="
                              $t(
                                'fx_spot_forward_swap.trade_form.client_buy_sell1'
                              )
                            "
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="
                                $t(
                                  'fx_spot_forward_swap.trade_form.client_buy_sell1'
                                )
                              "
                            >
                              <v-select
                                v-model="tradeForm.clientBuySell1"
                                :options="optclientBuySells"
                                :clearable="true"
                                :placeholder="
                                  $t(
                                    'fx_spot_forward_swap.trade_form.client_buy_sell1'
                                  )
                                "
                                :reduce="(val) => val"
                                :state="errors.length > 0 ? false : null"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>

                        <!-- ------ Client 2 ------------ -->
                        <!-- clientAmount2 -->
                        <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                          <b-form-group
                            :label="
                              $t(
                                'fx_spot_forward_swap.trade_form.client_amount2'
                              )
                            "
                          >
                            <div>
                              <b-input-group>
                                <cleave
                                  id="clientPrice"
                                  v-model.number="tradeForm.clientAmount2"
                                  class="form-control"
                                  :raw="true"
                                  :options="cleave4DecimalOptions.number"
                                  @blur.native="
                                    calculateAsclientAmount2(
                                      tradeForm.clientAmount2
                                    )
                                  "
                                  :placeholder="
                                    $t('fx_spot_forward_swap.trade_form.min')
                                  "
                                />
                                <cleave
                                  id="clientPrice"
                                  v-model.number="tradeForm.clientAmount2End"
                                  class="form-control"
                                  :raw="true"
                                  :options="cleave4DecimalOptions.number"
                                  @blur.native="
                                    calculateAsclientAmount2(
                                      tradeForm.clientAmount2End
                                    )
                                  "
                                  :placeholder="
                                    $t('fx_spot_forward_swap.trade_form.min')
                                  "
                                />
                              </b-input-group>
                            </div>
                          </b-form-group>
                        </b-col>
                        <!-- clientCcy2 -->
                        <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                          <b-form-group
                            :label="$t('fx_spot_forward_swap.trade_form.ccy')"
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="$t('fx_spot_forward_swap.trade_form.ccy')"
                            >
                              <v-select
                                v-model="tradeForm.clientCcy2"
                                :options="optPriceTypes"
                                :clearable="true"
                                :placeholder="
                                  $t('fx_spot_forward_swap.trade_form.ccy')
                                "
                                :reduce="(val) => val"
                                :state="errors.length > 0 ? false : null"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <!-- clientBuySell2 -->
                        <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                          <b-form-group
                            :label="
                              $t(
                                'fx_spot_forward_swap.trade_form.client_buy_sell2'
                              )
                            "
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="
                                $t(
                                  'fx_spot_forward_swap.trade_form.client_buy_sell2'
                                )
                              "
                            >
                              <v-select
                                v-model="tradeForm.clientBuySell2"
                                :options="optclientBuySells"
                                :clearable="true"
                                :placeholder="
                                  $t(
                                    'fx_spot_forward_swap.trade_form.client_buy_sell2'
                                  )
                                "
                                :reduce="(val) => val"
                                :state="errors.length > 0 ? false : null"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <!-- Spot Ref -->
                        <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                          <b-form-group
                            :label="
                              $t('fx_spot_forward_swap.trade_form.spot_ref')
                            "
                          >
                            <validation-provider
                              #default="{ errors }"
                              name="spot_ref"
                            >
                              <b-form-input
                                id="spot_ref"
                                v-model="tradeForm.spotRef"
                                :name="
                                  $t('fx_spot_forward_swap.trade_form.spot_ref')
                                "
                                :state="errors.length > 0 ? false : null"
                                :placeholder="
                                  $t('fx_spot_forward_swap.trade_form.spot_ref')
                                "
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>

                        <!-- Spred comm -->
                        <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                          <b-form-group
                            :label="
                              $t('fx_spot_forward_swap.trade_form.spread_comm')
                            "
                          >
                            <div>
                              <b-input-group>
                                <cleave
                                  id="clientPrice"
                                  v-model.number="tradeForm.spreadAmount"
                                  class="form-control"
                                  :raw="true"
                                  :options="cleaveOptions.number"
                                  :placeholder="
                                    $t('fx_spot_forward_swap.trade_form.min')
                                  "
                                />
                                <cleave
                                  id="clientPrice"
                                  v-model.number="tradeForm.spreadAmountEnd"
                                  class="form-control"
                                  :raw="true"
                                  :options="cleaveOptions.number"
                                  :placeholder="
                                    $t('fx_spot_forward_swap.trade_form.min')
                                  "
                                />
                              </b-input-group>
                            </div>
                          </b-form-group>
                        </b-col>
                        <!-- spread price types CCY -->
                        <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                          <b-form-group
                            :label="$t('fx_spot_forward_swap.trade_form.ccy')"
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="$t('fx_spot_forward_swap.trade_form.ccy')"
                            >
                              <v-select
                                v-model="tradeForm.spreadCcy"
                                :options="optPriceTypes"
                                :clearable="true"
                                :placeholder="
                                  $t('fx_spot_forward_swap.trade_form.ccy')
                                "
                                :reduce="(val) => val"
                                :state="errors.length > 0 ? false : null"
                                @option:selected="
                                  selectedSpreadCcy(tradeForm.productType)
                                "
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>

                        <!-- Comission -->
                        <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                          <b-form-group
                            :label="
                              $t('fx_spot_forward_swap.trade_form.comission')
                            "
                          >
                            <div>
                              <b-input-group>
                                <cleave
                                  id="clientPrice"
                                  v-model.number="tradeForm.comissionAmount"
                                  class="form-control"
                                  :raw="true"
                                  :options="cleaveOptions.number"
                                  :placeholder="
                                    $t('fx_spot_forward_swap.trade_form.min')
                                  "
                                />
                                <cleave
                                  id="clientPrice"
                                  v-model.number="tradeForm.comissionAmountEnd"
                                  class="form-control"
                                  :raw="true"
                                  :options="cleaveOptions.number"
                                  :placeholder="
                                    $t('fx_spot_forward_swap.trade_form.min')
                                  "
                                />
                              </b-input-group>
                            </div>
                          </b-form-group>
                        </b-col>
                        <!-- comission CCY -->
                        <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                          <b-form-group
                            :label="
                              $t('fx_spot_forward_swap.trade_form.price_type')
                            "
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="
                                $t('fx_spot_forward_swap.trade_form.price_type')
                              "
                            >
                              <v-select
                                v-model="tradeForm.comissionCcy"
                                :options="optPriceTypes"
                                :clearable="true"
                                :placeholder="
                                  $t(
                                    'fx_spot_forward_swap.trade_form.price_type'
                                  )
                                "
                                :reduce="(val) => val"
                                :state="errors.length > 0 ? false : null"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>

                        <!-- cut off time -->
                        <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                          <b-form-group
                            :label="
                              $t('fx_spot_forward_swap.trade_form.cut_off_time')
                            "
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="
                                $t(
                                  'fx_spot_forward_swap.trade_form.cut_off_time'
                                )
                              "
                            >
                              <v-select
                                v-model="tradeForm.cutOffTime"
                                :options="optCutOffTimes"
                                :clearable="true"
                                :placeholder="
                                  $t(
                                    'fx_spot_forward_swap.trade_form.cut_off_time'
                                  )
                                "
                                :reduce="(val) => val"
                                :state="errors.length > 0 ? false : null"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>

                        <!-- deliverable ccy-->
                        <!-- <b-col cols="6" xl="4" lg="4" md="4" sm="6">

                      <b-form-group
                        :label="
                          $t('fx_spot_forward_swap.trade_form.deliverable_ccy')
                        "
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="
                            $t(
                              'fx_spot_forward_swap.trade_form.deliverable_ccy'
                            )
                          "
                        >
                          <v-select
                            v-model="tradeForm.deliverableCcy"
                            :options="optPriceTypes"
                            :clearable="true"
                            :placeholder="
                              $t(
                                'fx_spot_forward_swap.trade_form.deliverable_ccy'
                              )
                            "
                            :reduce="(val) => val"
                            :state="errors.length > 0 ? false : null"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col> -->
                      </b-row>
                    </b-card>
                  </b-col>
                </b-row>

                <!-- /******************************* Swap Details***************************************************************** */ -->

                <b-row
                  v-if="tradeForm.productType == 'SWAP'"
                  class="animate__animated animate__fadeIn"
                >
                  <b-card
                    :title="$t('fx_spot_forward_swap.titles.swap_details')"
                  >
                    <b-row class="flex align-items-center">
                      <!-- Swap Trade Date -->
                      <b-col cols="6" xl="2" lg="2" md="4" sm="6">
                        <b-form-group
                          :label="
                            $t(
                              'fx_spot_forward_swap.trade_form.swap_trade_date'
                            )
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t(
                                'fx_spot_forward_swap.trade_form.swap_trade_date'
                              )
                            "
                          >
                            <b-input-group>
                              <flat-pickr
                                :placeholder="
                                  $t(
                                    'fx_spot_forward_swap.trade_form.swap_trade_date'
                                  )
                                "
                                v-model="swapTradeDateRange"
                                onClose="testClose"
                                class="form-control"
                                :config="dateConfig"
                              />
                              <b-input-group-append>
                                <b-button
                                  @click="clearSwapTradeDate()"
                                  variant="secondary"
                                  size="sm"
                                  >{{ $t("general_buttons.clear") }}</b-button
                                >
                              </b-input-group-append>
                            </b-input-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- Swap settle Date -->
                      <b-col cols="6" xl="2" lg="2" md="4" sm="6">
                        <b-form-group
                          :label="
                            $t(
                              'fx_spot_forward_swap.trade_form.swap_settle_date'
                            )
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t(
                                'fx_spot_forward_swap.trade_form.swap_settle_date'
                              )
                            "
                          >
                            <b-input-group>
                              <flat-pickr
                                :placeholder="
                                  $t(
                                    'fx_spot_forward_swap.trade_form.swap_settle_date'
                                  )
                                "
                                v-model="swapSettleDateRange"
                                onClose="testClose"
                                class="form-control"
                                :config="dateConfig"
                                @on-change="
                                  arrangeSwapTradetoSettleDays(
                                    'Swap Settle Date'
                                  )
                                "
                              />
                              <b-input-group-append>
                                <b-button
                                  @click="clearSwapSettleDateDate()"
                                  variant="secondary"
                                  size="sm"
                                  >{{ $t("general_buttons.clear") }}</b-button
                                >
                              </b-input-group-append>
                            </b-input-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- Swap Points -->
                      <b-col cols="6" xl="2" lg="2" md="4" sm="6">
                        <b-form-group
                          :label="
                            $t('fx_spot_forward_swap.trade_form.swap_points')
                          "
                        >
                          <div>
                            <b-input-group append="pips">
                              <cleave
                                id="swapPoints"
                                v-model.number="tradeForm.swapPoints"
                                class="form-control"
                                :raw="true"
                                :options="cleaveOptions.number"
                                @blur.native="
                                  changedSwapPoints(tradeForm.swapPoints)
                                "
                                :placeholder="
                                  $t('fx_spot_forward_swap.trade_form.min')
                                "
                              />
                              <cleave
                                id="swapPoints"
                                v-model.number="tradeForm.swapPointsEnd"
                                class="form-control"
                                :raw="true"
                                :options="cleaveOptions.number"
                                :placeholder="
                                  $t('fx_spot_forward_swap.trade_form.min')
                                "
                              />
                            </b-input-group>
                          </div>
                        </b-form-group>
                      </b-col>

                      <!-- swap cpty rate -->
                      <b-col cols="6" xl="2" lg="2" md="4" sm="6">
                        <b-form-group
                          :label="
                            $t('fx_spot_forward_swap.trade_form.swap_cpty_rate')
                          "
                        >
                          <div>
                            <b-input-group>
                              <cleave
                                id="swap_cpty_rate"
                                v-model.number="tradeForm.swapCptyRate"
                                class="form-control"
                                :raw="true"
                                :options="cleave8DecimalOptions.number"
                                :placeholder="
                                  $t('fx_spot_forward_swap.trade_form.min')
                                "
                              />
                              <cleave
                                id="swap_cpty_rate"
                                v-model.number="tradeForm.swapCptyRateEnd"
                                class="form-control"
                                :raw="true"
                                :options="cleave8DecimalOptions.number"
                                :placeholder="
                                  $t('fx_spot_forward_swap.trade_form.min')
                                "
                              />
                            </b-input-group>
                          </div>
                        </b-form-group>
                      </b-col>

                      <!-- swap calculate client rate radio buttons -->
                      <b-col
                        cols="6"
                        xl="2"
                        lg="2"
                        md="4"
                        sm="6"
                        class="mt-1 padleft-0"
                      >
                        <b-form-group>
                          <b-form-radio-group
                            v-model="tradeForm.swapCalcFromSpread"
                            :options="optCalcFromSpread"
                            name="radios-stacked2"
                            plain
                            stacked
                            class="fxDetailRadio"
                          />
                        </b-form-group>
                      </b-col>

                      <!-- <b-col cols="12" xl="12" lg="12" md="12" sm="12">
                      <h6 style="text-decoration:underline;text-align:center;">
                      {{ $t("fx_spot_forward_swap.trade_form.swap_sfc") }}</h6>
                    </b-col> -->

                      <!-- swap cptyAmount1 -->
                      <b-col cols="6" xl="2" lg="2" md="4" sm="6">
                        <b-form-group
                          :label="
                            $t(
                              'fx_spot_forward_swap.trade_form.swap_cpty_amount1'
                            )
                          "
                        >
                          <div>
                            <b-input-group>
                              <cleave
                                id="swapPoints"
                                v-model.number="tradeForm.swapCptyAmount1"
                                class="form-control"
                                :raw="true"
                                :options="cleaveOptions.number"
                                :placeholder="
                                  $t('fx_spot_forward_swap.trade_form.min')
                                "
                              />
                              <cleave
                                id="swapCptyAmount1"
                                v-model.number="tradeForm.swapCptyAmount1End"
                                class="form-control"
                                :raw="true"
                                :options="cleaveOptions.number"
                                :placeholder="
                                  $t('fx_spot_forward_swap.trade_form.min')
                                "
                              />
                            </b-input-group>
                          </div>
                        </b-form-group>
                      </b-col>
                      <!-- swapCptyCcy1 -->
                      <b-col cols="6" xl="1" lg="1" md="4" sm="6">
                        <b-form-group
                          :label="$t('fx_spot_forward_swap.trade_form.ccy')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_spot_forward_swap.trade_form.ccy')"
                          >
                            <v-select
                              v-model="tradeForm.swapCptyCcy1"
                              :options="optPriceTypes"
                              :clearable="true"
                              :placeholder="
                                $t('fx_spot_forward_swap.trade_form.ccy')
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!--swap  cptyBuySell 1 -->
                      <b-col cols="6" xl="1" lg="1" md="4" sm="6">
                        <b-form-group
                          :label="
                            $t(
                              'fx_spot_forward_swap.trade_form.swap_cpty_buysell1'
                            )
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t(
                                'fx_spot_forward_swap.trade_form.swap_cpty_buysell1'
                              )
                            "
                          >
                            <v-select
                              v-model="tradeForm.swapCptyBuySell1"
                              :options="optcptyBuySells"
                              :clearable="true"
                              :placeholder="
                                $t(
                                  'fx_spot_forward_swap.trade_form.swap_cpty_buysell1'
                                )
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- swap SFC 2 -->

                      <!-- swap cptyAmount 2 -->
                      <b-col cols="6" xl="2" lg="2" md="4" sm="6">
                        <b-form-group
                          :label="
                            $t(
                              'fx_spot_forward_swap.trade_form.swap_cpty_amount2'
                            )
                          "
                        >
                          <div>
                            <b-input-group>
                              <cleave
                                id="swapPoints"
                                v-model.number="tradeForm.swapCptyAmount2"
                                class="form-control"
                                :raw="true"
                                :options="cleaveOptions.number"
                                :placeholder="
                                  $t('fx_spot_forward_swap.trade_form.min')
                                "
                              />
                              <cleave
                                id="swapCptyAmount2"
                                v-model.number="tradeForm.swapCptyAmount2End"
                                class="form-control"
                                :raw="true"
                                :options="cleaveOptions.number"
                                :placeholder="
                                  $t('fx_spot_forward_swap.trade_form.min')
                                "
                              />
                            </b-input-group>
                          </div>
                        </b-form-group>
                      </b-col>
                      <!-- swap cptyPriceType 2 -->
                      <b-col cols="6" xl="1" lg="1" md="4" sm="6">
                        <b-form-group
                          :label="$t('fx_spot_forward_swap.trade_form.ccy')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_spot_forward_swap.trade_form.ccy')"
                          >
                            <v-select
                              v-model="tradeForm.swapCptyCcyType2"
                              :options="optPriceTypes"
                              :clearable="true"
                              :placeholder="
                                $t('fx_spot_forward_swap.trade_form.ccy')
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- swap cptyBuySell 2 -->
                      <b-col cols="6" xl="1" lg="1" md="4" sm="6">
                        <b-form-group
                          :label="
                            $t(
                              'fx_spot_forward_swap.trade_form.swap_cpty_buysell2'
                            )
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t(
                                'fx_spot_forward_swap.trade_form.swap_cpty_buysell2'
                              )
                            "
                          >
                            <v-select
                              v-model="tradeForm.swapCptyBuySell2"
                              :options="optcptyBuySells"
                              :clearable="true"
                              :placeholder="
                                $t(
                                  'fx_spot_forward_swap.trade_form.swap_cpty_buysell2'
                                )
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Swap Spread -->
                      <b-col cols="6" xl="2" lg="2" md="4" sm="6">
                        <b-form-group
                          :label="
                            $t('fx_spot_forward_swap.trade_form.swap_spread')
                          "
                        >
                          <div>
                            <b-input-group append="pips">
                              <cleave
                                id="swapPoints"
                                v-model.number="tradeForm.swapSpread"
                                class="form-control"
                                :raw="true"
                                :options="cleaveOptions.number"
                                :placeholder="
                                  $t('fx_spot_forward_swap.trade_form.min')
                                "
                              />
                              <cleave
                                id="swapSpread"
                                v-model.number="tradeForm.swapSpreadEnd"
                                class="form-control"
                                :raw="true"
                                :options="cleaveOptions.number"
                                :placeholder="
                                  $t('fx_spot_forward_swap.trade_form.min')
                                "
                              />
                            </b-input-group>
                          </div>
                        </b-form-group>
                      </b-col>
                      <!-- swap Client Rate -->
                      <b-col cols="6" xl="2" lg="2" md="4" sm="6">
                        <b-form-group
                          :label="
                            $t(
                              'fx_spot_forward_swap.trade_form.swap_client_rate'
                            )
                          "
                        >
                          <div>
                            <b-input-group>
                              <cleave
                                id="swapClientRate"
                                v-model.number="tradeForm.swapClientRate"
                                class="form-control"
                                :raw="true"
                                :options="cleave8DecimalOptions.number"
                                :placeholder="
                                  $t('fx_spot_forward_swap.trade_form.min')
                                "
                              />
                              <cleave
                                id="swapClientRate"
                                v-model.number="tradeForm.swapClientRateEnd"
                                class="form-control"
                                :raw="true"
                                :options="cleave8DecimalOptions.number"
                                :placeholder="
                                  $t('fx_spot_forward_swap.trade_form.min')
                                "
                              />
                            </b-input-group>
                          </div>
                        </b-form-group>
                      </b-col>

                      <!-- <b-col cols="12" xl="12" lg="12" md="12" sm="12">
                      <h6 style="text-decoration:underline;text-align:center;">
                      {{ $t("fx_spot_forward_swap.trade_form.swap_client") }}</h6>
                    </b-col> -->

                      <!-- swap clientAmount1 -->
                      <b-col cols="6" xl="2" lg="2" md="4" sm="6">
                        <b-form-group
                          :label="
                            $t(
                              'fx_spot_forward_swap.trade_form.swap_client_amount1'
                            )
                          "
                        >
                          <div>
                            <b-input-group>
                              <cleave
                                id="swapClientAmount1"
                                v-model.number="tradeForm.swapClientAmount1"
                                class="form-control"
                                :raw="true"
                                :options="cleaveOptions.number"
                                :placeholder="
                                  $t('fx_spot_forward_swap.trade_form.min')
                                "
                              />
                              <cleave
                                id="swapClientAmount1"
                                v-model.number="tradeForm.swapClientAmount1End"
                                class="form-control"
                                :raw="true"
                                :options="cleaveOptions.number"
                                :placeholder="
                                  $t('fx_spot_forward_swap.trade_form.min')
                                "
                              />
                            </b-input-group>
                          </div>
                        </b-form-group>
                      </b-col>
                      <!-- swap clientPriceType 1 -->
                      <b-col cols="6" xl="1" lg="1" md="4" sm="6">
                        <b-form-group
                          :label="$t('fx_spot_forward_swap.trade_form.ccy')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_spot_forward_swap.trade_form.ccy')"
                          >
                            <v-select
                              v-model="tradeForm.swapClientCcy1"
                              :options="optPriceTypes"
                              :clearable="true"
                              :placeholder="
                                $t('fx_spot_forward_swap.trade_form.ccy')
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- swap clientBuySell 1 -->
                      <b-col cols="6" xl="1" lg="1" md="4" sm="6">
                        <b-form-group
                          :label="
                            $t(
                              'fx_spot_forward_swap.trade_form.swap_client_buysell1'
                            )
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t(
                                'fx_spot_forward_swap.trade_form.swap_client_buysell1'
                              )
                            "
                          >
                            <v-select
                              v-model="tradeForm.swapClientBuySell1"
                              :options="optclientBuySells"
                              :clearable="true"
                              :placeholder="
                                $t(
                                  'fx_spot_forward_swap.trade_form.swap_client_buysell1'
                                )
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- swap Client 2 -->
                      <!-- swap clientAmount 2 -->
                      <b-col cols="12" xl="2" lg="2" md="4" sm="6">
                        <b-form-group
                          :label="
                            $t(
                              'fx_spot_forward_swap.trade_form.swap_client_amount2'
                            )
                          "
                        >
                          <div>
                            <b-input-group>
                              <cleave
                                id="swapClientAmount2"
                                v-model.number="tradeForm.swapClientAmount2"
                                class="form-control"
                                :raw="true"
                                :options="cleaveOptions.number"
                                :placeholder="
                                  $t('fx_spot_forward_swap.trade_form.min')
                                "
                              />
                              <cleave
                                id="swapClientAmount2"
                                v-model.number="tradeForm.swapClientAmount2End"
                                class="form-control"
                                :raw="true"
                                :options="cleaveOptions.number"
                                :placeholder="
                                  $t('fx_spot_forward_swap.trade_form.min')
                                "
                              />
                            </b-input-group>
                          </div>
                        </b-form-group>
                      </b-col>
                      <!-- swap clientPriceType 2 -->
                      <b-col cols="6" xl="1" lg="1" md="4" sm="6">
                        <b-form-group
                          :label="$t('fx_spot_forward_swap.trade_form.ccy')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_spot_forward_swap.trade_form.ccy')"
                          >
                            <v-select
                              v-model="tradeForm.swapClientCcy2"
                              :options="optPriceTypes"
                              :clearable="true"
                              :placeholder="
                                $t('fx_spot_forward_swap.trade_form.ccy')
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- swap clientBuySell 2 -->
                      <b-col cols="6" xl="1" lg="1" md="4" sm="6">
                        <b-form-group
                          :label="
                            $t(
                              'fx_spot_forward_swap.trade_form.swap_client_buysell2'
                            )
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t(
                                'fx_spot_forward_swap.trade_form.swap_client_buysell2'
                              )
                            "
                          >
                            <v-select
                              v-model="tradeForm.swapClientBuySell2"
                              :options="optclientBuySells"
                              :clearable="true"
                              :placeholder="
                                $t(
                                  'fx_spot_forward_swap.trade_form.swap_client_buysell2'
                                )
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!--swap Spred amount -->
                      <b-col cols="6" xl="2" lg="2" md="4" sm="6">
                        <b-form-group
                          :label="
                            $t('fx_spot_forward_swap.trade_form.spread_amount')
                          "
                        >
                          <div>
                            <b-input-group>
                              <cleave
                                id="swapSpreadAmount"
                                v-model.number="tradeForm.swapSpreadAmount"
                                class="form-control"
                                :raw="true"
                                :options="cleaveOptions.number"
                                :placeholder="
                                  $t('fx_spot_forward_swap.trade_form.min')
                                "
                              />
                              <cleave
                                id="swapSpreadAmount"
                                v-model.number="tradeForm.swapSpreadAmountEnd"
                                class="form-control"
                                :raw="true"
                                :options="cleaveOptions.number"
                                :placeholder="
                                  $t('fx_spot_forward_swap.trade_form.min')
                                "
                              />
                            </b-input-group>
                          </div>
                        </b-form-group>
                      </b-col>
                      <!--swap  spread price types CCY -->
                      <b-col cols="6" xl="2" lg="2" md="4" sm="6">
                        <b-form-group
                          :label="
                            $t('fx_spot_forward_swap.trade_form.price_type')
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t('fx_spot_forward_swap.trade_form.price_type')
                            "
                          >
                            <v-select
                              v-model="tradeForm.swapSpreadCcy"
                              :options="optPriceTypes"
                              :clearable="true"
                              :placeholder="
                                $t('fx_spot_forward_swap.trade_form.price_type')
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-card>
                </b-row>
                <!-- /******************************* Settlement Details***************************************************************** */ -->

                <b-row class="justify-content-end">
                  <b-col
                    cols="12"
                    xl="6"
                    lg="6"
                    md="6"
                    sm="12"
                    class="pl-0 pr-0"
                  >
                    <b-card title="Settlement Details">
                      <b-row>
                        <!-- UTI  -->
                        <b-col cols="6" xl="4" lg="4" md="6" sm="6">
                          <b-form-group
                            :label="$t('fx_spot_forward_swap.trade_form.uti')"
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="$t('fx_spot_forward_swap.trade_form.uti')"
                            >
                              <b-form-input
                                id="UTI"
                                v-model="tradeForm.uti"
                                name="UTI"
                                :state="errors.length > 0 ? false : null"
                                :placeholder="
                                  $t('fx_spot_forward_swap.trade_form.uti')
                                "
                              />

                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <!-- Unique Link ID -->
                        <b-col cols="6" xl="4" lg="4" md="6" sm="6">
                          <b-form-group
                            :label="
                              $t(
                                'fx_spot_forward_swap.trade_form.unique_link_id'
                              )
                            "
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="
                                $t(
                                  'fx_spot_forward_swap.trade_form.unique_link_id'
                                )
                              "
                            >
                              <b-form-input
                                id="uniqueLinkID"
                                v-model.number="tradeForm.uniqueLinkID"
                                type="number"
                                name="uniqueLinkID"
                                :state="errors.length > 0 ? false : null"
                                :placeholder="
                                  $t(
                                    'fx_spot_forward_swap.trade_form.unique_link_id'
                                  )
                                "
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <!-- Trade Ref -->
                        <b-col cols="12" xl="4" lg="4" md="6" sm="6">
                          <b-form-group
                            :label="
                              $t('fx_spot_forward_swap.trade_form.trade_ref')
                            "
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="
                                $t('fx_spot_forward_swap.trade_form.trade_ref')
                              "
                            >
                              <b-form-input
                                id="tradeRef"
                                v-model="tradeForm.tradeRef"
                                name="tradeRef"
                                :state="errors.length > 0 ? false : null"
                                :placeholder="
                                  $t(
                                    'fx_spot_forward_swap.trade_form.trade_ref'
                                  )
                                "
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </b-card>
                  </b-col>
                </b-row>
              </b-collapse>
            </b-form>
          </validation-observer>
        </b-container>
        <template #overlay v-if="tradeModalLoading">
          <div
            class="text-center d-flex justify-content-center align-items-start"
          >
            <b-icon icon="stopwatch" font-scale="3" animation="cylon"> </b-icon>
            <p id="cancel-label">
              <feather-icon icon="DownloadIcon" />
              <b>{{
                $t("fx_spot_forward_swap.overlay_messages.searching_message")
              }}</b>
            </p>
          </div>
        </template>
      </b-overlay>
      {{/****END*** search for trades modal*/}}

      <template #modal-footer>
        <b-row class="align-items-center" style="flex: 1;">
          <b-col
            cols="12"
            md="12"
            xl="6"
            lg="6"
            sm="12"
            class="d-flex justify-content-start align-items-center"
          >
            <b-overlay
              :show="loadingSavedSearch"
              rounded="lg"
              opacity="0.6"
              class="d-flex align-items-center"
            >
              <template v-slot:overlay>
                <div class="d-flex align-items-center">
                  <b-spinner small type="grow" variant="secondary" />
                  <b-spinner type="grow" variant="dark" />
                  <b-spinner small type="grow" variant="secondary" />
                  <!-- We add an SR only text for screen readers -->
                  <span class="sr-only">Please wait...</span>
                </div>
              </template>

              <b-form-checkbox
                v-model="showSearchInputs"
                class="float-right compact_form_checkbox"
                style="margin-right:0.8rem"
                plain
              >
                {{ "Save this search" }}
              </b-form-checkbox>

              <validation-observer
                ref="tradeSearchValidation"
                style="margin-right:0.5rem"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('general_title.default_entry_name')"
                  :rules="showSearchInputs ? 'required|min:3' : ''"
                >
                  <b-form-input
                    v-if="showSearchInputs"
                    v-model="searchName"
                    placeholder="Enter search name"
                    id="searchName"
                    name="searchName"
                    class="float-right animate__animated animate__fadeIn compact_form-input"
                    type="text"
                    autofocus
                    :state="errors.length > 0 ? false : null"
                  />
                </validation-provider>
              </validation-observer>

              <b-button
                class="btn gradient-info animate__animated animate__fadeIn"
                size="sm"
                variant="gradient-info"
                @click="saveSearch"
                v-if="showSearchInputs"
              >
                Save this search
              </b-button>
            </b-overlay>
          </b-col>

          <!-- trade ok operation buttons -->
          <b-col
            cols="12"
            md="12"
            xl="6"
            lg="6"
            sm="12"
            class="d-flex justify-content-end align-items-center"
          >
            <b-dropdown
              text="My Saved Searches"
              dropup
              right
              class="mr-1 animate__animated animate__fadeIn"
              variant="dark"
              size="sm"
              v-if="savedSearchesList.length > 0"
            >
              <b-dropdown-item
                v-for="(n, index) in savedSearchesList"
                :key="index"
                class="d-flex align-items-center"
              >
                <feather-icon
                  class="mr-1"
                  style="color:red;"
                  @click="deleteSavedSearch(n.id)"
                  icon="XIcon"
                  size="12"
                />
                <span @click="selectSavedSearch(n.SearchData)"
                  >{{ n.SearchName }}
                </span>
              </b-dropdown-item>
            </b-dropdown>

            <b-button
              v-b-modal.modal-close_visit
              class="btn btn-secondary mr-1"
              @click="resetSearchForTradesModal"
              size="sm"
            >
              {{ $t("equity_bond.modals.modal_cancel") }}
            </b-button>

            <b-button
              v-b-modal.modal-close_visit
              class="btn btn-primary mr-1"
              variant="primary"
              @click="searchForTrades"
              size="sm"
            >
              {{ $t("equity_bond.modals.search_trade_modal_ok") }}
            </b-button>
          </b-col>
        </b-row>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BOverlay,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormRating,
  VBModal,
  BTabs,
  BTab,
  BCardText,
  BIcon,
  BPopover,
} from "bootstrap-vue";
import store from "@/store";
import { onUnmounted } from "@vue/composition-api";
import fXSpotForwardSwapStoreModule from "./FXSpotForwardSwapStoreModule";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, alphaNum, email } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import axios from "axios";
import axiosIns from "@/libs/axios";
import router from "@/router";
import moment from "moment";
import Cleave from "vue-cleave-component";
import "animate.css";
import _ from "lodash";
import { mapState, mapGetters, mapActions } from "vuex";

export default {
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BTabs,
    BTab,
    BCardText,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormRating,
    BIcon,
    BPopover,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    ToastificationContent,
    flatPickr,
    Cleave,
  },

  directives: {
    Ripple,
    "b-modal": VBModal,
  },

  setup() {
    const FXSPOTFORWARDSWAP_APP_STORE_MODULE_NAME = "fxSFS";
    // Register module
    if (!store.hasModule(FXSPOTFORWARDSWAP_APP_STORE_MODULE_NAME))
      store.registerModule(
        FXSPOTFORWARDSWAP_APP_STORE_MODULE_NAME,
        fXSpotForwardSwapStoreModule
      );

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(FXSPOTFORWARDSWAP_APP_STORE_MODULE_NAME))
        store.unregisterModule(FXSPOTFORWARDSWAP_APP_STORE_MODULE_NAME);
    });
  },

  mounted() {},

  props: {
    searchedCounterparties: {
      type: Array,
      default: function() {
        return [];
      },
    },
    searchedClients: {
      type: Array,
      default: function() {
        return [];
      },
    },
    optCostCenters: {
      type: Array,
      default: function() {
        return [];
      },
    },
    optClients: {
      type: Array,
      default: function() {
        return [];
      },
    },
    optCounterParties: {
      type: Array,
      default: function() {
        return [];
      },
    },
  },

  data() {
    return {
      searchForTradesModal: false,
      tradeModalLoading: false,
      loadingSecurityDesc: false,
      loadingClient: false,
      loadingCounterparty: false,
      timerSecuritydesc: null,
      orderDurationDataDisabled: true,
      orderDurationTimeDisabled: true,
      orderDurationInputsVisible: true,
      loadingSavedSearch: false,
      weekday: [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ],
      optEntryTypes: ["Order", "Execution"],
      optbuySells: ["Buy", "Sell"],
      optOrderTypes: ["Limit", "Market", "Stop Limit", "Stop", "Trailing Stop"],
      optTimeInForces: ["DAY", "GTC", "GTD", "FOK", "FAK"],
      optproductTypes: ["SPOT", "FORWARD", "SWAP"],
      optSettlementTypes: ["PHYSICAL", "CASH"],
      optEnableOCOs: [
        "All Oco Orders",
        "Enable Connected OCO Orders",
        "Disable Connected OCO Orders",
      ],
      optCalcFromSpread: [
        {
          text: "Calculate Client Rate From Spread",
          value: "Calculate Client Rate From Spread",
        },
        {
          text: "Calculate Spread From Client Rate",
          value: "Calculate Spread From Client Rate",
        },
      ],
      optPriceTypes: [],
      optCutOffTimes: ["10:00 NY", "12:00 LN"],
      optcptyBuySells: [],
      optclientBuySells: [],
      optCounterPartyTraders: [],

      orderDateRange: null,
      tradeDateRange: null,
      settleDateRange: null,
      durationDateRange: null,
      cptyOrderDateRange: null,
      swapTradeDateRange: null,
      swapSettleDateRange: null,

      optScfUsers: [],
      optSearches: [],
      searchName: null,
      showSearchInputs: false,
      updownButtonText: "down",
      optActingCapacities: ["PRINCIPAL", "AGENT", "MATCHED PRINCIPAL"],
      optOrderTakenVias: [],
      optOrderGivenThroughs: [],
      tradeForm: {
        costCenter: null,
        orderDurationDate: null,
        orderDurationDateEnd: null,
        orderDurationTime: null,
        orderDurationTimeEnd: null,

        entryType: null,
        buySell: null,
        orderType: null,
        timeInForce: null,
        orderDate: null,
        orderDateEnd: null,
        orderTime: null,
        orderTimeEnd: null,

        cptyOrderDate: null,
        cptyOrderDateEnd: null,
        cptyOrderTime: null,
        cptyOrderTimeEnd: null,

        enableConnectedOCOOrders: "All Oco Orders",
        stopPrice: null,
        trailingAmount: null,
        takeProfitLimit: null,
        stopLossStop: null,
        stopLossLimit: null,
        client: null,
        clientAccount: null,
        counterparty: null,
        cptyAccount: null,
        productType: null,
        settlementType: null,
        counterpartyRate: null,
        cptyCalculateFromRate1: true,
        clientRate: null,
        clientCalculateFromRate2: true,
        cptyAmount1: null,
        cptyAmount2: null,
        cptyCcy1: null,
        cptyCcy2: null,
        cptyBuySell1: null, //sfcBuySell1
        cptyBuySell2: null, //sfcBuySell2
        calcFromSpread: "Calculate Client Rate From Spread",
        spread: null,
        clientAmount1: null,
        clientAmount2: null,
        clientCcy1: null,
        clientCcy2: null,
        clientBuySell1: null,
        clientBuySell2: null,
        spotRef: null,
        spreadAmount: null,
        spreadCcy: null,
        comissionAmount: null,
        comissionCcy: null,
        cutOffTime: null,
        deliverableCcy: null,
        tradeDate: null,
        tradeDateEnd: null,
        tradeDateName: null,
        settlementDate: null,
        settlementDateEnd: null,
        settlementDateName: null,
        executionTime: null,
        executionTimeEnd: null,

        counterpartyTrader: null,
        scfUser: null,
        orderTakenVia: null,
        orderGivenThrough: null,
        actingCapaticy: null,
        note: null,
        swapTradeDate: null,
        swapTradeDateEnd: null,
        swapTradeDateName: null,
        maturity: null,
        swapSettleDate: null,
        swapSettleDateEnd: null,
        swapSettleDateName: null,
        swapPoints: null,
        swapCptyRate: null,
        swapCptyRateEnd: null,
        swapCptyAmount1: null,
        swapCptyCcy1: null,
        swapCptyBuySell1: null,
        swapCptyAmount2: null,
        swapCptyCcyType2: null,
        swapCptyBuySell2: null,
        swapSpread: null,
        swapCalcFromSpread: "Calculate Client Rate From Spread",
        swapClientRate: null,
        swapClientAmount1: null,
        swapClientCcy1: null,
        swapClientBuySell1: null,
        swapClientAmount2: null,
        swapClientCcy2: null,
        swapClientBuySell2: null,
        swapSpreadAmount: null,
        swapSpreadCcy: null,
        uti: null,
        uniqueLinkID: null,
        tradeRef: null,
        entryType2: null,
        tradeStart: null,
        tradeEnd: null,
        settleStart: null,
        settleEnd: null,

        cptyCalculateFromRate1End: true,
        clientCalculateFromRate2End: true,
        stopPriceEnd: null,
        trailingAmountEnd: null,
        takeProfitLimitEnd: null,
        stopLossStopEnd: null,
        stopLossLimitEnd: null,
        clientRateEnd: null,
        swapClientRateEnd: null,
        counterpartyRateEnd: null,
        cptyAmount1End: null,
        cptyAmount2End: null,
        clientAmount1End: null,
        clientAmount2End: null,
        spreadAmountEnd: null,
        comissionAmountEnd: null,
        swapPointsEnd: null,
        swapCptyAmount1End: null,
        swapCptyAmount2End: null,
        swapSpreadEnd: null,
        swapClientAmount1End: null,
        swapClientAmount2End: null,
        swapSpreadAmountEnd: null,
        maturityEnd: null,
        spreadEnd: null,
      },
      timeConfig: {
        wrap: true,
        static: true,
        enableTime: true,
        noCalendar: true,
        dateFormat: "H:i",
        time_24hr: true,
      },
      dateConfig: {
        wrap: true,
        static: true,
        allowInput: true,
        dateFormat: "d-m-Y",
        mode: "range",
      },
      orderDateConfig: {
        wrap: true,
        static: true,
        allowInput: true,
        dateFormat: "d-m-Y",
        mode: "range",
        // onChange: function(selectedDates, dateStr, instance) {
        //   console.log(instance)
        // }
      },
      cleaveOptions: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: "thousand",
        },
        time: {
          time: true,
          timePattern: ["h", "m", "s"],
        },
      },
      cleave4DecimalOptions: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: "thousand",
          numeralDecimalScale: 4,
        },
      },
      cleave8DecimalOptions: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: "thousand",
          numeralDecimalScale: 8,
        },
      },
    };
  },
  computed: {
    user: {
      get() {
        return this.$store.getters["authUser"];
      },
      set(newValue) {
        return this.$store.dispatch("setUser", newValue);
      },
    },

    savedSearchesList() {
      return this.optSearches;
    },
  },
  methods: {
    ...mapActions(["saveLastSearchFxForwardSwap"]),

    deleteSavedSearch(id) {
      store
        .dispatch("fxSFS/deleteSavedSearch", {
          auth: this.user,
          id: id,
        })
        .then((res) => {
          this.searchName = null;
          this.getSavedSearches();

          this.successMessage(res.data.message);
        })
        .catch((error) => {
          this.errorMessage(error);

          console.log(error);
        });
    },

    saveSearch() {
      this.loadingSavedSearch = true;
      this.tradeForm.orderDateRange = this.orderDateRange;
      this.tradeForm.tradeDateRange = this.tradeDateRange;
      this.tradeForm.settleDateRange = this.settleDateRange;
      this.tradeForm.durationDateRange = this.durationDateRange;
      this.tradeForm.swapTradeDateRange = this.swapTradeDateRange;
      this.tradeForm.swapSettleDateRange = this.swapSettleDateRange;

      store
        .dispatch("fxSFS/saveSearch", {
          auth: this.user,
          searchData: this.tradeForm,
          searchName: this.searchName,
          searchType: "FX",
          LastSearch: 0,
        })
        .then((res) => {
          this.searchName = null;
          this.getSavedSearches();

          this.successMessage(res.data.message);
          this.loadingSavedSearch = false;
        })
        .catch((error) => {
          this.errorMessage(error);
          this.loadingSavedSearch = false;

          console.log(error);
        });
    },

    selectSavedSearch(savedTrade) {
      if (savedTrade) {
        this.orderDateRange = savedTrade.orderDateRange;
        this.tradeDateRange = savedTrade.tradeDateRange;
        this.settleDateRange = savedTrade.settleDateRange;
        this.durationDateRange = savedTrade.durationDateRange;
        this.cptyOrderDateRange = savedTrade.cptyOrderDateRange;
        this.swapTradeDateRange = savedTrade.swapTradeDateRange;
        this.swapSettleDateRange = savedTrade.swapSettleDateRange;
        this.tradeForm = savedTrade;
      }
    },

    getSavedSearches() {
      store
        .dispatch("fxSFS/getSavedSearches", { SearchType: "FX" })
        .then((res) => {
          if (res.data.savedSearches.length > 0) {
            this.optSearches = _.remove(res.data.savedSearches, {
              LastSearch: false,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getArangeFxExecutedTCUsers() {
      store
        .dispatch("fxSFS/fetchArangeFxExecutedTCUsers", {
          auth: this.user,
          teamName: this.user.role,
        })
        .then((res) => {
          if (res.data.info == "Pass") {
            this.optScfUsers = res.data.users;
          } else if (res.data.info == "no_users") {
            this.errorMessage(res.data.message);
          } else {
            this.errorMessage(res.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getArrangeFxExecutedOrderTakenGivenThrough() {
      store
        .dispatch(
          "fxSFS/fetchArrangeFxExecutedOrderTakenGivenThrough",
          this.user
        )
        .then((res) => {
          if (
            res.data.orderGivenThroughs.length > 0 ||
            res.data.orderTakenVias.length > 0
          ) {
            this.optOrderGivenThroughs = res.data.orderGivenThroughs;
            this.optOrderTakenVias = res.data.orderTakenVias;
          }
          // else {
          //   this.errorMessage(
          //     this.$t("fx_spot_forward_swap.messages.creating_message")
          //   );
          // }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getArrangeFxExecutedCurrencies() {
      store
        .dispatch("fxSFS/fetchArrangeFxExecutedCurrencies", this.user)
        .then((res) => {
          if (res.data.info == "Pass") {
            this.optPriceTypes = res.data.currencies;
          } else {
            this.errorMessage(res.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    searchForTrades(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.$checkAccessRight("FxExecuted", "Search").then((res) => {
        if (res.data.valid) {
          this.tradeModalLoading = true;
          //set and check search data
          let searchData = this.setSearchData();

          store
            .dispatch("fxSFS/searchFxExecuted", {
              auth: this.user,
              searchData: searchData,
            })
            .then((res) => {
              this.$emit("searchedData", res.data);
              //this.resetSearchForTradesModal()
              this.saveLastSearchFxForwardSwap(searchData);
              this.tradeModalLoading = false;
            })
            .catch((error) => {
              this.tradeModalLoading = false;
              console.log(error);
            });
        } else {
          this.searchForTradesModal = false;
          this.notAuthToastMessage();
        }
      });
    },

    // getAllAccounts() {
    //   store
    //     .dispatch("fxSFS/getAllAccounts")
    //     .then((res) => {
    //       this.optClients = [];
    //       this.optCounterParties = [];
    //       this.searchedClients = res.data;
    //       this.searchedCounterparties = res.data;

    //       if (res.data.length > 0) {
    //         res.data.forEach((data) => {
    //           this.optClients.push(data.AccountName);
    //         });

    //         res.data.forEach((data) => {
    //           this.optCounterParties.push(data.AccountName);
    //         });
    //       }
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
    // },

    searchClient(search) {
      if (search.length > 2) {
        if (this.timerSecuritydesc) {
          clearTimeout(this.timerSecuritydesc);
          this.timerSecuritydesc = null;
        }

        this.timerSecuritydesc = setTimeout(() => {
          this.loadingClient = true;

          return new Promise((resolve, reject) => {
            axiosIns
              .post(`searchClient`, { auth: this.user, search: search })
              .then((res) => {
                if (res.data.info == "Pass") {
                  this.optClients = [];
                  this.searchedClients = res.data.resData;
                  res.data.resData.forEach((data) => {
                    this.optClients.push(data.AccountName);
                  });

                  this.loadingClient = false;
                } else {
                  this.notFoundMessage("Client");
                  this.loadingClient = false;
                }
              })
              .catch((error) => reject(error));
          });
        }, 800);
      }
    },
    selectedClient(search) {
      this.tradeForm.clientAccount = null;

      let selecting = this.searchedClients.find(
        (s) => s.AccountName === search
      );
      this.tradeForm.clientAccount = selecting.AccountNumber;
    },

    changedBuySell(type) {
      this.arrangeBuySell();
      this.arrangeRatesAndAmounts();
    },
    arrangeBuySell() {
      if (this.tradeForm.buySell == "Buy") {
        this.tradeForm.clientBuySell1 = "Buys";
        this.tradeForm.clientBuySell2 = "Sells";
        this.tradeForm.swapClientBuySell1 = "Buys";
        this.tradeForm.swapClientBuySell2 = "Sells";

        this.tradeForm.swapCptyBuySell1 = "Sells";
        this.tradeForm.swapCptyBuySell2 = "Buys";
        this.tradeForm.cptyBuySell1 = "Buys"; //sfcBuySell1
        this.tradeForm.cptyBuySell2 = "Sells"; //sfcBuySell2
      } else if (this.tradeForm.buySell == "Sell") {
        this.tradeForm.clientBuySell1 = "Sells";
        this.tradeForm.clientBuySell2 = "Buys";
        this.tradeForm.swapClientBuySell1 = "Sells";
        this.tradeForm.swapClientBuySell2 = "Buys";
        this.tradeForm.cptyBuySell1 = "Sells"; //sfcBuySell1
        this.tradeForm.cptyBuySell2 = "Buys"; //sfcBuySell2
        this.tradeForm.swapCptyBuySell1 = "Buys";
        this.tradeForm.swapCptyBuySell2 = "Sells";
      }
    },

    checkAccount() {
      if (this.tradeForm.client == null) {
        this.tradeForm.clientAccount = null;
      }
    },
    checkCptyAccount() {
      if (this.tradeForm.counterparty == null) {
        this.tradeForm.cptyAccount = null;
      }
    },

    selectedCounterparty(search) {
      this.tradeForm.counterparty = null;
      this.tradeForm.cptyAccount = null;
      this.tradeForm.counterpartyTrader = null;

      let selecting = this.searchedClients.find(
        (s) => s.AccountName === search
      );
      this.tradeForm.cptyAccount = selecting.AccountNumber;

      return new Promise((resolve, reject) => {
        axiosIns
          .post(`selectedCounterpartyForTrader`, {
            auth: this.user,
            search: search,
          })
          .then((res) => {
            if (res.data.info == "Pass") {
              this.tradeForm.counterpartyTrader = res.data.resTrader[0].Trader;
              let listTraders = [];
              res.data.resTrader.forEach((element) => {
                if (!listTraders.includes(element.Trader)) {
                  listTraders.push(element.Trader);
                }
              });
              this.optCounterPartyTraders = listTraders;
            }
          })
          .catch((error) => reject(error));
      });
    },
    selectedProductType(type) {
      if (type == "SPOT" && this.tradeForm.counterpartyRate != null) {
        this.tradeForm.spotRef = this.tradeForm.counterpartyRate;
        this.arrangeRatesAndAmounts();
      }
    },
    selectedSettlementType(type) {
      this.arrangeCurrencies();
      if (type == "PHYSICAL") {
        this.tradeForm.cutOffTime = null;
      }
    },
    changedCounterpartyRate(type) {
      if (
        this.tradeForm.productType == "SPOT" &&
        this.tradeForm.counterpartyRate != null
      ) {
        this.tradeForm.spotRef = this.tradeForm.counterpartyRate;
      }

      if (this.tradeForm.productType == "SWAP") {
        this.tradeForm.enableConnectedOCOOrders = "All Oco Orders";
      }
    },
    changedCptyCalculateFromRate1() {
      this.arrangeRatesAndAmounts();
      this.arrangeInputFormats();
    },

    selectedCptyCcy1() {
      this.tradeForm.clientCcy1 = this.tradeForm.cptyCcy1;
      this.tradeForm.deliverableCcy = this.tradeForm.cptyCcy1;
    },
    selectedCptyCcy2() {
      this.tradeForm.clientCcy2 = this.tradeForm.cptyCcy2;
      this.tradeForm.deliverableCcy = this.tradeForm.cptyCcy2;
      this.tradeForm.spreadCcy = this.tradeForm.cptyCcy2;
    },
    arrangeCurrencies() {
      this.tradeForm.clientCcy1 = this.tradeForm.cptyCcy1;
      this.tradeForm.clientCcy2 = this.tradeForm.cptyCcy2;
      this.tradeForm.swapCptyCcy1 = this.tradeForm.cptyCcy1;
      this.tradeForm.swapCptyCcy2 = this.tradeForm.cptyCcy2;
      this.tradeForm.swapClientCcy1 = this.tradeForm.cptyCcy1;
      this.tradeForm.swapClientCcy2 = this.tradeForm.cptyCcy2;
    },
    changedCalcFromSpread(type) {
      if (type == "Calculate Client Rate From Spread") {
        this.tradeForm.clientCalculateFromRate2 = true;
      }
      this.arrangeRatesAndAmounts();
      this.arrangeInputFormats();
    },
    changedSpread(spread) {
      this.arrangeRatesAndAmounts();
      this.arrangeInputFormats();
    },
    changedClientRate(type) {
      this.arrangeRatesAndAmounts();
      this.arrangeInputFormats();
    },
    changedClientCalculateFromRate2() {
      this.arrangeRatesAndAmounts();
      this.arrangeInputFormats();
    },
    calculateAsCptyAmount1(amount) {
      if (this.tradeForm.cptyAmount1 == this.tradeForm.clientAmount1) {
        this.tradeForm.spreadCcy = this.tradeForm.cptyCcy2;
      } else if (this.tradeForm.cptyAmount2 == this.tradeForm.clientAmount2) {
        this.tradeForm.spreadCcy = this.tradeForm.cptyCcy1;
      } else {
        this.tradeForm.clientAmount1 = this.tradeForm.cptyAmount1;
        this.tradeForm.spreadCcy = this.tradeForm.cptyCcy2;
      }

      if (this.tradeForm.cptyCalculateFromRate1) {
        if (
          this.tradeForm.counterpartyRate != null &&
          this.tradeForm.cptyAmount1 != null
        ) {
          if (this.tradeForm.cptyAmount2 > 0) {
            if (
              this.tradeForm.cptyAmount1 * this.tradeForm.counterpartyRate !=
              this.tradeForm.cptyAmount2
            ) {
              if (
                this.tradeForm.cptyAmount1 == this.tradeForm.clientAmount1 &&
                this.tradeForm.cptyAmount2 != null
              ) {
                this.tradeForm.cptyAmount2 = this.roundToTwo(
                  this.tradeForm.cptyAmount1 * this.tradeForm.counterpartyRate
                ).toFixed(2);
              } else if (
                this.tradeForm.clientAmount2 == this.tradeForm.cptyAmount2
              ) {
                this.tradeForm.cptyAmount1 = this.roundToTwo(
                  this.tradeForm.cptyAmount2 / this.tradeForm.counterpartyRate
                ).toFixed(2);
              } else {
                this.tradeForm.cptyAmount2 = this.roundToTwo(
                  this.tradeForm.cptyAmount1 * this.tradeForm.counterpartyRate
                ).toFixed(2);
              }
            }
          } else {
            this.tradeForm.cptyAmount2 = this.roundToTwo(
              this.tradeForm.cptyAmount1 * this.tradeForm.counterpartyRate
            );
          }
        }
      } else {
        if (
          this.tradeForm.cptyAmount1 != null &&
          this.tradeForm.cptyAmount2 != null
        ) {
          if (this.tradeForm.counterpartyRate > 0) {
            if (
              this.tradeForm.cptyAmount2 / this.tradeForm.cptyAmount1 !=
              this.tradeForm.counterpartyRate
            ) {
              this.tradeForm.counterpartyRate =
                this.tradeForm.cptyAmount2 / this.tradeForm.cptyAmount1;
            } else {
              this.tradeForm.counterpartyRate =
                this.tradeForm.cptyAmount2 / this.tradeForm.cptyAmount1;
            }
          }
        }
      }
      this.arrangeRatesAndAmounts();
      this.tradeForm.clientAmount1 = this.tradeForm.cptyAmount1;
      this.arrangeInputFormats();
    },
    calculateAsCptyAmount2(amount) {
      if (this.tradeForm.cptyAmount1 == this.tradeForm.clientAmount1) {
        this.tradeForm.spreadCcy = this.tradeForm.cptyCcy2;
      } else if (this.tradeForm.cptyAmount2 == this.tradeForm.clientAmount2) {
        this.tradeForm.spreadCcy = this.tradeForm.cptyCcy1;
      } else {
        this.tradeForm.clientAmount2 = this.tradeForm.cptyAmount2;
        this.tradeForm.spreadCcy = this.tradeForm.cptyCcy2;
      }

      if (this.tradeForm.cptyCalculateFromRate1) {
        if (
          this.tradeForm.counterpartyRate != null &&
          this.tradeForm.cptyAmount2 != null
        ) {
          if (
            this.tradeForm.cptyAmount1 > 0 &&
            this.tradeForm.cptyAmount2 > 0
          ) {
            if (
              this.tradeForm.cptyAmount2 / this.tradeForm.counterpartyRate !=
              this.tradeForm.cptyAmount1
            ) {
              this.tradeForm.cptyAmount1 = this.roundToTwo(
                this.tradeForm.cptyAmount2 / this.tradeForm.counterpartyRate
              );
            }
          } else {
            if (this.tradeForm.cptyAmount2 == this.tradeForm.clientAmount2) {
              this.tradeForm.cptyAmount1 = this.roundToTwo(
                this.tradeForm.cptyAmount2 / this.tradeForm.counterpartyRate
              );
            } else if (
              this.tradeForm.cptyAmount1 == this.tradeForm.clientAmount1 &&
              this.tradeForm.cptyAmount1 != null
            ) {
              this.tradeForm.cptyAmount2 = this.roundToTwo(
                this.tradeForm.cptyAmount1 * this.tradeForm.counterpartyRate
              );
            } else {
              this.tradeForm.cptyAmount1 = this.roundToTwo(
                this.tradeForm.cptyAmount2 / this.tradeForm.counterpartyRate
              );
            }
          }
        }
      } else {
        if (
          this.tradeForm.cptyAmount1 != null &&
          this.tradeForm.cptyAmount2 != null
        ) {
          if (this.tradeForm.counterpartyRate > 0) {
            if (
              this.tradeForm.cptyAmount2 / this.tradeForm.cptyAmount1 !=
              this.tradeForm.counterpartyRate
            ) {
              this.tradeForm.counterpartyRate =
                this.tradeForm.cptyAmount2 / this.tradeForm.cptyAmount1;
            } else {
              this.tradeForm.counterpartyRate =
                this.tradeForm.cptyAmount2 / this.tradeForm.cptyAmount1;
            }
          }
        }
      }
      this.arrangeRatesAndAmounts();
      this.arrangeInputFormats();
    },
    calculateAsClientAmount1() {
      if (this.tradeForm.cptyAmount1 == this.tradeForm.clientAmount1) {
        this.tradeForm.spreadCcy = this.tradeForm.cptyCcy2;
      } else if (this.tradeForm.cptyAmount2 == this.tradeForm.clientAmount2) {
        this.tradeForm.spreadCcy = this.tradeForm.cptyCcy1;
      } else {
        this.tradeForm.cptyAmount1 = this.tradeForm.clientAmount1;
        this.tradeForm.spreadCcy = this.tradeForm.cptyCcy2;
      }

      if (this.tradeForm.clientAmount1 > 0) {
        if (
          this.tradeForm.cptyAmount1 != null &&
          this.tradeForm.cptyAmount2 != null
        ) {
          this.tradeForm.cptyAmount1 = this.tradeForm.clientAmount1;
        }
      }

      if (this.tradeForm.clientCalculateFromRate2) {
        if (
          this.tradeForm.clientRate != null &&
          this.tradeForm.clientAmount1 != null
        ) {
          if (this.tradeForm.clientAmount2 > 0) {
            if (
              this.roundToTwo(
                this.tradeForm.clientAmount1 * this.tradeForm.clientRate
              ).toFixed(2) != this.tradeForm.clientAmount2 &&
              this.roundToTwo(
                (
                  this.tradeForm.clientAmount1 * this.tradeForm.clientRate
                ).toFixed(2)
              ) -
                this.tradeForm.clientAmount2 >
                0.01
            ) {
              if (this.tradeForm.clientAmount1 == this.tradeForm.cptyAmount1) {
                this.tradeForm.clientAmount2 = this.roundToTwo(
                  this.tradeForm.clientAmount1 * this.tradeForm.clientRate
                ).toFixed(2);
              } else if (
                this.tradeForm.clientAmount2 == this.tradeForm.cptyAmount2
              ) {
                this.tradeForm.clientAmount1 = this.roundToTwo(
                  this.tradeForm.clientAmount2 / this.tradeForm.clientRate
                ).toFixed(2);
              } else {
                this.tradeForm.clientAmount2 = this.tradeForm
                  .roundToTwo(
                    this.tradeForm.clientAmount1 * this.tradeForm.clientRate
                  )
                  .toFixed(2);
              }
            }
          } else {
            this.tradeForm.clientAmount2 = this.roundToTwo(
              this.tradeForm.clientAmount1 * this.tradeForm.clientRate
            ).toFixed(2);
          }
        }
      } else {
        if (
          this.tradeForm.clientAmount1 != null &&
          this.tradeForm.clientAmount2 != null
        ) {
          if (this.tradeForm.clientRate > 0) {
            if (
              this.roundToTwo(
                this.tradeForm.clientAmount2 / this.tradeForm.clientAmount1
              ).toFixed(8) != this.tradeForm.clientRate &&
              this.roundToTwo(
                (
                  this.tradeForm.clientAmount2 / this.tradeForm.clientAmount1
                ).toFixed(8)
              ) -
                this.tradeForm.clientRate >
                0.01
            ) {
              this.tradeForm.clientRate = this.roundToTwo(
                this.tradeForm.clientAmount2 / this.tradeForm.clientAmount1
              ).toFixed(8);
            }
          } else {
            this.tradeForm.clientRate = this.roundToTwo(
              this.tradeForm.clientAmount2 / this.tradeForm.clientAmount1
            ).toFixed(8);
          }
        }
      }

      this.arrangeRatesAndAmounts();

      if (this.tradedAmountsConsistent()) {
        this.wrongToastMessage(
          $t("fx_spot_forward_swap.messages.traded_amount_consistent"),
          8000
        );
        this.tradeForm.clientAmount1 = this.tradeForm.cptyAmount1;
        this.calculateAsCptyAmount1();
      }

      this.arrangeInputFormats();
    },
    calculateAsClientAmount2() {
      if (this.tradeForm.cptyAmount1 == this.tradeForm.clientAmount1) {
        this.tradeForm.spreadCcy = this.tradeForm.cptyCcy2;
      } else if (this.tradeForm.cptyAmount2 == this.tradeForm.clientAmount2) {
        this.tradeForm.spreadCcy = this.tradeForm.cptyCcy1;
      } else {
        this.tradeForm.cptyAmount1 = this.tradeForm.clientAmount1;
        this.tradeForm.spreadCcy = this.tradeForm.cptyCcy1;
      }

      if (this.tradeForm.clientCalculateFromRate2) {
        if (
          this.tradeForm.clientRate != null &&
          this.tradeForm.clientAmount2 != null
        ) {
          if (
            this.tradeForm.clientAmount1 > 0 &&
            this.tradeForm.clientAmount2 > 0
          ) {
            if (
              this.roundToTwo(
                this.tradeForm.clientAmount2 / this.tradeForm.clientRate
              ).toFixed(2) != this.tradeForm.clientAmount1 &&
              this.roundToTwo(
                (
                  this.tradeForm.clientAmount2 / this.tradeForm.clientRate
                ).toFixed(2)
              ) -
                this.tradeForm.clientAmount1 >
                0.01
            ) {
              if (this.tradeForm.clientAmount2 == this.tradeForm.cptyAmount2) {
                this.tradeForm.clientAmount1 = this.roundToTwo(
                  this.tradeForm.clientAmount2 / this.tradeForm.clientRate
                ).toFixed(2);
              } else if (
                this.tradeForm.clientAmount1 == this.tradeForm.cptyAmount1
              ) {
                this.tradeForm.clientAmount1 = this.roundToTwo(
                  this.tradeForm.clientAmount2 / this.tradeForm.clientRate
                ).toFixed(2);
              } else {
                this.tradeForm.clientAmount1 = this.tradeForm
                  .roundToTwo(
                    this.tradeForm.clientAmount2 / this.tradeForm.clientRate
                  )
                  .toFixed(2);
              }
            }
          } else {
            this.tradeForm.clientAmount1 = this.roundToTwo(
              this.tradeForm.clientAmount2 * this.tradeForm.clientRate
            ).toFixed(2);
          }
        }
      } else {
        if (
          this.tradeForm.clientAmount1 != null &&
          this.tradeForm.clientAmount2 != null
        ) {
          if (this.tradeForm.clientRate > 0) {
            if (
              this.roundToTwo(
                this.tradeForm.clientAmount2 / this.tradeForm.clientAmount1
              ).toFixed(8) != this.tradeForm.clientRate &&
              this.roundToTwo(
                (
                  this.tradeForm.clientAmount2 / this.tradeForm.clientAmount1
                ).toFixed(8)
              ) -
                this.tradeForm.clientRate >
                0.01
            ) {
              this.tradeForm.clientRate = this.roundToTwo(
                this.tradeForm.clientAmount2 / this.tradeForm.clientAmount1
              ).toFixed(8);
            }
          } else {
            this.tradeForm.clientRate = this.roundToTwo(
              this.tradeForm.clientAmount2 / this.tradeForm.clientAmount1
            ).toFixed(8);
          }
        }
      }

      this.arrangeRatesAndAmounts();

      if (this.tradedAmountsConsistent()) {
        this.wrongToastMessage(
          $t("fx_spot_forward_swap.messages.traded_amount_consistent"),
          8000
        );
        this.tradeForm.clientAmount1 = this.tradeForm.cptyAmount1;
        this.calculateAsCptyAmount1();
      }

      this.arrangeInputFormats();
    },
    selectedSpreadCcy() {
      this.arrangeRatesAndAmounts();
      this.arrangeInputFormats();
    },

    tradedAmountsConsistent() {
      if (
        this.tradeForm.clientAmount1 != null &&
        this.tradeForm.clientAmount2
      ) {
        if (
          this.tradeForm.cptyAmount1 != this.tradeForm.clientAmount1 &&
          this.tradeForm.cptyAmount2 != this.tradeForm.clientAmount2
        ) {
          return true;
        }
      }
    },

    changedSwapPoints() {
      this.arrangeRatesAndAmounts();
      this.arrangeInputFormats();
    },
    changedSwapSpread() {
      this.arrangeRatesAndAmounts();
      this.arrangeInputFormats();
    },
    setSwapTradetoSettleDays(day) {
      if (this.tradeForm.swapSettleDate != null) {
        let settleDate = new Date(
          this.MDYdateFormat(this.tradeForm.swapSettleDate)
        );

        //set settle date name
        this.tradeForm.swapSettleDateName = this.weekday[settleDate.getDay()];

        let period = this.tradeForm.maturity;

        settleDate.setDate(settleDate.getDate() + period);
        //set trade date name
        this.tradeForm.swapTradeDateName = this.weekday[settleDate.getDay()];

        this.tradeForm.swapTradeDate = settleDate;
      }
    },

    arrangeSwapTradetoSettleDays(type) {
      if (type == "Swap Trade Date") {
        if (
          this.tradeForm.swapTradeDate != null &&
          this.tradeForm.maturity != null
        ) {
        } else if (
          this.tradeForm.swapTradeDate != null &&
          this.tradeForm.swapSettleDate != null
        ) {
        }
      } else if (type == "Swap Settle Date") {
        if (this.tradeForm.maturity != null && this.tradeForm.maturity > 0) {
          let settleDate = new Date(
            this.MDYdateFormat(this.tradeForm.swapSettleDate)
          );

          //set settle date name
          this.tradeForm.swapSettleDateName = this.weekday[settleDate.getDay()];

          let period = this.tradeForm.maturity;

          settleDate.setDate(settleDate.getDate() + period);
          //set trade date name
          this.tradeForm.swapTradeDateName = this.weekday[settleDate.getDay()];

          this.tradeForm.swapTradeDate = settleDate;
        }
      }
    },

    arrangeRatesAndAmounts() {
      if (this.tradeForm.cptyAmount1 == this.tradeForm.clientAmount1) {
        this.tradeForm.spreadCcy = this.tradeForm.cptyCcy2;
      } else if (this.tradeForm.cptyAmount2 == this.tradeForm.clientAmount2) {
        this.tradeForm.spreadCcy = this.tradeForm.cptyCcy1;
      } else {
        this.tradeForm.clientAmount1 = this.tradeForm.cptyAmount1;
        this.tradeForm.spreadCcy = this.tradeForm.cptyCcy2;
      }

      if (this.tradeForm.cptyCalculateFromRate1) {
        if (this.tradeForm.counterpartyRate != null) {
          if (this.tradeForm.cptyAmount1 != null) {
            if (this.tradeForm.cptyAmount2 > 0) {
              if (
                this.roundToTwo(
                  this.tradeForm.cptyAmount1 * this.tradeForm.counterpartyRate
                ).toFixed(2) != this.tradeForm.cptyAmount2 &&
                Math.abs(
                  this.roundToTwo(
                    this.tradeForm.cptyAmount1 * this.tradeForm.counterpartyRate
                  ).toFixed(2) - this.tradeForm.cptyAmount2
                ) > 0.01
              ) {
                if (
                  this.tradeForm.cptyAmount1 == this.tradeForm.clientAmount1
                ) {
                  this.tradeForm.cptyAmount2 = this.roundToTwo(
                    this.tradeForm.cptyAmount1 * this.tradeForm.counterpartyRate
                  ).toFixed(2);
                } else if (
                  this.tradeForm.clientAmount2 == this.tradeForm.cptyAmount2
                ) {
                  this.tradeForm.cptyAmount1 = this.roundToTwo(
                    this.tradeForm.cptyAmount2 / this.tradeForm.counterpartyRate
                  ).toFixed(2);
                } else {
                  this.tradeForm.cptyAmount2 = this.roundToTwo(
                    this.tradeForm.cptyAmount1 * this.tradeForm.counterpartyRate
                  ).toFixed(2);
                }
              } else {
                this.tradeForm.cptyAmount2 = this.roundToTwo(
                  this.tradeForm.cptyAmount1 * this.tradeForm.counterpartyRate
                ).toFixed(2);
              }
            }
          } else if (this.tradeForm.cptyAmount2 > 0) {
            if (this.tradeForm.cptyAmount1 > 0) {
              if (
                this.roundToTwo(
                  this.tradeForm.cptyAmount2 / this.tradeForm.counterpartyRate
                ).toFixed(2) != this.tradeForm.cptyAmount1 &&
                Math.abs(
                  this.roundToTwo(
                    this.tradeForm.cptyAmount2 / this.tradeForm.counterpartyRate
                  ).toFixed(2) - this.tradeForm.cptyAmount1
                ) > 0.01
              ) {
                if (
                  this.tradeForm.cptyAmount2 == this.tradeForm.clientAmount2
                ) {
                  this.tradeForm.cptyAmount1 =
                    this.tradeForm.cptyAmount2 /
                    this.tradeForm.counterpartyRate;
                } else if (
                  this.tradeForm.cptyAmount1 == this.tradeForm.clientAmount1
                ) {
                  this.tradeForm.cptyAmount2 = this.roundToTwo(
                    this.tradeForm.cptyAmount1 * this.tradeForm.counterpartyRate
                  ).toFixed(2);
                } else {
                  this.tradeForm.cptyAmount1 =
                    this.tradeForm.cptyAmount2 /
                    this.tradeForm.counterpartyRate;
                }
              }
            } else {
              this.tradeForm.cptyAmount1 = this.roundToTwo(
                this.tradeForm.cptyAmount2 / this.tradeForm.counterpartyRate
              ).toFixed(2);
            }
          }
        }
      } else {
        if (
          this.tradeForm.cptyAmount1 != null &&
          this.tradeForm.cptyAmount2 != null
        ) {
          if (
            this.roundToTwo(
              this.tradeForm.cptyAmount1 / this.tradeForm.cptyAmount2
            ).toFixed(8) != this.tradeForm.counterpartyRate
          ) {
            this.tradeForm.counterpartyRate = this.roundToTwo(
              this.tradeForm.cptyAmount2 / this.tradeForm.cptyAmount1
            ).toFixed(8);
          }
        }
      }

      if (
        this.tradeForm.calcFromSpread == "Calculate Spread From Client Rate"
      ) {
        if (
          this.tradeForm.counterpartyRate != null &&
          this.tradeForm.clientRate != null
        ) {
          if (this.tradeForm.cptyBuySell1 == "Buys") {
            this.tradeForm.spread =
              this.tradeForm.clientRate -
              this.tradeForm.counterpartyRate / 10000;
          } else if (this.tradeForm.cptyBuySell1 == "Sells") {
            this.tradeForm.spread =
              this.tradeForm.counterpartyRate -
              this.tradeForm.clientRate / 10000;
          }
        }
      } else if (
        this.tradeForm.calcFromSpread == "Calculate Client Rate From Spread"
      ) {
        if (this.tradeForm.spread != null) {
          if (this.tradeForm.counterpartyRate != null) {
            if (this.tradeForm.cptyBuySell1 == "Buys") {
              this.tradeForm.clientRate =
                this.tradeForm.counterpartyRate + this.tradeForm.spread / 10000;
            } else if (this.tradeForm.cptyBuySell1 == "Sells") {
              this.tradeForm.clientRate =
                this.tradeForm.counterpartyRate - this.tradeForm.spread / 10000;
            }
          } else if (this.tradeForm.clientRate != null) {
            if (this.tradeForm.cptyBuySell1 == "Buys") {
              this.tradeForm.counterpartyRate =
                this.tradeForm.clientRate - this.tradeForm.spread / 10000;
            } else if (this.tradeForm.cptyBuySell1 == "Sells") {
              this.tradeForm.counterpartyRate =
                this.tradeForm.clientRate + this.tradeForm.spread / 10000;
            }
          }
        }
      }

      if (this.tradeForm.clientCalculateFromRate2) {
        if (this.tradeForm.clientRate != null) {
          if (this.tradeForm.clientAmount1 != null) {
            if (this.tradeForm.clientAmount2 > 0) {
              if (
                this.roundToTwo(
                  this.tradeForm.clientAmount1 * this.tradeForm.clientRate
                ).toFixed(2) != this.tradeForm.clientAmount2 &&
                Math.abs(
                  this.roundToTwo(
                    this.tradeForm.clientAmount1 * this.tradeForm.clientRate
                  ).toFixed(2) - this.tradeForm.clientAmount2
                ) > 0.01
              ) {
                if (
                  this.tradeForm.clientAmount1 == this.tradeForm.cptyAmount1
                ) {
                  this.clientAmount2 = this.roundToTwo(
                    this.tradeForm.clientAmount1 * this.tradeForm.clientRate
                  ).toFixed(2);
                } else if (
                  this.tradeForm.clientAmount2 == this.tradeForm.cptyAmount2
                ) {
                  this.tradeForm.clientAmount1 = this.roundToTwo(
                    this.tradeForm.clientAmount2 / this.tradeForm.clientRate
                  ).toFixed(2);
                } else {
                  this.tradeForm.clientAmount2 = this.roundToTwo(
                    this.tradeForm.clientAmount1 * this.tradeForm.clientRate
                  ).toFixed(2);
                }
              }
            } else {
              this.clientAmount2 = this.roundToTwo(
                this.tradeForm.clientAmount1 * this.tradeForm.clientRate
              ).toFixed(2);
            }
          } else if (this.tradeForm.clientAmount2 != null) {
            if (this.tradeForm.clientAmount1 > 0) {
              if (
                this.roundToTwo(
                  this.tradeForm.clientAmount2 / this.tradeForm.clientRate
                ).toFixed(2) != this.tradeForm.clientAmount1 &&
                Math.abs(
                  this.roundToTwo(
                    this.tradeForm.clientAmount2 / this.tradeForm.clientRate
                  ).toFixed(2) - this.tradeForm.clientAmount1
                ) > 0.01
              ) {
                if (
                  this.tradeForm.clientAmount2 == this.tradeForm.cptyAmount2
                ) {
                  this.clientAmount1 =
                    this.tradeForm.clientAmount2 / this.tradeForm.clientRate;
                } else if (
                  this.tradeForm.clientAmount2 == this.tradeForm.cptyAmount2
                ) {
                  this.tradeForm.clientAmount2 = this.roundToTwo(
                    this.tradeForm.clientAmount1 * this.tradeForm.clientRate
                  ).toFixed(2);
                } else {
                  this.tradeForm.clientAmount1 =
                    this.tradeForm.clientAmount2 / this.tradeForm.clientRate;
                }
              }
            } else {
              this.clientAmount1 = this.roundToTwo(
                this.tradeForm.clientAmount2 / this.tradeForm.clientRate
              ).toFixed(2);
            }
          }
        }
      } else {
        if (
          this.tradeForm.clientAmount1 != null &&
          this.tradeForm.clientAmount2 != null
        ) {
          if (
            this.roundToTwo(
              this.tradeForm.clientAmount1 / this.tradeForm.clientAmount2
            ).toFixed(8) != this.tradeForm.clientRate
          ) {
            this.tradeForm.clientRate = this.roundToTwo(
              this.tradeForm.clientAmount2 / this.tradeForm.clientAmount1
            ).toFixed(8);
          }
        }
      }

      if (
        this.tradeForm.calcFromSpread == "Calculate Client Rate From Spread"
      ) {
        if (
          this.tradeForm.counterpartyRate != null &&
          this.tradeForm.spread != null
        ) {
          if (this.tradeForm.cptyBuySell1 == "Buys") {
            this.tradeForm.counterpartyRate = this.roundToTwo(
              this.tradeForm.counterpartyRate + this.tradeForm.spread / 10000
            ).toFixed(8);
          } else if (this.tradeForm.cptyBuySell1 == "Sells") {
            this.tradeForm.spread = this.roundToTwo(
              this.tradeForm.counterpartyRate - this.tradeForm.spread / 10000
            ).toFixed(8);
          }
        }
      }

      if (
        this.tradeForm.calcFromSpread == "Calculate Spread From Client Rate"
      ) {
        if (
          this.tradeForm.counterpartyRate != null &&
          this.tradeForm.clientRate != null
        ) {
          if (this.tradeForm.cptyBuySell1 == "Buys") {
            this.tradeForm.spread = this.roundToTwo(
              this.tradeForm.clientRate -
                this.tradeForm.counterpartyRate / 10000
            ).toFixed(4);
          } else if (this.tradeForm.cptyBuySell1 == "Sells") {
            this.tradeForm.spread = this.roundToTwo(
              this.tradeForm.counterpartyRate -
                this.tradeForm.clientRate / 10000
            ).toFixed(8);
          }
        }
      }

      if (this.tradeForm.spreadCcy != null) {
        if (this.tradeForm.spreadCcy == this.tradeForm.cptyCcy1) {
          if (this.tradeForm.cptyAmount1 != null) {
            if (this.tradeForm.clientRate != null) {
              if (this.tradeForm.spread != null) {
                this.tradeForm.spreadAmount = this.roundToTwo(
                  (((this.tradeForm.cptyAmount1 * this.tradeForm.spread) /
                    this.tradeForm.clientRate /
                    10000) *
                    1) /
                    100
                ).toFixed(2);
              } else if (this.tradeForm.counterpartyRate != null) {
                if (this.tradeForm.cptyBuySell1 == "Buys") {
                  this.tradeForm.spreadAmount = this.roundToTwo(
                    (((this.tradeForm.cptyAmount1 *
                      (this.tradeForm.clientRate -
                        this.tradeForm.counterpartyRate)) /
                      this.tradeForm.clientRate) *
                      1) /
                      100
                  ).toFixed(2);
                } else if (this.tradeForm.cptyBuySell1 == "Sells") {
                  this.tradeForm.spreadAmount = this.roundToTwo(
                    (((this.tradeForm.cptyAmount1 *
                      (this.tradeForm.counterpartyRate -
                        this.tradeForm.clientRate)) /
                      this.tradeForm.clientRate) *
                      1) /
                      100
                  ).toFixed(2);
                }
              }
            }
          }
        } else if (this.tradeForm.spreadCcy == this.tradeForm.cptyCcy2) {
          if (this.tradeForm.cptyAmount1 != null) {
            if (this.tradeForm.spread != null) {
              this.tradeForm.spreadAmount = this.roundToTwo(
                this.tradeForm.cptyAmount1 * (this.tradeForm.spread / 10000)
              ).toFixed(2);
            }
          }
        }
      }

      if (this.tradeForm.productType == "SWAP") {
        this.tradeForm.swapSpreadCcy = this.tradeForm.spreadCcy;

        if (this.tradeForm.spreadCcy == this.tradeForm.cptyCcy2) {
          this.tradeForm.swapClientAmount1 = this.tradeForm.clientAmount1;
          this.tradeForm.swapCptyAmount1 = this.tradeForm.cptyAmount1;
        } else if (this.tradeForm.spreadCcy == this.tradeForm.cptyCcy1) {
          this.tradeForm.swapClientAmount2 = this.tradeForm.clientAmount2;
          this.tradeForm.swapCptyAmount2 = this.tradeForm.cptyAmount2;
        }

        // calculate swap cpty rate
        if (this.tradeForm.counterpartyRate > 0) {
          if (this.tradeForm.swapPoints > 0) {
            if (this.tradeForm.swapCptyRate > 0) {
              this.tradeForm.swapCptyRate = this.roundToTwo(
                this.tradeForm.counterpartyRate +
                  this.tradeForm.swapPoints / 10000
              ).toFixed(2);
              this.tradeForm.swapCptyRateEnd = this.roundToTwo(
                this.tradeForm.counterpartyRateEnd +
                  this.tradeForm.swapPointsEnd / 10000
              ).toFixed(2);
            } else {
              this.tradeForm.swapCptyRate = this.roundToTwo(
                this.tradeForm.counterpartyRate
              ).toFixed(4);
              this.tradeForm.swapCptyRateEnd = this.roundToTwo(
                this.tradeForm.counterpartyRateEnd
              ).toFixed(4);
            }
          }
        } else {
          this.tradeForm.swapCptyRate = null;
          this.tradeForm.swapCptyRateEnd = null;
        }

        // calculate swap client rate

        if (
          this.tradeForm.swapCalcFromSpread ==
          "Calculate Client Rate From Spread"
        ) {
          if (this.tradeForm.swapCptyRate > 0) {
            if (this.tradeForm.swapSpread > 0) {
              if (this.tradeForm.buySell == "Buy") {
                this.tradeForm.swapClientRate = this.roundToTwo(
                  this.tradeForm.swapCptyRate -
                    this.tradeForm.swapSpread / 10000
                ).toFixed(4);
              } else {
                this.tradeForm.swapClientRate = this.roundToTwo(
                  this.tradeForm.swapCptyRate +
                    this.tradeForm.swapSpread / 10000
                ).toFixed(4);
              }
            } else {
              if (this.tradeForm.buySell == "Buy") {
                this.tradeForm.swapClientRate = this.roundToTwo(
                  this.swapCptyRate
                ).toFixed(4);
              } else {
                this.tradeForm.swapClientRate = this.roundToTwo(
                  this.swapCptyRate
                ).toFixed(4);
              }
            }
          }
        } else if (
          this.tradeForm.swapCalcFromSpread ==
          "Calculate Spread From Client Rate"
        ) {
          if (this.tradeForm.swapCptyRate > 0) {
            if (this.tradeForm.swapClientRate > 0) {
              if (this.tradeForm.buySell == "Buy") {
                this.tradeForm.swapSpread = this.roundToTwo(
                  (-this.tradeForm.swapClientRate +
                    this.tradeForm.swapCptyRate) *
                    10000
                ).toFixed(2);
              } else {
                this.tradeForm.swapSpread = this.roundToTwo(
                  (this.tradeForm.swapClientRate +
                    this.tradeForm.swapCptyRate) *
                    10000
                ).toFixed(2);
              }
            } else {
              this.tradeForm.swapSpread = null;
            }
          } else {
            this.tradeForm.swapSpread = null;
          }
        }

        if (
          this.tradeForm.swapClientAmount1 != null ||
          this.tradeForm.swapClientAmount2 != null
        ) {
          if (this.tradeForm.swapSpreadCcy == this.tradeForm.cptyCcy1) {
            if (this.tradeForm.swapClientRate != null) {
              if (
                this.tradeForm.swapCptyAmount2 > 0 &&
                this.tradeForm.swapCptyRate > 0
              ) {
                this.tradeForm.swapCptyAmount1 = this.roundToTwo(
                  this.tradeForm.swapCptyAmount2 / this.tradeForm.swapCptyRate
                ).toFixed(2);
              }
              if (this.tradeForm.buySell == "Buy") {
                if (this.tradeForm.swapSpread > 0) {
                  this.tradeForm.swapSpreadAmount = this.roundToTwo(
                    this.tradeForm.swapClientAmount2 /
                      this.tradeForm.swapClientRate -
                      this.tradeForm.swapClientAmount2 /
                        this.tradeForm.swapCptyRate
                  ).toFixed(2);
                } else {
                  this.tradeForm.swapSpreadAmount = null;
                }
              } else {
                if (this.tradeForm.swapSpread > 0) {
                  this.tradeForm.swapSpreadAmount = this.roundToTwo(
                    -(
                      this.tradeForm.swapClientAmount2 /
                      this.tradeForm.swapClientRate
                    ) +
                      this.tradeForm.swapClientAmount2 /
                        this.tradeForm.swapCptyRate
                  ).toFixed(2);
                } else {
                  this.tradeForm.swapSpreadAmount = null;
                }
              }
            }
          } else if (this.tradeForm.swapSpreadCcy == this.tradeForm.cptyCcy2) {
            if (this.tradeForm.swapClientRate != null) {
              if (
                this.tradeForm.swapCptyAmount1 > 0 &&
                this.tradeForm.swapCptyRate > 0
              ) {
                this.tradeForm.swapCptyAmount2 = this.roundToTwo(
                  this.tradeForm.swapCptyAmount1 / this.tradeForm.swapCptyRate
                ).toFixed(2);
              }
              if (this.tradeForm.buySell == "Buy") {
                if (this.tradeForm.swapSpread > 0) {
                  this.tradeForm.swapSpreadAmount = this.roundToTwo(
                    -(
                      this.tradeForm.swapClientAmount1 *
                      this.tradeForm.swapClientRate
                    ) +
                      this.tradeForm.swapClientAmount1 *
                        this.tradeForm.swapCptyRate
                  ).toFixed(2);
                } else {
                  this.tradeForm.swapSpreadAmount = null;
                }
              } else {
                if (this.tradeForm.swapSpread > 0) {
                  this.tradeForm.swapSpreadAmount = this.roundToTwo(
                    -(
                      this.tradeForm.swapClientAmount1 *
                      this.tradeForm.swapClientRate
                    ) -
                      this.tradeForm.swapClientAmount1 *
                        this.tradeForm.swapCptyRate
                  ).toFixed(2);
                } else {
                  this.tradeForm.swapSpreadAmount = null;
                }
              }
            }
          }
        }
      }
    },

    arrangeInputFormats() {
      if (this.tradeForm.counterpartyRate != null) {
        this.tradeForm.counterpartyRate = this.formatPrice(
          this.tradeForm.counterpartyRate,
          8
        );
      }
      if (this.tradeForm.clientRate != null) {
        this.tradeForm.clientRate = this.formatPrice(
          this.tradeForm.clientRate,
          8
        );
      }
      if (this.tradeForm.cptyAmount1 != null) {
        this.tradeForm.cptyAmount1 = this.formatPrice(
          this.tradeForm.cptyAmount1,
          2
        );
      }
      if (this.tradeForm.cptyAmount2 != null) {
        this.tradeForm.cptyAmount2 = this.formatPrice(
          this.tradeForm.cptyAmount2,
          2
        );
      }
      if (this.tradeForm.spread != null) {
        this.tradeForm.spread = this.formatPrice(this.tradeForm.spread, 2);
      }
      if (this.tradeForm.spreadAmount != null) {
        this.tradeForm.spreadAmount = this.formatPrice(
          this.tradeForm.spreadAmount,
          2
        );
      }
      if (this.tradeForm.comissionAmount != null) {
        this.tradeForm.comissionAmount = this.formatPrice(
          this.tradeForm.comissionAmount,
          2
        );
      }
      if (this.tradeForm.clientAmount1 != null) {
        this.tradeForm.clientAmount1 = this.formatPrice(
          this.tradeForm.clientAmount1,
          2
        );
      }
      if (this.tradeForm.clientAmount2 != null) {
        this.tradeForm.clientAmount2 = this.formatPrice(
          this.tradeForm.clientAmount2,
          2
        );
      }
      if (this.tradeForm.spotRef != null) {
        this.tradeForm.spotRef = this.formatPrice(this.tradeForm.spotRef, 4);
      }
      if (this.tradeForm.stopPrice != null) {
        this.tradeForm.stopPrice = this.formatPrice(
          this.tradeForm.stopPrice,
          2
        );
      }
      if (this.tradeForm.trailingAmount != null) {
        this.tradeForm.trailingAmount = this.formatPrice(
          this.tradeForm.trailingAmount,
          2
        );
      }
      if (this.tradeForm.takeProfitLimit != null) {
        this.tradeForm.takeProfitLimit = this.formatPrice(
          this.tradeForm.takeProfitLimit,
          2
        );
      }
      if (this.tradeForm.stopLossLimit != null) {
        this.tradeForm.stopLossLimit = this.formatPrice(
          this.tradeForm.stopLossLimit,
          2
        );
      }
      if (this.tradeForm.stopLossStop != null) {
        this.tradeForm.stopLossStop = this.formatPrice(
          this.tradeForm.stopLossStop,
          2
        );
      }
      if (this.tradeForm.swapClientAmount1 != null) {
        this.tradeForm.swapClientAmount1 = this.formatPrice(
          this.tradeForm.swapClientAmount1,
          2
        );
      }
      if (this.tradeForm.swapClientAmount2 != null) {
        this.tradeForm.swapClientAmount2 = this.formatPrice(
          this.tradeForm.swapClientAmount2,
          2
        );
      }
      if (this.tradeForm.swapSpreadAmount != null) {
        this.tradeForm.swapSpreadAmount = this.formatPrice(
          this.tradeForm.swapSpreadAmount,
          2
        );
      }
      if (this.tradeForm.swapSpread != null) {
        this.tradeForm.swapSpread = this.formatPrice(
          this.tradeForm.swapSpread,
          2
        );
      }
      if (this.tradeForm.swapClientRate != null) {
        this.tradeForm.swapClientRate = this.formatPrice(
          this.tradeForm.swapClientRate,
          8
        );
      }
      if (this.tradeForm.swapCptyRate != null) {
        this.tradeForm.swapCptyRate = this.formatPrice(
          this.tradeForm.swapCptyRate,
          8
        );
      }
      if (this.tradeForm.swapCptyRateEnd != null) {
        this.tradeForm.swapCptyRateEnd = this.formatPrice(
          this.tradeForm.swapCptyRateEnd,
          8
        );
      }
      if (this.tradeForm.swapCptyAmount1 != null) {
        this.tradeForm.swapCptyAmount1 = this.formatPrice(
          this.tradeForm.swapCptyAmount1,
          2
        );
      }
      if (this.tradeForm.swapCptyAmount2 != null) {
        this.tradeForm.swapCptyAmount2 = this.formatPrice(
          this.tradeForm.swapCptyAmount2,
          2
        );
      }
      if (this.tradeForm.swapPoints != null) {
        this.tradeForm.swapPoints = this.formatPrice(
          this.tradeForm.swapPoints,
          2
        );
      }
    },
    checkEnableOCOInputs(input) {
      if (input == "takeProfitLimit") {
        if (this.tradeForm.takeProfitLimit > 0) {
          if (this.tradeForm.buySell == "Buy") {
            if (this.tradeForm.stopLossStop > 0) {
              if (
                this.tradeForm.takeProfitLimit < this.tradeForm.stopLossStop
              ) {
                this.checkedEnableOCOInputsMessage(
                  this.$t(
                    "fx_spot_forward_swap.messages.take_profit_lower_stop_price"
                  )
                );
                this.tradeForm.takeProfitLimit = null;
              }
            }
            if (
              this.tradeForm.takeProfitLimit > 0 &&
              this.tradeForm.stopLossLimit > 0
            ) {
              if (
                this.tradeForm.takeProfitLimit < this.tradeForm.stopLossLimit
              ) {
                this.checkedEnableOCOInputsMessage(
                  this.$t(
                    "fx_spot_forward_swap.messages.take_profit_lower_limit_price"
                  )
                );
                this.tradeForm.takeProfitLimit = null;
              }
            }
          } else if (this.tradeForm.buySell == "Sell") {
            if (this.tradeForm.stopLossStop > 0) {
              if (
                this.tradeForm.takeProfitLimit > this.tradeForm.stopLossStop
              ) {
                this.checkedEnableOCOInputsMessage(
                  this.$t(
                    "fx_spot_forward_swap.messages.take_profit_greater_stop_price"
                  )
                );
                this.tradeForm.takeProfitLimit = null;
              }
            }
            if (
              this.tradeForm.takeProfitLimit > 0 &&
              this.tradeForm.stopLossLimit > 0
            ) {
              if (
                this.tradeForm.takeProfitLimit > this.tradeForm.stopLossLimit
              ) {
                this.checkedEnableOCOInputsMessage(
                  this.$t(
                    "fx_spot_forward_swap.messages.take_profit_greater_limit_price"
                  )
                );
                this.tradeForm.takeProfitLimit = null;
              }
            }
          }
        } else {
          this.greaterThanZeroMessage(
            this.$t(
              "fx_spot_forward_swap.messages.greater_then_zero_take_profit"
            )
          );
        }
      } else if (input == "stopLossStop") {
        if (this.tradeForm.stopLossStop > 0) {
          if (this.tradeForm.buySell == "Buy") {
            if (this.tradeForm.takeProfitLimit > 0) {
              if (
                this.tradeForm.stopLossStop > this.tradeForm.takeProfitLimit
              ) {
                this.checkedEnableOCOInputsMessage(
                  this.$t(
                    "fx_spot_forward_swap.messages.stop_loss_greater_profit_price"
                  )
                );
                this.tradeForm.stopLossStop = null;
              }
            }
          } else if (this.tradeForm.buySell == "Sell") {
            if (this.tradeForm.takeProfitLimit > 0) {
              if (
                this.tradeForm.takeProfitLimit > this.tradeForm.stopLossStop
              ) {
                this.checkedEnableOCOInputsMessage(
                  this.$t(
                    "fx_spot_forward_swap.messages.stop_loss_lower_profit_price"
                  )
                );
                this.tradeForm.stopLossStop = null;
              }
            }
          }
        } else {
          this.greaterThanZeroMessage(
            this.$t("fx_spot_forward_swap.messages.greater_then_zero_stop_loss")
          );
        }
      } else if (input == "stopLossLimit") {
        if (this.tradeForm.stopLossLimit > 0) {
          if (this.tradeForm.buySell == "Buy") {
            if (this.tradeForm.takeProfitLimit > 0) {
              if (
                this.tradeForm.stopLossLimit > this.tradeForm.takeProfitLimit
              ) {
                this.checkedEnableOCOInputsMessage(
                  this.$t(
                    "fx_spot_forward_swap.messages.stop_loss_limit_greater_profit_price"
                  )
                );
                this.tradeForm.stopLossLimit = null;
              }
            }
          } else if (this.tradeForm.buySell == "Sell") {
            if (this.tradeForm.takeProfitLimit > 0) {
              if (
                this.tradeForm.takeProfitLimit > this.tradeForm.stopLossLimit
              ) {
                this.checkedEnableOCOInputsMessage(
                  this.$t(
                    "fx_spot_forward_swap.messages.stop_loss_limit_lower_profit_price"
                  )
                );
                this.tradeForm.stopLossLimit = null;
              }
            }
          }
        } else {
          this.greaterThanZeroMessage(
            this.$t(
              "fx_spot_forward_swap.messages.greater_then_zero_stop_loss_limit"
            )
          );
        }
      }
    },
    checkTraSettlCptyDate(type) {
      if (type == "Trade Date") {
        let tradeDate = new Date(this.MDYdateFormat(this.tradeForm.tradeDate));

        //set trade date name
        this.tradeForm.tradeDateName = this.weekday[tradeDate.getDay()];

        if (
          Date.parse(this.YMDdateFormat(this.tradeForm.tradeDate)) >
          Date.parse(this.YMDdateFormat(this.tradeForm.settlementDate))
        ) {
          this.checkedTraSettlDateMessage(
            this.$t(
              "fx_spot_forward_swap.messages.trade_date_after_settlement_date"
            )
          );
          this.tradeForm.tradeDate = null;
        }

        if (
          Date.parse(this.YMDdateFormat(this.tradeForm.tradeDate)) <
          Date.parse(this.YMDdateFormat(this.tradeForm.orderDate))
        ) {
          this.checkedTraSettlDateMessage(
            this.$t(
              "fx_spot_forward_swap.messages.trade_date_before_order_date"
            )
          );
          this.tradeForm.tradeDate = null;
        }
        if (this.tradeForm.productType == "FI") {
          if (
            Date.parse(this.YMDdateFormat(this.tradeForm.tradeDate)) <
            Date.parse(this.YMDdateFormat(this.tradeForm.cptyOrderDate))
          ) {
            this.checkedTraSettlDateMessage(
              this.$t(
                "fx_spot_forward_swap.messages.trade_date_before_counterparty_order_date"
              )
            );
            this.tradeForm.tradeDate = null;
          }
        }
      } else if (type == "Settlement Date") {
        let settleDate = new Date(
          this.MDYdateFormat(this.tradeForm.settlementDate)
        );

        //set settle date name
        this.tradeForm.settlementDateName = this.weekday[settleDate.getDay()];

        if (
          Date.parse(this.YMDdateFormat(this.tradeForm.tradeDate)) >
          Date.parse(this.YMDdateFormat(this.tradeForm.settlementDate))
        ) {
          this.checkedTraSettlDateMessage(
            this.$t(
              "fx_spot_forward_swap.messages.trade_date_after_settlement_date"
            )
          );
          this.tradeForm.settlementDate = null;
        }

        if (
          Date.parse(this.YMDdateFormat(this.tradeForm.settlementDate)) <
          Date.parse(this.YMDdateFormat(this.tradeForm.orderDate))
        ) {
          this.checkedTraSettlDateMessage(
            this.$t(
              "fx_spot_forward_swap.messages.trade_date_after_settlement_date"
            )
          );
          this.tradeForm.settlementDate = null;
        }
        if (this.tradeForm.productType == "FI") {
          if (
            Date.parse(this.YMDdateFormat(this.tradeForm.settlementDate)) <
            Date.parse(this.YMDdateFormat(this.tradeForm.cptyOrderDate))
          ) {
            this.checkedTraSettlDateMessage(
              this.$t(
                "fx_spot_forward_swap.messages.settlement_date_before_counterparty_order_date"
              )
            );
            this.tradeForm.settlementDate = null;
          }
        }
      } else if ("Cpty Order Date") {
        if (
          Date.parse(this.YMDdateFormat(this.tradeForm.tradeDate)) <
          Date.parse(this.YMDdateFormat(this.tradeForm.cptyOrderDate))
        ) {
          this.checkedTraSettlDateMessage(
            this.$t(
              "fx_spot_forward_swap.messages.trade_date_before_counterparty_order_date"
            )
          );
          this.tradeForm.cptyOrderDate = null;
        }

        if (
          Date.parse(this.YMDdateFormat(this.tradeForm.settlementDate)) <
          Date.parse(this.YMDdateFormat(this.tradeForm.cptyOrderDate))
        ) {
          this.checkedTraSettlDateMessage(
            this.$t(
              "fx_spot_forward_swap.messages.settlement_date_before_counterparty_order_date"
            )
          );
          this.tradeForm.cptyOrderDate = null;
        }
      }
    },
    checkTime(type) {
      if (type == "Order Time") {
        //set time
        if (
          this.tradeForm.orderTime != null &&
          this.tradeForm.orderTime.length < 8
        ) {
          for (let i = this.tradeForm.orderTime.length; i < 8; i++) {
            if (i == 2) {
              this.tradeForm.orderTime += ":";
            } else if (i == 5) {
              this.tradeForm.orderTime += ":";
            } else {
              this.tradeForm.orderTime += "0";
            }
          }
        }

        if (
          Date.parse(this.YMDdateFormat(this.tradeForm.tradeDate)) ==
          Date.parse(this.YMDdateFormat(this.tradeForm.orderDate))
        ) {
          if (this.tradeForm.orderTime > this.tradeForm.executionTime) {
            this.checkTimeMessage(
              this.$t(
                "fx_spot_forward_swap.messages.order_time_later_execution_time"
              )
            );
            this.tradeForm.orderTime = null;
          }
        }

        this.checkOrdersTime("Order Time");
      } else if ("Execution Time") {
        //set time
        if (
          this.tradeForm.executionTime != null &&
          this.tradeForm.executionTime.length < 8
        ) {
          for (let i = this.tradeForm.executionTime.length; i < 8; i++) {
            if (i == 2) {
              this.tradeForm.executionTime += ":";
            } else if (i == 5) {
              this.tradeForm.executionTime += ":";
            } else {
              this.tradeForm.executionTime += "0";
            }
          }
        }

        if (
          Date.parse(this.YMDdateFormat(this.tradeForm.tradeDate)) ==
          Date.parse(this.YMDdateFormat(this.tradeForm.orderDate))
        ) {
          if (this.tradeForm.orderTime > this.tradeForm.executionTime) {
            this.checkTimeMessage(
              this.$t(
                "fx_spot_forward_swap.messages.execution_time_later_order_time"
              )
            );
            this.tradeForm.executionTime = null;
          }
        }

        //set time
        if (
          this.tradeForm.cptyOrderTime != null &&
          this.tradeForm.cptyOrderTime.length < 8
        ) {
          for (let i = this.tradeForm.cptyOrderTime.length; i < 8; i++) {
            if (i == 2) {
              this.tradeForm.cptyOrderTime += ":";
            } else if (i == 5) {
              this.tradeForm.cptyOrderTime += ":";
            } else {
              this.tradeForm.cptyOrderTime += "0";
            }
          }
        }

        if (
          Date.parse(this.YMDdateFormat(this.tradeForm.tradeDate)) ==
          Date.parse(this.YMDdateFormat(this.tradeForm.cptyOrderDate))
        ) {
          if (this.tradeForm.cptyOrderTime > this.tradeForm.executionTime) {
            this.checkTimeMessage(
              this.$t(
                "fx_spot_forward_swap.messages.execution_time_later_counterparty_order_time"
              )
            );
            this.tradeForm.executionTime = null;
          }
        }
      }
    },
    checkOrdersTime(type) {
      if (type == "Order Duration Time") {
        //set time
        if (
          this.tradeForm.orderDurationTime != null &&
          this.tradeForm.orderDurationTime.length < 8
        ) {
          for (let i = this.tradeForm.orderDurationTime.length; i < 8; i++) {
            if (i == 2) {
              this.tradeForm.orderDurationTime += ":";
            } else if (i == 5) {
              this.tradeForm.orderDurationTime += ":";
            } else {
              this.tradeForm.orderDurationTime += "0";
            }
          }
        }

        if (
          Date.parse(this.YMDdateFormat(this.tradeForm.orderDate)) ==
          Date.parse(this.YMDdateFormat(this.tradeForm.orderDurationDate))
        ) {
          if (this.tradeForm.orderTime > this.tradeForm.orderDurationTime) {
            this.checkTimeMessage(
              this.$t(
                "fx_spot_forward_swap.messages.order_duration_later_order_time"
              )
            );
            this.tradeForm.orderDurationTime = null;
          }
        }
      } else if (type == "Order Time") {
        //set time
        if (
          this.tradeForm.orderTime != null &&
          this.tradeForm.orderTime.length < 8
        ) {
          for (let i = this.tradeForm.orderTime.length; i < 8; i++) {
            if (i == 2) {
              this.tradeForm.orderTime += ":";
            } else if (i == 5) {
              this.tradeForm.orderTime += ":";
            } else {
              this.tradeForm.orderTime += "0";
            }
          }
        }

        if (
          Date.parse(this.YMDdateFormat(this.tradeForm.orderDate)) ==
          Date.parse(this.YMDdateFormat(this.tradeForm.orderDurationDate))
        ) {
          if (this.tradeForm.orderTime > this.tradeForm.orderDurationTime) {
            this.checkTimeMessage(
              this.$t(
                "fx_spot_forward_swap.messages.order_time_less_order_duration_time"
              )
            );
            this.tradeForm.orderTime = null;
          }
        }
      }
    },
    checkOrdersDate(type) {
      if (type == "Order Date") {
        if (
          Date.parse(this.YMDdateFormat(this.tradeForm.orderDate)) >
          Date.parse(this.YMDdateFormat(this.tradeForm.orderDurationDate))
        ) {
          this.checkedTraSettlDateMessage(
            this.$t(
              "fx_spot_forward_swap.messages.order_date_after_order_duration_date"
            )
          );
          this.tradeForm.orderDate = null;
        }
      } else if (type == "Order Duration Date") {
        if (
          Date.parse(this.YMDdateFormat(this.tradeForm.orderDate)) >
          Date.parse(this.YMDdateFormat(this.tradeForm.orderDurationDate))
        ) {
          this.checkedTraSettlDateMessage(
            this.$t(
              "fx_spot_forward_swap.messages.order_duration_less_order_date"
            )
          );
          this.tradeForm.orderDurationDate = null;
        }
      }
    },
    changeTimeInForceType() {
      // if (this.tradeForm.timeInForce == "DAY") {
      //   this.tradeForm.orderDurationDate = this.tradeForm.orderDate;
      //   this.tradeForm.orderDurationTime = moment()
      //     .endOf("day")
      //     .format("HH:mm:ss")
      //     .toString();
      //   this.orderDurationDataDisabled = true;
      //   this.orderDurationTimeDisabled = true;
      //   this.orderDurationInputsVisible = true;
      // } else if (this.tradeForm.timeInForce == "GTC") {
      //   this.orderDurationInputsVisible = false;
      //   this.tradeForm.orderDurationDate = null;
      //   this.tradeForm.orderDurationTime = null;
      // } else if (this.tradeForm.timeInForce == "GTD") {
      //   this.tradeForm.orderDurationDate = this.tradeForm.orderDate;
      //   this.tradeForm.orderDurationTime = moment()
      //     .endOf("day")
      //     .format("HH:mm:ss")
      //     .toString();
      //   this.orderDurationDataDisabled = false;
      //   this.orderDurationTimeDisabled = false;
      //   this.orderDurationInputsVisible = true;
      // } else if (this.tradeForm.timeInForce == "FOK") {
      //   this.orderDurationInputsVisible = false;
      //   this.tradeForm.orderDurationDate = null;
      //   this.tradeForm.orderDurationTime = null;
      // } else if (this.tradeForm.timeInForce == "FAK") {
      //   this.orderDurationInputsVisible = false;
      //   this.tradeForm.orderDurationDate = null;
      //   this.tradeForm.orderDurationTime = null;
      // }
    },
    clearOrderDate() {
      this.orderDateRange = null;
      this.tradeForm.orderDate = null;
      this.tradeForm.orderDateEnd = null;
    },
    clearCptyOrderDate() {
      this.cptyOrderDateRange = null;
      this.tradeForm.cptyOrderDate = null;
      this.tradeForm.cptyOrderDateEnd = null;
    },
    clearDurationDate() {
      this.durationDateRange = null;
      this.tradeForm.orderDurationDate = null;
      this.tradeForm.orderDurationDateEnd = null;
    },
    clearSwapTradeDate() {
      this.swapTradeDateRange = null;
      this.tradeForm.swapTradeDate = null;
      this.tradeForm.swapTradeDateEnd = null;
      this.tradeForm.swapTradeDateName = null;
    },
    clearSwapSettleDateDate() {
      this.swapSettleDateRange = null;
      this.tradeForm.swapSettleDate = null;
      this.tradeForm.swapSettleDateEnd = null;
      this.tradeForm.swapSettleDateName = null;
    },
    clearTradeDate() {
      this.tradeDateRange = null;
      this.tradeForm.tradeDate = null;
      this.tradeForm.tradeDateEnd = null;
    },
    clearSettleDate() {
      this.settleDateRange = null;
      this.tradeForm.settlementDate = null;
      this.tradeForm.settlementDateEnd = null;
    },
    resetSearchForTradesModal() {
      this.searchForTradesModal = false;
      this.tradeModalLoading = false;

      this.tradeForm = {
        costCenter: null,
        orderDurationDate: null,
        orderDurationDateEnd: null,
        orderDurationTime: null,
        orderDurationTimeEnd: null,

        entryType: null,
        buySell: null,
        orderType: null,
        timeInForce: null,
        orderDate: null,
        orderDateEnd: null,
        orderTime: null,
        orderTimeEnd: null,

        cptyOrderDate: null,
        cptyOrderDateEnd: null,
        cptyOrderTime: null,
        cptyOrderTimeEnd: null,

        enableConnectedOCOOrders: "All Oco Orders",
        stopPrice: null,
        trailingAmount: null,
        takeProfitLimit: null,
        stopLossStop: null,
        stopLossLimit: null,
        client: null,
        clientAccount: null,
        counterparty: null,
        cptyAccount: null,
        productType: null,
        settlementType: null,
        counterpartyRate: null,
        cptyCalculateFromRate1: true,
        clientRate: null,
        clientCalculateFromRate2: true,
        cptyAmount1: null,
        cptyAmount2: null,
        cptyCcy1: null,
        cptyCcy2: null,
        cptyBuySell1: null,
        cptyBuySell2: null,
        calcFromSpread: "Calculate Spread From Client Rate",
        spread: null,
        clientAmount1: null,
        clientAmount2: null,
        clientCcy1: null,
        clientCcy2: null,
        clientBuySell1: null,
        clientBuySell2: null,
        spotRef: null,
        spreadAmount: null,
        spreadCcy: null,
        comissionAmount: null,
        comissionCcy: null,
        cutOffTime: null,
        deliverableCcy: null,
        tradeDate: null,
        tradeDateEnd: null,
        tradeDateName: null,
        settlementDate: null,
        settlementDateEnd: null,
        settlementDateName: null,
        executionTime: null,
        executionTimeEnd: null,

        counterpartyTrader: null,
        scfUser: null,
        orderTakenVia: null,
        orderGivenThrough: null,
        actingCapaticy: null,
        note: null,
        swapTradeDate: null,
        swapTradeDateEnd: null,
        swapTradeDateName: null,
        maturity: null,
        swapSettleDate: null,
        swapSettleDateEnd: null,
        swapSettleDateName: null,
        swapPoints: null,
        swapCptyRate: null,
        swapCptyRateEnd: null,

        swapCptyAmount1: null,
        swapCptyCcy1: null,
        swapCptyBuySell1: null,
        swapCptyAmount2: null,
        swapCptyCcyType2: null,
        swapCptyBuySell2: null,
        swapSpread: null,
        swapCalcFromSpread: "Calculate Client Rate From Spread",
        swapClientRate: null,
        swapClientAmount1: null,
        swapClientCcy1: null,
        swapClientBuySell1: null,
        swapClientAmount2: null,
        swapClientCcy2: null,
        swapClientBuySell2: null,
        swapSpreadAmount: null,
        swapSpreadCcy: null,
        uti: null,
        uniqueLinkID: null,
        tradeRef: null,
        entryType2: null,
        tradeStart: null,
        tradeEnd: null,
        settleStart: null,
        settleEnd: null,

        cptyCalculateFromRate1End: true,
        clientCalculateFromRate2End: true,
        stopPriceEnd: null,
        trailingAmountEnd: null,
        takeProfitLimitEnd: null,
        stopLossStopEnd: null,
        stopLossLimitEnd: null,
        clientRateEnd: null,
        swapClientRateEnd: null,
        counterpartyRateEnd: null,
        cptyAmount1End: null,
        cptyAmount2End: null,
        clientAmount1End: null,
        clientAmount2End: null,
        spreadAmountEnd: null,
        comissionAmountEnd: null,
        swapPointsEnd: null,
        swapCptyAmount1End: null,
        swapCptyAmount2End: null,
        swapSpreadEnd: null,
        swapClientAmount1End: null,
        swapClientAmount2End: null,
        swapSpreadAmountEnd: null,
        maturityEnd: null,
        spreadEnd: null,
      };

      // this.optClients = [];
      this.optPriceTypes = [];
      // this.optCounterParties = [];
      this.optCounterPartyTraders = [];
      this.optScfUsers = [];
      this.optOrderTakenVias = [];
      this.optOrderGivenThroughs = [];
    },
    greaterThanZeroMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: `${val} must be greater than 0`,
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    },
    checkedEnableOCOInputsMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    databaseErrorMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: this.$t(
            "fx_spot_forward_swap.toast_messages.database_connection_error"
          ),
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    },
    checkedTraSettlDateMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    checkTimeMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    errorMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    notFoundMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: `The searched ${val} data is not found`,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    wrongToastMessage(val, timeout) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Error",
            text: val,
            icon: "AlertTriangleIcon",
            variant: "warning",
          },
        },
        {
          timeout,
        }
      );
    },

    successMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Success",
          text: message,
          icon: "TickIcon",
          variant: "success",
        },
      });
    },

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    DMYdateFormat(val) {
      if (val != undefined) {
        return moment(String(val), "DD-MM-YYYY").format("DD-MM-YYYY");
      }
    },
    YMDdateFormat(val) {
      if (val != undefined) {
        return moment(String(val), "DD-MM-YYYY").format("YYYY-MM-DD");
      }
    },
    MDYdateFormat(val) {
      if (val != undefined) {
        return moment(String(val), "DD-MM-YYYY").format("MM-DD-YYYY");
      }
    },
    timeFormat(val) {
      if (val != undefined) {
        return moment(String(val), "HH:mm:ss")._i;
      }
    },
    dateFormatWithTime(val) {
      if (val != undefined) {
        return moment(String(val), "DD-MM-YYYY HH:mm:ss").format(
          "DD-MM-YYYY HH:mm:ss"
        );
      }
    },
    formatPrice(value, exp) {
      if (typeof exp === "undefined" || +exp === 0) return Math.round(value);

      value = +value;
      exp = +exp;

      if (isNaN(value) || !(typeof exp === "number" && exp % 1 === 0))
        return NaN;

      // Shift
      value = value.toString().split("e");
      value = Math.round(
        +(value[0] + "e" + (value[1] ? +value[1] + exp : exp))
      );

      // Shift back
      value = value.toString().split("e");
      return +(value[0] + "e" + (value[1] ? +value[1] - exp : -exp));
    },

    roundToTwo(val) {
      if (val != undefined && val > 0) {
        return Math.round(val * 100) / 100;
        // return +(Math.round(val + "e+2") + "e-2");
      }
    },
    setSearchData() {
      if (
        this.tradeForm.timeInForce != null &&
        this.tradeForm.timeInForce.find(
          (x) => x == "GTC" || x == "FOK" || x == "FAK"
        )
      ) {
        this.tradeForm.orderDurationDate = null;
        this.tradeForm.orderDurationTime = null;
      }

      let setSearch = {
        costCenter: this.tradeForm.costCenter,

        client: this.tradeForm.client != null ? this.tradeForm.client : null,
        clientAcc:
          this.tradeForm.clientAccount != null
            ? this.tradeForm.clientAccount
            : null,
        counterparty:
          this.tradeForm.counterparty != null
            ? this.tradeForm.counterparty
            : null,
        cptyAccount:
          this.tradeForm.cptyAccount != null
            ? this.tradeForm.cptyAccount
            : null,
        cptyRate:
          this.tradeForm.counterpartyRate != null
            ? parseFloat(this.tradeForm.counterpartyRate).toFixed(4)
            : null,
        cptyBuySell1:
          this.tradeForm.cptyBuySell1 != null
            ? this.tradeForm.cptyBuySell1
            : null,
        cptyCcy1:
          this.tradeForm.cptyCcy1 != null ? this.tradeForm.cptyCcy1 : null,
        cptyCcy2:
          this.tradeForm.cptyCcy2 != null ? this.tradeForm.cptyCcy2 : null,
        cptyAmount1:
          this.tradeForm.cptyAmount1 != null
            ? parseFloat(this.tradeForm.cptyAmount1).toFixed(2)
            : null,
        cptyAmount2:
          this.tradeForm.cptyAmount2 != null
            ? parseFloat(this.tradeForm.cptyAmount2).toFixed(2)
            : null,
        spreadCcy:
          this.tradeForm.spreadCcy != null ? this.tradeForm.spreadCcy : null,
        spread:
          this.tradeForm.spread != null
            ? parseFloat(this.tradeForm.spread).toFixed(2)
            : null,
        spreadAmount:
          this.tradeForm.spreadAmount != null
            ? parseFloat(this.tradeForm.spreadAmount).toFixed(2)
            : null,
        comissionCcy:
          this.tradeForm.comissionCcy != null
            ? this.tradeForm.comissionCcy
            : null,
        comissionAmount:
          this.tradeForm.comissionAmount != null
            ? parseFloat(this.tradeForm.comissionAmount).toFixed(2)
            : null,
        clientRate:
          this.tradeForm.clientRate != null
            ? parseFloat(this.tradeForm.clientRate).toFixed(4)
            : null,
        clientAmount1:
          this.tradeForm.clientAmount1 != null
            ? parseFloat(this.tradeForm.clientAmount1).toFixed(2)
            : null,
        clientAmount2:
          this.tradeForm.clientAmount2 != null
            ? parseFloat(this.tradeForm.clientAmount2).toFixed(2)
            : null,
        tradeDate:
          this.tradeForm.tradeDate != null
            ? this.YMDdateFormat(this.tradeForm.tradeDate)
            : null,
        tradeDateEnd:
          this.tradeForm.tradeDateEnd != null
            ? this.YMDdateFormat(this.tradeForm.tradeDateEnd)
            : null,
        settlementDate:
          this.tradeForm.settlementDate != null
            ? this.YMDdateFormat(this.tradeForm.settlementDate)
            : null,
        settlementDateEnd:
          this.tradeForm.settlementDateEnd != null
            ? this.YMDdateFormat(this.tradeForm.settlementDateEnd)
            : null,
        orderTime:
          this.tradeForm.orderTime != null
            ? this.timeFormat(this.tradeForm.orderTime)
            : null,
        orderTimeEnd:
          this.tradeForm.orderTimeEnd != null
            ? this.timeFormat(this.tradeForm.orderTimeEnd)
            : null,
        executionTime:
          this.tradeForm.executionTime != null
            ? this.timeFormat(this.tradeForm.executionTime)
            : null,
        executionTimeEnd:
          this.tradeForm.executionTimeEnd != null
            ? this.timeFormat(this.tradeForm.executionTimeEnd)
            : null,

        cptyTrader:
          this.tradeForm.counterpartyTrader != null
            ? this.tradeForm.counterpartyTrader
            : null,
        tcUser: this.tradeForm.scfUser != null ? this.tradeForm.scfUser : null,
        orderTakenVia:
          this.tradeForm.orderTakenVia != null
            ? this.tradeForm.orderTakenVia
            : null,
        orderGivenThrough:
          this.tradeForm.orderGivenThrough != null
            ? this.tradeForm.orderGivenThrough
            : null,
        actingCapaticy:
          this.tradeForm.actingCapaticy != null
            ? this.tradeForm.actingCapaticy
            : null,
        traderNote: this.tradeForm.note != null ? this.tradeForm.note : null,
        relatedFxOptionId: null,
        cpRef: null,
        productType:
          this.tradeForm.productType != null
            ? this.tradeForm.productType
            : null,
        settlementType:
          this.tradeForm.settlementType != null
            ? this.tradeForm.settlementType
            : null,
        spotRef:
          this.tradeForm.spotRef != null
            ? parseFloat(this.tradeForm.spotRef).toFixed(2)
            : null,
        cutOffTime:
          this.tradeForm.settlementType != null
            ? this.tradeForm.settlementType
            : null,
        deliverableCcy:
          this.tradeForm.deliverableCcy != null
            ? this.tradeForm.deliverableCcy
            : null,
        confirmationDate: null,
        confirmationTime: null,
        confirmationTrader: null,
        cptyUti: null,
        tcUti: this.tradeForm.uti != null ? this.tradeForm.uti : null,
        uniqueLinkId:
          this.tradeForm.uniqueLinkID != null
            ? this.tradeForm.uniqueLinkID
            : null,
        clearingMemberId: null,
        confirmationDateClient: null,
        confirmationTimeClient: null,
        rabate: null,
        future4: null,
        future5: null,
        future6: null,
        future7: null,
        future8: null,
        future9: null,
        future10: null,
        future11: null,
        future12: null,
        future13: null,
        future14: null,
        future15: null,
        entryType:
          this.tradeForm.entryType != null ? this.tradeForm.entryType : null,
        buySell: this.tradeForm.buySell != null ? this.tradeForm.buySell : null,
        orderType:
          this.tradeForm.orderType != null ? this.tradeForm.orderType : null,
        timeInForce:
          this.tradeForm.timeInForce != null
            ? this.tradeForm.timeInForce
            : null,
        stopPrice:
          this.tradeForm.stopPrice != null
            ? parseFloat(this.tradeForm.stopPrice).toFixed(2)
            : null,

        trailingAmount:
          this.tradeForm.trailingAmount != null
            ? parseFloat(this.tradeForm.trailingAmount).toFixed(2)
            : null,

        takeProfitLimit:
          this.tradeForm.takeProfitLimit != null
            ? parseFloat(this.tradeForm.takeProfitLimit).toFixed(2)
            : null,

        stopLoss:
          this.tradeForm.stopLossStop != null
            ? parseFloat(this.tradeForm.stopLossStop).toFixed(2)
            : null,

        stopLossLimit:
          this.tradeForm.stopLossLimit != null
            ? parseFloat(this.tradeForm.stopLossLimit).toFixed(2)
            : null,

        orderAmount: null,

        enableOCO: this.tradeForm.enableConnectedOCOOrders,

        orderPrice: null,

        orderDate:
          this.tradeForm.orderDate != null
            ? this.YMDdateFormat(this.tradeForm.orderDate)
            : null,
        orderDateEnd:
          this.tradeForm.orderDateEnd != null
            ? this.YMDdateFormat(this.tradeForm.orderDateEnd)
            : null,

        cptyOrderDate:
          this.tradeForm.cptyOrderDate != null
            ? this.YMDdateFormat(this.tradeForm.cptyOrderDate)
            : null,
        cptyOrderDateEnd:
          this.tradeForm.cptyOrderDateEnd != null
            ? this.YMDdateFormat(this.tradeForm.cptyOrderDateEnd)
            : null,

        counterpartOrderTime:
          this.tradeForm.cptyOrderTime != null
            ? this.timeFormat(this.tradeForm.cptyOrderTime)
            : null,
        counterpartOrderTimeEnd:
          this.tradeForm.cptyOrderTimeEnd != null
            ? this.timeFormat(this.tradeForm.cptyOrderTimeEnd)
            : null,

        orderDurationDate:
          this.tradeForm.orderDurationDate != null
            ? this.YMDdateFormat(this.tradeForm.orderDurationDate)
            : null,
        orderDurationDateEnd:
          this.tradeForm.orderDurationDateEnd != null
            ? this.YMDdateFormat(this.tradeForm.orderDurationDateEnd)
            : null,
        orderDurationTime:
          this.tradeForm.orderDurationTime != null
            ? this.timeFormat(this.tradeForm.orderDurationTime)
            : null,
        orderDurationTimeEnd:
          this.tradeForm.orderDurationTimeEnd != null
            ? this.timeFormat(this.tradeForm.orderDurationTimeEnd)
            : null,

        ocoLink: null,
        swapMaturity:
          this.tradeForm.maturity != null ? this.tradeForm.maturity : null,
        swapForwardRate:
          this.tradeForm.swapPoints != null
            ? parseFloat(this.tradeForm.swapPoints).toFixed(4)
            : null,
        swapSpread:
          this.tradeForm.swapSpread != null
            ? parseFloat(this.tradeForm.swapSpread).toFixed(2)
            : null,
        swapClientRate:
          this.tradeForm.swapClientRate != null
            ? parseFloat(this.tradeForm.swapClientRate).toFixed(4)
            : null,
        swapClientBuySell1:
          this.tradeForm.swapClientBuySell1 != null
            ? this.tradeForm.swapClientBuySell1
            : null,
        swapClientBuySell2:
          this.tradeForm.swapClientBuySell2 != null
            ? this.tradeForm.swapClientBuySell2
            : null,
        swapClientCcy1:
          this.tradeForm.swapClientCcy1 != null
            ? this.tradeForm.swapClientCcy1
            : null,
        swapClientCcy2:
          this.tradeForm.swapClientCcy2 != null
            ? this.tradeForm.swapClientCcy2
            : null,
        swapClientAmount1:
          this.tradeForm.swapClientAmount1 != null
            ? parseFloat(this.tradeForm.swapClientAmount1).toFixed(2)
            : null,
        swapClientAmount2:
          this.tradeForm.swapClientAmount2 != null
            ? parseFloat(this.tradeForm.swapClientAmount2).toFixed(2)
            : null,
        swapCptyRate:
          this.tradeForm.swapCptyRate != null
            ? parseFloat(this.tradeForm.swapCptyRate).toFixed(4)
            : null,
        swapCptyRateEnd:
          this.tradeForm.swapCptyRateEnd != null
            ? parseFloat(this.tradeForm.swapCptyRateEnd).toFixed(4)
            : null,
        swapCptyBuySell1:
          this.tradeForm.swapCptyBuySell1 != null
            ? this.tradeForm.swapCptyBuySell1
            : null,
        swapCptyBuySell2:
          this.tradeForm.swapCptyBuySell2 != null
            ? this.tradeForm.swapCptyBuySell2
            : null,
        swapCptyCcy1:
          this.tradeForm.swapCptyCcy1 != null
            ? this.tradeForm.swapCptyCcy1
            : null,
        swapCptyCcy2:
          this.tradeForm.swapCptyCcy2 != null
            ? this.tradeForm.swapCptyCcy2
            : null,
        swapCptyAmount1:
          this.tradeForm.swapCptyAmount1 != null
            ? parseFloat(this.tradeForm.swapCptyAmount1).toFixed(2)
            : null,
        swapCptyAmount2:
          this.tradeForm.swapCptyAmount2 != null
            ? parseFloat(this.tradeForm.swapCptyAmount2).toFixed(2)
            : null,
        swapSpreadCcy:
          this.tradeForm.swapSpreadCcy != null
            ? this.tradeForm.swapSpreadCcy
            : null,
        swapSpreadAmount:
          this.tradeForm.swapSpreadAmount != null
            ? parseFloat(this.tradeForm.swapSpreadAmount).toFixed(2)
            : null,
        swapTradeDate: this.tradeForm.swapTradeDate
          ? this.tradeForm.swapTradeDate
          : null,
        swapTradeDateEnd:
          this.tradeForm.swapTradeDateEnd != null
            ? this.tradeForm.swapTradeDateEnd
            : null,
        swapSettleDate:
          this.tradeForm.swapSettleDate != null
            ? this.tradeForm.swapSettleDate
            : null,
        swapSettleDateEnd:
          this.tradeForm.swapSettleDateEnd != null
            ? this.tradeForm.swapSettleDateEnd
            : null,
        startTradeDate:
          this.tradeForm.tradeStart != null
            ? this.YMDdateFormat(this.tradeForm.tradeStart)
            : null,
        endTradeDate:
          this.tradeForm.tradeEnd != null
            ? this.YMDdateFormat(this.tradeForm.tradeEnd)
            : null,
        startSettlementDate:
          this.tradeForm.settleStart != null
            ? this.YMDdateFormat(this.tradeForm.settleStart)
            : null,
        endSettlementDate:
          this.tradeForm.settleEnd != null
            ? this.YMDdateFormat(this.tradeForm.settleEnd)
            : null,

        swapPoints: this.tradeForm.swapPoints,
        stopPriceEnd: this.tradeForm.stopPriceEnd,
        trailingAmountEnd: this.tradeForm.trailingAmountEnd,
        takeProfitLimitEnd: this.tradeForm.takeProfitLimitEnd,
        stopLossStopEnd: this.tradeForm.stopLossStopEnd,
        stopLossLimitEnd: this.tradeForm.stopLossLimitEnd,
        clientRateEnd: this.tradeForm.clientRateEnd,
        swapClientRateEnd: this.tradeForm.swapClientRateEnd,
        counterpartyRateEnd: this.tradeForm.counterpartyRateEnd,
        cptyAmount1End: this.tradeForm.cptyAmount1End,
        cptyAmount2End: this.tradeForm.cptyAmount2End,
        clientAmount1End: this.tradeForm.clientAmount1End,
        clientAmount2End: this.tradeForm.clientAmount2End,
        spreadAmountEnd: this.tradeForm.spreadAmountEnd,
        comissionAmountEnd: this.tradeForm.comissionAmountEnd,
        swapPointsEnd: this.tradeForm.swapPointsEnd,
        swapCptyAmount1End: this.tradeForm.swapCptyAmount1End,
        swapCptyAmount2End: this.tradeForm.swapCptyAmount2End,
        swapSpreadEnd: this.tradeForm.swapSpreadEnd,
        swapClientAmount1End: this.tradeForm.swapClientAmount1End,
        swapClientAmount2End: this.tradeForm.swapClientAmount2End,
        swapSpreadAmountEnd: this.tradeForm.swapSpreadAmountEnd,
        maturityEnd: this.tradeForm.maturityEnd,
        spreadEnd: this.tradeForm.spreadEnd,

        ID: 0,
      };

      return setSearch;
    },
  },
  watch: {
    searchForTradesModal: {
      handler: function(val, before) {
        if (val) {
          this.getArangeFxExecutedTCUsers();
          this.getArrangeFxExecutedCurrencies();
          this.getArrangeFxExecutedOrderTakenGivenThrough();
        }
      },
    },

    orderDateRange: {
      handler: function(val, before) {
        if (val) {
          if (val.includes("to")) {
            this.tradeForm.orderDate = val.split("to")[0];
            this.tradeForm.orderDateEnd = val.split("to")[1];
          } else {
            this.tradeForm.orderDate = val;
          }
        }
      },
    },

    tradeDateRange: {
      handler: function(val, before) {
        if (val) {
          if (val.includes("to")) {
            this.tradeForm.tradeDate = val.split("to")[0];
            this.tradeForm.tradeDateEnd = val.split("to")[1];
          } else {
            this.tradeForm.tradeDate = val;
          }
        }
      },
    },

    settleDateRange: {
      handler: function(val, before) {
        if (val) {
          if (val.includes("to")) {
            this.tradeForm.settlementDate = val.split("to")[0];
            this.tradeForm.settlementDateEnd = val.split("to")[1];
          } else {
            this.tradeForm.settlementDate = val;
          }
        }
      },
    },

    durationDateRange: {
      handler: function(val, before) {
        if (val) {
          if (val.includes("to")) {
            this.tradeForm.orderDurationDate = val.split("to")[0];
            this.tradeForm.orderDurationDateEnd = val.split("to")[1];
          } else {
            this.tradeForm.orderDurationDate = val;
          }
        }
      },
    },

    cptyOrderDateRange: {
      handler: function(val, before) {
        if (val) {
          if (val.includes("to")) {
            this.tradeForm.cptyOrderDate = val.split("to")[0];
            this.tradeForm.cptyOrderDateEnd = val.split("to")[1];
          } else {
            this.tradeForm.cptyOrderDate = val;
          }
        }
      },
    },

    swapTradeDateRange: {
      handler: function(val, before) {
        if (val) {
          if (val.includes("to")) {
            this.tradeForm.swapTradeDate = val.split("to")[0];
            this.tradeForm.swapTradeDateEnd = val.split("to")[1];
          } else {
            this.tradeForm.swapTradeDate = val;
          }
        }
      },
    },

    swapSettleDateRange: {
      handler: function(val, before) {
        if (val) {
          if (val.includes("to")) {
            this.tradeForm.swapSettleDate = val.split("to")[0];
            this.tradeForm.swapSettleDateEnd = val.split("to")[1];
          } else {
            this.tradeForm.swapSettleDate = val;
          }
        }
      },
    },
  },
};
</script>

<style>
#searchTradeModal .modal-header .modal-title {
  color: white !important;
}
.fxDetailRadio .custom-control:nth-child(odd) {
  margin-bottom: 5px;
}

.animate__animated {
  animation-fill-mode: none;
}
.swapSettleName .input-group-append .input-group-text {
  color: red;
}
.swapTradeName .input-group-append .input-group-text {
  color: red;
}
.tradeDateName .input-group-append .input-group-text {
  color: red;
}
.settlementDateName .input-group-append .input-group-text {
  color: red;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>

<!-- compact design scss code -->

<style lang="scss" scoped>
@import "../../assets/scss/compact-design.scss";

// .v-select::v-deep .vs__selected {
//     position: relative !important;
//   }

.fxDetailRadio::v-deep .form-check-label {
  font-size: 0.75rem !important;
}
</style>
