<template>
  <div>
    {{ /*verify selected trade modal*/ }}
    <b-modal
      id="tradeModal"
      v-model="verifySelectedTradeModal"
      :title="$t('fx_spot_forward_swap.titles.verify_trade')"
      @hidden="resetTradeModal"
      size="xl"
      :ok-title="$t('fx_spot_forward_swap.modals.verify_trade_modal_ok')"
      :cancel-title="$t('fx_spot_forward_swap.modals.modal_cancel')"
      @ok="tradeOkOperation"
      :body-bg-variant="'light'"
      :header-bg-variant="'dark'"
      class="tradeModal"
      body-class="compact_form_modal"
      footer-class ="compact_modal_footer"
      no-close-on-backdrop
    >
      <b-overlay :show="tradeModalLoading" rounded="sm" no-fade>
        <b-container>
          <validation-observer ref="tradeValidation">
            <b-form>
              <b-row>

              <!-- /////**************Order Details && Client & Counterparty Details && Trade Details ***************************************************************** */ -->

              <b-col cols="12" sm="12" md="12" lg="6" xl="6" class="pl-0" style="padding-right:0.2rem!important"> 
              
                <!-- /*******************************Order Details***************************************************************** */ -->
                <b-card
                  :title="$t('fx_spot_forward_swap.titles.order_details')"
                >
                  <b-row>
                    <!-- order date-->
                    <b-col cols="6" xl="2" lg="2" md="3" sm="6">
                      <b-form-group
                        :label="
                          $t('fx_spot_forward_swap.trade_form.order_date')
                        "
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="
                            $t('fx_spot_forward_swap.trade_form.order_date')
                          "
                          rules="required"
                        >
                          <flat-pickr
                            :disabled="true"
                            ref="orderDate"
                            :placeholder="
                              $t('fx_spot_forward_swap.trade_form.order_date')
                            "
                            v-model="tradeForm.orderDate"
                            onClose="testClose"
                            class="form-control"
                            :config="orderDateConfig"
                            :state="errors.length > 0 ? false : null"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- order time-->
                    <b-col cols="6" xl="2" lg="2" md="3" sm="6" >
                      <b-form-group
                        :label="
                          $t('fx_spot_forward_swap.trade_form.order_time')
                        "
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="
                            $t('fx_spot_forward_swap.trade_form.order_time')
                          "
                          rules="required"
                        >
                          <cleave
                            :disabled="true"
                            ref="orderTime"
                            id="orderTime"
                            v-model="tradeForm.orderTime"
                            class="form-control"
                            :raw="false"
                            :options="cleaveOptions.time"
                            :placeholder="
                              $t('fx_spot_forward_swap.trade_form.order_time')
                            "
                            :state="errors.length > 0 ? false : null"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    
                    
                    <!-- Order duration date -->
                    <b-col cols="6" xl="2" lg="2" md="3" sm="6" >
                      <b-form-group
                        :label="
                          $t(
                            'fx_spot_forward_swap.trade_form.order_duration_date'
                          )
                        "
                        v-if="orderDurationInputsVisible"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="
                            $t(
                              'fx_spot_forward_swap.trade_form.order_duration_date'
                            )
                          "
                          :rules="
                            tradeForm.timeInForce == 'GTD' ? 'required' : ''
                          "
                        >
                          <flat-pickr
                            :placeholder="
                              $t(
                                'fx_spot_forward_swap.trade_form.order_duration_date'
                              )
                            "
                            v-model="tradeForm.orderDurationDate"
                            onClose="testClose"
                            class="form-control"
                            :config="dateConfig"
                            :state="errors.length > 0 ? false : null"
                            :disabled="true"
                            ref="orderDurationDate"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- Order duration time -->
                    <b-col cols="6" xl="2" lg="2" md="3" sm="6" >
                      <b-form-group
                        :label="
                          $t(
                            'fx_spot_forward_swap.trade_form.order_duration_time'
                          )
                        "
                        v-if="orderDurationInputsVisible"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="
                            $t(
                              'fx_spot_forward_swap.trade_form.order_duration_time'
                            )
                          "
                          :rules="
                            tradeForm.timeInForce == 'GTD' ? 'required' : ''
                          "
                        >
                          <cleave
                            id="orderDurationTime"
                            v-model="tradeForm.orderDurationTime"
                            class="form-control"
                            :raw="false"
                            :options="cleaveOptions.time"
                            :placeholder="
                              $t(
                                'fx_spot_forward_swap.trade_form.order_duration_time'
                              )
                            "
                            :state="errors.length > 0 ? false : null"
                            :disabled="true"
                            ref="orderDurationTime"
                          />

                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- entry type -->
                    <b-col cols="6" xl="2" lg="2" md="3" sm="6" >
                      <b-form-group
                        :label="
                          $t('fx_spot_forward_swap.trade_form.entry_type')
                        "
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="
                            $t('fx_spot_forward_swap.trade_form.entry_type')
                          "
                          rules="required"
                        >
                          <v-select
                            v-model="tradeForm.entryType"
                            :options="optEntryTypes"
                            :clearable="false"
                            :placeholder="
                              $t(
                                'fx_spot_forward_swap.trade_form.select_entry_type'
                              )
                            "
                            :reduce="(val) => val"
                            :state="errors.length > 0 ? false : null"
                            :disabled="true"
                            ref="entryType"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- buy/sell -->
                    <b-col cols="6" xl="2" lg="2" md="3" sm="6" >
                      <b-form-group
                        :label="$t('fx_spot_forward_swap.trade_form.buy_sell')"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('fx_spot_forward_swap.trade_form.buy_sell')"
                          rules="required"
                        >
                          <v-select
                            v-model="tradeForm.buySell"
                            :options="optbuySells"
                            :clearable="false"
                            :placeholder="
                              $t(
                                'fx_spot_forward_swap.trade_form.select_buy_sell'
                              )
                            "
                            :reduce="(val) => val"
                            :state="errors.length > 0 ? false : null"
                            :disabled="true"
                            ref="buySell"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- order type-->
                    <b-col cols="6" xl="2" lg="2" md="3" sm="6">
                      <b-form-group
                        :label="
                          $t('fx_spot_forward_swap.trade_form.order_type')
                        "
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="
                            $t('fx_spot_forward_swap.trade_form.order_type')
                          "
                          rules="required"
                        >
                          <v-select
                            v-model="tradeForm.orderType"
                            :options="optOrderTypes"
                            :clearable="false"
                            :placeholder="
                              $t(
                                'fx_spot_forward_swap.trade_form.select_order_type'
                              )
                            "
                            :reduce="(val) => val"
                            :state="errors.length > 0 ? false : null"
                            :disabled="true"
                            ref="orderType"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- time in force-->
                    <b-col cols="6" xl="2" lg="2" md="3" sm="6" >
                      <b-form-group
                        :label="
                          $t('fx_spot_forward_swap.trade_form.time_in_force')
                        "
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="
                            $t('fx_spot_forward_swap.trade_form.time_in_force')
                          "
                          rules="required"
                        >
                          <v-select
                            v-model="tradeForm.timeInForce"
                            :options="optTimeInForces"
                            :clearable="false"
                            :placeholder="
                              $t(
                                'fx_spot_forward_swap.trade_form.select_time_in_force'
                              )
                            "
                            :reduce="(val) => val"
                            :state="errors.length > 0 ? false : null"
                            :disabled="true"
                            ref="timeInForce"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- cpty order date-->
                    <b-col cols="6" xl="2" lg="2" md="3" sm="6" >
                      <b-form-group
                        :label="
                          $t(
                            'fx_spot_forward_swap.trade_form.cpty_order_date'
                          )
                        "
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="
                            $t(
                              'fx_spot_forward_swap.trade_form.cpty_order_date'
                            )
                          "
                          rules="required"
                        >
                          <flat-pickr
                            :placeholder="
                              $t(
                                'fx_spot_forward_swap.trade_form.cpty_order_date'
                              )
                            "
                            v-model="tradeForm.cptyOrderDate"
                            onClose="testClose"
                            class="form-control"
                            :config="dateConfig"
                            :state="errors.length > 0 ? false : null"
                            :disabled="true"
                            ref="counterpartyOrderDate"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- cpty order time-->
                    <b-col cols="6" xl="2" lg="2" md="3" sm="6" >
                      <b-form-group
                        :label="
                          $t(
                            'fx_spot_forward_swap.trade_form.cpty_order_time'
                          )
                        "
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="
                            $t(
                              'fx_spot_forward_swap.trade_form.cpty_order_time'
                            )
                          "
                          rules="required"
                        >
                          <cleave
                            id="orderTime"
                            v-model="tradeForm.cptyOrderTime"
                            class="form-control"
                            :raw="false"
                            :options="cleaveOptions.time"
                            :placeholder="
                              $t(
                                'fx_spot_forward_swap.trade_form.cpty_order_time'
                              )
                            "
                            :state="errors.length > 0 ? false : null"
                            :disabled="true"
                            ref="counterpartyOrderTime"
                          />

                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- Stop Price -->
                    <b-col cols="6" xl="2" lg="2" md="3" sm="6" 
                      v-if="
                        tradeForm.orderType == 'Stop' ||
                          tradeForm.orderType == 'Stop Limit' ||
                          tradeForm.orderType == 'Trailing Stop'
                      "
                    >
                      <b-form-group
                        :label="
                          $t('fx_spot_forward_swap.trade_form.stop_price')
                        "
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="
                            $t('fx_spot_forward_swap.trade_form.stop_price')
                          "
                          :rules="
                            tradeForm.orderType == 'Stop'
                              ? 'required|min_value:0'
                              : '' || tradeForm.orderType == 'Trailing Stop'
                              ? 'required|min_value:0'
                              : ''
                          "
                        >
                          <cleave
                            id="stopPrice"
                            :state="errors.length > 0 ? false : null"
                            v-model="tradeForm.stopPrice"
                            class="form-control"
                            :raw="true"
                            :options="cleaveOptions.number"
                            :placeholder="
                              $t('fx_spot_forward_swap.trade_form.stop_price')
                            "
                            :disabled="true"
                            ref="stopPrice"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- Trailing Amount -->
                    <b-col cols="6" xl="2" lg="2" md="3" sm="6" 
                      v-if="tradeForm.orderType == 'Trailing Stop'"
                    >
                      <b-form-group
                        :label="
                          $t('fx_spot_forward_swap.trade_form.trailing_amount')
                        "
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="
                            $t(
                              'fx_spot_forward_swap.trade_form.trailing_amount'
                            )
                          "
                          :rules="
                            tradeForm.orderType == 'Trailing Stop'
                              ? 'required|min_value:0'
                              : ''
                          "
                        >
                          <b-form-input
                            id="trailingAmount"
                            v-model.number="tradeForm.trailingAmount"
                            name="trailingAmount"
                            type="number"
                            :state="errors.length > 0 ? false : null"
                            :placeholder="
                              $t(
                                'fx_spot_forward_swap.trade_form.trailing_amount'
                              )
                            "
                            autofocus
                            :disabled="true"
                            ref="trailingAmount"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <!-- OCO orders inputs-->
                  <!-- cpty profit limit & cpty stop loss stop & cpty stop loss limit -->
                  <b-row
                  >
                    <!-- enable connected OCO orders-->
                    <b-col cols="12" xl="3" lg="3" md="3" sm="12" class="mt-1">
                      <b-form-group>
                        <validation-provider
                          #default="{ errors }"
                          name="enable OCO orders"
                        >
                          <b-form-checkbox
                            v-model="tradeForm.enableConnectedOCOOrders"
                            :disabled="true"
                            ref="enableConnectedOCOOrders"
                            plain
                          >
                            {{
                              $t("fx_spot_forward_swap.trade_form.enable_oco")
                            }}
                          </b-form-checkbox>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <!-- cpty Take Profit Limit -->
                      <b-col cols="4" xl="3" lg="3" md="3" sm="4">
                        <b-form-group
                          :label="
                            $t(
                              'fx_spot_forward_swap.trade_form.cpty_take_profit_limit'
                            )
                          "
                          v-if="tradeForm.enableConnectedOCOOrders"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t(
                                'fx_spot_forward_swap.trade_form.cpty_take_profit_limit'
                              )
                            "
                            :rules="
                              tradeForm.enableConnectedOCOOrders
                                ? 'required|min_value:0'
                                : ''
                            "
                          >
                            <cleave
                              id="cptyTakeProfitLimit"
                              ref="cptyTakeProfitLimit"
                              :state="errors.length > 0 ? false : null"
                              v-model="tradeForm.cptyTakeProfitLimit"
                              class="form-control"
                              :raw="true"
                              :options="cleave8DecimalOptions.number"
                              :placeholder="
                                $t(
                                  'fx_spot_forward_swap.trade_form.cpty_take_profit_limit'
                                )
                              "
                              :disabled="true"
                            />

                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- cpty Stop Loss Stop -->
                      <b-col cols="4" xl="3" lg="3" md="3" sm="4">
                        <b-form-group
                          :label="
                            $t(
                              'fx_spot_forward_swap.trade_form.cpty_stop_loss_stop'
                            )
                          "
                          v-if="tradeForm.enableConnectedOCOOrders"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t(
                                'fx_spot_forward_swap.trade_form.cpty_stop_loss_stop'
                              )
                            "
                            :rules="
                              tradeForm.enableConnectedOCOOrders &&
                              tradeForm.cptyStopLossStop > 0
                                ? 'required|min_value:0'
                                : ''
                            "
                          >
                            <cleave
                              id="cptystopLossStop"
                              ref="cptystopLossStop"
                              :state="errors.length > 0 ? false : null"
                              v-model="tradeForm.cptyStopLossStop"
                              class="form-control"
                              :raw="true"
                              :options="cleave8DecimalOptions.number"
                              :placeholder="
                                $t(
                                  'fx_spot_forward_swap.trade_form.cpty_stop_loss_stop'
                                )
                              "
                              :disabled="true"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- cpty Stop Loss Limit -->
                      <b-col cols="4" xl="3" lg="3" md="3" sm="4">
                        <b-form-group
                          :label="
                            $t(
                              'fx_spot_forward_swap.trade_form.cpty_stop_loss_limit'
                            )
                          "
                          v-if="tradeForm.enableConnectedOCOOrders"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t(
                                'fx_spot_forward_swap.trade_form.cpty_stop_loss_limit'
                              )
                            "
                            :rules="
                              tradeForm.enableConnectedOCOOrders &&
                              tradeForm.cptyStopLossLimit > 0
                                ? 'required|min_value:0'
                                : ''
                            "
                          >
                            <cleave
                              id="cptyStopLossLimit"
                              ref="cptyStopLossLimit"
                              :state="errors.length > 0 ? false : null"
                              v-model="tradeForm.cptyStopLossLimit"
                              class="form-control"
                              :raw="true"
                              :options="cleave8DecimalOptions.number"
                              :placeholder="
                                $t(
                                  'fx_spot_forward_swap.trade_form.cpty_stop_loss_limit'
                                )
                              "
                              :disabled="true"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                  </b-row>
                  
                  <!-- client profit limit & client stop loss stop & client stop loss limit -->
                    <b-row>
                      <!-- Take Profit Limit -->
                      <b-col offset-xl="3" cols="4" xl="3" lg="3" md="3" sm="4">
                        <b-form-group
                          :label="
                            $t(
                              'fx_spot_forward_swap.trade_form.take_profit_limit'
                            )
                          "
                          v-if="tradeForm.enableConnectedOCOOrders"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t(
                                'fx_spot_forward_swap.trade_form.take_profit_limit'
                              )
                            "
                            :rules="
                              tradeForm.enableConnectedOCOOrders
                                ? 'required|min_value:0'
                                : ''
                            "
                          >
                            <cleave
                              id="takeProfitLimit"
                              ref="takeProfitLimit"
                              :state="errors.length > 0 ? false : null"
                              v-model="tradeForm.takeProfitLimit"
                              class="form-control"
                              :raw="true"
                              :options="cleave8DecimalOptions.number"
                              :placeholder="
                                $t(
                                  'fx_spot_forward_swap.trade_form.take_profit_limit'
                                )
                              "
                              :disabled="true"
                            />

                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Stop Loss Stop -->
                      <b-col cols="4" xl="3" lg="3" md="3" sm="4">
                        <b-form-group
                          :label="
                            $t('fx_spot_forward_swap.trade_form.stop_loss_stop')
                          "
                          v-if="tradeForm.enableConnectedOCOOrders"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t(
                                'fx_spot_forward_swap.trade_form.stop_loss_stop'
                              )
                            "
                            :rules="
                              tradeForm.enableConnectedOCOOrders &&
                              tradeForm.stopLossStop > 0
                                ? 'required|min_value:0'
                                : ''
                            "
                          >
                            <cleave
                              id="stopLossStop"
                              ref="stopLossStop"
                              :state="errors.length > 0 ? false : null"
                              v-model="tradeForm.stopLossStop"
                              class="form-control"
                              :raw="true"
                              :options="cleave8DecimalOptions.number"
                              :placeholder="
                                $t(
                                  'fx_spot_forward_swap.trade_form.stop_loss_stop'
                                )
                              "
                              :disabled="true"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Stop Loss Limit -->
                      <b-col cols="4" xl="3" lg="3" md="3" sm="4">
                        <b-form-group
                          :label="
                            $t(
                              'fx_spot_forward_swap.trade_form.stop_loss_limit'
                            )
                          "
                          v-if="tradeForm.enableConnectedOCOOrders"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t(
                                'fx_spot_forward_swap.trade_form.stop_loss_limit'
                              )
                            "
                            :rules="
                              tradeForm.enableConnectedOCOOrders &&
                              tradeForm.stopLossLimit > 0
                                ? 'required|min_value:0'
                                : ''
                            "
                          >
                            <cleave
                              id="stopLossLimit"
                              ref="stopLossLimit"
                              :state="errors.length > 0 ? false : null"
                              v-model="tradeForm.stopLossLimit"
                              class="form-control"
                              :raw="true"
                              :options="cleave8DecimalOptions.number"
                              :placeholder="
                                $t(
                                  'fx_spot_forward_swap.trade_form.stop_loss_limit'
                                )
                              "
                              :disabled="true"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
               
                </b-card>

              <!-- /******************************* Client & Counterparty Details***************************************************************** */ -->
                <b-card
                  :title="
                    $t(
                      'fx_spot_forward_swap.titles.client_counterparty_details'
                    )
                  "
                >
                  <b-row>
                      <!-- client -->
                      <b-col cols="12" xl="3" lg="3" md="3" sm="12">
                        <b-form-group
                          :label="$t('fx_spot_forward_swap.trade_form.client')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_spot_forward_swap.trade_form.client')"
                            :rules="tradeForm.entryType == 'Execution' ? 'required' : ''"
                          >
                            <v-select
                              v-model="tradeForm.client"
                              :options="optClients"
                              :clearable="false"
                              :placeholder="
                                $t(
                                  'fx_spot_forward_swap.trade_form.select_client'
                                )
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                              :loading="loadingClient"
                              :disabled="true"
                              ref="client"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- client account -->
                      <b-col cols="12" xl="3" lg="3" md="3" sm="12" >
                        <b-form-group
                          :label="
                            $t('fx_spot_forward_swap.trade_form.client_account')
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t(
                                'fx_spot_forward_swap.trade_form.client_account'
                              )
                            "
                            :rules="tradeForm.entryType == 'Execution' ? 'required' : ''"
                          >
                            <b-form-input
                              id="clientAccount"
                              v-model="tradeForm.clientAccount"
                              name="clientAccount"
                              :state="errors.length > 0 ? false : null"
                              :placeholder="
                                $t(
                                  'fx_spot_forward_swap.trade_form.client_account'
                                )
                              "
                              autofocus
                              :disabled="true"
                              ref="clientAcc"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- counter party -->
                      <b-col cols="12" xl="3" lg="3" md="3" sm="12" >
                        <b-form-group
                          :label="
                            $t('fx_spot_forward_swap.trade_form.counterparty')
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t('fx_spot_forward_swap.trade_form.counterparty')
                            "
                            :rules="tradeForm.entryType == 'Execution' ? 'required' : ''"
                          >
                            <v-select
                              v-model="tradeForm.counterparty"
                              :options="optCounterParties"
                              :clearable="false"
                              :placeholder="
                                $t(
                                  'fx_spot_forward_swap.trade_form.select_counterparty'
                                )
                              "
                              :reduce="(val) => val"
                              :loading="loadingCounterparty"
                              :disabled="true"
                              ref="cpty"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- cpty account -->
                      <b-col cols="12" xl="3" lg="3" md="3" sm="12" >
                        <b-form-group
                          :label="
                            $t('fx_spot_forward_swap.trade_form.cpty_account')
                          "
                          
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t('fx_spot_forward_swap.trade_form.cpty_account')
                            "
                            :rules="tradeForm.entryType == 'Execution' ? 'required' : ''"
                          >
                            <b-form-input
                              id="cptyAccount"
                              v-model="tradeForm.cptyAccount"
                              name="cpty account"
                              :state="errors.length > 0 ? false : null"
                              :placeholder="
                                $t(
                                  'fx_spot_forward_swap.trade_form.cpty_account'
                                )
                              "
                              autofocus
                              :disabled="true"
                              ref="cptyAcc"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                  </b-row>
                </b-card>

                  <!-- /******************************* Trade Details***************************************************************** */ -->

                <b-card
                      :title="$t('fx_spot_forward_swap.titles.trade_details')"
                      style="padding-bottom:0.5rem;"
                    >
                    <b-row>
                        <!-- Trade Date -->
                        <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                          <b-form-group
                            :label="
                              $t('fx_spot_forward_swap.trade_form.trade_date')
                            "
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="
                                $t('fx_spot_forward_swap.trade_form.trade_date')
                              "
                            >
                              <b-input-group
                                :class="
                                  tradeForm.tradeDateName == 'Sunday' ||
                                  tradeForm.tradeDateName == 'Saturday'
                                    ? 'tradeDateName'
                                    : ''
                                "
                                :append="tradeForm.tradeDateName"
                              >
                                <flat-pickr
                                  :placeholder="
                                    $t(
                                      'fx_spot_forward_swap.trade_form.trade_date'
                                    )
                                  "
                                  v-model="tradeForm.tradeDate"
                                  onClose="testClose"
                                  class="form-control"
                                  :config="dateConfig"
                                  :disabled="true"
                                  ref="tradeDate"
                                />
                              </b-input-group>
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <!-- Settlement Date -->
                        <b-col cols="6" xl="4" lg="4" md="4" sm="6" >
                          <b-form-group
                            :label="
                              $t(
                                'fx_spot_forward_swap.trade_form.settlement_date'
                              )
                            "
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="
                                $t(
                                  'fx_spot_forward_swap.trade_form.settlement_date'
                                )
                              "
                            >
                              <b-input-group
                                :class="
                                  tradeForm.settlementDateName == 'Sunday' ||
                                  tradeForm.settlementDateName == 'Saturday'
                                    ? 'settlementDateName'
                                    : ''
                                "
                                :append="tradeForm.settlementDateName"
                              >
                                <flat-pickr
                                  :placeholder="
                                    $t(
                                      'fx_spot_forward_swap.trade_form.settlement_date'
                                    )
                                  "
                                  v-model="tradeForm.settlementDate"
                                  onClose="testClose"
                                  class="form-control"
                                  :config="dateConfig"
                                  :disabled="true"
                                  ref="settlDate"
                                />
                              </b-input-group>
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <!-- execution time -->
                        <b-col cols="6" xl="4" lg="4" md="4" sm="6" >
                          <b-form-group
                            :label="
                              $t(
                                'fx_spot_forward_swap.trade_form.execution_time'
                              )
                            "
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="
                                $t(
                                  'fx_spot_forward_swap.trade_form.execution_time'
                                )
                              "
                            >
                              <cleave
                                id="executionTime"
                                v-model="tradeForm.executionTime"
                                class="form-control"
                                :raw="false"
                                :options="cleaveOptions.time"
                                :placeholder="
                                  $t(
                                    'fx_spot_forward_swap.trade_form.execution_time'
                                  )
                                "
                                :state="errors.length > 0 ? false : null"
                                :disabled="true"
                                ref="execTime"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>

                        <!-- counter party trader -->
                        <b-col cols="6" xl="4" lg="4" md="4" sm="6" >
                          <b-form-group
                            :label="
                              $t(
                                'fx_spot_forward_swap.trade_form.counterparty_trader'
                              )
                            "
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="
                                $t(
                                  'fx_spot_forward_swap.trade_form.counterparty_trader'
                                )
                              "
                            >
                              <v-select
                                v-model="tradeForm.counterpartyTrader"
                                :options="optCounterPartyTraders"
                                :clearable="false"
                                :placeholder="
                                  $t(
                                    'fx_spot_forward_swap.trade_form.select_counterparty_trader'
                                  )
                                "
                                :reduce="(val) => val"
                                :disabled="true"
                                ref="cptyTrader"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <!-- sfc user -->
                                             <b-col cols="6" xl="4" lg="4" md="4" sm="6" >

                          <b-form-group
                            :label="
                              $t('fx_spot_forward_swap.trade_form.sfc_user')
                            "
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="
                                $t('fx_spot_forward_swap.trade_form.sfc_user')
                              "
                              rules="required"
                            >
                              <v-select
                                v-model="tradeForm.scfUser"
                                :options="optScfUsers"
                                :clearable="false"
                                :placeholder="
                                  $t(
                                    'fx_spot_forward_swap.trade_form.select_sfc_user'
                                  )
                                "
                                :reduce="(val) => val"
                                :state="errors.length > 0 ? false : null"
                                :disabled="true"
                                ref="tcUser"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <!-- order taken via -->
                        <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                          <b-form-group
                            :label="
                              $t(
                                'fx_spot_forward_swap.trade_form.order_taken_via'
                              )
                            "
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="
                                $t(
                                  'fx_spot_forward_swap.trade_form.order_taken_via'
                                )
                              "
                            >
                              <v-select
                                v-model="tradeForm.orderTakenVia"
                                :options="optOrderTakenVias"
                                :clearable="false"
                                :placeholder="
                                  $t(
                                    'fx_spot_forward_swap.trade_form.select_order_taken_via'
                                  )
                                "
                                :reduce="(val) => val"
                                :disabled="true"
                                ref="takenVia"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <!-- order given through -->
                        <b-col cols="6" xl="4" lg="4" md="4" sm="6" >
                          <b-form-group
                            :label="
                              $t(
                                'fx_spot_forward_swap.trade_form.order_given_through'
                              )
                            "
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="
                                $t(
                                  'fx_spot_forward_swap.trade_form.order_given_through'
                                )
                              "
                            >
                              <v-select
                                v-model="tradeForm.orderGivenThrough"
                                :options="optOrderGivenThroughs"
                                :clearable="false"
                                :placeholder="
                                  $t(
                                    'fx_spot_forward_swap.trade_form.select_order_given_through'
                                  )
                                "
                                :reduce="(val) => val"
                                :disabled="true"
                                ref="givenThrg"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <!-- acting capacity -->
                        <b-col cols="6" xl="4" lg="4" md="4" sm="6" >

                          <b-form-group
                            :label="
                              $t(
                                'fx_spot_forward_swap.trade_form.acting_capacity'
                              )
                            "
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="
                                $t(
                                  'fx_spot_forward_swap.trade_form.acting_capacity'
                                )
                              "
                              rules="required"
                            >
                              <v-select
                                v-model="tradeForm.actingCapaticy"
                                :options="optActingCapacities"
                                :clearable="false"
                                :placeholder="
                                  $t(
                                    'fx_spot_forward_swap.trade_form.select_acting_capacity'
                                  )
                                "
                                :reduce="(val) => val"
                                :state="errors.length > 0 ? false : null"
                                :disabled="true"
                                ref="actingCap"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                      <!-- note -->
                      <b-col cols="12" xl="12" lg="12" md="12" sm="12">
                        <b-form-group
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_spot_forward_swap.trade_form.note')"
                          >
                            <b-form-textarea
                              id="form-textarea"
                              :placeholder="
                                $t('fx_spot_forward_swap.trade_form.note')
                              "
                              no-resize
                              v-model="tradeForm.note"
                              :disabled="true"
                              ref="traderNote"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-card>
              </b-col>

              <!-- ///////**************FX Details && Settlement Details ***************************************************************** */ -->

              <b-col cols="12" sm="12" md="12" lg="6" xl="6" class="pr-0" style="padding-left:0.2rem!important;">
              

                <!-- /******************************* FX Details***************************************************************** */ -->

                <b-card
                    :title="$t('fx_spot_forward_swap.titles.fx_details')"
                    style="padding-bottom:1rem !important;"
                  >
                  <b-row>
                    <!-- product type-->
                    <b-col cols="6" xl="3" lg="3" md="3" sm="6">
                      <b-form-group
                        :label="
                          $t('fx_spot_forward_swap.trade_form.product_type')
                        "
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="
                            $t('fx_spot_forward_swap.trade_form.product_type')
                          "
                          rules="required"
                        >
                          <v-select
                            v-model="tradeForm.productType"
                            :options="optproductTypes"
                            :clearable="false"
                            :placeholder="
                              $t(
                                'fx_spot_forward_swap.trade_form.select_product_type'
                              )
                            "
                            :reduce="(val) => val"
                            :state="errors.length > 0 ? false : null"
                            :disabled="true"
                            ref="productType"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- settlement type-->
                    <b-col cols="6" xl="3" lg="3" md="3" sm="6" >
                      <b-form-group
                        :label="
                          $t('fx_spot_forward_swap.trade_form.settlement_type')
                        "
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="
                            $t(
                              'fx_spot_forward_swap.trade_form.settlement_type'
                            )
                          "
                          rules="required"
                        >
                          <v-select
                            v-model="tradeForm.settlementType"
                            :options="optSettlementTypes"
                            :clearable="false"
                            :placeholder="
                              $t(
                                'fx_spot_forward_swap.trade_form.select_settlement_type'
                              )
                            "
                            :reduce="(val) => val"
                            :state="errors.length > 0 ? false : null"
                            :disabled="true"
                            ref="settlementType"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                      <!-- cost center -->
                    <b-col cols="6" xl="3" lg="3" md="3" sm="6" >
                    <b-form-group
                      :label="$t('equity_bond.trade_form.cost_center')"
                    >
                    <validation-provider
                        #default="{ errors }"
                        :name="$t('equity_bond.trade_form.cost_center')"
                        rules="required"
                      >


                  <v-select
                    :disabled="true"
                    v-model="tradeForm.costCenter"
                    :options="optCostCenters"
                    :clearable="false"
                    :placeholder="$t('equity_bond.trade_form.select_cost_center')"
                    :reduce="(val) => val.CostCenter"
                    label = 'CostCenter'
                    ref="costCenter"

                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                </b-form-group>
                  </b-col>


                    <!-- Counterparty Rate -->
                    <b-col cols="6" xl="3" lg="3" md="3" sm="6" >
                      <b-form-group
                        :label="
                          $t(
                            'fx_spot_forward_swap.trade_form.counterparty_rate'
                          )
                        "
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="
                            $t(
                              'fx_spot_forward_swap.trade_form.counterparty_rate'
                            )
                          "
                          :rules="tradeForm.entryType == 'Execution' ? 'required|min_value:0' : ''
                          "
                        >
                          <b-input-group>
                            <b-input-group-prepend is-text>
                              <b-form-checkbox
                                plain
                                name="checkbox-input"
                                v-model="tradeForm.cptyCalculateFromRate1"
                                v-b-tooltip.hover.top
                                :title="
                                  $t(
                                    'fx_spot_forward_swap.trade_form.counterparty_rate_tooltip'
                                  )
                                "
                                :disabled="true"
                                ref="cptyCalculateFromRate1"
                              />
                            </b-input-group-prepend>

                            <cleave
                              id="counterpartyRate"
                              :state="errors.length > 0 ? false : null"
                              v-model="tradeForm.counterpartyRate"
                              class="form-control"
                              :raw="true"
                              :options="cleave8DecimalOptions.number"
                              :placeholder="
                                $t(
                                  'fx_spot_forward_swap.trade_form.counterparty_rate'
                                )
                              "
                              :disabled="true"
                              ref="cptyRate"
                            />
                          </b-input-group>

                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- <b-col cols="12" xl="12" lg="12" md="12" sm="12">
                    
                      <h6 style="text-decoration:underline;text-align:center;">
                        {{ $t("fx_spot_forward_swap.trade_form.sfc") }}</h6>
                    </b-col> -->

                          <!-- ------ SFC ------------ -->
                    <!-- cptyAmount1 -->
                    <b-col cols="6" xl="6" lg="6" md="4" sm="6">
                      <b-form-group
                        :label="
                          $t('fx_spot_forward_swap.trade_form.cpty_amount1')
                        "
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="
                            $t('fx_spot_forward_swap.trade_form.cpty_amount1')
                          "
                          :rules="tradeForm.entryType == 'Execution' ? 'required|min_value:0' : ''"
                        >
                          <cleave
                            id="cpty_amount1"
                            :state="errors.length > 0 ? false : null"
                            v-model="tradeForm.cptyAmount1"
                            class="form-control"
                            :raw="true"
                            :options="cleaveOptions.number"
                            :placeholder="
                              $t(
                                'fx_spot_forward_swap.trade_form.cpty_amount1'
                              )
                            "
                            :disabled="true"
                            ref="cptyAmount1"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- cptyCcy1 -->
                    <b-col cols="6" xl="3" lg="3" md="4" sm="6" 
                    >
                      <b-form-group
                        :label="$t('fx_spot_forward_swap.trade_form.ccy')"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('fx_spot_forward_swap.trade_form.ccy')"
                          rules="required"
                        >
                          <v-select
                            v-model="tradeForm.cptyCcy1"
                            :options="optPriceTypes"
                            :clearable="false"
                            :placeholder="
                              $t('fx_spot_forward_swap.trade_form.ccy')
                            "
                            :reduce="(val) => val"
                            :state="errors.length > 0 ? false : null"
                            :disabled="true"
                            ref="ccy1"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- //sfcBuySell1 -->
                    <b-col cols="6" xl="3" lg="3" md="4" sm="6" 
                    >
                      <b-form-group
                        :label="
                          $t('fx_spot_forward_swap.trade_form.sfc_buy_sell1')
                        "
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="
                            $t(
                              'fx_spot_forward_swap.trade_form.sfc_buy_sell1'
                            )
                          "
                          rules="required"
                        >
                          <v-select
                            v-model="tradeForm.cptyBuySell1"
                            :options="optcptyBuySells"
                            :clearable="false"
                            :placeholder="
                              $t(
                                'fx_spot_forward_swap.trade_form.sfc_buy_sell1'
                              )
                            "
                            :reduce="(val) => val"
                            :state="errors.length > 0 ? false : null"
                            :disabled="true"
                            ref="cptyBuySell1"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                          <!-- ------ SFC 2 ------------ -->
                    <!-- cptyAmount2 -->
                    <b-col cols="6" xl="6" lg="6" md="4" sm="6" >
                      <b-form-group
                        :label="
                          $t('fx_spot_forward_swap.trade_form.cpty_amount2')
                        "
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="
                            $t('fx_spot_forward_swap.trade_form.cpty_amount2')
                          "
                          :rules="tradeForm.entryType == 'Execution' ? 'required|min_value:0' : ''"
                        >
                          <cleave
                            id="cpty_amount2"
                            :state="errors.length > 0 ? false : null"
                            v-model="tradeForm.cptyAmount2"
                            class="form-control"
                            :raw="true"
                            :options="cleaveOptions.number"
                            :placeholder="
                              $t(
                                'fx_spot_forward_swap.trade_form.cpty_amount2'
                              )
                            "
                            :disabled="true"
                            ref="cptyAmount2"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- cptyCcy2 -->
                    <b-col cols="6" xl="3" lg="3" md="4" sm="6" 
                    >
                      <b-form-group
                        :label="$t('fx_spot_forward_swap.trade_form.ccy')"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('fx_spot_forward_swap.trade_form.ccy')"
                          rules="required"
                        >
                          <v-select
                            v-model="tradeForm.cptyCcy2"
                            :options="optPriceTypes"
                            :clearable="false"
                            :placeholder="
                              $t('fx_spot_forward_swap.trade_form.ccy')
                            "
                            :reduce="(val) => val"
                            :state="errors.length > 0 ? false : null"
                            :disabled="true"
                            ref="ccy2"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- //sfcBuySell2 -->
                    <b-col cols="6" xl="3" lg="3" md="4" sm="6" 
                    >
                      <b-form-group
                        :label="
                          $t('fx_spot_forward_swap.trade_form.sfc_buy_sell2')
                        "
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="
                            $t(
                              'fx_spot_forward_swap.trade_form.sfc_buy_sell2'
                            )
                          "
                          rules="required"
                        >
                          <v-select
                            v-model="tradeForm.cptyBuySell2"
                            :options="optcptyBuySells"
                            :clearable="false"
                            :placeholder="
                              $t(
                                'fx_spot_forward_swap.trade_form.sfc_buy_sell2'
                              )
                            "
                            :reduce="(val) => val"
                            :state="errors.length > 0 ? false : null"
                            :disabled="true"
                            ref="cptyBuySell2"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- calculate client rate radio buttons -->
                    <b-col cols="6" sm="4" xl="4" lg="4" md="6" class="mt-1">
                      <b-form-group>
                        <b-form-radio-group
                          v-model="tradeForm.calcFromSpread"
                          :options="optCalcFromSpread"
                          name="radios-stacked"
                          plain
                          stacked
                          class="fxDetailRadio"
                          :disabled="true"
                          ref="calcFromSpread"
                        />
                      </b-form-group>
                    </b-col>

                    <!-- Spread -->
                    <b-col cols="6" xl="4" lg="4" md="6" sm="6" >
                      <b-form-group
                        :label="$t('fx_spot_forward_swap.trade_form.spread')"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('fx_spot_forward_swap.trade_form.spread')"
                          :rules="
                            tradeForm.calculateCommissionFromRate2
                              ? 'min_value:0'
                              : ''
                          "
                        >
                          <b-input-group append="pips">
                            <cleave
                              id="spread"
                              :state="errors.length > 0 ? false : null"
                              v-model="tradeForm.spread"
                              class="form-control"
                              :raw="true"
                              :options="cleave8DecimalOptions.number"
                              :placeholder="
                                $t('fx_spot_forward_swap.trade_form.spread')
                              "
                              :disabled="true"
                              ref="spread"
                            />
                          </b-input-group>

                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <!-- Client Rate -->
                    <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                      <b-form-group
                        :label="
                          $t('fx_spot_forward_swap.trade_form.client_rate')
                        "
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="
                            $t('fx_spot_forward_swap.trade_form.client_rate')
                          "
                          :rules="tradeForm.entryType == 'Execution' ? 'required|min_value:0' : ''"
                        >
                          <b-input-group>
                            <b-input-group-prepend is-text>
                              <b-form-checkbox
                                plain
                                name="checkbox-input"
                                v-model="tradeForm.clientCalculateFromRate2"
                                v-b-tooltip.hover.top
                                :title="
                                  $t(
                                    'fx_spot_forward_swap.trade_form.client_rate_tooltip'
                                  )
                                "
                                :disabled="true"
                                ref="clientCalculateFromRate2"
                              >
                              </b-form-checkbox>
                            </b-input-group-prepend>

                            <cleave
                              id="clientRate"
                              :state="errors.length > 0 ? false : null"
                              v-model="tradeForm.clientRate"
                              class="form-control"
                              :raw="true"
                              :options="cleave8DecimalOptions.number"
                              :placeholder="
                                $t(
                                  'fx_spot_forward_swap.trade_form.client_rate'
                                )
                              "
                              :disabled="true"
                              ref="clientRate"
                            />
                          </b-input-group>

                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <!-- <b-col cols="12" xl="12" lg="12" md="12" sm="12">
                      <h6 style="text-decoration:underline;text-align:center;">
                      {{ $t("fx_spot_forward_swap.trade_form.client2") }}</h6>
                    </b-col> -->

                            <!-- ------ Client ------------ -->
                    <!-- clientAmount1 -->
                    <b-col cols="6" xl="6" lg="6" md="4" sm="6">
                      <b-form-group
                        :label="
                          $t('fx_spot_forward_swap.trade_form.client_amount1')
                        "
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="
                            $t(
                              'fx_spot_forward_swap.trade_form.client_amount1'
                            )
                          "
                          :rules="tradeForm.entryType == 'Execution' ? 'required|min_value:0' : ''"
                        >
                          <cleave
                            id="clientPrice"
                            :state="errors.length > 0 ? false : null"
                            v-model="tradeForm.clientAmount1"
                            class="form-control"
                            :raw="true"
                            :options="cleaveOptions.number"
                            :placeholder="
                              $t(
                                'fx_spot_forward_swap.trade_form.client_amount1'
                              )
                            "
                            :disabled="true"
                            ref="clientAmount1"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- clientCcy1 -->
                    <b-col cols="6" xl="3" lg="3" md="4" sm="6" >
                      <b-form-group
                        :label="$t('fx_spot_forward_swap.trade_form.ccy')"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('fx_spot_forward_swap.trade_form.ccy')"
                          rules="required"
                        >
                          <v-select
                            v-model="tradeForm.clientCcy1"
                            :options="optPriceTypes"
                            :clearable="false"
                            :placeholder="
                              $t('fx_spot_forward_swap.trade_form.ccy')
                            "
                            :reduce="(val) => val"
                            :state="errors.length > 0 ? false : null"
                            :disabled="true"
                            ref="clientCcy1"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- clientBuySell1 -->
                    <b-col cols="6" xl="3" lg="3" md="4" sm="6" >
                      <b-form-group
                        :label="
                          $t(
                            'fx_spot_forward_swap.trade_form.client_buy_sell1'
                          )
                        "
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="
                            $t(
                              'fx_spot_forward_swap.trade_form.client_buy_sell1'
                            )
                          "
                          rules="required"
                        >
                          <v-select
                            v-model="tradeForm.clientBuySell1"
                            :options="optclientBuySells"
                            :clearable="false"
                            :placeholder="
                              $t(
                                'fx_spot_forward_swap.trade_form.client_buy_sell1'
                              )
                            "
                            :reduce="(val) => val"
                            :state="errors.length > 0 ? false : null"
                            :disabled="true"
                            ref="clientBuySell1"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <!-- ------ Client 2 ------------ -->
                      <!-- clientAmount2 -->
                      <b-col cols="6" xl="6" lg="6" md="4" sm="6">
                        <b-form-group
                          :label="
                            $t('fx_spot_forward_swap.trade_form.client_amount2')
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t(
                                'fx_spot_forward_swap.trade_form.client_amount2'
                              )
                            "
                            :rules="tradeForm.entryType == 'Execution' ? 'required|min_value:0' : ''"
                          >
                            <cleave
                              id="clientPrice"
                              :state="errors.length > 0 ? false : null"
                              v-model="tradeForm.clientAmount2"
                              class="form-control"
                              :raw="true"
                              :options="cleaveOptions.number"
                              :placeholder="
                                $t(
                                  'fx_spot_forward_swap.trade_form.client_amount2'
                                )
                              "
                              :disabled="true"
                              ref="clientAmount2"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- clientCcy2 -->
                      <b-col cols="6" xl="3" lg="3" md="4" sm="6" >
                        <b-form-group
                          :label="$t('fx_spot_forward_swap.trade_form.ccy')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('fx_spot_forward_swap.trade_form.ccy')"
                            rules="required"
                          >
                            <v-select
                              v-model="tradeForm.clientCcy2"
                              :options="optPriceTypes"
                              :clearable="false"
                              :placeholder="
                                $t('fx_spot_forward_swap.trade_form.ccy')
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                              :disabled="true"
                              ref="clientCcy2"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- clientBuySell2 -->
                      <b-col cols="6" xl="3" lg="3" md="4" sm="6" >
                        <b-form-group
                          :label="
                            $t(
                              'fx_spot_forward_swap.trade_form.client_buy_sell2'
                            )
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t(
                                'fx_spot_forward_swap.trade_form.client_buy_sell2'
                              )
                            "
                            rules="required"
                          >
                            <v-select
                              v-model="tradeForm.clientBuySell2"
                              :options="optclientBuySells"
                              :clearable="false"
                              :placeholder="
                                $t(
                                  'fx_spot_forward_swap.trade_form.client_buy_sell2'
                                )
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                              :disabled="true"
                              ref="clientBuySell2"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                    <!-- Spot Ref -->
                    <b-col cols="6" xl="6" lg="6" md="4" sm="6">
                      <b-form-group
                        :label="$t('fx_spot_forward_swap.trade_form.spot_ref')"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="spot_ref"
                          :rules="tradeForm.entryType == 'Execution' ? 'required|min_value:0' : ''"
                        >
                        <cleave
                            id="spot_ref"
                            :state="errors.length > 0 ? false : null"
                            v-model="tradeForm.spotRef"
                            class="form-control"
                            :raw="true"
                            :options="cleave8DecimalOptions.number"
                            :placeholder="
                              $t(
                                'fx_spot_forward_swap.trade_form.spot_ref'
                              )
                            "
                            :disabled="true"
                            ref="spotRef"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- Spred comm -->
                    <b-col cols="6" xl="4" lg="4" md="4" sm="6" >
                        <b-form-group
                          :label="
                            $t('fx_spot_forward_swap.trade_form.spread_comm')
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t('fx_spot_forward_swap.trade_form.spread_comm')
                            "
                            :rules="tradeForm.entryType == 'Execution' ? 'required' : ''"
                          >
                            <cleave
                              id="spreadAmount"
                              :state="errors.length > 0 ? false : null"
                              v-model="tradeForm.spreadAmount"
                              class="form-control"
                              :raw="true"
                              :options="cleaveOptions.number"
                              :placeholder="
                                $t(
                                  'fx_spot_forward_swap.trade_form.spread_comm'
                                )
                              "
                              :disabled="true"
                              ref="spreadAmount"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                    </b-col>
                    <!-- spread price types CCY -->
                    <b-col cols="12" xl="2" lg="2" md="4" sm="12">

                      <b-form-group
                        :label="$t('fx_spot_forward_swap.trade_form.ccy')"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('fx_spot_forward_swap.trade_form.ccy')"
                          rules="required"
                        >
                          <v-select
                            v-model="tradeForm.spreadCcy"
                            :options="optPriceTypes"
                            :clearable="false"
                            :placeholder="
                              $t('fx_spot_forward_swap.trade_form.ccy')
                            "
                            :reduce="(val) => val"
                            :state="errors.length > 0 ? false : null"
                            :disabled="true"
                            ref="spreadCcy"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <!-- Comission -->
                    <b-col cols="12" xl="6" lg="6" md="4" sm="12">
                        <b-form-group
                          :label="
                            $t('fx_spot_forward_swap.trade_form.comission')
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t('fx_spot_forward_swap.trade_form.comission')
                            "
                            rules="min_value:0"
                          >
                            <cleave
                              id="clientPrice"
                              :state="errors.length > 0 ? false : null"
                              v-model="tradeForm.comissionAmount"
                              class="form-control"
                              :raw="true"
                              :options="cleaveOptions.number"
                              :placeholder="
                                $t('fx_spot_forward_swap.trade_form.comission')
                              "
                              :disabled="true"
                              ref="commAmount"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                    </b-col>
                    <!-- comission CCY -->
                    <b-col cols="12" xl="3" lg="3" md="4" sm="12" 
                    >
                      <b-form-group
                        :label="
                          $t('fx_spot_forward_swap.trade_form.price_type')
                        "
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="
                            $t('fx_spot_forward_swap.trade_form.price_type')
                          "
                        >
                          <v-select
                            v-model="tradeForm.comissionCcy"
                            :options="optPriceTypes"
                            :clearable="false"
                            :placeholder="
                              $t('fx_spot_forward_swap.trade_form.price_type')
                            "
                            :reduce="(val) => val"
                            :state="errors.length > 0 ? false : null"
                            :disabled="true"
                            ref="commCcy"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <!-- cut off time -->
                    <b-col cols="12" xl="3" lg="3" md="4" sm="12">
                      <b-form-group
                        :label="
                          $t('fx_spot_forward_swap.trade_form.cut_off_time')
                        "
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="
                            $t('fx_spot_forward_swap.trade_form.cut_off_time')
                          "
                          :rules="tradeForm.entryType == 'Execution' ? 'required' : ''"
                        >
                          <v-select
                            v-model="tradeForm.cutOffTime"
                            :options="optCutOffTimes"
                            :clearable="false"
                            :placeholder="
                              $t('fx_spot_forward_swap.trade_form.cut_off_time')
                            "
                            :reduce="(val) => val"
                            :state="errors.length > 0 ? false : null"
                            :disabled="true"
                            ref="cutOffTime"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <!-- deliverable ccy-->
                    <!-- <b-col cols="12" xl="2" lg="2" md="4" sm="12" >
                      <b-form-group
                        :label="
                          $t('fx_spot_forward_swap.trade_form.deliverable_ccy')
                        "
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="
                            $t(
                              'fx_spot_forward_swap.trade_form.deliverable_ccy'
                            )
                          "
                          rules="required"
                        >
                          <v-select
                            v-model="tradeForm.deliverableCcy"
                            :options="optPriceTypes"
                            :clearable="false"
                            :placeholder="
                              $t(
                                'fx_spot_forward_swap.trade_form.deliverable_ccy'
                              )
                            "
                            :reduce="(val) => val"
                            :state="errors.length > 0 ? false : null"
                            :disabled="true"
                            ref="deliverableCcy"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col> -->
                  </b-row>
                  </b-card>

                  
              </b-col>


              </b-row>
                  <!-- /******************************* Swap Details***************************************************************** */ -->

              <b-row 
                      v-if="tradeForm.productType == 'SWAP'"
                      class="animate__animated animate__fadeIn">
                  
                    <b-card
                      :title="$t('fx_spot_forward_swap.titles.swap_details')"
                    >
                    <b-row class="flex align-items-center">
                        <!-- Swap Trade Date -->
                        <b-col cols="6" xl="2" lg="2" md="4" sm="6">
                          <b-form-group
                            :label="
                              $t(
                                'fx_spot_forward_swap.trade_form.swap_trade_date'
                              )
                            "
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="
                                $t(
                                  'fx_spot_forward_swap.trade_form.swap_trade_date'
                                )
                              "
                              :rules="tradeForm.entryType == 'Execution' && tradeForm.productType == 'SWAP' ? 'required' : ''"
                            >
                              <b-input-group
                                :class="
                                  tradeForm.swapTradeDateName == 'Sunday' ||
                                  tradeForm.swapTradeDateName == 'Saturday'
                                    ? 'swapTradeName'
                                    : ''
                                "
                                :append="tradeForm.swapTradeDateName"
                              >
                                <flat-pickr
                                  :placeholder="
                                    $t(
                                      'fx_spot_forward_swap.trade_form.swap_trade_date'
                                    )
                                  "
                                  v-model="tradeForm.swapTradeDate"
                                  onClose="testClose"
                                  class="form-control"
                                  :config="dateConfig"
                                  :disabled="true"
                                  ref="swapTradeDate"
                                />
                              </b-input-group>
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>

                        <!-- Maturity -->
                       <b-col cols="6" xl="2" lg="2" md="4" sm="6" >

                          <b-form-group
                            :label="
                              $t('fx_spot_forward_swap.trade_form.maturity')
                            "
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="
                                $t('fx_spot_forward_swap.trade_form.maturity')
                              "
                              :rules="tradeForm.entryType == 'Execution' && tradeForm.productType == 'SWAP' ? 'required|min_value:0' : ''"
                            >
                              <b-input-group append="Days">
                                <b-form-input
                                  id="maturity"
                                  v-model.number="tradeForm.maturity"
                                  :state="errors.length > 0 ? false : null"
                                  type="number"
                                  :placeholder="
                                    $t(
                                      'fx_spot_forward_swap.trade_form.maturity'
                                    )
                                  "
                                  autofocus
                                  :disabled="true"
                                  ref="swapMaturity"
                                />
                              </b-input-group>

                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>

                        <!-- Swap settle Date -->
                                             <b-col cols="6" xl="4" lg="4" md="4" sm="6" >


                          <b-form-group
                            :label="
                              $t(
                                'fx_spot_forward_swap.trade_form.swap_settle_date'
                              )
                            "
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="
                                $t(
                                  'fx_spot_forward_swap.trade_form.swap_settle_date'
                                )
                              "
                              :rules="tradeForm.entryType == 'Execution' && tradeForm.productType == 'SWAP' ? 'required' : ''"
                            >
                              <b-input-group
                                :class="
                                  tradeForm.swapSettleDateName == 'Sunday' ||
                                  tradeForm.swapSettleDateName == 'Saturday'
                                    ? 'swapSettleName'
                                    : ''
                                "
                                :append="tradeForm.swapSettleDateName"
                              >
                                <flat-pickr
                                  :placeholder="
                                    $t(
                                      'fx_spot_forward_swap.trade_form.swap_settle_date'
                                    )
                                  "
                                  v-model="tradeForm.swapSettleDate"
                                  onClose="testClose"
                                  class="form-control"
                                  :config="dateConfig"
                                  :disabled="true"
                                  ref="swapSettlementDate"
                                />
                              </b-input-group>
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <!-- Swap Points -->
                        <b-col cols="6" xl="2" lg="2" md="6" sm="6" >
                          <b-form-group
                            :label="
                              $t('fx_spot_forward_swap.trade_form.swap_points')
                            "
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="
                                $t(
                                  'fx_spot_forward_swap.trade_form.swap_points'
                                )
                              "
                              :rules="tradeForm.entryType == 'Execution' && tradeForm.productType == 'SWAP' ? 'required|min_value:0' : ''"
                            >
                              <b-input-group append="pips">
                                <cleave
                                  id="swap_points"
                                  :state="errors.length > 0 ? false : null"
                                  v-model="tradeForm.swapPoints"
                                  class="form-control"
                                  :raw="true"
                                  :options="cleave8DecimalOptions.number"
                                  :placeholder="
                                    $t(
                                      'fx_spot_forward_swap.trade_form.swap_points'
                                    )
                                  "
                                  :disabled="true"
                                  ref="swapForwardRate"
                                />
                              </b-input-group>

                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>

                        <!-- swap cpty rate -->
                        <b-col cols="6" xl="2" lg="2" md="6" sm="6" >

                          <b-form-group
                            :label="
                              $t(
                                'fx_spot_forward_swap.trade_form.swap_cpty_rate'
                              )
                            "
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="
                                $t(
                                  'fx_spot_forward_swap.trade_form.swap_cpty_rate'
                                )
                              "
                              :rules="tradeForm.entryType == 'Execution' && tradeForm.productType == 'SWAP' ? 'required|min_value:0' : ''"
                            >
                              <cleave
                                id="swap_cpty_rate"
                                :state="errors.length > 0 ? false : null"
                                v-model="tradeForm.swapCptyRate"
                                class="form-control"
                                :raw="true"
                                :options="cleave8DecimalOptions.number"
                                :placeholder="
                                  $t(
                                    'fx_spot_forward_swap.trade_form.swap_cpty_rate'
                                  )
                                "
                                :disabled="true"
                                ref="swapCptyRate"
                              />

                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>

                        <!-- swap calculate client rate radio buttons -->
                        <b-col cols="6" xl="2" lg="2" md="6" sm="6" class="mt-1 padleft-0">
                        <b-form-group>
                          <b-form-radio-group
                            v-model="tradeForm.swapCalcFromSpread"
                            :options="optCalcFromSpread"
                            name="radios-stacked2"
                            :disabled="true"
                            plain
                            stacked
                            class="fxDetailRadio"
                          />
                        </b-form-group>
                      </b-col>

                      <!-- <b-col cols="12" xl="12" lg="12" md="12" sm="12">
                      <h6 style="text-decoration:underline;text-align:center;">
                      {{ $t("fx_spot_forward_swap.trade_form.swap_sfc") }}</h6>
                    </b-col> -->


                    <!-- Swap SFC -->
                        <!-- swap cptyAmount1 -->
                        <b-col cols="6" xl="2" lg="2" md="4" sm="6">
                          <b-form-group
                            :label="
                              $t(
                                'fx_spot_forward_swap.trade_form.swap_cpty_amount1'
                              )
                            "
                            
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="
                                $t(
                                  'fx_spot_forward_swap.trade_form.swap_cpty_amount1'
                                )
                              "
                             :rules="tradeForm.entryType == 'Execution' && tradeForm.productType == 'SWAP' ? 'required|min_value:0' : ''"
                            >
                              <cleave
                                id="clientPrice"
                                :state="errors.length > 0 ? false : null"
                                v-model="tradeForm.swapCptyAmount1"
                                class="form-control"
                                :raw="true"
                                :options="cleaveOptions.number"
                                :placeholder="
                                  $t(
                                    'fx_spot_forward_swap.trade_form.swap_cpty_amount1'
                                  )
                                "
                                :disabled="true"
                                ref="swapCptyAmount1"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <!-- swapCptyCcy1 -->
                        <b-col cols="6" xl="1" lg="1" md="4" sm="6" "
                        >
                          <b-form-group
                            :label="$t('fx_spot_forward_swap.trade_form.ccy')"
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="$t('fx_spot_forward_swap.trade_form.ccy')"
                              :rules="tradeForm.entryType == 'Execution' && tradeForm.productType == 'SWAP' ? 'required' : ''"
                            >
                              <v-select
                                v-model="tradeForm.swapCptyCcy1"
                                :options="optPriceTypes"
                                :clearable="false"
                                :placeholder="
                                  $t('fx_spot_forward_swap.trade_form.ccy')
                                "
                                :reduce="(val) => val"
                                :state="errors.length > 0 ? false : null"
                                :disabled="true"
                                ref="swapCptyCcy1"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <!--swap  cptyBuySell 1 -->
                        <b-col cols="6" xl="1" lg="1" md="4" sm="6" 
                        >
                          <b-form-group
                            :label="
                              $t(
                                'fx_spot_forward_swap.trade_form.swap_cpty_buysell1'
                              )
                            "
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="
                                $t(
                                  'fx_spot_forward_swap.trade_form.swap_cpty_buysell1'
                                )
                              "
                              :rules="tradeForm.entryType == 'Execution' && tradeForm.productType == 'SWAP' ? 'required' : ''"
                            >
                              <v-select
                                v-model="tradeForm.swapCptyBuySell1"
                                :options="optcptyBuySells"
                                :clearable="false"
                                :placeholder="
                                  $t(
                                    'fx_spot_forward_swap.trade_form.swap_cpty_buysell1'
                                  )
                                "
                                :reduce="(val) => val"
                                :state="errors.length > 0 ? false : null"
                                :disabled="true"
                                ref="swapCptyBuySell1"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>

                      <!-- swap SFC 2 -->
                        <!-- swap cptyAmount 2 -->
                        <b-col cols="6" xl="2" lg="2" md="4" sm="6">
                          <b-form-group
                            :label="
                              $t(
                                'fx_spot_forward_swap.trade_form.swap_cpty_amount2'
                              )
                            "
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="
                                $t(
                                  'fx_spot_forward_swap.trade_form.swap_cpty_amount2'
                                )
                              "
                              :rules="tradeForm.entryType == 'Execution' && tradeForm.productType == 'SWAP' ? 'required|min_value:0' : ''"
                            >
                              <cleave
                                id="clientPrice"
                                :state="errors.length > 0 ? false : null"
                                v-model="tradeForm.swapCptyAmount2"
                                class="form-control"
                                :raw="true"
                                :options="cleaveOptions.number"
                                :placeholder="
                                  $t(
                                    'fx_spot_forward_swap.trade_form.swap_cpty_amount2'
                                  )
                                "
                                :disabled="true"
                                ref="swapCptyAmount2"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <!-- swap cptyPriceType 2 -->
                        <b-col cols="6" xl="1" lg="1" md="4" sm="6" 
                        >
                          <b-form-group
                            :label="$t('fx_spot_forward_swap.trade_form.ccy')"
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="$t('fx_spot_forward_swap.trade_form.ccy')"
                              :rules="tradeForm.entryType == 'Execution' && tradeForm.productType == 'SWAP' ? 'required' : ''"
                            >
                              <v-select
                                v-model="tradeForm.swapCptyCcyType2"
                                :options="optPriceTypes"
                                :clearable="false"
                                :placeholder="
                                  $t('fx_spot_forward_swap.trade_form.ccy')
                                "
                                :reduce="(val) => val"
                                :state="errors.length > 0 ? false : null"
                                :disabled="true"
                                ref="swapCptyCcy2"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <!-- swap cptyBuySell 2 -->
                        <b-col cols="6" xl="1" lg="1" md="4" sm="6" 
                        >
                          <b-form-group
                            :label="
                              $t(
                                'fx_spot_forward_swap.trade_form.swap_cpty_buysell2'
                              )
                            "
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="
                                $t(
                                  'fx_spot_forward_swap.trade_form.swap_cpty_buysell2'
                                )
                              "
                              :rules="tradeForm.entryType == 'Execution' && tradeForm.productType == 'SWAP' ? 'required' : ''"
                            >
                              <v-select
                                v-model="tradeForm.swapCptyBuySell2"
                                :options="optcptyBuySells"
                                :clearable="false"
                                :placeholder="
                                  $t(
                                    'fx_spot_forward_swap.trade_form.swap_cpty_buysell2'
                                  )
                                "
                                :reduce="(val) => val"
                                :state="errors.length > 0 ? false : null"
                                :disabled="true"
                                ref="swapCptyBuySell2"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                      

                        <!-- Swap Spread -->
                        <b-col cols="6" xl="2" lg="2" md="4" sm="6" >

                          <b-form-group
                            :label="
                              $t('fx_spot_forward_swap.trade_form.swap_spread')
                            "
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="
                                $t(
                                  'fx_spot_forward_swap.trade_form.swap_spread'
                                )
                              "
                              :rules="tradeForm.entryType == 'Execution' && tradeForm.productType == 'SWAP' ? 'required|min_value:0' : ''"
                            >
                              <b-input-group append="pips">
                                <cleave
                                  id="swap_spread"
                                  :state="errors.length > 0 ? false : null"
                                  v-model="tradeForm.swapSpread"
                                  class="form-control"
                                  :raw="true"
                                  :options="cleave8DecimalOptions.number"
                                  :placeholder="
                                    $t(
                                      'fx_spot_forward_swap.trade_form.swap_spread'
                                    )
                                  "
                                  :disabled="true"
                                  ref="swapSpread"
                                />
                              </b-input-group>

                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>

                        <!-- swap Client Rate -->
                        <b-col cols="6" xl="2" lg="2" md="4" sm="6" >

                          <b-form-group
                            :label="
                              $t(
                                'fx_spot_forward_swap.trade_form.swap_client_rate'
                              )
                            "
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="
                                $t(
                                  'fx_spot_forward_swap.trade_form.swap_client_rate'
                                )
                              "
                              :rules="tradeForm.entryType == 'Execution' && tradeForm.productType == 'SWAP' ? 'required|min_value:0' : ''"
                            >
                              <cleave
                                id="clientRate"
                                :state="errors.length > 0 ? false : null"
                                v-model="tradeForm.swapClientRate"
                                class="form-control"
                                :raw="true"
                                :options="cleave8DecimalOptions.number"
                                :placeholder="
                                  $t(
                                    'fx_spot_forward_swap.trade_form.swap_client_rate'
                                  )
                                "
                                :disabled="true"
                                ref="swapClientRate"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>

                      <!-- <b-col cols="12" xl="12" lg="12" md="12" sm="12">
                      <h6 style="text-decoration:underline;text-align:center;">
                      {{ $t("fx_spot_forward_swap.trade_form.swap_client") }}</h6>
                    </b-col> -->

                    <!-- swap Client -->
                        <!-- swap clientAmount1 -->
                        <b-col cols="6" xl="2" lg="2" md="4" sm="6">
                          <b-form-group
                            :label="
                              $t(
                                'fx_spot_forward_swap.trade_form.swap_client_amount1'
                              )
                            "
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="
                                $t(
                                  'fx_spot_forward_swap.trade_form.swap_client_amount1'
                                )
                              "
                              :rules="tradeForm.entryType == 'Execution' && tradeForm.productType == 'SWAP' ? 'required|min_value:0' : ''"
                            >
                              <cleave
                                id="clientPrice"
                                :state="errors.length > 0 ? false : null"
                                v-model="tradeForm.swapClientAmount1"
                                class="form-control"
                                :raw="true"
                                :options="cleaveOptions.number"
                                :placeholder="
                                  $t(
                                    'fx_spot_forward_swap.trade_form.swap_client_amount1'
                                  )
                                "
                                :disabled="true"
                                ref="swapClientAmount1"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <!-- swap clientPriceType 1 -->
                        <b-col cols="6" xl="1" lg="1" md="4" sm="6" 
                        >
                          <b-form-group
                            :label="$t('fx_spot_forward_swap.trade_form.ccy')"
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="$t('fx_spot_forward_swap.trade_form.ccy')"
                              :rules="tradeForm.entryType == 'Execution' && tradeForm.productType == 'SWAP' ? 'required' : ''"
                            >
                              <v-select
                                v-model="tradeForm.swapClientCcy1"
                                :options="optPriceTypes"
                                :clearable="false"
                                :placeholder="
                                  $t('fx_spot_forward_swap.trade_form.ccy')
                                "
                                :reduce="(val) => val"
                                :state="errors.length > 0 ? false : null"
                                :disabled="true"
                                ref="swapClientCcy1"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <!-- swap clientBuySell 1 -->
                        <b-col cols="6" xl="1" lg="1" md="4" sm="6" 
                        >
                          <b-form-group
                            :label="
                              $t(
                                'fx_spot_forward_swap.trade_form.swap_client_buysell1'
                              )
                            "
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="
                                $t(
                                  'fx_spot_forward_swap.trade_form.swap_client_buysell1'
                                )
                              "
                              :rules="tradeForm.entryType == 'Execution' && tradeForm.productType == 'SWAP' ? 'required' : ''"
                            >
                              <v-select
                                v-model="tradeForm.swapClientBuySell1"
                                :options="optclientBuySells"
                                :clearable="false"
                                :placeholder="
                                  $t(
                                    'fx_spot_forward_swap.trade_form.swap_client_buysell1'
                                  )
                                "
                                :reduce="(val) => val"
                                :state="errors.length > 0 ? false : null"
                                :disabled="true"
                                ref="swapClientBuySell1"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>

                      <!-- swap Client 2 -->
                        <!-- swap clientAmount 2 -->
                        <b-col cols="6" xl="2" lg="2" md="4" sm="6">
                          <b-form-group
                            :label="
                              $t(
                                'fx_spot_forward_swap.trade_form.swap_client_amount2'
                              )
                            "
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="
                                $t(
                                  'fx_spot_forward_swap.trade_form.swap_client_amount2'
                                )
                              "
                              :rules="tradeForm.entryType == 'Execution' && tradeForm.productType == 'SWAP' ? 'required|min_value:0' : ''"
                            >
                              <cleave
                                id="clientPrice"
                                :state="errors.length > 0 ? false : null"
                                v-model="tradeForm.swapClientAmount2"
                                class="form-control"
                                :raw="true"
                                :options="cleaveOptions.number"
                                :placeholder="
                                  $t(
                                    'fx_spot_forward_swap.trade_form.swap_client_amount2'
                                  )
                                "
                                :disabled="true"
                                ref="swapClientAmount2"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <!-- swap clientPriceType 2 -->
                        <b-col cols="6" xl="1" lg="1" md="4" sm="6" 
                        >
                          <b-form-group
                            :label="$t('fx_spot_forward_swap.trade_form.ccy')"
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="$t('fx_spot_forward_swap.trade_form.ccy')"
                              :rules="tradeForm.entryType == 'Execution' && tradeForm.productType == 'SWAP' ? 'required' : ''"
                            >
                              <v-select
                                v-model="tradeForm.swapClientCcy2"
                                :options="optPriceTypes"
                                :clearable="false"
                                :placeholder="
                                  $t('fx_spot_forward_swap.trade_form.ccy')
                                "
                                :reduce="(val) => val"
                                :state="errors.length > 0 ? false : null"
                                :disabled="true"
                                ref="swapClientCcy2"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <!-- swap clientBuySell 2 -->
                        <b-col cols="6" xl="1" lg="1" md="4" sm="6" 
                        >
                          <b-form-group
                            :label="
                              $t(
                                'fx_spot_forward_swap.trade_form.swap_client_buysell2'
                              )
                            "
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="
                                $t(
                                  'fx_spot_forward_swap.trade_form.swap_client_buysell2'
                                )
                              "
                              :rules="tradeForm.entryType == 'Execution' && tradeForm.productType == 'SWAP' ? 'required' : ''"
                            >
                              <v-select
                                v-model="tradeForm.swapClientBuySell2"
                                :options="optclientBuySells"
                                :clearable="false"
                                :placeholder="
                                  $t(
                                    'fx_spot_forward_swap.trade_form.swap_client_buysell2'
                                  )
                                "
                                :reduce="(val) => val"
                                :state="errors.length > 0 ? false : null"
                                :disabled="true"
                                ref="swapClientBuySell2"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>

                      <!--swap Spred amount -->
                        <!-- swap spread amount -->
                        <b-col cols="6" xl="2" lg="2" md="4" sm="6" >

                          <b-form-group
                            :label="
                              $t(
                                'fx_spot_forward_swap.trade_form.spread_amount'
                              )
                            "
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="
                                $t(
                                  'fx_spot_forward_swap.trade_form.spread_amount'
                                )
                              "
                             :rules="tradeForm.entryType == 'Execution' && tradeForm.productType == 'SWAP' ? 'required' : ''"
                            >
                              <cleave
                                id="clientPrice"
                                :state="errors.length > 0 ? false : null"
                                v-model="tradeForm.swapSpreadAmount"
                                class="form-control"
                                :raw="true"
                                :options="cleaveOptions.number"
                                :placeholder="
                                  $t(
                                    'fx_spot_forward_swap.trade_form.spread_amount'
                                  )
                                "
                                :disabled="true"
                                ref="swapSpreadAmount"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <!--swap  spread price types CCY -->
                        <b-col cols="6" xl="2" lg="2" md="4" sm="6" >

                          <b-form-group
                            :label="
                              $t('fx_spot_forward_swap.trade_form.price_type')
                            "
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="
                                $t('fx_spot_forward_swap.trade_form.price_type')
                              "
                              :rules="tradeForm.entryType == 'Execution' ? 'required' : ''"
                            >
                              <v-select
                                v-model="tradeForm.swapSpreadCcy"
                                :options="optPriceTypes"
                                :clearable="false"
                                :placeholder="
                                  $t(
                                    'fx_spot_forward_swap.trade_form.price_type'
                                  )
                                "
                                :reduce="(val) => val"
                                :state="errors.length > 0 ? false : null"
                                :disabled="true"
                                ref="swapSpreadCcy"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </b-card>
              </b-row>
                  <!-- /******************************* Settlement Details***************************************************************** */ -->

              <b-row class="justify-content-end">

                <b-col cols="12" xl="6" lg="6" md="12" sm="12" class="pl-0 pr-0">
                 <b-card
                      title="Settlement Details"
                    >
                      <b-row>
                        <!-- UTI  -->
                        <b-col cols="4" xl="4" lg="4" md="4" sm="4">
                          <b-form-group
                            :label="$t('fx_spot_forward_swap.trade_form.uti')"
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="$t('fx_spot_forward_swap.trade_form.uti')"
                            >
                              <b-form-input
                                id="UTI"
                                v-model="tradeForm.uti"
                                name="UTI"
                                :state="errors.length > 0 ? false : null"
                                :placeholder="
                                  $t('fx_spot_forward_swap.trade_form.uti')
                                "
                                autofocus
                                :disabled="true"
                                ref="tcUti"
                              />

                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <!-- Unique Link ID -->
                        <b-col cols="4" xl="4" lg="4" md="4" sm="4">
                          <b-form-group
                            :label="
                              $t(
                                'fx_spot_forward_swap.trade_form.unique_link_id'
                              )
                            "
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="
                                $t(
                                  'fx_spot_forward_swap.trade_form.unique_link_id'
                                )
                              "
                            >
                              <b-form-input
                                id="uniqueLinkID"
                                v-model.number="tradeForm.uniqueLinkID"
                                type="number"
                                name="uniqueLinkID"
                                :state="errors.length > 0 ? false : null"
                                :placeholder="
                                  $t(
                                    'fx_spot_forward_swap.trade_form.unique_link_id'
                                  )
                                "
                                autofocus
                                :disabled="true"
                                ref="uniqueLinkId"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <!-- Trade Ref -->
                        <b-col cols="4" xl="4" lg="4" md="4" sm="4">
                          <b-form-group
                          :label="
                              $t('fx_spot_forward_swap.trade_form.trade_ref')
                            ">
                            <validation-provider
                              #default="{ errors }"
                              :name="
                                $t('fx_spot_forward_swap.trade_form.trade_ref')
                              "
                            >
                              <b-form-input
                                id="tradeRef"
                                v-model="tradeForm.tradeRef"
                                name="tradeRef"
                                :state="errors.length > 0 ? false : null"
                                :placeholder="
                                  $t(
                                    'fx_spot_forward_swap.trade_form.trade_ref'
                                  )
                                "
                                autofocus
                                :disabled="true"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                      </b-row>

                    </b-card>
                </b-col>
                 
              </b-row>
            </b-form>
          </validation-observer>
        </b-container>
        <template #overlay v-if="tradeModalLoading">
          <div
            class="text-center d-flex justify-content-center align-items-start"
          >
            <b-icon icon="stopwatch" font-scale="3" animation="cylon"> </b-icon>
            <p id="cancel-label">
              <feather-icon icon="DownloadIcon" /><b> {{ $t('fx_spot_forward_swap.overlay_messages.verifying_message') }}</b>
            </p>
          </div>
        </template>
      </b-overlay>
      <!-- modal footer -->
      <template #modal-footer>
        <b-row class="d-flex align-items-center" style="flex: 1;">
          
          <b-col cols="6" md="6" xl="6" lg="6" sm="6" class=" pl-0 d-flex justify-content-start align-items-center">
            <!-- trade actions -->
            <b-button
              id="tradeAction-button"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-primary"
              size="sm"
              class="float-left mb-0"
              :disabled="actionsData == null || actionsData.length == 0"
            >
              <feather-icon
                icon="EyeIcon"
                size="14"
                style="margin-right: 0.2rem;"
              />
  
              <span class="text-nowrap">{{ $t('fx_spot_forward_swap.buttons.trade_actions') }}</span>
            </b-button>
              <b-popover
                target="tradeAction-button"
                placement="top"
                triggers="focus"
                id="actions-popover"
              >
                <template #title>
                  {{ $t('fx_spot_forward_swap.titles.trade_actions') }}
                </template>
                <b-table-simple hover small caption-top responsive bordered>
                  <b-tbody class="compact_form_actions-table">
                    <b-tr v-for="(action, index) in actionsData" :key="index">
                    <td style="width: 20%;">
                      {{ action.type }}
                    </td>
                    <td style="width: 20%;">
                      {{ action.user }}
                    </td>
                    <td style="width: 30%;">
                      {{ action.date }}
                    </td>
                    <td style="width: 30%;">
                      {{ action.reason }}
                    </td>
                  </b-tr>
                  </b-tbody>
                </b-table-simple>
              </b-popover>
  
              <!-- changed input message -->
            <p class="d-inline ml-1 mb-0" v-if="changedInputs.length > 0">
            <b-icon
              class="ml-1"
              title=""
              icon="record-fill"
              style="color:orange!important"
              data-toggle="tooltip"
              data-placement="top"
              font-scale="0.5"
            ></b-icon
            ><b style="margin-left:0.3rem;color:#6e6b7b;font-size: 0.7rem;">{{
              $t('equity_bond.modals.inputs_written_red_inputs')
            }}</b>
          </p>
  
          </b-col>
  
            <!-- trade ok operation buttons -->
          <b-col cols="6" md="6" xl="6" lg="6" sm="6" class=" pr-0 d-flex justify-content-end align-items-center">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="secondary"
              size="sm"
              class="mr-1"
              @click="resetTradeModal"
            >
              {{$t('fx_spot_forward_swap.modals.modal_close')}}
            </b-button>
  
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              size="sm"
              @click="tradeOkOperation"
            >
            {{ $t('fx_spot_forward_swap.modals.verify_trade_modal_ok') }}
            </b-button>
          
          </b-col>
            
          </b-row>
      </template>
      {{/****END*** verify for trades modal*/}}
    </b-modal>
  </div>
</template>

<script>
import {
  BOverlay,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormRating,
  VBModal,
  BTabs,
  BTab,
  BCardText,
  BIcon,
  BPopover,
} from "bootstrap-vue";
import store from "@/store";
import { onUnmounted } from "@vue/composition-api";
import fXSpotForwardSwapStoreModule from "./FXSpotForwardSwapStoreModule";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, alphaNum, email } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import axios from "axios";
import axiosIns from "@/libs/axios";
import router from "@/router";
import moment from "moment";
import Cleave from "vue-cleave-component";
import "animate.css";
import _ from "lodash";
import { mapState, mapGetters, mapActions } from "vuex";

export default {
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BTabs,
    BTab,
    BCardText,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormRating,
    BIcon,
    BPopover,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    ToastificationContent,
    flatPickr,
    Cleave,
  },

  directives: {
    Ripple,
    "b-modal": VBModal,
  },

  setup() {
      const FXSPOTFORWARDSWAP_APP_STORE_MODULE_NAME = "fxSFS";
      // Register module
      if (!store.hasModule(FXSPOTFORWARDSWAP_APP_STORE_MODULE_NAME))
        store.registerModule(
          FXSPOTFORWARDSWAP_APP_STORE_MODULE_NAME,
          fXSpotForwardSwapStoreModule
        );
  
      // UnRegister on leave
      onUnmounted(() => {
        if (store.hasModule(FXSPOTFORWARDSWAP_APP_STORE_MODULE_NAME))
          store.unregisterModule(FXSPOTFORWARDSWAP_APP_STORE_MODULE_NAME);
      });
    },

  mounted() {},

  props: {
    
    searchedCounterparties: {
      type: Array,
        default: function() {
        return [];
      }
    },
    searchedClients: {
      type: Array,
        default: function() {
        return [];
      }
    },
    optCostCenters: {
      type: Array,
        default: function() {
        return [];
      }
    },
    optClients: {
      type: Array,
        default: function() {
        return [];
      }
    },
    optCounterParties: {
      type: Array,
        default: function() {
        return [];
      }
    }
},
  data() {
    return {
      verifySelectedTradeModal: false,
      tradeModalLoading: false,
      loadingClient: false,
      loadingCounterparty: false,
      timerSecuritydesc: null,
      actionsData: null,
      checkVerify: false,
      orderDurationDataDisabled:true,
      orderDurationTimeDisabled: true,
      orderDurationInputsVisible: true,
      weekday: [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ],
      optEntryTypes: ["Order", "Execution"],
      optbuySells: ["Buy", "Sell"],
      optOrderTypes: ["Limit", "Market", "Stop Limit", "Stop", "Trailing Stop"],
      optTimeInForces: ["DAY", "GTC", "GTD", "FOK", "FAK"],
      optproductTypes: ["SPOT", "FORWARD", "SWAP"],
      optSettlementTypes: ["PHYSICAL", "CASH"],
      optCalcFromSpread: [
        {
          text: "Calculate Client Rate From Spread",
          value: "Calculate Client Rate From Spread",
        },
        {
          text: "Calculate Spread From Client Rate",
          value: "Calculate Spread From Client Rate",
        },
      ],

      // optClients: [],
      optPriceTypes: [],
      optCounterPartyTraders: [],
      optCutOffTimes: ["10:00 NY", "12:00 LN"],
      optcptyBuySells: [],
      optclientBuySells: [],
      optScfUsers: [],
      optActingCapacities: ["PRINCIPAL", "AGENT","MATCHED PRINCIPAL"],
      optOrderTakenVias: [],
      optOrderGivenThroughs: [],
      fetchedTradeData: {},
      oldTradeFormData: {},
      changedInputs: [],
      tradeForm: {
        costCenter : null,
        orderDurationDate: null,
        orderDurationTime: null,
        entryType: null,
        buySell: null,
        orderType: null,
        timeInForce: null,
        orderDate: null,
        orderTime: null,
        cptyOrderDate: null,
        cptyOrderTime: null,
        enableConnectedOCOOrders: false,
        stopPrice: null,
        trailingAmount: null,
        takeProfitLimit: null,
        stopLossStop: null,
        stopLossLimit: null,
        client: null,
        clientAccount: null,
        counterparty: null,
        cptyAccount: null,
        productType: null,
        settlementType: null,
        counterpartyRate: null,
        cptyCalculateFromRate1: true,
        clientRate: null,
        clientCalculateFromRate2: true,
        cptyAmount1: null,
        cptyAmount2: null,
        cptyCcy1: null,
        cptyCcy2: null,
        cptyBuySell1: null, //sfcBuySell1
        cptyBuySell2: null, //sfcBuySell2
        calcFromSpread: "Calculate Spread From Client Rate",
        spread: null,
        clientAmount1: null,
        clientAmount2: null,
        clientCcy1: null,
        clientCcy2: null,
        clientBuySell1: null,
        clientBuySell2: null,
        spotRef: null,
        spreadAmount: null,
        spreadCcy: null,
        comissionAmount: null,
        comissionCcy: null,
        cutOffTime: null,
        deliverableCcy: null,
        tradeDate: null,
        tradeDateName: null,
        settlementDate: null,
        settlementDateName: null,
        executionTime: null,
        counterpartyTrader: null,
        scfUser: null,
        orderTakenVia: null,
        orderGivenThrough: null,
        actingCapaticy: null,
        note: null,
        swapTradeDate: null,
        swapTradeDateName: null,
        maturity: null,
        swapSettleDate: null,
        swapSettleDateName: null,
        swapPoints: null,
        swapCptyRate: null,
        swapCptyAmount1: null,
        swapCptyCcy1: null,
        swapCptyBuySell1: null,
        swapCptyAmount2: null,
        swapCptyCcyType2: null,
        swapCptyBuySell2: null,
        swapSpread: null,
        swapCalcFromSpread: "Calculate Client Rate From Spread",
        swapClientRate: null,
        swapClientAmount1: null,
        swapClientCcy1: null,
        swapClientBuySell1: null,
        swapClientAmount2: null,
        swapClientCcy2: null,
        swapClientBuySell2: null,
        swapSpreadAmount: null,
        swapSpreadCcy: null,
        uti: null,
        uniqueLinkID: null,
        tradeRef: null,
        entryType2: null,
        tradeStart: null,
        tradeEnd: null,
        settleStart: null,
        settleEnd: null,
      },
      changedValuesForm:{
        costCenter:null,
        orderDurationDate:null,
        orderDurationTime:null,
        entryType: null,
        buySell: null,
        orderType: null,
        timeinForce:null,
        orderDate:null,
        orderTime:null,
        counterpartyOrderDate:null,
        counterpartyOrderTime:null,
        enableOCO: null,
        stopPrice: null,
        trailingAmount:null,
        takeProfitLimit:null,
        stopLoss: null,
        stopLossLimit:null,
        cptyTakeProfitLimit: null,
        cptyStopLoss: null,
        cptyStopLossLimit: null,
        client: null,
        clientAccount: null,
        cpty: null,
        cptyAcc: null,
        productType: null,
        settlementType: null,
        cptyRate: null,
        clientRate: null,
        cptyAmount1: null,
        cptyAmount2: null,
        ccy1: null,
        ccy2: null,
        cptyBuySell1: null, //sfcBuySell1
        cptyBuySell2: null, //sfcBuySell2
        spread: null,
        clientAmount1: null,
        clientAmount2: null,
        clientCcy1: null,
        clientCcy2: null,
        clientBuySell1: null,
        clientBuySell2: null,
        spotRef: null,
        spreadAmount: null,
        spreadCcy: null,
        commAmount: null,
        commCcy: null,
        cutOffTime: null,
        deliverableCcy: null,
        tradeDate: null,
        settlDate: null,
        execTime: null,
        cptyTrader: null,
        tcUser: null,
        takenVia: null,
        givenThrg: null,
        actingCap: null,
        traderNote: null,
        swapTradeDate: null,
        swapMaturity: null,
        swapSettlementDate: null,
        swapForwardRate: null,
        swapCptyRate: null,
        swapCptyAmount1: null,
        swapCptyCcy1: null,
        swapCptyBuySell1: null,
        swapCptyAmount2: null,
        swapCptyCcy2: null,
        swapCptyBuySell2: null,
        swapSpread: null,
        swapClientRate: null,
        swapClientAmount1: null,
        swapClientCcy1: null,
        swapClientBuySell1: null,
        swapClientAmount2: null,
        swapClientCcy2: null,
        swapClientBuySell2: null,
        swapSpreadAmount: null,
        swapSpreadCcy: null,
        tcUti: null,
        uniqueLinkId: null,
      },
      timeConfig: {
        enableTime: true,
        noCalendar: true,
        dateFormat: "H:i:s",
        time_24hr: true,
      },
      dateConfig: {
        allowInput:true,
        dateFormat: "d-m-Y",
      },
      orderDateConfig: {
        allowInput:true,
        dateFormat: "d-m-Y",
        // onChange: function(selectedDates, dateStr, instance) {
        //   console.log(instance)
        // }
      },
      cleaveOptions: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: "thousand",
          numeralDecimalScale: 2,
          numeralDecimalMarkAlternativeInput: '.',
          completeDecimalsOnBlur: true
        },
        time: {
          time: true,
          timePattern: ["h", "m", "s"],
        },
      },
      cleave4DecimalOptions:{
        number: {
          numeral: true,
          numeralThousandsGroupStyle: "thousand",
          numeralDecimalScale: 8,
          numeralDecimalMarkAlternativeInput: '.',
          completeDecimalsOnBlur: true

        },
        },
        cleave8DecimalOptions:{
        number: {
          numeral: true,
          numeralThousandsGroupStyle: "thousand",
          numeralDecimalScale: 8,
          numeralDecimalMarkAlternativeInput: '.',
          completeDecimalsOnBlur: true

        },
        },
    };
  },
  computed: {
    user: {
      get() {
        return this.$store.getters["authUser"];
      },
      set(newValue) {
        return this.$store.dispatch("setUser", newValue);
      },
    },
  },
  methods: {
    ...mapActions(["saveLastSearchFxForwardSwap"]),

    tradeOkOperation(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.$refs.tradeValidation.validate().then((success) => {
        if (success) {
          this.$checkAccessRight("FxExecuted", "Verify").then((res) => {
            if (res.data.valid) {
              if (this.tradeForm.uti != null) {
                  this.tradeModalLoading = true
                store
                  .dispatch("fxSFS/tradeFxVerifyOperation", {
                    auth: this.user,
                    trade: this.tradeForm,
                  })
                  .then((res) => {
                    if (res.data.info == "Pass") {
                        this.verifiedTradeToastMessage()
                        this.resetTradeModal()
                        this.$emit('verified', true);
                        this.tradeModalLoading = false
                    } else if(res.data.info == 'amended_trade'){
                      this.amendedTradeVerifymessage()
                    } else {
                      if(res.data.info == 'verified'){
                        this.checkTradeVerifyOperationLangMessage(res.data.user, res.data.date);
                      } else {
                        this.checkTradeVerifyOperationMessage(res.data.message);
                      }
                      this.tradeModalLoading = false
                    }
                  })
                  .catch((error) => {
                    this.tradeModalLoading = false
                    console.log(error);
                  });
              } else {
                  this.notFoundUTIValue(10000)
              }
            } else {
              this.notAuthToastMessage();
            }
          });
        } else {
          let notEnteredDatas = "";

          let checkRequiredDatas = _.map(
            this.$refs.tradeValidation.errors,
            (value, key) => ({ key, value })
          );

          if (checkRequiredDatas.length > 0) {
            let first = true;
            checkRequiredDatas.forEach((r) => {
              if (r.value.length > 0) {
                if (first) {
                  notEnteredDatas += '\n' + r.key;
                  first = false;
                } else {
                  notEnteredDatas += ", \n" + r.key;
                  first = false;
                }
              }
            });
            this.checkRequiredInputsMessage(notEnteredDatas, 20000);
          }
        }
      });
    },
    changeTimeInForceType() {
      if (this.tradeForm.timeInForce == "DAY") {
        this.tradeForm.orderDurationDate = this.tradeForm.orderDate;
        this.tradeForm.orderDurationTime = moment()
          .endOf("day")
          .format("HH:mm:ss")
          .toString();
        this.orderDurationDataDisabled = true;
        this.orderDurationTimeDisabled = true;
        this.orderDurationInputsVisible = true;
      } else if (this.tradeForm.timeInForce == "GTC") {
        this.orderDurationInputsVisible = false;
        this.tradeForm.orderDurationDate = null;
        this.tradeForm.orderDurationTime = null;
      } else if (this.tradeForm.timeInForce == "GTD") {
        this.tradeForm.orderDurationDate = this.tradeForm.orderDate;
        this.tradeForm.orderDurationTime = moment()
          .endOf("day")
          .format("HH:mm:ss")
          .toString();
        this.orderDurationDataDisabled = false;
        this.orderDurationTimeDisabled = false;
        this.orderDurationInputsVisible = true;
      } else if (this.tradeForm.timeInForce == "FOK") {
        this.orderDurationInputsVisible = false;
        this.tradeForm.orderDurationDate = null;
        this.tradeForm.orderDurationTime = null;
      } else if (this.tradeForm.timeInForce == "FAK") {
        this.orderDurationInputsVisible = false;
        this.tradeForm.orderDurationDate = null;
        this.tradeForm.orderDurationTime = null;
      }
    },
    arrangeCurrencies() {
      this.tradeForm.clientCcy1 = this.tradeForm.cptyCcy1;
      this.tradeForm.clientCcy2 = this.tradeForm.cptyCcy2;
      this.tradeForm.swapCptyCcy1 = this.tradeForm.cptyCcy1;
      this.tradeForm.swapCptyCcy2 = this.tradeForm.cptyCcy2;
      this.tradeForm.swapClientCcy1 = this.tradeForm.cptyCcy1;
      this.tradeForm.swapClientCcy2 = this.tradeForm.cptyCcy2;
    },
    arrangeBuySell() {
      if (this.tradeForm.buySell == "Buy") {

   
        this.tradeForm.clientBuySell1 = "Buys";
        this.tradeForm.clientBuySell2 = "Sells";
        this.tradeForm.swapClientBuySell1 = "Buys";
        this.tradeForm.swapClientBuySell2 = "Sells";

        this.tradeForm.cptyBuySell1 = "Buys";//sfcBuySell1
        this.tradeForm.cptyBuySell2 = "Sells";//sfcBuySell2
        this.tradeForm.swapCptyBuySell1 = "Sells";
        this.tradeForm.swapCptyBuySell2 = "Buys";

        
       
      } else if (this.tradeForm.buySell == "Sell") {

      
        this.tradeForm.clientBuySell1 = "Sells";
        this.tradeForm.clientBuySell2 = "Buys";
        this.tradeForm.swapClientBuySell1 = "Sells";
        this.tradeForm.swapClientBuySell2 = "Buys";
        this.tradeForm.cptyBuySell1 = "Sells"; //sfcBuySell1
        this.tradeForm.cptyBuySell2 = "Buys"; //sfcBuySell2

        this.tradeForm.swapCptyBuySell1 = "Buys";
        this.tradeForm.swapCptyBuySell2 = "Sells";

      
      }
    },
    resetTradeModal() {
      this.verifySelectedTradeModal = false;
      this.fetchedTradeData = {};
      this.actionsData = null;
      this.changedInputs = [];
    },
    databaseErrorMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: this.$t('fx_spot_forward_swap.toast_messages.database_connection_error'),
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    },
    amendedTradeVerifymessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: this.$t('fx_spot_forward_swap.toast_messages.amended_trade_not_verify'),
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    checkTradeVerifyOperationMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    checkTradeVerifyOperationLangMessage(user, date) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: this.$t("backend_language_message.verify_trade1") + ' ' + user + ' ' + this.$t("backend_language_message.verify_trade_on") + ' ' + date,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    checkRequiredInputsMessage(message, timeout) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Warning",
            text: this.$t('fx_spot_forward_swap.messages.required_fill_inputs') + ` ${message}`,
            icon: "AlertTriangleIcon",
            variant: "warning",
          },
        },
        {
          timeout,
        }
      );
    },
    notAuthToastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Not Authorized",
          text: this.$t('fx_spot_forward_swap.general_messages.not_authorized_message'),
          icon: "UserXIcon",
          variant: "warning",
        },
      });
    },
    notFoundUTIValue(timeout) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Not Found UTI",
          text: this.$t('fx_spot_forward_swap.messages.not_found_uti'),
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      },{
          timeout,
        });
    },
    errorMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    wrongToastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: val,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    verifiedTradeToastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Successfully",
          text: this.$t('fx_spot_forward_swap.toast_messages.trade_verified_success'),
          icon: "ThumbsUpIcon",
          variant: "success",
        },
      });
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    clearDate() {
      this.newHoliday.date = null;
    },
    DMYdateFormat(val) {
      if (val != undefined) {
        return moment(String(val), "DD-MM-YYYY").format("DD-MM-YYYY");
      }
    },
    MDYdateFormat(val) {
      if (val != undefined) {
        return moment(String(val), "DD-MM-YYYY").format("MM-DD-YYYY");
      }
    },
    YMDdateFormat(val) {
      if (val != undefined) {
        return moment(String(val), "DD-MM-YYYY").format("YYYY-MM-DD");
      }
    },
    dateFormat(val) {
      if (val != undefined) {
        return moment(String(val)).format("DD-MM-YYYY");
      }
    },
    timeFormat(val) {
      if (val != undefined) {
        return moment(String(val), "HH:mm:ss")._i;
      }
    },
    HmstimeFormat(val) {
      if (val != undefined) {
        return moment(String(val), "HH:mm:ss")._i;
      }
    },
    dateFormatWithTime(val) {
      if (val != undefined) {
        return moment(String(val), "DD-MM-YYYY HH:mm:ss").format(
          "DD-MM-YYYY HH:mm:ss"
        );
      }
    },
    formatPrice(value, exp) {
      if (typeof exp === "undefined" || +exp === 0) return Math.round(value);

      value = +value;
      exp = +exp;

      if (isNaN(value) || !(typeof exp === "number" && exp % 1 === 0))
        return NaN;

      // Shift
      value = value.toString().split("e");
      value = Math.round(
        +(value[0] + "e" + (value[1] ? +value[1] + exp : exp))
      );

      // Shift back
      value = value.toString().split("e");
      return +(value[0] + "e" + (value[1] ? +value[1] - exp : -exp));
    },

    roundToTwo(val) {
      if (val != undefined && val > 0) {
        return Math.round(val * 100) / 100;
        // return +(Math.round(val + "e+2") + "e-2");
      }
    },
    arrangeInputFormats() {
      if (this.tradeForm.counterpartyRate) {
        //this.tradeForm.counterpartyRate = this.formatPrice(this.tradeForm.counterpartyRate, 4);
        this.tradeForm.counterpartyRate = Number.parseFloat(
          this.tradeForm.counterpartyRate
        ).toFixed(8);
      }
      if (this.tradeForm.spotRef) {
        // this.tradeForm.spotRef = this.formatPrice(this.tradeForm.spotRef, 4);
        this.tradeForm.spotRef = Number.parseFloat(
          this.tradeForm.spotRef
        ).toFixed(8);
      }
      if (this.tradeForm.clientRate) {
        // this.tradeForm.clientRate = this.formatPrice(this.tradeForm.clientRate, 4);
        this.tradeForm.clientRate = Number.parseFloat(
          this.tradeForm.clientRate
        ).toFixed(8);
      }
      if (this.tradeForm.cptyAmount1) {
        this.tradeForm.cptyAmount1 = Number.parseFloat(
          this.tradeForm.cptyAmount1
        ).toFixed(2);
      }
      if (this.tradeForm.cptyAmount2) {
        this.tradeForm.cptyAmount2 = Number.parseFloat(
          this.tradeForm.cptyAmount2
        ).toFixed(2);
      }
      if (this.tradeForm.spread) {
        this.tradeForm.spread = Number.parseFloat(
          this.tradeForm.spread
        ).toFixed(8);
      }
      if (this.tradeForm.spreadAmount) {
        this.tradeForm.spreadAmount = Number.parseFloat(
          this.tradeForm.spreadAmount
        ).toFixed(2);
      }
      if (this.tradeForm.comissionAmount) {
        this.tradeForm.comissionAmount = Number.parseFloat(
          this.tradeForm.comissionAmount
        ).toFixed(2);
      }
      if (this.tradeForm.clientAmount1) {
        this.tradeForm.clientAmount1 = Number.parseFloat(
          this.tradeForm.clientAmount1
        ).toFixed(2);
      }
      if (this.tradeForm.clientAmount2) {
        this.tradeForm.clientAmount2 = Number.parseFloat(
          this.tradeForm.clientAmount2
        ).toFixed(2);
      }

      if (this.tradeForm.stopPrice) {
        this.tradeForm.stopPrice = Number.parseFloat(
          this.tradeForm.stopPrice
        ).toFixed(2);
      }
      if (this.tradeForm.trailingAmount) {
        this.tradeForm.trailingAmount = Number.parseFloat(
          this.tradeForm.trailingAmount
        ).toFixed(2);
      }
      if (this.tradeForm.takeProfitLimit) {
        this.tradeForm.takeProfitLimit = Number.parseFloat(
          this.tradeForm.takeProfitLimit
        ).toFixed(8);
      }
      if (this.tradeForm.stopLossLimit) {
        this.tradeForm.stopLossLimit = Number.parseFloat(
          this.tradeForm.stopLossLimit
        ).toFixed(8);
      }
      if (this.tradeForm.stopLossStop) {
        this.tradeForm.stopLossStop = Number.parseFloat(
          this.tradeForm.stopLossStop
        ).toFixed(8);
      }
      if (this.tradeForm.cptyTakeProfitLimit) {
        this.tradeForm.cptyTakeProfitLimit = Number.parseFloat(
          this.tradeForm.cptyTakeProfitLimit
        ).toFixed(8);
      }
      if (this.tradeForm.cptyStopLossLimit) {
        this.tradeForm.cptyStopLossLimit = Number.parseFloat(
          this.tradeForm.cptyStopLossLimit
        ).toFixed(8);
      }
      if (this.tradeForm.cptyStopLossStop) {
        this.tradeForm.cptyStopLossStop = Number.parseFloat(
          this.tradeForm.cptyStopLossStop
        ).toFixed(8);
      }
      if (this.tradeForm.swapClientAmount1) {
        this.tradeForm.swapClientAmount1 = Number.parseFloat(
          this.tradeForm.swapClientAmount1
        ).toFixed(2);
      }
      if (this.tradeForm.swapClientAmount2) {
        this.tradeForm.swapClientAmount2 = Number.parseFloat(
          this.tradeForm.swapClientAmount2
        ).toFixed(2);
      }
      if (this.tradeForm.swapSpreadAmount) {
        this.tradeForm.swapSpreadAmount = Number.parseFloat(
          this.tradeForm.swapSpreadAmount
        ).toFixed(2);
      }
      if (this.tradeForm.swapSpread) {
        this.tradeForm.swapSpread = Number.parseFloat(
          this.tradeForm.swapSpread
        ).toFixed(8);
      }
      if (this.tradeForm.swapClientRate) {
        this.tradeForm.swapClientRate = Number.parseFloat(
          this.tradeForm.swapClientRate
        ).toFixed(8);
      }
      if (this.tradeForm.swapCptyRate) {
        this.tradeForm.swapCptyRate = Number.parseFloat(
          this.tradeForm.swapCptyRate
        ).toFixed(8);
      }
      if (this.tradeForm.swapCptyAmount1) {
        this.tradeForm.swapCptyAmount1 = Number.parseFloat(
          this.tradeForm.swapCptyAmount1
        ).toFixed(2);
      }
      if (this.tradeForm.swapCptyAmount2) {
        this.tradeForm.swapCptyAmount2 = Number.parseFloat(
          this.tradeForm.swapCptyAmount2
        ).toFixed(2);
      }
      if (this.tradeForm.swapPoints) {
        this.tradeForm.swapPoints = Number.parseFloat(
          this.tradeForm.swapPoints
        ).toFixed(8);
      }
    },
  },
  watch: {
    verifySelectedTradeModal: {
      handler: function(val, before) {
        if (val) {

          //for set changes data to style color red
          setTimeout(() => {
            if (this.changedInputs.length > 0) {
              // console.log(this.changedInputs)
              this.changedInputs.forEach((i) => {
                if (this.changedValuesForm.hasOwnProperty(_.camelCase(i.key))) {
                  let refKey = _.camelCase(i.key);
                  
                  if (this.$refs[refKey]) {
                    if(this.$refs[refKey].$el.classList.length > 0 || this.$refs[refKey].$el.classList.indexOf('v-select') == 1){
                      this.$refs[refKey].$el.classList.add('change_selectbox_style') // for v-select box
                      
                    }

                    this.$refs[refKey].$el.style = "color:orange";
                  }
                }
              });
            }
          }, 500);

        }
      },
    },
    fetchedTradeData: {
      handler: function(val, before) {
        if (val.ID != 0) {

          this.tradeForm = {
             costCenter:val.CostCenter,
            orderDurationDate:
              val.OrderDurationDate != null
                ? this.dateFormat(val.OrderDurationDate)
                : null,
            orderDurationTime:
              val.OrderDurationTime != null
                ? this.HmstimeFormat(val.OrderDurationTime)
                : null,
            entryType: val.EntryType ?? null,
            buySell: val.BuySell ?? null,
            orderType: val.OrderType ?? null,
            timeInForce: val.TimeinForce ?? null,
            orderDate:
              val.OrderDate != null ? this.dateFormat(val.OrderDate) : null,
            orderTime:
              val.OrderTime != null ? this.HmstimeFormat(val.OrderTime) : null,
            cptyOrderDate:
              val.CounterpartyOrderDate != null
                ? this.dateFormat(val.CounterpartyOrderDate)
                : null,
            cptyOrderTime:
              val.CounterpartyOrderTime != null
                ? this.HmstimeFormat(val.CounterpartyOrderTime)
                : null,
            enableConnectedOCOOrders: val.EnableOCO == "true" ? true : false,
            stopPrice: val.StopPrice != null ? parseFloat(val.StopPrice) : null,
            trailingAmount:
              val.TrailingAmount != null ? parseFloat(val.TrailingAmount) : null,
            takeProfitLimit:
              val.TakeProfitLimit != null
                ? parseFloat(val.TakeProfitLimit)
                : null,
            stopLossStop: val.StopLoss != null ? parseFloat(val.StopLoss) : null,
            stopLossLimit:
              val.StopLossLimit != null ? parseFloat(val.StopLossLimit) : null,
              cptyTakeProfitLimit:
              val.CptyTakeProfitLimit != null
                ? parseFloat(val.CptyTakeProfitLimit)
                : null,
            cptyStopLossStop:
              val.CptyStopLoss != null ? parseFloat(val.CptyStopLoss) : null,
            cptyStopLossLimit:
              val.CptyStopLossLimit != null
                ? parseFloat(val.CptyStopLossLimit)
                : null,
            client: val.Client ?? null,
            clientAccount: val.ClientAcc != null ? val.ClientAcc : null,
            counterparty: val.Cpty ?? null,
            cptyAccount: val.CptyAcc ?? null,
            productType: val.ProductType ?? null,
            settlementType: val.SettlementType ?? null,
            counterpartyRate:
              val.CptyRate != null ? parseFloat(val.CptyRate) : null,

            clientRate:
              val.ClientRate != null ? parseFloat(val.ClientRate) : null,

            cptyAmount1:
              val.CptyAmount1 != null ? parseFloat(val.CptyAmount1) : null,

            cptyAmount2:
              val.CptyAmount2 != null ? parseFloat(val.CptyAmount2) : null,

            cptyCcy1: val.Ccy1 != null ? val.Ccy1 : null,
            cptyCcy2: val.Ccy2 != null ? val.Ccy2 : null,
            cptyBuySell1: val.CptyBuySell1 == "Buys" ? "Buys" : "Sells",
            cptyBuySell2: val.CptyBuySell1 == "Buys" ? "Sells" : "Buys",
            spread: val.Spread != null ? parseFloat(val.Spread) : null,
            clientAmount1:
              val.ClientAmount1 != null ? parseFloat(val.ClientAmount1) : null,
            clientAmount2:
              val.ClientAmount2 != null ? parseFloat(val.ClientAmount2) : null,
            clientCcy1: val.Ccy1 != null ? val.Ccy1 : null,
            clientCcy2: val.Ccy2 != null ? val.Ccy2 : null,
            clientBuySell1: val.CptyBuySell1 == "Buys" ? "Buys" : "Sells",
            clientBuySell2: val.CptyBuySell1 == "Buys" ? "Sells" : "Buys",
            spotRef: val.SpotRef != null ? parseFloat(val.SpotRef) : null,
            spreadAmount:
              val.SpreadAmount != null ? parseFloat(val.SpreadAmount) : null,
            spreadCcy: val.SpreadCcy != null ? val.SpreadCcy : null,
            comissionAmount:
              val.CommAmount != null ? parseFloat(val.CommAmount) : null,
            comissionCcy: val.CommCcy != null ? val.CommCcy : null,
            cutOffTime: val.CutOffTime != null ? val.CutOffTime : null,
            deliverableCcy:
              val.DeliverableCcy != null ? val.DeliverableCcy : null,
            tradeDate:
              val.TradeDate != null ? this.dateFormat(val.TradeDate) : null,
            settlementDate:
              val.SettlDate != null ? this.dateFormat(val.SettlDate) : null,
            executionTime:
              val.ExecTime != null ? this.HmstimeFormat(val.ExecTime) : null,
            counterpartyTrader: val.CptyTrader != null ? val.CptyTrader : null,
            scfUser: val.TcUser != null ? val.TcUser : null,
            orderTakenVia: val.TakenVia != null ? val.TakenVia : null,
            orderGivenThrough: val.GivenThrg != null ? val.GivenThrg : null,
            actingCapaticy: val.ActingCap != null ? val.ActingCap : null,
            note: val.TraderNote != null ? val.TraderNote : null,
            swapTradeDate:
              val.SwapTradeDate
                ? this.dateFormat(val.SwapTradeDate)
                : null,
            maturity: val.SwapMaturity != null ? val.SwapMaturity : null,
            swapSettleDate:
              val.SwapSettlementDate != null
                ? this.dateFormat(val.SwapSettlementDate)
                : null,
            swapPoints:
              val.SwapForwardRate != null
                ? parseFloat(val.SwapForwardRate)
                : null,
            swapCptyRate:
              val.SwapCptyRate != null ? parseFloat(val.SwapCptyRate) : null,
            swapCptyAmount1:
              val.SwapCptyAmount1 != null
                ? parseFloat(val.SwapCptyAmount1)
                : null,
            swapCptyCcy1: val.SwapCptyCcy1 != null ? val.SwapCptyCcy1 : null,
            swapCptyBuySell1:
              val.SwapCptyBuySell1 != null ? val.SwapCptyBuySell1 : null,
            swapCptyAmount2:
              val.SwapCptyAmount2 != null
                ? parseFloat(val.SwapCptyAmount2)
                : null,
            swapCptyCcyType2: val.SwapCptyCcy2 != null ? val.SwapCptyCcy2 : null,
            swapCptyBuySell2:
              val.SwapCptyBuySell2 != null ? val.SwapCptyBuySell2 : null,
            swapSpread: val.SwapSpread != null ? val.SwapSpread : null,
            swapClientRate:
              val.SwapClientRate != null ? parseFloat(val.SwapClientRate) : null,
            swapClientAmount1:
              val.SwapClientAmount1 != null
                ? parseFloat(val.SwapClientAmount1)
                : null,
            swapClientCcy1:
              val.SwapClientCcy1 != null ? val.SwapClientCcy1 : null,
            swapClientBuySell1:
              val.SwapClientBuySell1 != null ? val.SwapClientBuySell1 : null,
            swapClientAmount2:
              val.SwapClientAmount2 != null
                ? parseFloat(val.SwapClientAmount2)
                : null,
            swapClientCcy2:
              val.SwapClientCcy2 != null ? val.SwapClientCcy2 : null,
            swapClientBuySell2:
              val.SwapClientBuySell2 != null ? val.SwapClientBuySell2 : null,
            swapSpreadAmount:
              val.SwapSpreadAmount != null
                ? parseFloat(val.SwapSpreadAmount)
                : null,
            swapSpreadCcy: val.SwapSpreadCcy != null ? val.SwapSpreadCcy : null,
            uti: val.TcUti != null ? val.TcUti : null,
            uniqueLinkID: val.UniqueLinkId != null ? val.UniqueLinkId : null,
            tradeRef: val.id,
            saveDate: val.SaveDate != null ? val.SaveDate : null,
            savetime: val.SaveTime != null ? val.SaveTime : null,
            savedBy: val.SavedBy != null ? val.SavedBy : null,
            amanededBy: val.AmendedBy != null ? val.AmendedBy : null,
            amendmentDate: val.AmendmentDate != null ? val.AmendmentDate : null,
            amendmentReason:
              val.AmendmentReason != null ? val.AmendmentReason : null,
            amendmentTime: val.AmendmentTime != null ? val.AmendmentTime : null,
            cancelDate: val.CancelDate != null ? val.CancelDate : null,
            cancelReason: val.CancelReason != null ? val.CancelReason : null,
            cancelTime: val.CancelTime != null ? val.CancelTime : null,
            cancelled: val.Cancelled != "0" ? val.CancelTime : "1",
            cancelledBy: val.CancelledBy != null ? val.CancelledBy : null,
            calcFromSpread:
              val.CalculateFrom ?? "Calculate Spread From Client Rate",
          };

          if(this.tradeForm.tradeDate != null){
            let tradeDate = new Date(
              this.MDYdateFormat(this.tradeForm.tradeDate)
            );

            //set trade date name
            this.tradeForm.tradeDateName = this.weekday[tradeDate.getDay()];
          }


          if(this.tradeForm.settlementDate != null){
            let settleDate = new Date(
              this.MDYdateFormat(this.tradeForm.settlementDate)
            );

            //set settle date name
            this.tradeForm.settlementDateName = this.weekday[settleDate.getDay()];
          }
          

          if(this.tradeForm.swapSettleDate != null){
            let settleDate = new Date(
            this.MDYdateFormat(this.tradeForm.swapSettleDate)
          );

          //set swap settle date name
          this.tradeForm.swapSettleDateName = this.weekday[settleDate.getDay()];
          }

          if(this.tradeForm.swapTradeDate != null){
            let tradeDate = new Date(
            this.MDYdateFormat(this.tradeForm.swapTradeDate)
          );

          //set swap trade date name
            this.tradeForm.swapTradeDateName = this.weekday[tradeDate.getDay()];
          }

          // this.tradeForm.calcFromSpread = this.optCalcFromSpread[0].value
          // this.tradeForm.swapCalcFromSpread = this.optCalcFromSpread[1].value
          this.tradeForm.cptyCalculateFromRate1 = true
          this.tradeForm.clientCalculateFromRate2 = true


          // if(this.tradeForm.swapSettleDate != null && this.tradeForm.swapTradeDate != null){
          //   const swapSettleDate = new Date(
          //   this.MDYdateFormat(this.tradeForm.swapSettleDate)
          // );
          // const swapTradeDate = new Date(
          //   this.MDYdateFormat(this.tradeForm.swapTradeDate)
          // );
          // const diffTime = Math.abs(swapSettleDate - swapTradeDate);
          // const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

          // this.tradeForm.maturity = diffDays;
          // }

          



          this.oldTradeFormData = JSON.parse(JSON.stringify(this.tradeForm));

          // this.tradeForm.orderDurationDate = this.tradeForm.orderDate
          // this.tradeForm.orderDurationTime = moment()
          // .endOf("day")
          // .format("HH:mm:ss")
          // .toString()

          this.changeTimeInForceType()
          this.arrangeCurrencies();
          this.arrangeBuySell();
          this.arrangeInputFormats();
          
        }
      },
    },
  },
};
</script>

<style>
#tradeModal .modal-header .modal-title {
  color: white !important;
}

.fxDetailRadio .custom-control:nth-child(odd) {
  margin-bottom: 5px;
}

.animate__animated {
  animation-fill-mode: none;
}
.swapSettleName .input-group-append .input-group-text {
  color: red;
}
.swapTradeName .input-group-append .input-group-text {
  color: red;
}
.tradeDateName .input-group-append .input-group-text {
  color: red;
}
.settlementDateName .input-group-append .input-group-text {
  color: red;
}

#actions-popover {
  width: 500px !important;
  max-width: 500px !important;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.change_selectbox_style {
  .vs__dropdown-toggle{
    .vs__selected-options{
      .vs__selected {
        color:orange
       }
    }
  }
}

</style>


<!-- compact design scss code -->

<style lang="scss" scoped>

@import "../../assets/scss/compact-design.scss";


// .v-select::v-deep .vs__selected {
//     position: relative !important;
//   }
</style>


