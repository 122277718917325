<template>
  <div>
    {{ /*view selected trade modal*/ }}
    <b-modal
      id="tradeModal"
      v-model="viewJournalTradeModal"
      :title="$t('fx_spot_forward_swap.titles.view_journal_entries')"
      @hidden="resetTradeModal"
      size="xl"
      :ok-title="$t('fx_spot_forward_swap.modals.view_trade_journal_modal_ok')"
      :cancel-title="$t('fx_spot_forward_swap.modals.modal_cancel')"
      @ok="tradeOkOperation"
      :body-bg-variant="'light'"
      :header-bg-variant="'dark'"
      class="tradeModal"
      body-class="compact_form_modal"
      footer-class="compact_modal_footer"
      no-close-on-backdrop
    >
      <b-overlay :show="tradeModalLoading" rounded="sm" no-fade>
        <b-container>
          <validation-observer ref="createValidation">
            <b-form>
              <b-row class="mt-0">
                <b-col
                  cols="12"
                  xl="12"
                  md="12"
                  sm="12"
                  class="bodyRow mt-1"
                  style="padding: 0.5rem 0;"
                >
                  <b-table
                    striped
                    hover
                    ref="refJournalEntriesTable"
                    class="compact_table"
                    :items="journalEntries"
                    :filter="filter"
                    filter-debounce="250"
                    :filter-included-fields="filterOn"
                    selectable
                    select-mode="single"
                    responsive
                    head-variant="light"
                    :fields="tableColumns"
                    show-empty
                    :empty-text="
                      $t('general_messages.no_matching_recors_found')
                    "
                  >
                    <template #cell(tcUti)="data">
                      <strong>{{ data.item.tcUti }}</strong>
                    </template>

                    <template #cell(accountName)="data">
                      <strong>{{ data.item.accountName }}</strong>
                    </template>

                    <template #cell(accountNumber)="data">
                      <strong>{{ data.item.accountNumber }}</strong>
                    </template>

                    <template #cell(accountCode)="data">
                      <strong>{{ data.item.accountCode }}</strong>
                    </template>

                    <template #cell(settlementDate)="data">
                      <strong>{{ data.item.settlementDate }}</strong>
                    </template>

                    <template #cell(tradeDate)="data">
                      <strong>{{ data.item.tradeDate }}</strong>
                    </template>

                    <template #cell(underlying)="data">
                      <strong>{{ data.item.underlying }}</strong>
                    </template>

                    <template #cell(amount)="data">
                      <strong>{{ data.item.amount }}</strong>
                    </template>

                    <template #cell(price)="data">
                      <strong>{{ data.item.price }}</strong>
                    </template>

                    <template #cell(transactionType)="data">
                      <strong>{{ data.item.transactionType }}</strong>
                    </template>

                    <template #cell(entryDescription)="data">
                      <strong>{{ data.item.entryDescription }}</strong>
                    </template>

                    <template #cell(tradeNote)="data">
                      <strong>{{ data.item.tradeNote }}</strong>
                    </template>
                  </b-table>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-container>
        <template #overlay v-if="tradeModalLoading">
          <div
            class="text-center d-flex justify-content-center align-items-start"
          >
            <b-icon icon="stopwatch" font-scale="3" animation="cylon"> </b-icon>
            <p id="cancel-label">
              <feather-icon icon="DownloadIcon" />
              {{
                $t(
                  "fx_spot_forward_swap.overlay_messages.creating_journal_message"
                )
              }}
            </p>
          </div>
        </template>
      </b-overlay>
      <!-- modal footer -->
      <template #modal-footer>
        <b-row class="d-flex align-items-center" style="flex: 1;">
          <!-- trade actions -->
          <b-col
            cols="6"
            md="6"
            xl="6"
            lg="6"
            sm="6"
            class=" pl-0 d-flex justify-content-start align-items-center"
          >
            <b-button
              id="tradeAction-button"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-primary"
              size="sm"
              class="float-left mb-0"
              :disabled="actionsData == null || actionsData.length == 0"
            >
              <feather-icon
                icon="EyeIcon"
                size="14"
                style="margin-right: 0.2rem;"
              />

              <span class="text-nowrap">{{
                $t("fx_spot_forward_swap.buttons.trade_actions")
              }}</span>
            </b-button>
            <b-popover
              target="tradeAction-button"
              placement="top"
              triggers="click"
              id="actions-popover"
            >
              <template #title>
                {{ $t("equity_bond.titles.trade_actions") }}
              </template>
              <b-table-simple hover small caption-top responsive bordered>
                <b-tbody class="compact_form_actions-table">
                  <b-tr v-for="(action, index) in actionsData" :key="index">
                    <td style="width: 20%;">
                      {{ action.type }}
                    </td>
                    <td style="width: 20%;">
                      {{ action.user }}
                    </td>
                    <td style="width: 30%;">
                      {{ action.date }}
                    </td>
                    <td style="width: 30%;">
                      {{ action.reason }}
                    </td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </b-popover>
          </b-col>
          <!-- trade ok operation buttons -->
          <b-col
            cols="6"
            md="6"
            xl="6"
            lg="6"
            sm="6"
            class=" pr-0 d-flex justify-content-end align-items-center"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="secondary"
              size="sm"
              class="float-right mr-1"
              @click="resetTradeModal"
            >
              {{ $t("fx_spot_forward_swap.modals.modal_close") }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              size="sm"
              class="float-right mr-1"
              @click="tradeOkOperation"
            >
              {{
                $t("fx_spot_forward_swap.modals.view_trade_journal_modal_ok")
              }}
            </b-button>
          </b-col>
        </b-row>
      </template>
      {{/****END*** view journal for trades modal*/}}
    </b-modal>
  </div>
</template>

<script>
import {
  BOverlay,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormRating,
  VBModal,
  BTabs,
  BTab,
  BCardText,
  BIcon,
  BPopover,
} from "bootstrap-vue";
import store from "@/store";
import { onUnmounted } from "@vue/composition-api";
import fXSpotForwardSwapStoreModule from "./FXSpotForwardSwapStoreModule";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, alphaNum, email } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import axios from "axios";
import axiosIns from "@/libs/axios";
import router from "@/router";
import moment from "moment";
import Cleave from "vue-cleave-component";
import "animate.css";
import _ from "lodash";
import { mapState, mapGetters, mapActions } from "vuex";

export default {
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BTabs,
    BTab,
    BCardText,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormRating,
    BIcon,
    BPopover,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    ToastificationContent,
    flatPickr,
    Cleave,
  },

  directives: {
    Ripple,
    "b-modal": VBModal,
  },

  setup() {
    const FXSPOTFORWARDSWAP_APP_STORE_MODULE_NAME = "fxSFS";
    // Register module
    if (!store.hasModule(FXSPOTFORWARDSWAP_APP_STORE_MODULE_NAME))
      store.registerModule(
        FXSPOTFORWARDSWAP_APP_STORE_MODULE_NAME,
        fXSpotForwardSwapStoreModule
      );

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(FXSPOTFORWARDSWAP_APP_STORE_MODULE_NAME))
        store.unregisterModule(FXSPOTFORWARDSWAP_APP_STORE_MODULE_NAME);
    });
  },

  mounted() {},

  data() {
    return {
      viewJournalTradeModal: false,
      tradeModalLoading: false,
      createJournalOperation: false,
      fetchedTradeData: {},
      actionsData: null,
      journalEntries: [],
      filterOn: ["accountName"],
      filter: null,
      tableColumns: [
        {
          key: "tcUti",
          label: this.$t(
            "fx_spot_forward_swap.journal_entries_table.related_tc_uti"
          ),
          class: "text-center table-padding white-space",
        },
        {
          key: "accountName",
          label: this.$t(
            "fx_spot_forward_swap.journal_entries_table.account_name"
          ),
          sortable: false,
          class: "text-center table-padding white-space",
        },
        {
          key: "accountNumber",
          label: this.$t(
            "fx_spot_forward_swap.journal_entries_table.account_number"
          ),
          sortable: false,
          class: "text-center table-padding white-space",
        },
        {
          key: "accountCode",
          label: this.$t(
            "fx_spot_forward_swap.journal_entries_table.account_code"
          ),
          sortable: false,
          class: "text-center table-padding white-space",
        },
        {
          key: "settlementDate",
          label: this.$t(
            "fx_spot_forward_swap.journal_entries_table.value_date"
          ),
          sortable: false,
          class: "text-center table-padding white-space",
        },
        {
          key: "tradeDate",
          label: this.$t(
            "fx_spot_forward_swap.journal_entries_table.trade_date"
          ),
          sortable: false,
          class: "text-center table-padding white-space",
        },
        {
          key: "underlying",
          label: this.$t(
            "fx_spot_forward_swap.journal_entries_table.underlying"
          ),
          sortable: false,
          class: "text-center table-padding white-space",
        },
        {
          key: "amount",
          label: this.$t("fx_spot_forward_swap.journal_entries_table.amount"),
          sortable: false,
          class: "text-center table-padding white-space",
        },
        {
          key: "price",
          label: this.$t("fx_spot_forward_swap.journal_entries_table.price"),
          sortable: true,
          class: "text-center table-padding white-space",
        },
        {
          key: "transactionType",
          label: this.$t(
            "fx_spot_forward_swap.journal_entries_table.transaction_type"
          ),
          sortable: false,
          class: "text-center table-padding white-space",
        },
        {
          key: "entryDescription",
          label: this.$t(
            "fx_spot_forward_swap.journal_entries_table.entry_description"
          ),
          sortable: false,
          class: "text-center table-padding white-space",
        },
        {
          key: "tradeNote",
          label: this.$t("fx_spot_forward_swap.journal_entries_table.note"),
          sortable: false,
          class: "text-center",
        },
      ],
      tradeForm: {
        costCenter: null,
        orderDurationDate: null,
        orderDurationTime: null,
        entryType: null,
        buySell: null,
        orderType: null,
        timeInForce: null,
        orderDate: null,
        orderTime: null,
        cptyOrderDate: null,
        cptyOrderTime: null,
        enableConnectedOCOOrders: false,
        stopPrice: null,
        trailingAmount: null,
        takeProfitLimit: null,
        stopLossStop: null,
        stopLossLimit: null,
        client: null,
        clientAccount: null,
        counterparty: null,
        cptyAccount: null,
        productType: null,
        settlementType: null,
        counterpartyRate: null,
        cptyCalculateFromRate1: true,
        clientRate: null,
        clientCalculateFromRate2: true,
        cptyAmount1: null,
        cptyAmount2: null,
        cptyCcy1: null,
        cptyCcy2: null,
        cptyBuySell1: null, //sfcBuySell1
        cptyBuySell2: null, //sfcBuySell2
        calcFromSpread: "Calculate Spread From Client Rate",
        spread: null,
        clientAmount1: null,
        clientAmount2: null,
        clientCcy1: null,
        clientCcy2: null,
        clientBuySell1: null,
        clientBuySell2: null,
        spotRef: null,
        spreadAmount: null,
        spreadCcy: null,
        comissionAmount: null,
        comissionCcy: null,
        cutOffTime: null,
        deliverableCcy: null,
        tradeDate: null,
        tradeDateName: null,
        settlementDate: null,
        settlementDateName: null,
        executionTime: null,
        counterpartyTrader: null,
        scfUser: null,
        orderTakenVia: null,
        orderGivenThrough: null,
        actingCapaticy: null,
        note: null,
        swapTradeDate: null,
        swapTradeDateName: null,
        maturity: null,
        swapSettleDate: null,
        swapSettleDateName: null,
        swapPoints: null,
        swapCptyRate: null,
        swapCptyAmount1: null,
        swapCptyCcy1: null,
        swapCptyBuySell1: null,
        swapCptyAmount2: null,
        swapCptyCcyType2: null,
        swapCptyBuySell2: null,
        swapSpread: null,
        swapCalcFromSpread: "Calculate Client Rate From Spread",
        swapClientRate: null,
        swapClientAmount1: null,
        swapClientCcy1: null,
        swapClientBuySell1: null,
        swapClientAmount2: null,
        swapClientCcy2: null,
        swapClientBuySell2: null,
        swapSpreadAmount: null,
        swapSpreadCcy: null,
        uti: null,
        uniqueLinkID: null,
        tradeRef: null,
        entryType2: null,
        tradeStart: null,
        tradeEnd: null,
        settleStart: null,
        settleEnd: null,
      },
      timeConfig: {
        enableTime: true,
        noCalendar: true,
        dateFormat: "H:i:s",
        time_24hr: true,
      },
      dateConfig: {
        allowInput: true,
        dateFormat: "d-m-Y",
      },
      cleaveOptions: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: "thousand",
        },
        time: {
          time: true,
          timePattern: ["h", "m", "s"],
        },
      },
    };
  },
  computed: {
    user: {
      get() {
        return this.$store.getters["authUser"];
      },
      set(newValue) {
        return this.$store.dispatch("setUser", newValue);
      },
    },
  },
  methods: {
    ...mapActions(["saveLastSearchFxForwardSwap"]),

    tradeOkOperation(bvModalEvt) {
      bvModalEvt.preventDefault();
      const self = this;
      if (this.createJournalOperation == true) {
        this.$checkAccessRight("FxExecuted", "CreateJournal").then((res) => {
          if (res.data.valid) {
            if (this.tradeForm.uti != null) {
              this.tradeModalLoading = true;
              store
                .dispatch("fxSFS/checkJournalTradeVerifyOperation", {
                  auth: this.user,
                  trade: this.tradeForm,
                })
                .then((res) => {
                  if (res.data.info == "verified") {
                    this.checkJournalCreated(
                      "Journal",
                      this.tradeForm.uti
                    ).then((res) => {
                      if (res.data.info == true) {
                        /// amount & price string to float
                        if (this.journalEntries.length > 0) {
                          this.journalEntries.forEach((entry) => {
                            entry.amount = entry.amount;
                            entry.price = entry.price;
                          });
                        }
                        store
                          .dispatch("fxSFS/createFxJournalEntry", {
                            auth: this.user,
                            entries: this.journalEntries,
                            tcuser: this.tradeForm.scfUser,
                            tcUti: this.tradeForm.uti,
                            tradeId: this.tradeForm.tradeRef,
                            saveDateTime: moment().format(
                              "YYYY-MM-DD HH:mm:ss"
                            ),
                          })
                          .then((res) => {
                            if (res.data.info == "Pass") {
                              this.$emit("createdJournalEntry", true);
                              this.createdJournalEntryMessage();
                              this.resetTradeModal();
                              this.tradeModalLoading = false;
                            } else {
                              if (res.data.info == "ExistingJournal") {
                                this.tradeModalLoading = false;

                                const tableData = res.data.failedProcesses;

                                let tableHTML = `<table class="table table-striped table-hover"><thead><tr>`;

                                tableHTML += `<th>ID</th>`;
                                tableHTML += `<th>TcUti</th>`;

                                tableHTML += `</tr></thead><tbody>`;

                                // Generate table rows
                                tableData.forEach((item) => {
                                  tableHTML += `<tr>`;
                                  tableHTML += `<td><strong>${item["ID"]}</strong></td>`;
                                  tableHTML += `<td><strong>${item["Tcuti"]}</strong></td>`;

                                  tableHTML += `</tr>`;
                                });

                                tableHTML += `</tbody></table>`;

                                this.$swal({
                                  title:
                                    self.$t(
                                      "multiCheckBoxTitles.multiselect_existing_journal_warning"
                                    ) +
                                    " Do you still want to create the journal entries?",
                                  html: tableHTML,

                                  //icon: "warning",
                                  showCancelButton: true,
                                  showLoaderOnConfirm: true,
                                  width: "800px",
                                  inputAttributes: {
                                    style: "margin-top:5px!important",
                                  },
                                  confirmButtonColor: "#3085d6",
                                  cancelButtonColor: "#d33",
                                  confirmButtonText:
                                    "Create with duplicate values",
                                  cancelButtonText: self.$t(
                                    "general_buttons.cancel"
                                  ),
                                  customClass: {
                                    confirmButton: "btn btn-primary mr-1",
                                    cancelButton: "btn btn-danger",
                                    denyButton: "btn btn-warning",
                                    text: "mb-1",
                                  },
                                  showClass: {
                                    popup:
                                      "animate__animated animate__fadeInDown",
                                  },
                                  hideClass: {
                                    popup:
                                      "animate__animated animate__fadeOutUp",
                                  },
                                }).then((result) => {
                                  if (result.isConfirmed) {
                                    store
                                      .dispatch(
                                        "fxSFS/createExistingFXJournalEntry",
                                        {
                                          auth: this.user,
                                          entries: this.journalEntries,
                                          tcuser: this.tradeForm.scfUser,
                                          tcUti: this.tradeForm.uti,
                                          tradeId: this.tradeForm.tradeRef,
                                          saveDateTime: moment().format(
                                            "YYYY-MM-DD HH:mm:ss"
                                          ),
                                        }
                                      )

                                      .then((res) => {
                                        if (res.data.info == "Pass") {
                                          this.$emit(
                                            "createdJournalEntry",
                                            true
                                          );
                                          this.createdJournalEntryMessage();
                                          this.resetTradeModal();
                                          this.tradeModalLoading = false;
                                        } else {
                                          this.wrongToastMessage(
                                            res.data.message,
                                            10000
                                          );
                                          this.tradeModalLoading = false;
                                        }
                                      });
                                  }
                                });
                              } else {
                                this.wrongToastMessage(res.data.message, 10000);
                                this.tradeModalLoading = false;
                              }
                            }
                          })
                          .catch((error) => {
                            this.tradeModalLoading = false;
                            console.log(error);
                          });
                      } else {
                        this.checkJournalCreatedMessage(res.data.message);
                      }
                    });
                  } else {
                    if (res.data.info == "not_verified") {
                      this.checkTradeUnVerifyOperationLangMessage();
                    } else {
                      this.checkTradeVerifyOperationMessage(res.data.message);
                    }
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            } else {
              this.notFoundUTIValue(10000);
            }
          } else {
            this.notAuthToastMessage();
          }
        });
      } else {
        this.resetTradeModal();
      }
    },
    checkJournalCreated(messageType, tcUti) {
      if (tcUti != null && tcUti != "") {
        return new Promise((resolve, reject) => {
          store
            .dispatch("fxSFS/checkTCUtiJournalCreated", {
              auth: this.user,
              type: messageType,
              tcUti: tcUti,
            })
            .then((res) => {
              resolve(res);
            })
            .catch((error) => {
              console.log(error);
              reject(error);
            });
        });
      } else {
        this.errorMessage(
          this.$t("fx_spot_forward_swap.messages.tc_uti_not_found")
        );
      }
    },
    resetTradeModal() {
      this.fetchedTradeData = {};
      this.actionsData = null;
      this.journalEntries = [];
      this.viewJournalTradeModal = false;
      this.createJournalOperation = false;
    },
    databaseErrorMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: this.$t(
            "fx_spot_forward_swap.toast_messages.database_connection_error"
          ),
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    },
    checkTradeVerifyOperationMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    checkTradeUnVerifyOperationLangMessage() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: this.$t("backend_language_message.un_verify_message"),
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    checkJournalOperationsMessage(message, timeout) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Warning",
            text: message,
            icon: "AlertTriangleIcon",
            variant: "warning",
          },
        },
        {
          timeout,
        }
      );
    },
    checkRequiredInputsMessage(message, timeout) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Warning",
            text:
              this.$t("fx_spot_forward_swap.messages.required_fill_inputs") +
              ` ${message}`,
            icon: "AlertTriangleIcon",
            variant: "warning",
          },
        },
        {
          timeout,
        }
      );
    },
    notAuthToastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Not Authorized",
          text: this.$t(
            "fx_spot_forward_swap.general_messages.not_authorized_message"
          ),
          icon: "UserXIcon",
          variant: "warning",
        },
      });
    },
    notFoundUTIValue(timeout) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Not Found UTI",
            text: this.$t("fx_spot_forward_swap.messages.not_found_uti"),
            icon: "AlertTriangleIcon",
            variant: "warning",
          },
        },
        {
          timeout,
        }
      );
    },
    errorMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    checkJournalCreatedMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    },
    wrongToastMessage(message, timeout) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Error",
            text: message,
            icon: "AlertTriangleIcon",
            variant: "warning",
          },
        },
        {
          timeout,
        }
      );
    },
    createdJournalEntryMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Successfully",
          text: this.$t(
            "fx_spot_forward_swap.toast_messages.journal_success_create"
          ),
          icon: "ThumbsUpIcon",
          variant: "success",
        },
      });
    },
    oncreatedJournalEntryArrayed(createdJournalEntryArrayedItems) {
      this.totalRows = createdJournalEntryArrayedItems.length;
      this.currentPage = 1;
    },
    clearDate() {
      this.newHoliday.date = null;
    },
    DMYdateFormat(val) {
      if (val != undefined) {
        return moment(String(val), "DD-MM-YYYY").format("DD-MM-YYYY");
      }
    },
    YMDdateFormat(val) {
      if (val != undefined) {
        return moment(String(val), "DD-MM-YYYY").format("YYYY-MM-DD");
      }
    },
    dateFormat(val) {
      if (val != undefined) {
        return moment(String(val)).format("DD-MM-YYYY");
      }
    },
    timeFormat(val) {
      if (val != undefined) {
        return moment(String(val), "HH:mm:ss")._i;
      }
    },
    HmstimeFormat(val) {
      if (val != undefined) {
        return moment(String(val)).format("HH:mm:ss");
      }
    },
    dateFormatWithTime(val) {
      if (val != undefined) {
        return moment(String(val), "DD-MM-YYYY HH:mm:ss").format(
          "DD-MM-YYYY HH:mm:ss"
        );
      }
    },
    formatPrice(value, val) {
      if (value != "") {
        let val = (value / 1).toFixed(2).replace(",", ".");
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
  },
  watch: {
    viewJournalTradeModal: {
      handler: function(val, before) {
        if (val) {
        }
      },
    },
    fetchedTradeData: {
      handler: function(val, before) {
        if (val.ID != 0) {
          this.tradeForm = val;
        }
      },
    },
  },
};
</script>

<style>
#tradeModal .modal-header .modal-title {
  color: white !important;
}

.white-space {
  white-space: nowrap;
}

#actions-popover {
  width: 500px !important;
  max-width: 500px !important;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
